import { createModel, RematchRootState } from "@rematch/core";
import { RootModel } from ".";
import {
  EnumerationClass,
  EnumerationInfo,
  FileValue,
  isSubject,
  Layout,
  Link,
  OperationType,
  RawLink,
  ReportParams,
  SaveStateType,
  Subject,
  SubjectCardInfo,
  SubjectData,
  SubjectDiff,
  SubjectState,
  UploadMetaData,
  UploadTask,
  Value,
} from "../types/subject";
import * as c from "../constants/subject";
import { FetchError, isFetchError } from "../types/error";
import {
  ALERT_LEVEL_DANGER,
  ALERT_LEVEL_SUCCESS,
  ALERT_LEVEL_WARNING,
} from "../constants/alert";
import { copyTextToClipboard } from "../services/clipboard";
import { dispatchError } from "../services/alert";
import { FragmentData } from "../types/fragment";
import { ServerError } from "../actions/utils";
import {
  ajaxUploadFiles,
  cancelSave,
  changeTab,
  checkLayoutFetchNeeded,
  checkSavePossible,
  clearUploadTasks,
  downloadFile,
  downloadFileImpl,
  enqueUpload,
  fetchEnumeration,
  fetchLayoutImpl,
  fetchLockImpl,
  fetchNewSubject,
  fetchSubjectImpl,
  fetchValidateLink,
  filterReferences,
  getLockStatus,
  getUploadTaskList,
  isSubjectEditing,
  layoutReceived,
  linkSubject,
  makeDiff,
  makeForm,
  makeLayout,
  makeState,
  mergeSubjectValues,
  nodeUpdated,
  obtainData,
  parseEnumeration,
  refreshLinkSubject,
  removeSubjectFromStore,
  runPreSave,
  safeFetchValidateLink,
  saveSubjectImpl,
  subjectAddedFire,
  subjectChangeData,
  subjectReceived,
  syncDataAndLayout,
  toUrlSearchParams,
  validateLinks,
  wrapSubjectData,
} from "../services/subject";
import {
  I18NString,
  ModalOptions,
  TableModalOptions,
  TreeModalOptions,
} from "../types/modal";
import { isEmptyObject } from "../services/app";
import { retrieveFunction } from "../services/automation";
import { parseFragmentList, parseFragments } from "./fragments";
export const DEFAULT_SUBJECT_STATE: SubjectState = {
  subjects: {},
  layouts: {},
  loading: {},
  enumerations: {},
  layoutsStatus: {},
  layoutsLoading: {},
  changeLoading: {},
  saveState: {},
  loadingLock: {},
};

const defaultState = DEFAULT_SUBJECT_STATE;

export const subject = createModel<RootModel>()({
  state: defaultState,
  reducers: {
    sendSubjectDestroy(state, subjectKey: string) {
      const newState: SubjectState = { ...state };
      delete newState.subjects[subjectKey];
      delete newState.saveState[subjectKey];
      delete newState.changeLoading[subjectKey];
      delete newState.loading[subjectKey];
      return newState;
    },
    sendSubjectStartSave(state, subjectKey: string) {
      // const saveState: { [SUBJECT_KEY: string]: SaveStateType } = { ...state.saveState, [subjectKey]: constants.SAVE_STATE_START }

      const current = state.saveState[subjectKey];
      if (current && current != c.SAVE_STATE_SHOW_ERRORS) {
        //Do not change if state is already in progress
        return state;
      }
      const subject = state.subjects[subjectKey];
      if (!isSubject(subject)) {
        return state;
      }
      const layout = state.layouts[subject.className];
      const valid =
        layout.rootId && subject.validation[layout.rootId] ? false : true; //check error for rootId
      const saveState: { [SUBJECT_KEY: string]: SaveStateType } = Object.assign(
        {},
        state.saveState,
        {
          [subjectKey]: valid ? c.SAVE_STATE_START : c.SAVE_STATE_SHOW_ERRORS,
        }
      );
      return { ...state, saveState };
    },
    sendFragmentLoading(state, loading: boolean = true) {
      const fragmentTree = { ...state.fragmentTree };
      if (!fragmentTree) {
        return state;
      }
      fragmentTree.treeLoading = loading;
      return { ...state, fragmentTree };
    },
    sendFragmentTree(
      state,
      payload: {
        l: FragmentData[];
        r?: string;
      }
    ) {
      const { l, r } = payload;
      const fragmentState = state.fragmentTree || {};
      return { ...state, fragmentTree: parseFragmentList(fragmentState, l, r) };
    },
    sendFragmentError(state, payload: { error: FetchError }) {
      const { error } = payload;
      const fragmentTree = { ...state.fragmentTree };
      if (!fragmentTree) {
        return state;
      }
      fragmentTree.error = error;
      return { ...state, fragmentTree };
    },
    sendFragmentSelected(state, id?: string) {
      const fragmentTree = { ...state.fragmentTree };
      if (!fragmentTree) {
        return state;
      }
      fragmentTree.selected = id;
      return { ...state, fragmentTree };
    },
    sendSubject(
      state,
      payload: {
        subjectKey: string;
        subject: Subject;
        operation: OperationType;
        notifyId: any;
        diff?: SubjectDiff;
      }
    ) {
      const { subject, subjectKey, notifyId, operation, diff } = payload;
      return subjectReceived(
        state,
        subjectKey,
        operation,
        subject,
        notifyId,
        diff
      );
    },
    sendLayoutLoading(
      state,
      payload: {
        subjectClass: string;
        loading: boolean;
      }
    ) {
      const { subjectClass, loading } = payload;
      const layoutsLoading = {
        ...state.layoutsLoading,
        [subjectClass]: loading,
      };
      const layoutsStatus = { ...state.layoutsStatus };
      layoutsStatus[subjectClass] = c.STATUS_LOADING;
      return { ...state, layoutsLoading, layoutsStatus };
    },
    sendEnumerationLoading(state, className: string) {
      const enumerationState: EnumerationClass = {
        loading: true,
        error: false,
        enumerationInfo: null,
      };
      const enumerations = {
        ...state.enumerations,
        [className]: enumerationState,
      };
      return { ...state, enumerations };
    },
    sendEnumeration(
      state,
      payload: { className: string; data: EnumerationInfo }
    ) {
      const enumerationState: EnumerationClass = {
        loading: false,
        error: false,
        enumerationInfo: payload.data,
      };
      const enumerations = {
        ...state.enumerations,
        [payload.className]: enumerationState,
      };
      return { ...state, enumerations };
    },
    sendEnumerationError(
      state,
      payload: {
        className: string;
        error: any;
      }
    ) {
      const enumerationState: EnumerationClass = {
        loading: false,
        error: true,
        enumerationInfo: null,
      };
      const enumerations = {
        ...state.enumerations,
        [payload.className]: enumerationState,
      };
      return { ...state, enumerations };
    },
    sendCachedLayout(
      state,
      payload: {
        className: string;
        subjectKey: string;
        layout: Layout;
        storeDiffList?: SubjectDiff[];
      }
    ) {
      const { className, subjectKey, layout, storeDiffList } = payload;
      return layoutReceived(
        state,
        subjectKey,
        className,
        layout,
        storeDiffList
      );
    },
    sendSubjectLoading(
      state,
      payload: {
        subjectKey: string;
        loading: boolean;
      }
    ) {
      const { subjectKey, loading: l } = payload;
      const loading = { ...state.loading, [payload.subjectKey]: l };
      return { ...state, loading };
    },
    sendSubjectChangeLoading: (state, subjectKey: string) => {
      const subjects = { ...state.subjects };
      const subject = subjects[subjectKey];
      if (!isSubject(subject)) {
        return state;
      }
      return {
        ...state,
        changeLoading: { ...state.changeLoading, [subjectKey]: true },
      };
    },
    sendSubjectChangeData: (
      state,
      payload: {
        data: SubjectDiff;
        nodeId: string;
      }
    ) => {
      const subjectKey = payload.data.subjectKey;
      const nodeId = payload.nodeId;
      const middleState = nodeUpdated(state, {
        subjectKey,
        nodeId,
        updated: true,
      });
      return subjectChangeData(middleState, payload.data);
    },
    sendSubjectChangeSaveState: (
      state,
      payload: {
        subjectKey: string;
        saveState: SaveStateType;
      }
    ) => {
      const { subjectKey, saveState: sstate } = payload;
      const saveState = { ...state.saveState, [subjectKey]: sstate };
      const loading = { ...state.loading };
      if (sstate === "show_errors") {
        loading[subjectKey] = false;
      }
      return { ...state, loading, saveState };
    },
    sendSubjectSaveCancel: (state, subjectKey: string) => {
      const subject = state.subjects[subjectKey];
      if (isSubject(subject) && subject.subjectData.$isNew) {
        return removeSubjectFromStore(state, subjectKey);
      }
      return cancelSave(state, subjectKey);
    },
    sendSubjectSaveWait: (state, subjectKey: string) => {
      const saveState: { [SUBJECT_KEY: string]: SaveStateType } = {
        ...state.saveState,
        [subjectKey]: c.SAVE_STATE_WAIT_SERVER,
      };
      return { ...state, saveState };
    },
    sendNewSubject(state, payload: { rdfId: string; isNew: boolean }) {
      const { rdfId, isNew } = payload;
      const newSubjects: { [SUBJECT_KEY: string]: boolean } = {
        ...state.newSubjects,
        [rdfId]: isNew,
      };
      if (isNew) {
        newSubjects[rdfId] = true;
      } else {
        delete newSubjects[rdfId];
      }
      const nextState: SubjectState = { ...state, newSubjects };
      if (isEmptyObject(newSubjects)) {
        delete nextState.newSubjects;
      }
      return nextState;
    },
    sendEditingSubject(
      state,
      payload: {
        rdfId: string;
        isEditing: boolean;
      }
    ) {
      const { rdfId, isEditing } = payload;
      const editingSubjects = { ...state.editingSubjects };
      if (isEditing) {
        editingSubjects[rdfId] = true;
      } else {
        delete editingSubjects[rdfId];
      }
      const nextState: SubjectState = { ...state, editingSubjects };
      if (isEmptyObject(editingSubjects)) {
        delete nextState.editingSubjects;
      }
      return nextState;
    },
    sendSubjectSaveDone(state, subjectKey: string) {
      const saveState: { [SUBJECT_KEY: string]: SaveStateType } = {
        ...state.saveState,
      };
      const loading = { ...state.loading, [subjectKey]: false };
      delete saveState[subjectKey];
      const subject = state.subjects[subjectKey];

      if (subject && isSubject(subject) && subject.subjectData.$isNew) {
        const subjects = { ...state.subjects };
        delete subjects[subjectKey];
        return { ...state, subjects, saveState, loading };
      }
      return { ...state, saveState, loading };
    },
    sendSubjectLockLoading(
      state,
      payload: {
        subjectKey: string;
        loading: boolean;
      }
    ) {
      const { subjectKey, loading: l } = payload;
      const loadingLock = {
        ...state.loadingLock,
        [subjectKey]: l,
      };
      return { ...state, loadingLock };
    },
    sendRemoveSubjectFromStore(state, subjectKey: string) {
      return removeSubjectFromStore(state, subjectKey);
    },
    sendSubjectChangeTab(
      state,
      payload: {
        subjectKey: string;
        navId: string;
        tabId: string;
      }
    ) {
      const { navId, subjectKey, tabId } = payload;
      return changeTab(state, subjectKey, tabId, navId);
    },
    sendNodeLoading(
      state,
      payload: {
        subjectKey: string;
        nodeId: string;
        loading: boolean;
      }
    ) {
      const { loading, subjectKey, nodeId } = payload;

      const nextState: SubjectState = { ...state };
      const newSubject = { ...nextState.subjects[subjectKey] };
      if (!isFetchError(newSubject)) {
        const newLoading = { ...newSubject.loading, [nodeId]: loading };
        newSubject.loading = newLoading;
        nextState.subjects[subjectKey] = newSubject;
        return nextState;
      } else {
        return state;
      }
    },
    resetToDefault: (state) => {
      return DEFAULT_SUBJECT_STATE;
    },
  },
  effects: (dispatch) => ({
    startSave: async (subjectKey: string, state) => {
      dispatch.subject.sendSubjectStartSave(subjectKey);
      const current = state.subject?.saveState[subjectKey];
      const subject = state.subject?.subjects[subjectKey];

      if (
        !isFetchError(subject) &&
        subject.validation &&
        current &&
        current === c.SAVE_STATE_SHOW_ERRORS
      ) {
        for (let val of Object.values(subject.validation)) {
          if (val?.id === "OBJECTCARD_FIELD_IS_MANDATORY") {
            //TODO:Add alert
            dispatch.alert.addAlert({
              type: ALERT_LEVEL_DANGER,
              message: {
                id: "OBJECTCARD_MANDATORY_NOT_PROVIDED",
              },
            });
            return;
          }
          if (val) {
            //TODO:Add alert
            dispatch.alert.addAlert({
              type: ALERT_LEVEL_DANGER,
              message: {
                id: "OBJECTCARD_VALIDATION_FAILED",
              },
            });
            return;
          }
        }
      }
    },
    copy: (value: string, state) => {
      copyTextToClipboard(value);
      dispatch.alert.addAlert({
        type: ALERT_LEVEL_SUCCESS,
        message: {
          id: "OBJECTCARD_COPY_SUCCESS",
        },
      });
    },
    getFragmentsBranch: async (rdfId: string, state) => {
      try {
        const nodeById = state.subject?.fragmentTree?.nodeById;
        const path = await dispatch.fragments.fetchFragmentsPathImpl(rdfId);

        if (!path || !path.length) {
          return;
        }

        path.forEach((p: string) => {
          if (!nodeById || !nodeById[p]) {
            dispatch.subject.fetchFragments(p);
          }
        });
      } catch (e) {
        dispatchError("FRAGMENT_SAVE_ERROR", e, dispatch);
      }
    },
    fetchFragments: async (parentId?: string) => {
      try {
        dispatch.subject.sendFragmentLoading();
        const fragmentTreeBranch = await dispatch.fragments.fetchFragmentsImpl(
          parentId
        );
        const { l, r } = fragmentTreeBranch;
        dispatch.subject.sendFragmentTree({ l: parseFragments(l), r });
      } catch (e: any) {
        dispatch.subject.sendFragmentLoading(false);
        if (e instanceof ServerError) {
          dispatch.subject.sendFragmentError({
            error: { code: e.code, message: e.message },
          });
        } else if (typeof e.message == "string") {
          dispatch.subject.sendFragmentError({
            error: { code: -1, message: e.message },
          });
        } else {
          dispatch.subject.sendFragmentError({
            error: { code: -1, message: "Unknown error" },
          });
        }
      }
    },
    mergeSubjectAndLayout: async (
      data: {
        subjectKey: string;
        subjectData: SubjectData;
        operation: OperationType;
        notifyId?: string;
      },
      state
    ) => {
      const { subjectKey, subjectData, operation, notifyId } = data;
      const className = subjectData.$class;
      let cachedLayout = state.subject?.layouts[className];
      dispatch.subject.fetchFragments();

      let subject: Subject = wrapSubjectData(subjectData);
      // subject.fragmentList = fragmentData.list;

      let diff; //Data difference
      if (cachedLayout) {
        // subject = { ...subject, ...cachedLayout };
        diff = syncDataAndLayout(subjectKey, subject, cachedLayout, true);
      }
      dispatch.subject.sendSubject({
        subjectKey,
        subject,
        operation,
        notifyId,
        diff,
      });
      if (/*state &&*/ checkLayoutFetchNeeded(className, state.subject)) {
        dispatch.subject.sendLayoutLoading({
          subjectClass: className,
          loading: true,
        });
        const parts = className.split(":");
        try {
          cachedLayout = await fetchLayoutImpl(parts[0], parts[1]);

          dispatch.subject.fetchLayoutEnumerations({
            className,
            layout: cachedLayout,
          });
          dispatch.subject.sendLayout({ subject, cachedLayout, subjectKey });
        } catch (e) {
          dispatch.subject.sendLayoutLoading({
            subjectClass: className,
            loading: false,
          });
          dispatchError("SUBJECT_LAYOUT_LOADING_ERROR", e, dispatch, {
            name: className,
          });
        }
      }
    },
    fetchLayoutEnumerations: (
      data: {
        className: string;
        layout: Layout;
      },
      s
    ) => {
      const state = s.subject;
      const { className, layout } = data;
      // if (!state) {
      //     return;
      // }

      for (let enumerationClsName in layout.enumerationsMap) {
        if (
          state.enumerations[enumerationClsName] &&
          !isFetchError(state.enumerations[enumerationClsName]) &&
          !(state.enumerations[enumerationClsName] as EnumerationClass).error
        ) {
          continue;
        }
        // ajaxFetchEnumeration(dispatch, enumerationClsName);

        dispatch.subject.ajaxFetchEnumeration(enumerationClsName);
      }
    },
    ajaxFetchEnumeration: async (className: string) => {
      dispatch.subject.sendEnumerationLoading(className);
      try {
        dispatch.subject.sendEnumeration({
          className,
          data: parseEnumeration(await fetchEnumeration(className)),
        });
      } catch (e) {
        dispatch.subject.sendEnumerationError({ className, error: e });
        dispatchError("OBJECTCARD_LOADING_ENUMERATIONS_FAILD", e, dispatch);
      }
    },
    sendLayout: (
      data: {
        subject: Subject;
        cachedLayout: Layout;
        subjectKey: string;
      },
      s
    ) => {
      const state = s.subject;
      const { subject, cachedLayout, subjectKey } = data;
      const { subjectData } = subject;

      let storeDiff = [];
      if (state) {
        for (let rdfId in state.subjects) {
          const subj = state.subjects[rdfId];
          if (isSubject(subj) && subj.className == subjectData.$class) {
            const diff = syncDataAndLayout(rdfId, subj, cachedLayout, false);
            const { lock, validation, values, visibility } = diff;
            if (lock || validation || values || visibility) {
              storeDiff.push(
                syncDataAndLayout(rdfId, subj, cachedLayout, false)
              );
            }
          }
        }
      }
      dispatch.subject.sendCachedLayout({
        className: subject.className,
        subjectKey,
        layout: cachedLayout,
        storeDiffList: storeDiff,
      });
    },
    fetchSubject: async (subjectKey: string, s) => {
      const state = s.subject;
      if (!subjectKey || !state || state.loading[subjectKey]) {
        return;
      }
      const subject = state.subjects[subjectKey];
      if (isSubjectEditing(subject)) {
        return;
      }
      dispatch.subject.sendSubjectLoading({ subjectKey, loading: true });
      try {
        const parts = subjectKey.split(":");
        const subjectData =
          parts.length > 1
            ? await fetchSubjectImpl(parts[1], parts[0])
            : await fetchSubjectImpl(subjectKey);
        dispatch.subject.mergeSubjectAndLayout({
          subjectKey,
          subjectData,
          operation: c.SUBJECT_OPERATION_GET,
        });
      } catch (e) {
        dispatch.subject.sendSubjectLoading({ subjectKey, loading: false });
        dispatchError("SUBJECT_FETCH_ERROR", e, dispatch, { name: subjectKey });
      }
    },
    initializeStore: async (
      data: {
        subjectKey: string;
        subjectData: SubjectData;
        layoutClass?: any;
      },
      s
    ) => {
      const { subjectKey, subjectData, layoutClass } = data;
      dispatch.subject.sendSubjectLoading({ subjectKey, loading: true });
      const state = s.subject;
      const className = subjectData.$class;
      let cachedLayout = state && state.layouts[className];
      const layout = cachedLayout ? cachedLayout : makeLayout(layoutClass);

      // const layout = await getLayout(subjectData.$class as string, dispatch, getState);
      let fragmentData: any = [];
      try {
        fragmentData = await dispatch.fragments.fetchFragmentsImpl();
      } catch (e) {
        dispatchError("SUBJECT_FRAGMENT_FETCH_ERROR", e, dispatch);
      }

      const fragments = !fragmentData
        ? []
        : fragmentData.list
        ? fragmentData.list
        : fragmentData;
      let subject: Subject = wrapSubjectData(subjectData);

      let diff; //Data difference
      if (layout) {
        // subject = { ...subject, ...cachedLayout };
        diff = syncDataAndLayout(subjectKey, subject, layout, true);
      }
      dispatch.subject.sendSubject({
        subjectKey,
        subject,
        operation: c.SUBJECT_OPERATION_INIT,
        notifyId: null,
        diff,
      });

      //Fetch layout if needed
      if (state && checkLayoutFetchNeeded(className, state)) {
        dispatch.subject.sendLayoutLoading({
          subjectClass: className,
          loading: true,
        });
        const parts = className.split(":");
        try {
          cachedLayout = await fetchLayoutImpl(parts[0], parts[1]);
          dispatch.subject.fetchLayoutEnumerations({
            className,
            layout: cachedLayout,
          });
          dispatch.subject.sendLayout({ subject, cachedLayout, subjectKey });
        } catch (e) {
          dispatch.subject.sendSubjectLoading({ subjectKey, loading: false });
          dispatch.subject.sendLayoutLoading({
            subjectClass: className,
            loading: false,
          });
          dispatchError("SUBJECT_LAYOUT_LOADING_ERROR", e, dispatch, {
            name: className,
          });
          console.error(e);
        }
      }
    },
    createNewSubject: async (
      data: {
        subjectKey: string;
        className: string;
        parent?: string;
        parentRef?: string;
        prototype?: string;
        notifyId?: string;
        initialData?: any;
      },
      state
    ) => {
      const {
        subjectKey,
        className,
        parent,
        parentRef,
        prototype,
        notifyId,
        initialData,
      } = data;
      try {
        const subject = await fetchNewSubject(
          subjectKey,
          className,
          parent,
          parentRef,
          prototype,
          initialData
        );
        dispatch.subject.addNewSubject({
          subjectKey,
          subjectData: subject,
          notifyId: notifyId || "",
        });
      } catch (e) {
        console.error(e);
        dispatch.subject.sendSubjectLoading({ subjectKey, loading: false });
        dispatch.subject.sendLayoutLoading({
          subjectClass: className,
          loading: false,
        });
        dispatchError("SUBJECT_LAYOUT_LOADING_ERROR", e, dispatch, {
          name: className,
        });
      }
    },
    addNewSubject: async (
      data: {
        subjectKey: string;
        subjectData: SubjectData;
        notifyId: string;
      },
      state
    ) => {
      const { subjectKey, subjectData, notifyId } = data;

      dispatch.subject
        .mergeSubjectAndLayout({
          subjectKey,
          subjectData,
          operation: c.SUBJECT_OPERATION_CREATE,
          notifyId,
        })
        .then((result) => {
          dispatch.subject.connectRefPredicates(subjectKey);
        });

      /**Fetch ref subjects data that added to subject manually */
    },
    connectRefPredicates: (subjectKey: string, s) => {
      const subjectsStore = s.subject.subjects;
      const subject = subjectsStore[subjectKey];
      if (!subject || isFetchError(subject)) {
        return;
      }

      /**Find all references without label and fetch subjects to fill values */
      const updatePredicatesBySubject: {
        [k: string]: (string | { predicateId: string; idx: number })[];
      } = {};
      for (let predicateId in subject.values) {
        const value = subject.values[predicateId];
        if (!value) {
          continue;
        }

        if (!Array.isArray(value)) {
          if (typeof value !== "object" || !value.$rdfId || value.$label) {
            continue;
          }
          const subjectKey = value.$namespace
            ? `${value.$namespace}:${value.$rdfId}`
            : value.$rdfId;
          if (!updatePredicatesBySubject[subjectKey]) {
            updatePredicatesBySubject[subjectKey] = [];
          }
          updatePredicatesBySubject[subjectKey].push(predicateId);
          continue;
        }
        for (let i = 0; i < value.length; ++i) {
          const item = value[i];
          if (typeof item !== "object" || !item.$rdfId || item.$label) {
            continue;
          }

          const subjectKey = item.$namespace
            ? `${item.$namespace}:${item.$rdfId}`
            : item.$rdfId;
          if (!updatePredicatesBySubject[subjectKey]) {
            updatePredicatesBySubject[subjectKey] = [];
          }

          updatePredicatesBySubject[subjectKey].push({ predicateId, idx: i });
        }
      }

      for (let fetchSubjectKey in updatePredicatesBySubject) {
        const fetchStoreSubject = subjectsStore[fetchSubjectKey];

        dispatch.subject.updatePredicatesSubjectData({
          subjectKey,
          fetchSubjectKey,
          predicates: updatePredicatesBySubject[fetchSubjectKey],
          storedSubject: fetchStoreSubject,
        });
      }
    },
    updatePredicatesSubjectData: async (
      data: {
        subjectKey: string;
        fetchSubjectKey: string;
        predicates: (string | { predicateId: string; idx: number })[];
        storedSubject?: Subject | FetchError;
      },
      state
    ) => {
      const { subjectKey, fetchSubjectKey, predicates, storedSubject } = data;
      const parts = fetchSubjectKey.split(":");

      try {
        const subjectData =
          parts.length > 1
            ? await fetchSubjectImpl(parts[1], parts[0])
            : await fetchSubjectImpl(fetchSubjectKey);
        const predicateData = {
          $namespace: subjectData.$namespace,
          $rdfId: subjectData.$rdfId,
          $label: subjectData.$label,
          $description: subjectData.$description,
        };
        for (let predicate of predicates) {
          if (typeof predicate === "string") {
            dispatch.subject.change({
              subjectKey,
              nodeId: predicate,
              data: predicateData,
            });
            continue;
          }
          dispatch.subject.changeAt({
            subjectKey,
            nodeId: predicate.predicateId,
            index: predicate.idx,
            data: predicateData,
          });
        }
      } catch (e) {}
    },
    change: <T>(
      payload: {
        subjectKey: string;
        nodeId: string;
        data: T;
        options?: any;
      },
      s: RematchRootState<RootModel, Record<string, never>>
    ) => {
      const { subjectKey, nodeId, data, options } = payload;
      const state = s.subject;
      const subject = state && state.subjects[subjectKey];
      if (!state || !subject || !isSubject(subject)) {
        return;
      }
      const layout = state.layouts[subject.className];
      if (!layout) {
        return;
      }
      dispatch.subject.sendSubjectChangeLoading(subjectKey);
      const valuesDiff = { [nodeId]: data };
      const invalidFormat =
        options && options.invalidFormat
          ? { [nodeId]: options.invalidFormat }
          : undefined;
      const currentState = makeState(subject);
      //Dispatch difference
      // const layout = card.layoutCache[card.data[store].$class];
      dispatch.subject.sendSubjectChangeData({
        data: makeDiff(
          subjectKey,
          layout,
          subject,
          valuesDiff,
          currentState,
          invalidFormat
        ),
        nodeId,
      });
    },
    changeAt: <T>(
      payload: {
        subjectKey: string;
        nodeId: string;
        index: number;
        data: T;
      },
      s: RematchRootState<RootModel, Record<string, never>>
    ) => {
      const { subjectKey, nodeId, index, data } = payload;
      const state = s.subject;

      const subject = state && state.subjects[subjectKey];
      if (!state || !subject || !isSubject(subject)) {
        return;
      }
      const layout = state.layouts[subject.className];
      if (!layout) {
        return;
      }
      const values = subject.values || {}; //Get current values
      const valuesDiff: { [nodeId: string]: any } = {};
      if (Array.isArray(values[nodeId])) {
        valuesDiff[nodeId] = values[nodeId].map(
          (x: any, currentIndex: number) => {
            if (index != currentIndex) {
              return x;
            }
            return data;
          }
        );
      } else {
        console.error("changeAt is not an array", values[nodeId]);
      }
      //Dispatch difference
      // const layout = card.layoutCache[card.data[store].$class];
      const currentState = makeState(subject);
      dispatch.subject.sendSubjectChangeData({
        data: makeDiff(subjectKey, layout, subject, valuesDiff, currentState),
        nodeId,
      });
    },
    add: <T>(
      payload: {
        subjectKey: string;
        nodeId: string;
        data: T;
      },
      s: RematchRootState<RootModel, Record<string, never>>
    ) => {
      const { subjectKey, nodeId, data } = payload;
      const state = s.subject;
      const subject = state && state.subjects[subjectKey];
      if (!state || !subject || !isSubject(subject)) {
        return;
      }
      dispatch.subject.sendSubjectChangeLoading(subjectKey);
      const layoutClass = subject.subjectData.$class;
      const layout = state.layouts[layoutClass];

      const values = subject.values || {}; //Get current values
      const valuesDiff: any = {};
      if (Array.isArray(values[nodeId])) {
        // valuesDiff[nodeId] = [data.values[nodeId]];
        valuesDiff[nodeId] = [...values[nodeId], data];
      } else {
        if (!values[nodeId]) {
          values[nodeId] = [];
        }
        // valuesDiff[nodeId] = values[nodeId].concat(data.values[nodeId]);
        valuesDiff[nodeId] = values[nodeId].concat(data);
      }
      //Dispatch difference
      const currentState = makeState(subject);
      dispatch.subject.sendSubjectChangeData({
        data: makeDiff(subjectKey, layout, subject, valuesDiff, currentState),
        nodeId,
      });
    },
    patchUpload: (
      data: {
        subjectKey: string;
        nodeId: string;
        path: string[];
        sha1: string;
      },
      s
    ) => {
      const { subjectKey, nodeId, path, sha1 } = data;
      const state = s.subject;
      const subject = state && state.subjects[subjectKey];
      if (!state || !subject || !isSubject(subject)) {
        return;
      }
      const layout = state.layouts[subject.className];
      if (!layout) {
        return;
      }
      const values = subject.values || {}; //Get current values
      const valuesDiff: { [nodeId: string]: any } = {};
      if (path.length == 0) {
        //Shallow copy
        valuesDiff[nodeId] = Object.assign({}, values[nodeId], { $sha1: sha1 });
        delete valuesDiff[nodeId].$uploadKey;
      } else {
        //Make deep copy
        const current = values[nodeId];
        let obj;
        if (Array.isArray(current)) {
          obj = [];
          for (let f of current) {
            obj.push({ ...f });
          }
        } else {
          obj = { ...current };
        }
        valuesDiff[nodeId] = obj;
        for (let p of path) {
          obj = obj[p];
        }
        obj.$sha1 = sha1;
        delete obj.$uploadKey;
      }
      //Dispatch difference
      // const layout = card.layoutCache[card.data[store].$class];
      const currentState = makeState(subject);
      dispatch.subject.sendSubjectChangeData({
        data: makeDiff(subjectKey, layout, subject, valuesDiff, currentState),
        nodeId,
      });
    },
    uploadFiles: (
      data: {
        subjectKey: string;
        handleUploadProgress: (progress: number) => void;
      },
      s: RematchRootState<RootModel, Record<string, never>>
    ) => {
      const { subjectKey, handleUploadProgress } = data;
      const state = s.subject;
      const subject = state && state.subjects[subjectKey];
      if (!state || !subject || !isSubject(subject)) {
        return;
      }
      const values = subject.values;
      // const uploadTasks = getUploadTaskList(values);
      const uploadTasks: UploadTask[] = getUploadTaskList(values);

      if (uploadTasks.length > 0) {
        //With uploads
        // (task, data) => dispatch(patchUpload(subjectKey, task.nodeId, task.path, data.sha1))
        Promise.all(
          ajaxUploadFiles(
            uploadTasks,
            (task, sha1) =>
              dispatch.subject.patchUpload({
                subjectKey,
                nodeId: task.nodeId,
                path: task.path,
                sha1,
              }),
            handleUploadProgress
          )
        ).then(
          (uploads: UploadMetaData[]) => {
            clearUploadTasks(uploadTasks);
            dispatch.subject.sendSubjectChangeSaveState({
              subjectKey,
              saveState: c.SAVE_STATE_UPLOADS_READY,
            });
          },
          (error) => {
            //Uploads error
            const saveState = state.saveState[subjectKey];
            if (saveState) {
              //If save was in progress then show error
              dispatchError("OBJECTCARD_UPLOAD_FAILED", error, dispatch);
              dispatch.subject.sendSubjectSaveCancel(subjectKey);
            } else {
              console.log("Upload was aborted!!!");
            }
          }
        );
      } else {
        //Without uploads

        dispatch.subject.sendSubjectChangeSaveState({
          subjectKey,
          saveState: c.SAVE_STATE_UPLOADS_READY,
        });
      }
    },
    saveSubject: (
      data: {
        subjectKey: string;
        deffered?: any;
        saveCallback?: (subject: SubjectData) => void;
      },
      s
    ) => {
      const state = s.subject;
      const { subjectKey, deffered, saveCallback } = data;
      const subject = state && state.subjects[subjectKey];
      if (
        !state ||
        !subject ||
        !isSubject(subject) ||
        state.loading[subjectKey]
      ) {
        return;
      }
      dispatch.subject.sendSubjectLoading({ subjectKey, loading: true });
      const layout = state.layouts[subject.className];
      if (deffered) {
        //Make deep copy of data
        const newSubjectData = mergeSubjectValues(
          subject,
          layout,
          subject.values
        );
        deffered.resolve(newSubjectData);
      } else if (checkSavePossible(subject, subjectKey, state) && layout) {
        runPreSave(layout, async (changedPredicates: Value) => {
          //Get notify id
          const notifyId = subject.notifyId;
          //Make deep copy of data
          const diffValue: Value = { ...subject.values, ...changedPredicates };
          const newSubjectData = mergeSubjectValues(subject, layout, diffValue);
          if (!saveCallback) {
            dispatch.subject.sendSubjectSaveWait(subjectKey);
          }
          try {
            const subjectData = await saveSubjectImpl(newSubjectData);

            subject.isNew
              ? dispatch.subject.mergeSubjectAndLayout({
                  subjectKey: subjectData.$rdfId,
                  subjectData,
                  operation: c.SUBJECT_OPERATION_SAVE,
                })
              : dispatch.subject.mergeSubjectAndLayout({
                  subjectKey,
                  subjectData,
                  operation: c.SUBJECT_OPERATION_SAVE,
                });
            const message: I18NString = {
              id: "SUBJECT_SAVE_SUCCESS",
            };
            dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message });

            /**Used on modals to prevent default card saving callback */
            if (saveCallback) {
              saveCallback(subjectData);
              return;
            }
            if (subject.isNew) {
              subjectAddedFire(subjectData.$rdfId, subjectKey);
              //remove subject from new subject list
              dispatch.subject.sendNewSubject({
                rdfId: subjectData.$rdfId,
                isNew: false,
              });
            } else {
              dispatch.subject.sendEditingSubject({
                rdfId: subjectKey,
                isEditing: false,
              });
            }
            dispatch.subject.sendSubjectSaveDone(subjectKey);
            //TODO: implement tree notify update
            if (notifyId) {
              dispatch.tree.notifyTreeNodeUpdate(notifyId);
            } else {
              dispatch.tree.notifyTreeSubjectUpdate(subjectKey);
            }
          } catch (e) {
            dispatchError("SUBJECT_SAVE_ERROR", e, dispatch, {
              name: subject.className,
            });
            dispatch.subject.sendSubjectChangeSaveState({
              subjectKey,
              saveState: "show_errors",
            });
            if (!saveCallback) {
              // dispatch(sendSubjectSaveDone(subjectKey));
            }
          }
        });
      } else {
        console.error(
          "Cannot save. Save state must be with ready uploads, lock required or subject must be new: ",
          getLockStatus(subject)
        );
      }
    },
    fetchLock: async (
      data: {
        subjectKey: string;
        acquire: boolean;
        force?: boolean;
      },
      state
    ) => {
      const { subjectKey, acquire, force } = data;
      try {
        dispatch.subject.sendSubjectLockLoading({ subjectKey, loading: true });
        dispatch.subject.sendSubjectLoading({ subjectKey, loading: true });
        const parts = subjectKey.split(":");
        const subjectData =
          parts.length > 1
            ? await fetchLockImpl(parts[1], acquire, parts[0])
            : await fetchLockImpl(subjectKey, acquire);
        const operation = acquire
          ? c.SUBJECT_OPERATION_LOCK
          : c.SUBJECT_OPERATION_UNLOCK;
        dispatch.subject.mergeSubjectAndLayout({
          subjectKey,
          subjectData,
          operation,
        });
        if (acquire) {
          dispatch.subject.sendEditingSubject({
            rdfId: subjectKey,
            isEditing: true,
          });
        } else {
          dispatch.subject.sendEditingSubject({
            rdfId: subjectKey,
            isEditing: false,
          });
        }
      } catch (e) {
        dispatch.subject.sendSubjectLockLoading({ subjectKey, loading: false });
        dispatch.subject.sendSubjectLoading({ subjectKey, loading: false });
        dispatchError("OBJECTCARD_LOCK_FAILED", e, dispatch);
      }
    },

    subjectInfo(data: SubjectCardInfo, state) {
      dispatch.modal.openModal({
        id: "subject.info",
        type: "subjectInfo",
        options: { body: JSON.stringify(data), size: "large" },
        okCallback: async (result) => {},
      });
    },
    showSaveChanges(data: {
      subjectKey: string;
      name: string;
      okCallback: Function;
      cancelCallback: Function;
    }) {
      const { subjectKey, name, okCallback, cancelCallback } = data;
      dispatch.modal.openModal({
        id: "subject.showSaveChanges",
        type: "showSaveChanges",
        options: { body: JSON.stringify({ subjectKey, name }) },
        okCallback: async (result) => {
          if (result) {
            okCallback();
          } else if (result === false) {
            cancelCallback();
          }
        },
      });
    },
    addObjTableModal(data: {
      options: ModalOptions;
      modify: (subject: Subject) => void;
    }) {
      const { options, modify } = data;
      dispatch.modal.openModal({
        id: "subject.addObjTable",
        type: "card",
        options,
        okCallback: async (result) => {
          if (result) {
            modify(result);
            // dispatch(sendSubjectComponentUpdated(options.currentSubjectKey, options.nodeId, true))
          }
        },
      });
    },
    addTableRefTable(data: {
      options: TableModalOptions;
      modify: (subject: Subject) => void;
    }) {
      const { options, modify } = data;
      dispatch.modal.openModal({
        id: "subject.addRefTable",
        type: "cimtable",
        options,
        okCallback: async (result) => {
          if (result) {
            modify(result);
          }
        },
      });
    },
    confirmItemRemoveModal(callback: () => void) {
      dispatch.modal.openModal({
        id: "subject.confirmItemRemoveModal",
        type: "confirmItemRemoveModal",
        options: {
          title: { id: "OBJECTCARD_TABLE_CONFIRM_REMOVE_ROW_TITLE" },
          body: "OBJECTCARD_TABLE_CONFIRM_REMOVE_ROW",
        },
        okCallback: async (result) => {
          if (result) {
            callback();
          }
        },
      });
    },
    addTreeRefTable(data: {
      subjectKey: string;
      nodeId: string;
      options: TreeModalOptions;
    }) {
      const { nodeId, options, subjectKey } = data;
      dispatch.modal.openModal({
        id: "subject.addRefTable",
        type: "addRefTable",
        options,
        okCallback: async (data) => {
          if (data) {
            dispatch.subject.link({ subjectKey, nodeId, data });
          }
        },
      });
    },
    pasteRefTableModal(data: {
      subjectKey: string;
      nodeId: string;
      className: string;
      multiple: boolean;
    }) {
      const { nodeId, className, multiple, subjectKey } = data;
      dispatch.modal.openModal({
        id: "subject.pasteRefTable",
        type: "pasteRefTable",
        options: {},
        okCallback: async (refs: RawLink[]) => {
          if (refs) {
            dispatch.subject.pasteRefs({
              subjectKey,
              nodeId,
              relatedClass: className,
              references: multiple ? refs : refs.slice(0, 1),
            });
          }
        },
      });
    },
    //TODO implement validate link modal
    pasteRefs: async (
      payload: {
        subjectKey: string;
        nodeId: string;
        relatedClass: string;
        references: RawLink[];
      },
      s
    ) => {
      const { subjectKey, nodeId, relatedClass, references } = payload;
      const state = s.subject;
      const subject = state && state.subjects[subjectKey];
      if (
        !state ||
        !subject ||
        !isSubject(subject) ||
        state.loading[subjectKey]
      ) {
        return;
      }
      const contextPath = "/";
      // const contextPath = s.location.contextPath;
      const data: RawLink[] = obtainData(nodeId, subject.subjectData);

      let validLinks: Link[] = await validateLinks(
        contextPath,
        relatedClass,
        filterReferences(references, data),
        dispatch
      );

      if (!validLinks || !validLinks.length) {
        dispatch.alert.addAlert({
          type: ALERT_LEVEL_WARNING,
          message: { id: "OBJECTCARD_VALIDATION_EMPTY" },
        });

        return;
      }

      validLinks &&
        dispatch.modal.openModal({
          id: "subject.confirmPasteRefTable",
          type: "confirmPasteRefTable",
          options: { body: JSON.stringify(validLinks) },
          okCallback: (links) => {
            if (links && Array.isArray(links)) {
              for (let linkData of links) {
                dispatch.subject.link({
                  data: {
                    $rdfId: linkData.rdfId,
                    $label: linkData.label,
                    $description: linkData.description,
                  },
                  nodeId,
                  subjectKey,
                });
              }
            }
          },
        });
    },
    remove(
      data: {
        subjectKey: string;
        nodeId: string;
        indexList: number[] | number;
      },
      s
    ) {
      const { subjectKey, nodeId, indexList } = data;
      const state = s.subject;
      if (!state) {
        return;
      }
      const subject = state && state.subjects[subjectKey];

      if (
        !state ||
        !subject ||
        !isSubject(subject) ||
        state.loading[subjectKey]
      ) {
        return;
      }
      const className = subject.className;
      const layout = state.layouts[className];
      const values = subject.values || {}; //Get current values
      const valuesDiff: { [nodeId: string]: any } = {};
      const removeMap: { [ids: number]: boolean } = {};
      if (Array.isArray(indexList)) {
        //index list
        for (let idx of indexList) {
          removeMap[idx] = true;
        }
      } else {
        //one index
        removeMap[indexList] = true;
      }
      if (Array.isArray(values[nodeId])) {
        valuesDiff[nodeId] = values[nodeId].filter(
          (_: any, currentIndex: number) => !Boolean(removeMap[currentIndex])
        );
      }
      //Dispatch difference
      const currentState = makeState(subject);
      dispatch.subject.sendSubjectChangeData({
        data: makeDiff(subjectKey, layout, subject, valuesDiff, currentState),
        nodeId,
      });
    },
    link: async (
      payload: {
        subjectKey: string;
        nodeId: string;
        data: any;
      },
      s
    ) => {
      const { subjectKey, nodeId, data } = payload;
      const state = s.subject;
      if (!state) {
        return;
      }
      const subject = state && state.subjects[subjectKey];
      if (!isSubject(subject)) {
        return;
      }
      const layout = state.layouts[subject.className];
      const node = layout.nodeById[nodeId];
      if (!node) {
        return;
      }
      const values = subject.values || {}; //Get current values
      const multiple =
        layout.nodeById[nodeId] && layout.nodeById[nodeId].multiple
          ? true
          : false;
      const valuesDiff: { [nodeId: string]: any } = {};
      if (multiple) {
        if (!Array.isArray(values[nodeId])) {
          valuesDiff[nodeId] = [data];
        } else {
          valuesDiff[nodeId] = values[nodeId].concat(data);
        }
      } else {
        valuesDiff[nodeId] = data;
      }
      const funcId =
        layout.automation && layout.automation.linkBindings[nodeId];

      if (funcId) {
        //We have link action to do so we need to fetch subject from server
        let linkedSubject: SubjectData | null = null;
        try {
          linkedSubject = await fetchSubjectImpl(data.$rdfId, data.$namespace);
        } catch (e) {
          dispatchError("SUBJECT_FETCH_ERROR", e, dispatch, {
            name: data.$rdfId,
          });
        }
        if (!linkedSubject) {
          return;
        }
        if (multiple) {
          valuesDiff[nodeId].pop();
          valuesDiff[nodeId].push(refreshLinkSubject(linkedSubject));
        } else {
          valuesDiff[nodeId] = refreshLinkSubject(linkedSubject);
        }
        const nextValues = Object.assign({}, subject.values, valuesDiff);
        const form = makeForm(nextValues, subject.subjectData);
        const func = retrieveFunction(funcId);
        try {
          const result = func(form, linkedSubject);
          //Check if we need to fill other predicates
          if (result && typeof result === "object") {
            for (let predicateNodeId of layout.predicateNodesIds) {
              const d = obtainData(predicateNodeId, result);
              if (d) {
                valuesDiff[predicateNodeId] = d;
              }
            }
          }
          linkSubject(subjectKey, nodeId, valuesDiff, state, dispatch);
        } catch (e) {
          dispatchError("SUBJECT_LINK_BINDING_ERROR", e, dispatch, {
            name: subjectKey,
          });
        }
      } else {
        //Dispatch difference immediately

        linkSubject(subjectKey, nodeId, valuesDiff, state, dispatch);
      }
    },
    exportCard(data: {
      rdfId: string;
      namespace: any;
      type: c.EXPORT_TYPE;
      contextPath: string;
    }) {
      const { rdfId, namespace: ns, type, contextPath } = data;
      let namespace = ns;
      let href = `${contextPath}${c.SUBJECT_EXPORT_URL}`;
      if (namespace) {
        if (typeof namespace == "object") {
          namespace = namespace.$prefix;
        }
        href += `/${namespace}`;
      }
      href += `/${rdfId}?type=${type}`;
      try {
        downloadFileImpl(href);
      } catch (e) {
        dispatchError("FILEARCHIVE_NOT_FOUND", e, dispatch);
      }
    },
    checkRef: async (
      data: {
        relatedClass: string;
        reference: any;
        callback: Function;
        omitErrors?: boolean;
      },
      s
    ) => {
      const { relatedClass, reference, callback, omitErrors } = data;
      const contextPath = "/";
      // const contextPath = getState().location.contextPath;
      try {
        const result = await fetchValidateLink(
          contextPath,
          relatedClass,
          reference
        );
        callback(Boolean(result));
      } catch (e) {
        callback(false);
        if (!omitErrors) {
          dispatchError("OBJECTCARD_VALIDATION_ERROR", e, dispatch);
        }
      }
    },
    safeCheckRef: async (data: {
      relatedClass: string;
      reference: any;
      callback: Function;
      omitErrors?: boolean;
    }) => {
      const { relatedClass, reference, callback, omitErrors } = data;
      const contextPath = "/";
      // const contextPath = getState().location.contextPath;
      const result = await safeFetchValidateLink(
        contextPath,
        relatedClass,
        reference
      );
      if (
        result.failed &&
        (!omitErrors ||
          (result as any).error.code === 403 ||
          (result as any).error.code === 500)
      ) {
        dispatchError("OBJECTCARD_VALIDATION_ERROR", result.error, dispatch);
      }
      callback(result);
    },
    registerUpload(
      payload: {
        subjectKey: string;
        nodeId: string;
        files: File[];
        multiple: boolean;
      },
      s
    ) {
      const { subjectKey, nodeId, files, multiple } = payload;
      //Prepare data for redux
      let data: FileValue[] | FileValue | null = null;
      if (multiple) {
        data = [];
      }
      for (let file of files) {
        //Add data to upload queue
        const key = enqueUpload(file);
        //Create redux data
        let actionData: FileValue = {
          $uploadKey: key,
          $tmpName: file.name,
          $label: file.name,
          $description: "",
          $contentType: file.type,
          $contentSize: file.size,
        };
        if (multiple && Array.isArray(data)) {
          data.push(actionData);
        } else {
          data = actionData;
        }
      }
      //Send data to redux
      const state = s.subject;
      if (!state) {
        return;
      }
      const subject = state && state.subjects[subjectKey];
      if (!isSubject(subject)) {
        return;
      }
      const values = subject.values || {}; //Get current values
      const layout = state.layouts[subject.className];
      const valuesDiff: { [nodeId: string]: any } = {};
      if (Array.isArray(data)) {
        //Special case for multiple uploads
        if (Array.isArray(values[nodeId])) {
          valuesDiff[nodeId] = values[nodeId].concat(data);
        } else {
          valuesDiff[nodeId] = data;
        }
      } else {
        valuesDiff[nodeId] = data;
      }
      //Dispatch difference
      // const layout = card.layoutCache[card.data[store].$class];
      const currentState = makeState(subject);
      dispatch.subject.sendSubjectChangeData({
        data: makeDiff(subjectKey, layout, subject, valuesDiff, currentState),
        nodeId,
      });
    },
    click(
      payload: {
        subjectKey: string;
        buttonId: string;
      },
      s
    ) {
      const state = s.subject;
      const { subjectKey, buttonId } = payload;
      if (!state) {
        return;
      }
      const subject = state && state.subjects[subjectKey];
      if (!isSubject(subject)) {
        return;
      }
      const values = subject.values || {}; //Get current values
      const layout = state.layouts[subject.className];

      const data = subject.subjectData;
      const form = makeForm(values, data, dispatch);
      if (!layout.automation) {
        return;
      }
      const funcId = layout.automation.clickBindings[buttonId];

      const func = retrieveFunction(funcId);
      try {
        func(form);
      } catch (ex) {
        if (typeof func !== "function") {
          dispatchError("SUBJECT_CLICK_BINDING_ERROR_NO_FUNC", ex, dispatch);
          return;
        }
        dispatchError("SUBJECT_CLICK_BINDING_ERROR", ex, dispatch);
      }
    },
    reportClick(
      payload: {
        subjectKey: string;
        buttonId: string;
        href: string;
        params: ReportParams;
      },
      s
    ) {
      const { subjectKey, buttonId, href: hr, params } = payload;
      let href = hr;
      const state = s.subject;
      if (!state) {
        return;
      }
      const subject = state && state.subjects[subjectKey];
      if (!isSubject(subject)) {
        return;
      }
      const values = subject.values || {}; //Get current values
      const layout = state.layouts[subject.className];

      const data = subject.subjectData;
      if (!layout.automation) {
        return;
      }
      const funcId = layout.automation.clickBindings[buttonId];
      if (funcId) {
        const form = makeForm(values, data, dispatch);
        const func = retrieveFunction(funcId);
        try {
          const overrideParams = func(form, { filename: params.filename });
          params.filename = overrideParams.filename || params.filename;
        } catch (ex) {
          console.log("Report click binding error", buttonId, ex);
        }
      }
      href += "?" + toUrlSearchParams(params);
      downloadFile(href, params.filename);
    },
  }),
});
