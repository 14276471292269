import { createModel } from "@rematch/core";
import shortid from "shortid";
import { RootModel } from ".";
import {
  receiveSelection,
  receiveSelectionRedo,
  receiveSelectionUndo,
} from "../services/selection";
import { SelectionState } from "../types/selection";

const initialState: SelectionState = {};

export const selection = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendSelection(state, payload: { object: string; type: string }) {
      return receiveSelection(state, payload);
    },
    sendSelectionUndo(state) {
      return receiveSelectionUndo(state);
    },
    sendSelectionRedo(state) {
      return receiveSelectionRedo(state);
    },
    resetToDefault() {
      return initialState;
    },
  },
  effects: (dispatch) => ({}),
});
