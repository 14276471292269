import { NormalizedAutomation, Subject } from "../types/subject";
import { registerFunction } from "./automation";

export function buildLabelInfo(label: string) {
  let words = label.split(" ");
  let maxLength = 0;
  let longestWord = "";
  for (let word of words) {
    if (word.length > maxLength) {
      longestWord = word;
      maxLength = word.length;
    }
  }
  return { words, maxLength, longestWord };
}

export function normalizePredicate(predicate: string) {
  return predicate.replace(":", ".");
}
export function normalizeClass(cls: string) {
  return cls.replace(":", ".");
}
export function generateBindings(
  automation: NormalizedAutomation,
  className: string
) {
  return {
    clearAll: function () {
      for (const prop in automation) {
        //  type fieldType = keyof NormalizedAutomation;
        //  const aProp = prop as fieldType
        let automationAny = automation as any;
        if (!Array.isArray(automationAny[prop])) {
          automationAny[prop] = {};
        } else {
          automationAny[prop] = [];
        }
      }
    },
    bindValue: function (predicate: string, func: Function) {
      if (typeof func == "function") {
        automation.valueBindings[normalizePredicate(predicate)] =
          registerFunction(func);
      } else {
        delete automation.valueBindings[normalizePredicate(predicate)];
      }
    },
    bindEnumeration: function (predicate: string, func: Function) {
      const id = normalizePredicate(predicate);
      if (typeof func == "function") {
        automation.enumerationBindings[id] = registerFunction(func);
      } else {
        delete automation.enumerationBindings[id];
      }
    },
    bindValidation: function (predicate: string, func: Function) {
      if (typeof func == "function") {
        automation.validationBindings[normalizePredicate(predicate)] =
          registerFunction(func);
      } else {
        delete automation.validationBindings[normalizePredicate(predicate)];
      }
    },
    bindClick: function (id: string, func: Function) {
      if (typeof func == "function") {
        automation.clickBindings[id] = registerFunction(func);
      } else {
        delete automation.clickBindings[id];
      }
    },
    bindVisible: function (predicate: string, func: Function) {
      if (typeof func == "function") {
        automation.visibilityBindings[normalizePredicate(predicate)] =
          registerFunction(func);
      } else {
        delete automation.visibilityBindings[normalizePredicate(predicate)];
      }
    },
    bindUiVisible: function (nodeId: string, func: Function) {
      const uiNodeId = `${normalizeClass(className)}.ui${nodeId}`;
      if (typeof func == "function") {
        automation.visibilityBindings[uiNodeId] = registerFunction(func);
      } else {
        delete automation.visibilityBindings[uiNodeId];
      }
    },
    bindFill: function (predicate: string, func: Function) {
      if (typeof func == "function") {
        automation.fillBindings[normalizePredicate(predicate)] =
          registerFunction(func);
      } else {
        delete automation.fillBindings[normalizePredicate(predicate)];
      }
    },
    bindLink: function (predicate: string, func: Function) {
      if (typeof func == "function") {
        automation.linkBindings[normalizePredicate(predicate)] =
          registerFunction(func);
      } else {
        delete automation.linkBindings[normalizePredicate(predicate)];
      }
    },
    bindOptions: function (predicate: string, func: Function) {
      if (typeof func == "function") {
        automation.optionsBindings[normalizePredicate(predicate)] =
          registerFunction(func);
      } else {
        delete automation.optionsBindings[normalizePredicate(predicate)];
      }
    },
    bindLock: function (predicateOrFunc: string | Function, func: Function) {
      if (typeof predicateOrFunc == "string") {
        if (typeof func == "function") {
          automation.lockBindings[normalizePredicate(predicateOrFunc)] =
            registerFunction(func);
        } else {
          delete automation.lockBindings[normalizePredicate(predicateOrFunc)];
        }
      } else {
        if (typeof predicateOrFunc == "function") {
          automation.commonLockChecks.push(registerFunction(predicateOrFunc));
        } else {
          automation.commonLockChecks = [];
        }
      }
    },
    bindComment: function (predicate: string, func: Function) {
      if (typeof func == "function") {
        automation.commentBindings[normalizePredicate(predicate)] =
          registerFunction(func);
      } else {
        delete automation.commentBindings[normalizePredicate(predicate)];
      }
    },
    bindAddCompound: function (predicate: string, func: Function) {
      if (typeof func == "function") {
        automation.addCompound[normalizePredicate(predicate)] =
          registerFunction(func);
      } else {
        delete automation.addCompound[normalizePredicate(predicate)];
      }
    },
  };
}

export function isEditable(
  subject: Subject,
  nodeId?: string,
  editCard?: boolean
) {
  if (!nodeId) {
    return !!editCard;
  }
  let systemDriven;
  let virtual;
  const node = subject.nodeById[nodeId];
  if (node.options) {
    systemDriven =
      node.options && node.options.src
        ? subject.nodeById[node.options.src] &&
          subject.nodeById[nodeId].options.systemDriven
        : node.options.systemDriven;

    virtual = node.options.virtual;
  }
  const lock = subject.lock[nodeId] || false;
  const valueBound =
    subject.automation && subject.automation.valueBindings[nodeId]
      ? true
      : false;
  const editable =
    editCard && !lock && !systemDriven && !virtual && !valueBound
      ? true
      : false;
  return editable;
}

export function isCardEdit(subject: Subject) {
  const serverLock = subject.subjectData.$lock;
  const serverLockReady = serverLock && serverLock.status;
  const isNew = subject.isNew;
  const editCard = serverLockReady || isNew;
  return editCard;
}
