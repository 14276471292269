import * as React from "react";
import { useIntl } from "react-intl";
import Select from "react-select";
import {
  FinderCriteria,
  FinderCriteriaTreeSettings,
  FinderField,
  FinderFieldsStore,
  FinderObjectcardsStore,
  FinderPredicate,
  FinderPredicatesStore,
  FinderRelation,
  FinderSelection,
} from "../../types/finder";
import {
  CancelCallback,
  CloseCallback,
  I18NString,
  ModalOptions,
  OkCallback,
} from "../../types/modal";
import { AlertLevelType } from "../../types/alert";
import { SubjectData } from "../../types/subject";
import { PrimitiveType } from "../../types/profile";
import { MSG_FROM, MSG_OR, MSG_SELECT_PLACEHOLDER, MSG_TO } from "../messages";
import {
  booleanRelationValueList,
  dateRelationTypeList,
  enumerationRelationTypeList,
  fileRelationTypeList,
  fragmentRelationTypeList,
  numberRelationTypeList,
  referenceRelationTypeList,
  stringRelationTypeList,
  TYPE,
} from "../../constants/finder";
import { ALERT_LEVEL_DANGER } from "../../constants/alert";
import { readFromClipboard } from "../../services/clipboard";
import { getJoinedPredicateName, isRdfId } from "../../services/finder";

import DebounceInput from "../debounce/debounceinput";
import { SingleComboBox } from "../objectcard/inputs/ComboBoxInput";

import styles from "./Finder.module.css";

function getFieldName(name: string, namespace?: string | { prefix: string }) {
  let prefix = null;
  if (namespace) {
    switch (typeof namespace) {
      case "string":
        prefix = namespace;
        break;
      case "object":
        prefix = namespace.prefix;
        break;
    }
  }
  return prefix ? prefix + ":" + name : name;
}

const getTypeOptions = (
  fieldType?: number,
  dataType?: PrimitiveType,
  isFileValue?: boolean
) => {
  switch (fieldType) {
    case TYPE.STRING:
      if (!dataType) {
        return stringRelationTypeList;
      }
      if (dataType.name === "date") {
        return numberRelationTypeList;
      }
      return stringRelationTypeList;
    case TYPE.NUMBER:
      return numberRelationTypeList;
    case TYPE.DATE:
      return dateRelationTypeList;
    case TYPE.BOOLEAN:
      return null;
    case TYPE.FILE:
      if (!isFileValue) {
        return null;
      }
      return fileRelationTypeList;
    case TYPE.FRAGMENT:
      return fragmentRelationTypeList;
    case TYPE.ENUMERATION:
      return enumerationRelationTypeList;
    case TYPE.REFERENCE:
    case TYPE.TABLE:
    default:
      break;
  }
  return referenceRelationTypeList;
};

interface FinderCriterionProps {
  loadedFields: FinderFieldsStore;
  loadedObjectcards: FinderObjectcardsStore;
  loadedPredicates: FinderPredicatesStore;
  criteria: FinderCriteria;
  criteriaTree?: FinderCriteriaTreeSettings;
  fetchFields: (parentId: string) => void;
  fetchObjectcard: (rdfId: string) => void;
  addCriteriaRelation: (criteriaId: string) => void;
  removeCriteria: (criteriaId: string) => void;
  removeCriteriaRelation: (criteriaId: string, relationIdx: number) => void;
  changeCriteriaRelation: (
    criteriaId: string,
    relation: FinderRelation,
    relationIdx: number
  ) => void;
  changeCriteriaField: (criteriaId: string, fieldId: string) => void;
  openModal: (
    type: string,
    options: ModalOptions,
    okCallback?: OkCallback,
    cancelCallback?: CancelCallback,
    closeCallback?: CloseCallback
  ) => void;
  addAlert: (type: AlertLevelType, message: string | I18NString) => void;
}

/******************************
 * Finder Criterion Component *
 ******************************/
const FinderCriterion: React.FunctionComponent<FinderCriterionProps> =
  React.memo((props) => {
    const fieldId = props.criteria.fieldId;
    const field = fieldId ? props.loadedFields.byId[fieldId] : null;
    const predicateName = field ? getJoinedPredicateName(field) : null;
    const predicate = predicateName
      ? props.loadedPredicates.byName[predicateName]
      : null;

    const getSelectionList = () => {
      /* Get root fields */
      let rootSelection = [];
      for (let fieldId of props.loadedFields.rootIds) {
        rootSelection.push(props.loadedFields.byId[fieldId]);
      }

      let selectionList = [];
      if (fieldId && field) {
        /* If field isn't last in sequence - add chosing field */
        if (!field.predicate) {
          let selection = [];
          for (let childFieldId of props.loadedFields.children[fieldId]) {
            selection.push(props.loadedFields.byId[childFieldId]);
          }
          selectionList.push({
            selection: selection,
            field: {
              label: "--",
              id: "",
            },
            isLoading: props.loadedFields.loading[field.id],
            isFetched: props.loadedFields.fetched[field.id],
            parentId: field.id,
          });
        }

        selectionList.push({
          selection: null,
          field: field,
          isLoading: props.loadedFields.loading[field.parentId || "null"],
          isFetched: props.loadedFields.fetched[field.parentId || "null"],
          parentId: field.parentId,
        });
        let parentId = field.parentId;
        let visitedNodes: { [k: string]: boolean } = {};
        while (parentId) {
          if (visitedNodes[parentId]) {
            break;
          }
          let parentField = props.loadedFields.byId[parentId];
          if (!parentField) {
            break;
          }

          /* Fill selection field of child */
          let previousSelection = [];
          for (let fieldId of props.loadedFields.children[parentId]) {
            previousSelection.push(props.loadedFields.byId[fieldId]);
          }
          selectionList[selectionList.length - 1].selection = previousSelection;

          selectionList.push({
            selection: null,
            field: parentField,
            isLoading: props.loadedFields.loading[parentId],
            isFetched: props.loadedFields.fetched[parentId],
            parentId: parentId,
          });
          visitedNodes[parentId] = true;
          parentId = parentField.parentId;
        }
        selectionList[selectionList.length - 1].selection = rootSelection;
        selectionList.reverse();
      } else {
        /* User didn't select anything - setup choosing field */
        selectionList.push({
          selection: rootSelection,
          field: {
            label: "--",
            id: "",
          },
          isLoading: props.loadedFields.loading["null"],
          isFetched: props.loadedFields.fetched["null"],
          parentId: null,
        });
      }
      return selectionList;
    };

    const [selectionList, setSelectionList] = React.useState<any[]>([]);
    React.useEffect(() => {
      setSelectionList(getSelectionList());
    }, [props.loadedFields, field]);

    return (
      <div className={`${styles.tableRow}`}>
        <div className={`col-md-7 row m-0 ${styles.tableCell}`}>
          <SelectionList
            loadedFields={props.loadedFields}
            criteria={props.criteria}
            selectionList={selectionList}
            fetchFields={props.fetchFields}
            changeCriteriaField={props.changeCriteriaField}
          />
        </div>
        <div className={`col-md-5 p-0 ${styles.tableCellRelation}`}>
          <RelationList
            loadedFields={props.loadedFields}
            loadedObjectcards={props.loadedObjectcards}
            criteria={props.criteria}
            predicate={predicate}
            selectionListLength={selectionList.length}
            criteriaTree={props.criteriaTree}
            fetchObjectcard={props.fetchObjectcard}
            addCriteriaRelation={props.addCriteriaRelation}
            removeCriteria={props.removeCriteria}
            removeCriteriaRelation={props.removeCriteriaRelation}
            changeCriteriaRelation={props.changeCriteriaRelation}
            openModal={props.openModal}
            addAlert={props.addAlert}
          />
        </div>
      </div>
    );
  });

interface SelectionListProps {
  loadedFields: FinderFieldsStore;
  criteria: FinderCriteria;
  selectionList: any[];
  fetchFields: (parentId: string) => void;
  changeCriteriaField: (criteriaId: string, fieldId: string) => void;
}
const SelectionList: React.FunctionComponent<SelectionListProps> = React.memo(
  (props) => {
    let selectSize: string;
    switch (props.selectionList.length) {
      case 1:
        selectSize = "col-md-12";
        break;
      case 2:
        selectSize = "col-md-6";
        break;
      default:
        selectSize = "col-md-4";
        break;
    }
    return (
      <>
        {props.selectionList.map((selectionObject, index) => (
          <Selection
            key={index}
            criteria={props.criteria}
            isLast={index === props.selectionList.length - 1}
            selectionObject={selectionObject}
            selectSize={selectSize}
            fetchFields={props.fetchFields}
            changeCriteriaField={props.changeCriteriaField}
          />
        ))}
      </>
    );
  }
);

interface SelectionProps {
  criteria: FinderCriteria;
  isLast: boolean;
  selectionObject: any;
  selectSize: string;
  fetchFields: (parentId: string) => void;
  changeCriteriaField: (criteriaId: string, fieldId: string) => void;
}
const Selection: React.FunctionComponent<SelectionProps> = React.memo(
  (props) => {
    const { criteria, isLast, selectionObject, selectSize } = props;
    const changeField = (option?: { id: string; label: string } | null) => {
      if (!option) {
        /* Happens when user use backspace on empty search */
        return;
      }
      let fieldId = option.id;
      if (fieldId == "") {
        props.changeCriteriaField(criteria.id, selectionObject.field.parentId);
        return;
      }
      props.changeCriteriaField(criteria.id, fieldId);
    };
    let options = [];
    if (Array.isArray(selectionObject.selection)) {
      options = selectionObject.selection.map((field: FinderField) => {
        return { ...field, value: field.id };
      });
    }
    return (
      <div className={`${selectSize} ${styles.tableCellSelection}`}>
        {!isLast && (
          <span className={`fa fa-caret-right ${styles.criteriaSeparator}`}>
            &nbsp;
          </span>
        )}
        <LocalizedSelect
          name="field"
          isLoading={selectionObject.isLoading}
          value={selectionObject.field.id}
          options={options}
          onMenuOpen={
            !selectionObject.isFetched
              ? props.fetchFields.bind(null, selectionObject.parentId)
              : undefined
          }
          onChange={changeField}
          isClearable={false}
          valueKey="id"
        />
      </div>
    );
  }
);

interface RelationListProps {
  loadedFields: FinderFieldsStore;
  loadedObjectcards: FinderObjectcardsStore;
  criteria: FinderCriteria;
  predicate: FinderPredicate | null;
  selectionListLength: number;
  criteriaTree?: FinderCriteriaTreeSettings;
  fetchObjectcard: (rdfId: string) => void;
  addCriteriaRelation: (criteriaId: string) => void;
  removeCriteria: (criteriaId: string) => void;
  removeCriteriaRelation: (criteriaId: string, relationIdx: number) => void;
  changeCriteriaRelation: (
    criteriaId: string,
    relation: FinderRelation,
    relationIdx: number
  ) => void;
  openModal: (
    type: string,
    options: ModalOptions,
    okCallback?: OkCallback,
    cancelCallback?: CancelCallback,
    closeCallback?: CloseCallback
  ) => void;
  addAlert: (type: AlertLevelType, message: string | I18NString) => void;
}
const RelationList: React.FunctionComponent<RelationListProps> = React.memo(
  (props) => {
    const { predicate } = props;

    /* Calculate min relation height */
    let padding = 10;
    let rowHeight = 22;
    let minHeight =
      padding * 2 + rowHeight * Math.ceil(props.selectionListLength / 3);

    /* Selected field isn't last */
    if (
      !props.criteria.relations ||
      props.criteria.relations.length == 0 ||
      !predicate
    ) {
      return (
        <div
          className={`${styles.tableCellRelationRow}`}
          style={{ minHeight, borderTop: "none", borderBottom: "none" }}
        >
          <div className={`col-md-5 ${styles.tableCell}`}></div>
          <div className={`col-md-5 ${styles.tableCell}`}></div>
          <div className={`col-md-2 no-gutter ${styles.tableCell}`}>
            <div style={{ width: "100%" }}>
              <div
                className={`card-input pull-right ${styles.tableButton}`}
                onClick={() => {
                  props.removeCriteria(props.criteria.id);
                }}
              >
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        {props.criteria.relations.map((relation, index) => (
          <Relation
            key={index}
            index={index}
            isFirst={index == 0}
            isLast={index == props.criteria.relations.length - 1}
            criteria={props.criteria}
            relation={relation}
            predicate={predicate}
            loadedObjectcards={props.loadedObjectcards}
            minHeight={minHeight}
            criteriaTree={props.criteriaTree}
            fetchObjectcard={props.fetchObjectcard}
            addCriteriaRelation={props.addCriteriaRelation}
            removeCriteria={props.removeCriteria}
            removeCriteriaRelation={props.removeCriteriaRelation}
            changeCriteriaRelation={props.changeCriteriaRelation}
            openModal={props.openModal}
            addAlert={props.addAlert}
          />
        ))}
      </>
    );
  }
);

interface RelationProps {
  index: number;
  isFirst: boolean;
  isLast: boolean;
  criteria: FinderCriteria;
  relation: FinderRelation;
  predicate: FinderPredicate;
  loadedObjectcards: FinderObjectcardsStore;
  minHeight: number;
  criteriaTree?: FinderCriteriaTreeSettings;
  fetchObjectcard: (rdfId: string) => void;
  addCriteriaRelation: (criteriaId: string) => void;
  removeCriteria: (criteriaId: string) => void;
  removeCriteriaRelation: (criteriaId: string, relationIdx: number) => void;
  changeCriteriaRelation: (
    criteriaId: string,
    relation: FinderRelation,
    relationIdx: number
  ) => void;
  openModal: (
    type: string,
    options: ModalOptions,
    okCallback?: OkCallback,
    cancelCallback?: CancelCallback,
    closeCallback?: CloseCallback
  ) => void;
  addAlert: (type: AlertLevelType, message: string | I18NString) => void;
}
const Relation: React.FunctionComponent<RelationProps> = React.memo((props) => {
  const [relationTypeByIdx, setRelationTypeByIdx] = React.useState<{
    [k: number]: string;
  }>({});

  const changeRelationType = (relationIdx: number, type: string) => {
    if (relationTypeByIdx[relationIdx] === type) {
      return;
    }
    const newRelationTypeByIdx = { ...relationTypeByIdx };
    newRelationTypeByIdx[relationIdx] = type;
    setRelationTypeByIdx(newRelationTypeByIdx);
  };

  /* Calculate min height for row */
  let orRowHeight = 22;
  let rowMinHeight =
    (props.minHeight - orRowHeight * (props.criteria.relations.length - 1)) /
    props.criteria.relations.length;

  let style: React.CSSProperties = {
    minHeight: rowMinHeight,
  };
  if (props.isFirst) {
    style.borderTop = "none";
  }
  if (props.isLast) {
    style.borderBottom = "none";
  }

  let removeHandler =
    props.isFirst && props.isLast
      ? props.removeCriteria
      : props.removeCriteriaRelation;

  return (
    <>
      {props.index !== 0 && (
        <div className={`${styles.tableRowOr}`}>
          <span
            className={`border ${styles.advancedGrayscale} ${styles.tableElementOr}`}
          >
            {MSG_OR}
          </span>
        </div>
      )}
      <div className={`${styles.tableCellRelationRow}`} style={style}>
        <div className={`col-md-5 ${styles.tableCell}`}>
          <RelationType
            criteria={props.criteria}
            relation={props.relation}
            predicate={props.predicate}
            relationIdx={props.index}
            relationTypeByIdx={relationTypeByIdx}
            changeRelationType={changeRelationType}
            changeCriteriaRelation={props.changeCriteriaRelation}
          />
        </div>
        <div
          className={`col-md-5 ${styles.tableCell} ${styles.relationValueCell}`}
        >
          <RelationValue
            criteria={props.criteria}
            relation={props.relation}
            relationIdx={props.index}
            relationTypeByIdx={relationTypeByIdx}
            predicate={props.predicate}
            loadedObjectcards={props.loadedObjectcards}
            criteriaTree={props.criteriaTree}
            fetchObjectcard={props.fetchObjectcard}
            changeRelationType={changeRelationType}
            changeCriteriaRelation={props.changeCriteriaRelation}
            openModal={props.openModal}
            addAlert={props.addAlert}
          />
        </div>
        <div className={`col-md-2 no-gutter ${styles.tableCell}`}>
          <div style={{ width: "100%" }}>
            <div
              className={`card-input pull-right ${styles.tableButton}`}
              onClick={() => {
                removeHandler(props.criteria.id, props.index);
              }}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
            {props.isLast && (
              <div
                className={`card-input pull-right ${styles.tableButton}`}
                onClick={() => {
                  props.addCriteriaRelation(props.criteria.id);
                }}
              >
                <i className="fa fa-code-fork" aria-hidden="true"></i>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

interface RelationTypeProps {
  criteria: FinderCriteria;
  relation: FinderRelation;
  predicate: FinderPredicate;
  relationIdx: number;
  relationTypeByIdx: { [k: number]: string };
  isFileValue?: boolean;
  changeRelationType: (relationIdx: number, type: string) => void;
  changeCriteriaRelation: (
    criteriaId: string,
    relation: FinderRelation,
    relationIdx: number
  ) => void;
}
const RelationType: React.FunctionComponent<RelationTypeProps> = React.memo(
  (props) => {
    let value = props.relation.type;
    let fieldType = props.predicate.type;

    const changeRelation = (newValueObject: {
      value: string;
      label: string;
    }) => {
      let relationType = newValueObject.value;
      let relation: FinderRelation = {
        ...props.criteria.relations[props.relationIdx],
        type: relationType as any,
      };
      // switch (relationType) {
      //     case "between":
      //         relation.value = {
      //             from: null,
      //             to: null
      //         }
      //         break;
      // }
      props.changeCriteriaRelation(
        props.criteria.id,
        relation,
        props.relationIdx
      );
    };

    const [options, setOptions] = React.useState<any[] | null>(null);
    React.useEffect(() => {
      if (fieldType === TYPE.BOOLEAN) {
        props.changeRelationType(props.relationIdx, "equal");
      }
      const options = getTypeOptions(
        fieldType,
        props.predicate.dataType,
        props.isFileValue
      );
      if (options && !value) {
        props.changeRelationType(props.relationIdx, options[0].value as string);
      }
      setOptions(options);
    }, [fieldType]);

    if (!options) {
      return null;
    }

    // if (!value) {
    //   value = options[0].value;
    // }

    return (
      <LocalizedSelect
        name="relation-type"
        value={value}
        options={options}
        onChange={changeRelation}
        isClearable={false}
        isSearchable={false}
        localizationNeeded={true}
      />
    );
  }
);

interface ToolBtn {
  icon: string;
  enabled: boolean;
  iconStyle?: React.CSSProperties;
  onClick?: () => void;
}

interface RelationValueProps {
  criteria: FinderCriteria;
  relation: FinderRelation;
  relationIdx: number;
  relationTypeByIdx: { [k: number]: string };
  predicate: FinderPredicate;
  loadedObjectcards: FinderObjectcardsStore;
  criteriaTree?: FinderCriteriaTreeSettings;
  fetchObjectcard: (rdfId: string) => void;
  changeRelationType: (relationIdx: number, type: string) => void;
  changeCriteriaRelation: (
    criteriaId: string,
    relation: FinderRelation,
    relationIdx: number
  ) => void;
  openModal: (
    type: string,
    options: ModalOptions,
    okCallback?: OkCallback,
    cancelCallback?: CancelCallback,
    closeCallback?: CloseCallback
  ) => void;
  addAlert: (type: AlertLevelType, message: string | I18NString) => void;
}
const RelationValue: React.FunctionComponent<RelationValueProps> = React.memo(
  (props) => {
    const {
      criteria,
      relation,
      relationIdx,
      relationTypeByIdx,
      predicate,
      loadedObjectcards,
    } = props;
    const changeRelationBetweenValue = (
      path: "from" | "to",
      value: string | {}
    ) => {
      changeRelationValue(value, path);
    };

    const changeRelationEnumValue = (enumData: SubjectData) => {
      let value = enumData.$rdfId;
      if (enumData.$namespace) {
        value = `${enumData.$namespace}:${value}`;
      }
      changeRelationValue(value);
    };

    const changeRelationSelectValue = (option?: { value: string } | null) => {
      let value = option?.value || {};
      changeRelationValue(value);
    };

    const changeRelationValue = (
      value: string | number | {},
      path?: "from" | "to"
    ) => {
      let newValue;
      if (typeof value === "string") {
        newValue = value;
      } else if (typeof value === "number") {
        newValue = value.toString();
      } else {
        newValue = "";
      }
      let relation = criteria.relations[relationIdx];
      if (path) {
        (relation as any)[path] = newValue;
      } else {
        relation.value = newValue;
      }
      if (!relation.type) {
        const options = getTypeOptions(
          props.predicate.type,
          props.predicate.dataType
        );
        relation.type = (options ? options[0].value : "equal") as any;
      }
      props.changeCriteriaRelation(criteria.id, relation, relationIdx);
    };

    if (relation.type == "between") {
      const format =
        predicate.type == TYPE.DATE
          ? "date"
          : predicate.type == TYPE.DATETIME
          ? "datetime"
          : undefined;
      return (
        <div className="col-md-12 p-0">
          <div className="d-flex flex-row col-md-12 p-0 m-0 text-left between-relation-value">
            <span>{MSG_FROM}</span>
            <div className="d-flex flex-fill">
              <DebounceInput
                size="md"
                format={format}
                editable={!criteria.locked}
                valid={true}
                value={relation.from}
                className={"form-control"}
                change={changeRelationBetweenValue.bind(null, "from")}
                updateOnChange={true}
              ></DebounceInput>
            </div>
          </div>
          <div className="d-flex flex-row col-md-12 p-0 m-0 text-left between-relation-value">
            <span>{MSG_TO}</span>
            <div className="d-flex flex-fill">
              <DebounceInput
                size="md"
                format={format}
                editable={!criteria.locked}
                valid={true}
                value={relation.to}
                className={"form-control"}
                change={changeRelationBetweenValue.bind(null, "to")}
                updateOnChange={true}
              ></DebounceInput>
            </div>
          </div>
        </div>
      );
    }
    if (predicate.type == TYPE.BOOLEAN) {
      return (
        <LocalizedSelect
          name="relation-value"
          value={relation.value}
          options={booleanRelationValueList}
          onChange={changeRelationSelectValue}
          isClearable={false}
          isSearchable={false}
          localizationNeeded={true}
        />
      );
    }

    if (predicate.type == TYPE.ENUMERATION) {
      try {
        return (
          <SingleComboBox
            emptyValueLabel="123ee"
            enumerationInfo={
              predicate.classRelationInfo.peerClass.enumerationInfo
            }
            placeholderIfEmpty={true}
            editable={!criteria.locked}
            value={relation.value}
            link={changeRelationEnumValue}
            allowPartialSelection={true}
            className="w-100"
            menuShouldBlockScroll={true}
          />
        );
      } catch (e) {
        return null;
      }
    }
    if (predicate.type == TYPE.FILE) {
      if (!relation.type) {
        relationTypeByIdx[relationIdx] = fileRelationTypeList[0].value;
        props.changeCriteriaRelation(
          criteria.id,
          { type: relationTypeByIdx[relationIdx] as any, value: 0 },
          relationIdx
        );
      }
      return (
        <RelationType
          criteria={criteria}
          relation={relation}
          predicate={predicate}
          relationIdx={relationIdx}
          relationTypeByIdx={relationTypeByIdx}
          isFileValue={true}
          changeRelationType={props.changeRelationType}
          changeCriteriaRelation={props.changeCriteriaRelation}
        />
      );
    }
    let value = relation.value || "";
    return (
      <InputWithTool
        predicate={predicate}
        value={value}
        loadedObjectcards={loadedObjectcards}
        criteriaLocked={criteria.locked}
        treeSelections={props.criteriaTree?.treeSelections}
        tableSelections={props.criteriaTree?.tableSelections}
        fetchObjectcard={props.fetchObjectcard}
        changeRelationValue={changeRelationValue}
        openModal={props.openModal}
        addAlert={props.addAlert}
      />
    );
  }
);

function getSelectionPath(
  fieldName: string,
  selectionList?: FinderSelection[]
) {
  if (!selectionList) {
    return null;
  }
  for (let selection of selectionList) {
    for (let p of selection.predicates) {
      const re = new RegExp(p.name.replace(/\./g, ".").replace(/\*/g, ".*"));
      if (re.test(fieldName)) {
        return selection.path;
      }
    }
  }
  return null;
}

interface InputWithToolProps {
  predicate: FinderPredicate;
  value: string | number;
  loadedObjectcards: FinderObjectcardsStore;
  criteriaLocked?: boolean;
  treeSelections?: FinderSelection[];
  tableSelections?: FinderSelection[];
  fetchObjectcard: (rdfId: string) => void;
  changeRelationValue: (arg: any) => void;
  openModal: (
    type: string,
    options: ModalOptions,
    okCallback?: OkCallback,
    cancelCallback?: CancelCallback,
    closeCallback?: CloseCallback
  ) => void;
  addAlert: (type: AlertLevelType, message: string | I18NString) => void;
}
const InputWithTool: React.FunctionComponent<InputWithToolProps> = React.memo(
  (props) => {
    const { predicate, value, loadedObjectcards, criteriaLocked } = props;

    const setReference = (value: any) => {
      if (typeof value == "object") {
        value = value.$rdfId || value.key;
      }
      if (value && !loadedObjectcards.fetched[value]) {
        props.fetchObjectcard(value);
      }
      props.changeRelationValue(value);
    };

    const convertAndPasteRefs = (text: string) => {
      try {
        const refs = JSON.parse(text);
        text = refs;
      } catch (ex) {
        /* Pasted text may be rdfId string, so JSON.parse will fail, but pasted data is valid */
      }
      var clipboardData = text as any;
      if (Array.isArray(clipboardData)) {
        clipboardData = clipboardData[0];
      }
      let value = null;
      if (typeof clipboardData == "string") {
        value = clipboardData;
      } else if (typeof clipboardData == "object" && clipboardData.$rdfId) {
        value = clipboardData.$rdfId;
      }
      if (isRdfId(value)) {
        setReference(value);
        return;
      }
      props.addAlert(ALERT_LEVEL_DANGER, {
        id: "FINDER_REFERENCE_PASTE_WRONG_DATA",
        defaultMessage: "Clipboard data have wrong format",
      });
    };

    const pasteReferenceHandler = async () => {
      try {
        const text = await readFromClipboard();
        convertAndPasteRefs(text as string);
      } catch (e) {
        props.openModal("pasteRefTable", {}, (pastedData: string) => {
          convertAndPasteRefs(pastedData);
        });
      }
    };

    const getPasteReferenceBtn = (): ToolBtn => {
      return {
        icon: "clipboard",
        enabled: true,
        onClick: pasteReferenceHandler,
      };
    };

    const getTreeSelection = (fieldName: string) => {
      return getSelectionPath(fieldName, props.treeSelections);
    };

    const getTableSelection = (fieldName: string) => {
      return getSelectionPath(fieldName, props.tableSelections);
    };

    const getReferenceBtn = (): ToolBtn => {
      return {
        icon: "plus-circle",
        enabled: true,
        iconStyle: { color: "green" },
      };
    };

    const getDeleteReferenceBtn = () => {
      return {
        icon: "times",
        enabled: true,
        onClick: setReference.bind(null, {}),
      };
    };

    const getTreeReferenceBtn = (treePath: string) => {
      const button = getReferenceBtn();
      button.onClick = () => {
        const options: ModalOptions = {
          treeId: treePath,
          size: "large",
        };
        props.openModal("cimtree", options, setReference);
      };
      return button;
    };

    const getTableReferenceBtn = (tablePath: string) => {
      const button = getReferenceBtn();
      button.onClick = () => {
        const options: ModalOptions = {
          tableId: tablePath,
          forcedSelectType: "radio",
          size: "large",
          type: "table",
          parameters: { _ignoreLocationFields: "true" },
          paramsReadOnly: true,
        };
        props.openModal("cimtable", options, setReference);
      };
      return button;
    };

    let tool = null;
    let inputValue = value;
    if (predicate.type == TYPE.REFERENCE) {
      tool = [getPasteReferenceBtn()];
      if (predicate.name) {
        const fieldName = getFieldName(predicate.name, predicate.namespace);
        const treeSelection = getTreeSelection(fieldName);
        if (treeSelection != null) {
          tool.push(getTreeReferenceBtn(treeSelection));
        } else {
          const tableSelection = getTableSelection(fieldName);
          if (tableSelection != null) {
            tool.push(getTableReferenceBtn(tableSelection));
          }
        }
      }
      if (value) {
        tool.push(getDeleteReferenceBtn());
      }
      if (loadedObjectcards.byId[value as string]) {
        inputValue = loadedObjectcards.byId[value as string].$label;
      }
    }
    let format = predicate.format;
    // if (predicate.type == TYPE.DATE) {
    //   format = "date";
    // }
    return (
      <DebounceInput
        size="md"
        editable={Boolean(
          !criteriaLocked && predicate.id && predicate.type !== TYPE.REFERENCE
        )}
        valid={true}
        value={inputValue}
        className={"form-control"}
        change={props.changeRelationValue}
        tool={tool}
        updateOnChange={true}
        format={format}
      />
    );
  }
);

interface LocalizedSelectProps {
  name: string;
  options: any[];
  isLoading?: boolean;
  value?: any;
  isClearable?: boolean;
  isSearchable?: boolean;
  valueKey?: string;
  localizationNeeded?: boolean;
  onChange: (value: any, action: any) => void;
  onMenuOpen?: () => void;
}
const LocalizedSelect: React.FunctionComponent<LocalizedSelectProps> =
  React.memo((props) => {
    const intl = useIntl();
    const localizeOptions = () => {
      const localizedOptions: any[] = [];
      for (let option of props.options) {
        const label = props.localizationNeeded
          ? intl.formatMessage({ id: option.label })
          : option.label;
        localizedOptions.push({ ...option, label });
      }
      return localizedOptions;
    };
    const [localizedOptions, setLocalizedOptions] = React.useState<any[]>([]);
    React.useEffect(() => {
      setLocalizedOptions(localizeOptions());
    }, [intl.locale, props.options]);
    return (
      <Select
        name={props.name}
        classNamePrefix="cb-finder"
        className="minified-react-select npt-select w-100"
        loadingMessage={() => intl.formatMessage({ id: "MSG_SELECT_LOADING" })}
        placeholder={MSG_SELECT_PLACEHOLDER}
        noOptionsMessage={() =>
          intl.formatMessage({ id: "MSG_SELECT_NO_RESULTS" })
        }
        isLoading={props.isLoading}
        value={
          localizedOptions.find(
            (option) => option[props.valueKey || "value"] === props.value
          ) || null
        }
        options={localizedOptions}
        onMenuOpen={props.onMenuOpen}
        onChange={props.onChange}
        isClearable={props.isClearable}
        menuPosition="fixed"
        menuShouldBlockScroll={true}
      />
    );
  });

export default FinderCriterion;
