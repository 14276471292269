import { createModel } from "@rematch/core";
import shortid from "shortid";
import { RootModel } from ".";
import { ServerError } from "../actions/utils";
import { UserCardType } from "../constants/user";
import { dispatchError } from "../services/alert";
import { RootState } from "../store";
import { FragmentDataAccessRaw, FragmentTableInfo } from "../types/fragment";
import { LocaleState } from "../types/locale";

import { I18NString } from "../types/modal";
import { SecurityItem, SecurityItemResponse } from "../types/security";
import {
  BaseTableInfo,
  BriefUser,
  BriefUserRaw,
  UserCard,
  UserDataNode,
  UserFilter,
  UsersInfo,
  UserState,
  UserTableInfo,
} from "../types/user";

const initialState: UserState = { cardType: UserCardType.SIMPLE };

export const user = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendUserList(state, payload: { userList: UserDataNode[]; total: number }) {
      const usersInfo: UsersInfo = {
        ...state.users,
        table: parseListToTableInfo(payload.userList),
        search: "",
        pagination: {},
      };
      return { ...state, users: usersInfo, loading: false };
    },
    sendBriefUser(state, briefUser: BriefUser) {
      const userCard: UserCard = { ...state.userCard, data: { ...briefUser } };
      return { ...state, userCard: { ...userCard, loading: false } };
    },
    sendBriefUserSave(state, save?: boolean) {
      return { ...state, saved: save };
    },
    sendUserEdit(state, editing: boolean) {
      const user = editing ? state.userCard?.data : undefined;
      return {
        ...state,
        editUser: editing,
        userCard: { ...state.userCard, editedUser: user },
      };
    },
    sendUserListLoading(state, loading: boolean = true) {
      return { ...state, loading: Boolean(loading) };
    },
    sendUserCardType(state, cardType: UserCardType) {
      return { ...state, cardType };
    },
    sendBriefUserLoading(state, loading: boolean = true) {
      const userCard: UserCard = { ...state.userCard, loading } as UserCard;
      return { ...state, userCard: { ...userCard } };
    },
    sendUserUpdateFilter(state, filter: UserFilter) {
      return { ...state, filter };
    },
    sendUserTableSearch(state, search: string) {
      return { ...state, search };
    },
  },
  effects: (dispatch) => ({
    fetchUserList: async (data: { filter?: string; blocked?: boolean }, s) => {
      const { filter, blocked } = data;
      try {
        const search = s.user?.search || null;
        if (s.user?.loading) {
          return;
        }
        dispatch.user.sendUserListLoading();
        // parseUserList
        const response: SecurityItemResponse = await fetchUserListImpl(
          filter || null,
          search,
          blocked
        );
        const total = response.total;
        const list = parseUserList(response.itemList);
        dispatch.user.sendUserList({ userList: list, total });
      } catch (e) {
        dispatch.user.sendUserListLoading(false);
        dispatchError("USER_FETCH_USERS_ERROR", e, dispatch);
      }
    },
    fetchUser: async (data: { rdfId?: string }, s) => {
      const { rdfId } = data;
      try {
        dispatch.user.sendBriefUserLoading();
        // parseUserList
        const user: BriefUserRaw = rdfId
          ? await fetchUserImpl(rdfId)
          : await fetchNewUserImpl();
        dispatch.user.sendBriefUser(parseUser(user));
      } catch (e) {
        dispatch.user.sendBriefUserLoading(false);
        dispatchError("USER_FETCH_USER_ERROR", e, dispatch);
      }
    },
    fetchUserTemplate: async (rdfId: string, s) => {
      try {
        dispatch.user.sendBriefUserLoading();
        // parseUserList
        const user: BriefUserRaw = await fetchUserImpl(rdfId);

        const newUser: BriefUserRaw = await fetchNewUserImpl();
        user.rdfId = newUser.rdfId;
        user.password = undefined;

        dispatch.user.sendBriefUser(parseUser(user));
      } catch (e) {
        dispatch.user.sendBriefUserLoading(false);
        dispatchError("USER_FETCH_USER_ERROR", e, dispatch);
      }
    },
    fetchDropTwoAuth: async (rdfId: string, s) => {
      try {
        dispatch.user.sendBriefUserLoading();
        // parseUserList
        const user: BriefUserRaw = await fetchDropTwoFactorImpl(rdfId);
        dispatch.user.sendBriefUser(parseUser(user));
      } catch (e) {
        dispatch.user.sendBriefUserLoading(false);
        dispatchError("USER_FETCH_TWO_FACTOR_RESET_ERROR", e, dispatch);
      }
    },
    saveUser: async (
      data: { user: BriefUser; callback?: (rdfId?: string) => void },
      s
    ) => {
      const { user, callback } = data;
      try {
        if (s && typeof s.user != "undefined" && s.user.loading) {
          return;
        }
        dispatch.user.sendBriefUserLoading();
        const savedUser: BriefUserRaw = await fetchUserSaveImpl(user);

        dispatch.user.sendBriefUserSave(false);
        dispatch.user.sendUserEdit(false);

        callback && callback(savedUser.rdfId);
        savedUser.rdfId && dispatch.user.fetchUser({ rdfId: savedUser.rdfId });
        // dispatch(sendBriefUserLoading(false))
      } catch (e: any) {
        console.log(e);
        dispatch.user.sendBriefUserLoading(false);
        dispatchError("USER_SAVE_ERROR", e, dispatch);
        if (e?.code === 403) {
          dispatch.user.sendBriefUserSave(false);
        }

        // dispatch(fetchUser(user.rdfId));
      }
    },
    clearSelected: (field: keyof UserFilter) => {
      switch (field) {
        case "fragments":
          dispatch.fragments.sendFragmentClearSelect();
          break;
        case "roles":
          dispatch.role.sendRoleClearSelect();
          break;
        case "rights":
          dispatch.right.sendRightsClearSelect();
          break;
      }
    },
    confirmDelete: (data: { body: string; callback: () => void }) => {
      const { body, callback } = data;
      dispatch.modal.openModal({
        id: "cron.confirmDelete",
        type: "confirmDelete",
        options: { title: { id: "CONFIRM_MODAL_TITLE" }, body: body },
        okCallback: async (result: any) => {
          if (result) {
            callback();
          }
        },
      });
    },
  }),
});

export function createFilter(s: RootState) {
  const roleTree = s.role?.tree;
  const rightTree = s.right?.tree;
  const fragmentTree = s.fragments;

  const roles = roleTree?.selected
    ? roleTree.selected
    : roleTree?.active
    ? [roleTree.active]
    : [];
  const rights = rightTree?.selected
    ? rightTree.selected
    : rightTree?.active
    ? [rightTree.active]
    : [];
  const fragments = fragmentTree?.selected
    ? fragmentTree.selected
    : fragmentTree?.active
    ? [fragmentTree.active]
    : [];

  const user: UserFilter = { roles, rights, fragments };
  return user;
}

export function parseFilterToQuery(filter: UserFilter) {
  let { fragments, rights, roles } = filter;
  if (fragments.length === 0 && rights.length === 0 && roles.length === 0) {
    return "";
  }
  fragments = fragments.map((f) => `f=${f}`);
  roles = roles.map((f) => `r=${f}`);
  rights = rights.map((f) => `a=${f}`);

  const query = `${[...fragments, ...roles, ...rights].join("&")}`;
  return query;
}

function parseUserList(rawList: SecurityItem[]) {
  const userList: UserDataNode[] = [];
  rawList.forEach((rl) => {
    const { d, l, i, r, u, b } = rl;
    let lastName = "";
    let firstName = "";
    let patronymicName = "";

    const user: UserDataNode = {
      firstName,
      lastName,
      patronymicName,
      label: l,
      id: i,
      rdfId: r,
      userName: u || "",
      description: d,
      blocked: b,
    };
    userList.push(user);
  });
  return userList;
}

function parseToRawUser(briefUser: BriefUser) {
  const { accessInfo, fragmentInfo, roleInfo, ...other } = briefUser;
  const accessList: SecurityItem[] = [];
  const roleList: SecurityItem[] = [];
  const fragmentList: FragmentDataAccessRaw[] = [];

  accessInfo.rdfId.forEach((r) => {
    const l = accessInfo.label[r];
    const item: SecurityItem = { r, l } as SecurityItem;
    accessList.push(item);
  });
  roleInfo.rdfId.forEach((r) => {
    const l = roleInfo.label[r];
    const item: SecurityItem = { r, l } as SecurityItem;
    roleList.push(item);
  });
  fragmentInfo.rdfId.forEach((r) => {
    const l = fragmentInfo.label[r];
    const wr = fragmentInfo.write[r];
    const rd = fragmentInfo.read[r];
    const dl = fragmentInfo.delete[r];
    const item: FragmentDataAccessRaw = {
      r,
      l,
      rd,
      wr,
      dl,
    } as FragmentDataAccessRaw;
    fragmentList.push(item);
  });
  const user: BriefUserRaw = { ...other, accessList, roleList, fragmentList };
  return user;
}

async function fetchUserListImpl(
  filter: string | null,
  search: string | null,
  blocked?: boolean
): Promise<SecurityItemResponse> {
  let encodedSearch = search
    ? encodeURIComponent(search).replace("%5C", "%5C%5C")
    : null;
  const params = [];
  if (encodedSearch) {
    params.push("q=" + encodedSearch);
  }
  if (typeof blocked !== "undefined") {
    params.push("b=" + blocked);
  }
  let queryString = "";
  if (filter) {
    params.push(filter);
  }
  if (params.length) {
    queryString = `?${params.join("&")}`;
  }
  const url = `/rest/user/list${queryString}`;
  const response = await fetch(url);
  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
  return response.json();
}
async function fetchUserImpl(rdfId: string): Promise<BriefUserRaw> {
  // return FAKE_ONE_USER as any;
  const response = await fetch(`/rest/user/entity/${rdfId}`);
  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
  return response.json();
}
async function fetchDropTwoFactorImpl(rdfId: string): Promise<BriefUserRaw> {
  const response = await fetch(`/rest/user/token/${rdfId}`, {
    method: "DELETE",
  });
  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
  return response.json();
}
async function fetchNewUserImpl(): Promise<BriefUserRaw> {
  // return FAKE_ONE_USER as any;
  const response = await fetch(`/rest/user/new`);
  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
  return response.json();
}
async function fetchUserSaveImpl(user: BriefUser): Promise<BriefUserRaw> {
  const response = await fetch(`/rest/user/entity`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(parseToRawUser(user)),
  });
  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
  return response.json();
}

const otherPatterns: string[] = ["0-9", "!@#$%^&*", "{10,}"];
const letterPatterns: string[] = ["a-z", "A-Z", "А-Я", "а-я"];

const makePasswordRegExp = (length: number) => {
  let regex_string = "";
  let rules = [];
  let range = `{${length},}`;

  otherPatterns.forEach((pattern) => {
    rules.push(pattern);
    regex_string += `(?=.*[${pattern}])`;
  });

  regex_string += "(";
  for (let i = 0; i < letterPatterns.length; i++) {
    rules.push(letterPatterns[i]);
    regex_string += `(?=.*[${letterPatterns[i]}])`;
    if (i < letterPatterns.length - 1) {
      regex_string += "|";
    } else {
      regex_string += ")";
    }
  }

  regex_string += `[${rules.join("")}]${range}`;
  return new RegExp(`^(${regex_string})$`, "g");
};

const parseListToTableInfo = (usersList: UserDataNode[]) => {
  const userTableInfo: UserTableInfo = {
    description: {},
    id: {},
    label: {},
    rdfId: [],
    username: {},
    blocked: {},
  };

  usersList.forEach((u) => {
    const {
      firstName,
      description,
      lastName,
      patronymicName,
      id,
      label,
      rdfId,
      userName,
      blocked,
    } = u;
    userTableInfo.rdfId.push(rdfId);
    userTableInfo.id[rdfId] = id;
    userTableInfo.label[rdfId] = label || "";

    userTableInfo.username[rdfId] = userName;
    userTableInfo.description[rdfId] = description;
    userTableInfo.blocked[rdfId] = blocked;
  });
  return userTableInfo;
};

export function parseBaseTableInfo(list: SecurityItem[]) {
  const info: BaseTableInfo = { label: {}, rdfId: [] };
  list.forEach((item) => {
    const { r, l } = item;
    if (r) {
      info.rdfId.push(r);
      info.label[r] = l;
    }
  });
  return info;
}

export function parseFragmentTableInfo(list: FragmentDataAccessRaw[]) {
  const info: FragmentTableInfo = {
    label: {},
    rdfId: [],
    write: {},
    read: {},
    delete: {},
  };
  list.forEach((item) => {
    const { r, l, rd, dl, wr } = item;
    if (r) {
      info.rdfId.push(r);
      info.label[r] = l;
      info.write[r] = wr;
      info.read[r] = rd;
      info.delete[r] = dl;
    }
  });
  return info;
}

export function parseUser(rawUser: BriefUserRaw) {
  const { roleList, accessList, fragmentList, ...other } = rawUser;

  const roleInfo: BaseTableInfo = parseBaseTableInfo(roleList);
  const accessInfo: BaseTableInfo = parseBaseTableInfo(accessList);
  const fragmentInfo: FragmentTableInfo = parseFragmentTableInfo(fragmentList);

  const briefUser: BriefUser = { ...other, roleInfo, accessInfo, fragmentInfo };
  return briefUser;
}
