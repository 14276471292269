import { createModel } from "@rematch/core";
import moment from "moment";

import shortid from "shortid";
import { RootModel } from ".";
import { scriptCompiler, retrieveFunction } from "../services/automation";
import {
  replaceFieldPlaceholders,
  parseGantDate,
  roundGantTimestamp,
  getStandartDisplayedScales,
  getValueByScale,
  checkGantDateValue,
  getMinScaleValue,
  addTime,
  addGantDateCell,
} from "../services/gant";
import {
  generateTableBindings,
  getRowAutomationData,
  getTableRows,
  getGantGroupMap,
  createGantGroup,
  isHeaderLoaded,
  parseTableHeader,
  fetchTableHeaderImpl,
  composeTableRequest,
  prepareTableRequest,
  getAutomationTableModule,
  parseTableData,
  fetchTableDataImpl,
  getLocalSelection,
  getLocalRange,
  isFilterNeeded,
  getTableSearchFields,
  downloadFile,
  isPageFetched,
  saveTableImpl,
  parseToServerData,
  saveFilterImpl,
  fetchTableFilterSelectionImpl,
  fetchTableFilterRangeImpl,
  fetchReportUuid,
  cutOffModel,
} from "../services/table";
import { ThemeService } from "../services/theme";
import { AlertState, AlertLevelType, AlertOptions } from "../types/alert";
import { FetchError } from "../types/error";
import * as constants from "../constants/table";
import { I18NString } from "../types/modal";
import {
  TableReducerState,
  TableState,
  AutomationBindings,
  AutomationBindingFunctions,
  RowData,
  AutomationTableModule,
  ToolbarItem,
  isToolbarGroup,
  ToolbarGroup,
  GantDisplayScale,
  GantHeaderCell,
  TableFilterChanges,
  DateTimeFilterData,
  isStringFilterData,
  isNumberFilterData,
  isDateFilterData,
  isDateTimeFilterData,
  isBooleanFilterData,
  ColumnSortInfo,
  TableHeader,
  TableData,
  InitialLocalTableData,
  LocalTableData,
  GantViewType,
  GantDateLimits,
  TableGantOptions,
  RowDataMap,
  NumberFilterData,
  FilterData,
  DateFilterData,
  TableInitialOptions,
  TableRequest,
  ToolbarReport,
  TableUserSettings,
  ModelParameters,
  ColumnFilterInfo,
} from "../types/table";
import { receiveServerMessage, ServerError } from "../actions/utils";

import { FinderData } from "../types/finder";
import { getFileName } from "../services/reports";
import { buildUrl } from "../services/location";
import { ALERT_LEVEL_SUCCESS } from "../constants/alert";
import { dispatchError, dispatchErrorV2 } from "../services/alert";
import { parseFinderToFilter } from "../services/finder";
const DEFAULT_STATE: TableReducerState = {};
const initialState: TableReducerState = DEFAULT_STATE;

export const table = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendTableInitialData(
      state,
      payload: {
        tableId: string;
        data: { initialSorting?: ColumnSortInfo[] };
      }
    ) {
      return receiveTableInitialData(state, payload.data, payload.tableId);
    },
    sendTableHeader(
      state,
      payload: {
        tableId: string;
        header: TableHeader;
      }
    ) {
      return receiveTableHeader(
        state,
        { header: payload.header },
        payload.tableId
      );
    },
    sendTableData(
      state,
      payload: {
        tableId: string;
        data: TableData;
        getAutomationTableModule: (state: TableState) => AutomationTableModule;
        reset?: boolean;
        savePage?: boolean;
      }
    ) {
      const { tableId, reset, ...data } = payload;
      return receiveTableData(
        state,
        {
          ...data,
          reset: Boolean(reset),
        },
        tableId
      );
    },
    sendTableLoading(state, tableId: string) {
      return receiveTableLoading(state, null, tableId);
    },
    sendTableError(state, payload: { tableId: string; error: FetchError }) {
      return receiveTableError(
        state,
        { error: payload.error },
        payload.tableId
      );
    },
    sendTableDataLoading(
      state,
      payload: {
        tableId: string;
        page: number;
        pageSize: number;
        abort: AbortController;
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveTableDataLoading(state, data, tableId);
    },
    sendTableDataError(
      state,
      payload: {
        error: FetchError;
        page: number;
        pageSize: number;
        tableId: string;
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveTableDataError(state, data, tableId);
    },
    sendTableFilterSelectionLoading(
      state,
      payload: {
        field: string;
        tableId: string;
      }
    ) {
      const { field, tableId } = payload;
      return receiveTableFilterSelectionLoading(state, { field }, tableId);
    },
    sendTableFilterSelectionError(
      state,
      payload: {
        tableId: string;
        error: FetchError;
        field: string;
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveTableFilterSelectionError(state, data, tableId);
    },
    sendTableFilterSelection(
      state,
      payload: {
        tableId: string;
        selection: any[];
        field: string;
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveTableFilterSelection(state, data, tableId);
    },
    sendTableFilterRangeLoading(
      state,
      payload: {
        tableId: string;
        field: string;
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveTableFilterRangeLoading(state, data, tableId);
    },
    sendTableFilterRangeError(
      state,
      payload: {
        tableId: string;
        error: FetchError;
        field: string;
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveTableFilterRangeError(state, data, tableId);
    },
    sendTableFilterRange(
      state,
      payload: { tableId: string; range: { min: any; max: any }; field: string }
    ) {
      const { tableId, ...data } = payload;
      return receiveTableFilterRange(state, data, tableId);
    },
    sendTableSortOptions(
      state,
      payload: {
        tableId: string;
        sortColumns: ColumnSortInfo[];
        filterColumns: string[];
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveTableSortOptions(state, data, tableId);
    },
    sendTableColumns(
      state,
      payload: {
        tableId: string;
        selectedColumns: string[] | null;
        hiddenColumns: { [k: string]: boolean };
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveTableColumns(state, data, tableId);
    },
    sendSelectRow(state, payload: { tableId: string; key: string }) {
      const { tableId, ...data } = payload;
      return receiveSelectRow(state, data, tableId);
    },
    sendSelectAll(state, tableId: string) {
      return receiveSelectAll(state, null, tableId);
    },
    plainChangePageSize(
      state,
      payload: {
        tableId: string;
        pageSize: number;
      }
    ) {
      const { tableId, ...data } = payload;
      return receivePageSize(state, data, tableId);
    },
    plainChangePage(
      state,
      payload: {
        tableId: string;
        page: number;
        automationTableModule: AutomationTableModule;
        rowsCount?: number;
      }
    ) {
      const { tableId, ...data } = payload;
      return receivePage(state, data, tableId);
    },
    sortByColumn(state, payload: { tableId: string; key: string }) {
      const { tableId, ...data } = payload;
      return receiveSortKey(state, data, tableId);
    },
    filterByColumn(
      state,
      payload: {
        tableId: string;
        field: string;
        filterData: FilterData | null;
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveFilterData(state, data, tableId);
    },
    sendLocalTableInitialData(
      state,
      payload: {
        tableId: string;
        tableData: InitialLocalTableData;
      }
    ) {
      const { tableId, tableData } = payload;
      return receiveLocalTableInitialData(state, tableData, tableId);
    },
    sendLocalTableUpdateData(
      state,
      payload: {
        tableId: string;
        tableData: LocalTableData;
      }
    ) {
      const { tableId, tableData } = payload;
      return receiveLocalTableUpdateData(state, tableData, tableId);
    },
    sendLocalTableUninitialize(state, tableId: string) {
      return receiveLocalTableUninitialize(state, null, tableId);
    },
    sendField(
      state,
      payload: {
        tableId: string;
        parameter: string;
        value: string;
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveField(state, data, tableId);
    },
    sendGantViewType(
      state,
      payload: {
        tableId: string;
        type: GantViewType;
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveGantViewType(state, data, tableId);
    },
    sendGantSelectedGroup(
      state,
      payload: {
        tableId: string;
        group: string;
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveGantSelectedGroup(state, data, tableId);
    },
    sendGantChangeElement(
      state,
      payload: {
        tableId: string;
        rowIndex: number;
        itemIndex: number;
        newDate: GantDateLimits;
        isPlanned: boolean;
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveGantChangeElement(state, data, tableId);
    },
    sendDynamicColumnChanges(
      state,
      payload: { tableId: string; cols: string[] }
    ) {
      const { tableId, cols } = payload;
      const nextState = { ...state };

      const tableState = {
        ...(nextState[tableId] || DEFAULT_TABLE_STATE),
        tableId: tableId,
      };

      nextState[tableId] = tableState;
      tableState.dynamicColumnChanges = cols;
      return nextState;
    },
    sendDynamicColsChangesDeny(state, tableId: string) {
      const nextState = { ...state };

      const tableState = {
        ...(nextState[tableId] || DEFAULT_TABLE_STATE),
        tableId: tableId,
      };

      nextState[tableId] = tableState;
      if (!tableState.dynamicColumnChanges?.length) {
        tableState.dynamicColumns = null;
      } else {
        tableState.dynamicColumns = [
          ...(tableState.dynamicColumnChanges || []),
        ];
      }
      tableState.dynamicColumnChanges = undefined;
      return nextState;
    },
    sendDynamicColsChangesConfirm(state, tableId: string) {
      const nextState = { ...state };

      const tableState = {
        ...(nextState[tableId] || DEFAULT_TABLE_STATE),
        tableId: tableId,
      };

      nextState[tableId] = tableState;
      tableState.dynamicColumnChanges = undefined;
      return nextState;
    },
    sendFilterChangesConfirm(state, tableId: string) {
      return receiveFilterChangesConfirm(state, null, tableId);
    },
    sendFilterChangesDeny(state, tableId: string) {
      return receiveFilterChangesDeny(state, null, tableId);
    },

    sendTableSaveStart(state, tableId: string) {
      return receiveTableSaveStart(state, null, tableId);
    },
    sendTableSaveSuccess(state, tableId: string) {
      return receiveTableSaveSuccess(state, null, tableId);
    },
    sendTableSaveError(
      state,
      payload: {
        tableId: string;
        error: FetchError;
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveTableSaveError(state, data, tableId);
    },
    sendRowAdd(state, payload: { tableId: string; row: RowDataMap }) {
      const { tableId, ...data } = payload;
      return receiveTableRowAdd(state, data, tableId);
    },
    sendRowChange(
      state,
      payload: {
        tableId: string;
        row: RowDataMap;
        rowIdx: number;
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveTableRowChange(state, data, tableId);
    },
    sendCellChange(
      state,
      payload: {
        tableId: string;
        value: any;
        rowIdx: number;
        column: string;
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveTableCellChange(state, data, tableId);
    },
    sendClientSideFilter(
      state,
      payload: {
        tableId: string;
        clientSideFilter: boolean;
      }
    ) {
      const { tableId, ...data } = payload;
      return receiveTableClientSideFilter(state, data, tableId);
    },
    resetToDefault(state) {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    fetchTable: async (
      data: {
        tableId: string;
        fields?: { [k: string]: string };
        modelParameters?: ModelParameters;
        reset?: boolean;
        initOptions?: TableInitialOptions;
        forceLoadData?: boolean;
        forceLoadHeader?: boolean;
      },
      s
    ) => {
      const {
        tableId,
        fields: f,
        reset,
        forceLoadData,
        forceLoadHeader,
        initOptions,
      } = data;

      try {
        let fields = f;
        if (
          s &&
          s.table &&
          s.table[tableId] &&
          (s.table[tableId] as TableState).loading
        ) {
          return;
        }
        dispatch.table.sendTableInitialData({
          tableId,
          data: {
            initialSorting: initOptions?.initialSorting,
          },
        });

        if (typeof s.selection.info != "undefined") {
          fields = Object.assign({}, s.selection.info, fields);
        }
        if (!isHeaderLoaded(s.table[tableId]) || forceLoadHeader) {
          dispatch.table.sendTableLoading(tableId);

          const locationParams = fields?._ignoreLocationFields
            ? {}
            : s.location.params;

          fields = Object.assign({}, s.location.params, fields);
          const header = parseTableHeader(
            await fetchTableHeaderImpl(tableId),
            initOptions
          );
          if (header.userSettings?.fields) {
            fields = Object.assign(header.userSettings?.fields, fields);
          }
          if (header.finderOptions) {
            dispatch.finder.initializeFinder({
              finderId: tableId,
              finderOptions: header.finderOptions,
              finderFilter: header.userSettings?.finderInfo,
            });
          }
          dispatch.table.sendTableHeader({ tableId, header });
        }
        const finderState = s.finder[tableId];
        /**
         * If finder not ready - do not fetch data
         * (fetchTable will be again called from Table component when finder will be ready)
         **/
        if (finderState && !finderState.isReady) {
          console.log("Prevent table initialization: finder is not ready");
          return;
        }
        dispatch.table.fetchTableData({
          tableId,
          options: {
            fields,
            finder: finderState?.data,
            reset,
            resetFields: initOptions?.resetFields,
            modelParameters: data.modelParameters,
            onFetch: initOptions?.onFetch,
          },
          force: forceLoadData,
        });
      } catch (e: any) {
        console.error(e);
        if (e instanceof ServerError) {
          dispatch.table.sendTableError({
            tableId,
            error: { code: e.code, message: e.message },
          });
        } else if (typeof e.message == "string") {
          dispatch.table.sendTableError({
            tableId,
            error: { code: -1, message: e.message },
          });
        } else {
          dispatch.table.sendTableError({
            tableId,
            error: { code: -1, message: "Unknown error" },
          });
        }
      }
    },
    fetchTableData: async (
      data: {
        tableId: string;
        options: {
          fields?: { [k: string]: string } | null;
          modelParameters?: ModelParameters;
          finder?: FinderData | null;
          reset?: boolean;
          savePage?: boolean;
          resetFields?: boolean;
          onFetch?: (
            request: TableRequest
          ) => TableRequest | Promise<TableRequest>;
        };
        force?: boolean;
      },
      state
    ) => {
      const { tableId, options, force: f } = data;
      const force = f || false;
      const {
        fields,
        finder,
        reset,
        savePage,
        resetFields,
        onFetch,
        modelParameters: mp,
      } = options;
      let page = 0;
      let pageSize = -1;
      try {
        const tableState = state.table[tableId];
        const tableAbortLoading = tableState.abortLoading;
        if (tableState.loadingData) {
          console.warn("Can't fetch table data: data is already loading");
          return;
        }

        // if (tableState.loadingData && tableAbortLoading) {
        //   console.log("abort");
        //   tableAbortLoading.abort();
        // }
        const finderState = state.finder[tableId] || null;
        const finderData = finder || finderState?.data || null;
        /* Add pagination query parameters */
        const pageable = Boolean(tableState?.pageable);
        if (pageable) {
          if (reset) {
            pageSize = tableState.pageSize;
            if (savePage) {
              page = tableState.page;
            }
          } else {
            page = fields && fields._p ? parseInt(fields._p) : tableState.page;
            pageSize =
              fields && fields._ps ? parseInt(fields._ps) : tableState.pageSize;
          }
        }
        const modelParameters = mp || tableState.modelParameters;
        const compositeRequest = composeTableRequest({
          tableState,
          finderState,
          modelParameters,
          page,
          pageSize,
          fields: fields || {},

          language: state.locale.language,
          finderData,
          resetFields,
        });
        const tableRequest = await prepareTableRequest(
          compositeRequest,
          onFetch
        );
        const controller = new AbortController();
        const parameters = tableState?.parameters;

        dispatch.table.sendTableDataLoading({
          tableId,
          page: page,
          pageSize: pageSize,
          abort: controller,
        });

        if (parameters) {
          const searchData: { [k: string]: string } = {};
          for (let k in parameters) {
            const name = parameters[k].name;
            if (tableRequest.parameters?.[name]) {
              searchData[name] = tableRequest.parameters[name];
            }
          }
          let paramsReadOnly = fields?._paramsReadOnly;
          if (typeof paramsReadOnly == "undefined") {
            paramsReadOnly = tableState.fields._paramsReadOnly;
          }
          if (paramsReadOnly !== "true") {
            dispatch.location.changeSearch({ dataChanges: searchData });
          }
        }
        const getAutomationTable = (state: TableState) =>
          getAutomationTableModule(state, dispatch, onFetch);
        const tableData: TableData = parseTableData(
          await fetchTableDataImpl(tableId, tableRequest, controller),
          pageable,
          page,
          pageSize,
          tableState.totalRowsLength,
          fields?._paramsReadOnly
        );
        tableData.modelParameters = modelParameters;
        dispatch.table.sendTableData({
          tableId,
          data: tableData,
          getAutomationTableModule: getAutomationTable,
          reset,
          savePage,
        });
        return tableData;
      } catch (e: any) {
        console.log("e=====", e);
        if (e.name == "AbortError") {
          // console.log("error", e);
          return;
        }
        if (e instanceof ServerError) {
          dispatch.table.sendTableDataError({
            tableId,
            error: { code: e.code, message: e.message },
            page,
            pageSize,
          });
        } else if (typeof e.message == "string") {
          dispatch.table.sendTableDataError({
            tableId,
            error: { code: -1, message: e.message },
            page,
            pageSize,
          });
        } else {
          dispatch.table.sendTableDataError({
            tableId,
            error: { code: -1, message: "Unknown error" },
            page,
            pageSize,
          });
        }
      }
    },
    fetchTableFilterSelection: async (
      data: {
        tableId: string;
        columnField: string;
        filterData: FilterData | null;
        onFetch?: (
          request: TableRequest
        ) => TableRequest | Promise<TableRequest>;
      },
      state
    ) => {
      const { tableId: tableIdFull, columnField, filterData, onFetch } = data;
      try {
        const tableState = state.table[tableIdFull];
        const finderState = state.finder[tableIdFull] || null;
        const finderData = finderState?.data || null;

        if (tableState.clientSideFilter) {
          dispatch.table.sendTableFilterSelection({
            tableId: tableIdFull,
            field: columnField,
            selection: getLocalSelection(tableState, columnField),
          });

          return;
        }

        dispatch.table.sendTableFilterSelectionLoading({
          tableId: tableIdFull,
          field: columnField,
        });

        const columnFilters = tableState.filterInfo.columns.slice();
        const existingFilterInfoIdx = columnFilters.findIndex(
          (filter) => filter.field === columnField
        );
        if (existingFilterInfoIdx !== -1) {
          if (!filterData) {
            columnFilters.splice(existingFilterInfoIdx, 1);
          } else {
            columnFilters.splice(existingFilterInfoIdx, 1, {
              field: columnField,
              data: filterData,
            });
          }
        } else if (filterData) {
          columnFilters.push({ field: columnField, data: filterData });
        }
        const compositeRequest = composeTableRequest({
          tableState,
          finderState,
          page: tableState.page,
          pageSize: tableState.pageSize,
          fields: tableState.fields,
          language: state.locale.language,
          finderData,
          modelParameters: tableState.modelParameters,
        });
        const tableRequest = await prepareTableRequest(
          compositeRequest,
          onFetch
        );

        const dynamicCols = tableState.columns
          .filter((c) => c.dynamic && !c.hidden)
          .map((c) => c.field);

        const selection = await fetchTableFilterSelectionImpl(
          tableState.tableId,
          columnField,
          columnFilters.filter((c) => !dynamicCols?.includes(c.field)),
          tableRequest
        );
        dispatch.table.sendTableFilterSelection({
          tableId: tableIdFull,
          field: columnField,
          selection,
        });
      } catch (e: any) {
        console.error(e);
        if (e instanceof ServerError) {
          dispatch.table.sendTableFilterSelectionError({
            tableId: tableIdFull,
            error: { code: e.code, message: e.message },
            field: columnField,
          });
        } else if (typeof e.message == "string") {
          dispatch.table.sendTableFilterSelectionError({
            tableId: tableIdFull,
            error: { code: -1, message: e.message },
            field: columnField,
          });
        } else {
          dispatch.table.sendTableFilterSelectionError({
            tableId: tableIdFull,
            error: { code: -1, message: "Unknown error" },
            field: columnField,
          });
        }
      }
    },
    fetchTableFilterRange: async (
      data: {
        tableId: string;
        columnField: string;
        filterData: FilterData | null;
        onFetch?: (
          request: TableRequest
        ) => TableRequest | Promise<TableRequest>;
      },
      state
    ) => {
      const { tableId, columnField, filterData, onFetch } = data;
      try {
        const tableState = state.table[tableId];
        const finderState = state.finder[tableId] || null;
        const finderData = finderState?.data || null;

        if (tableState.clientSideFilter) {
          dispatch.table.sendTableFilterRange({
            tableId,
            field: columnField,
            range: getLocalRange(tableState, columnField),
          });

          return;
        }

        dispatch.table.sendTableFilterRangeLoading({
          tableId,
          field: columnField,
        });
        const columnFilters = tableState.filterInfo.columns.slice();
        const existingFilterInfoIdx = columnFilters.findIndex(
          (filter) => filter.field === columnField
        );
        if (existingFilterInfoIdx !== -1) {
          if (!filterData) {
            columnFilters.splice(existingFilterInfoIdx, 1);
          } else {
            columnFilters.splice(existingFilterInfoIdx, 1, {
              field: columnField,
              data: filterData,
            });
          }
        } else if (filterData) {
          columnFilters.push({ field: columnField, data: filterData });
        }
        const compositeRequest = composeTableRequest({
          tableState,
          finderState,
          page: tableState.page,
          pageSize: tableState.pageSize,
          fields: tableState.fields,
          language: state.locale.language,
          modelParameters: tableState.modelParameters,
          finderData,
        });
        const tableRequest = await prepareTableRequest(
          compositeRequest,
          onFetch
        );
        const dynamicCols = tableState.columns
          .filter((c) => c.dynamic && !c.hidden)
          .map((c) => c.field);

        const range = await fetchTableFilterRangeImpl(
          tableId,
          columnField,
          columnFilters.filter((c) => !dynamicCols?.includes(c.field)),

          tableRequest
        );
        dispatch.table.sendTableFilterRange({
          tableId,
          field: columnField,
          range,
        });
      } catch (e: any) {
        console.error(e);
        if (e instanceof ServerError) {
          dispatch.table.sendTableFilterRangeError({
            tableId,
            error: { code: e.code, message: e.message },
            field: columnField,
          });
        } else if (typeof e.message == "string") {
          dispatch.table.sendTableFilterRangeError({
            tableId,
            error: { code: -1, message: e.message },
            field: columnField,
          });
        } else {
          dispatch.table.sendTableFilterRangeError({
            tableId,
            error: { code: -1, message: "Unknown error" },
            field: columnField,
          });
        }
      }
    },
    openTableSortOptions: (tableId: string) => {
      dispatch.modal.openModal({
        id: "table_sort_options_modal",
        type: "tableSortOptions",
        options: { tableId },
        okCallback: (result: {
          sortColumns: ColumnSortInfo[];
          filterColumns: string[];
        }) => {
          dispatch.table.sendTableSortOptions({ tableId, ...result });
        },
      });
    },
    openTableDynamicColumns: (tableId: string, s) => {
      dispatch.modal.openModal({
        id: "table_dynamic_columns_options_modal",
        type: "tableDynamicColumnsOptions",
        options: { tableId },
        okCallback: (result: {
          selectedColumns: string[] | null;
          hiddenColumns: { [k: string]: boolean };
        }) => {
          const tableState = s.table[tableId];
          if (result.selectedColumns === null) {
            console.log("Reset dynamic columns");
            return;
          }
          dispatch.table.sendTableColumns({
            tableId,
            ...result,
          });
        },
      });
    },
    downloadReport: async (
      data: {
        tableId: string;
        report: ToolbarReport;
        onFetch?: (
          request: TableRequest
        ) => TableRequest | Promise<TableRequest>;
      },
      s
    ) => {
      const { tableId, report, onFetch } = data;
      const tableState = s.table[tableId];
      const finderState = s.finder[tableId] || null;
      const finderData = finderState?.data || null;

      const compositeRequest = composeTableRequest({
        tableState,
        finderState,
        page: tableState.page,
        pageSize: tableState.pageSize,
        fields: tableState.fields,
        language: s.locale.language,
        finderData,
      });
      const tableRequest = await prepareTableRequest(compositeRequest, onFetch);

      let href = null;
      const filename = getFileName(report.label, report.type, report.filename);
      if (!isFilterNeeded(tableRequest)) {
        const fields = getTableSearchFields(tableRequest);
        delete fields._p;
        delete fields._ps;
        fields._report = report.name;
        fields._filename = filename;
        href = buildUrl({
          url: `/rest/table/report${tableId}`,
          search: fields,
          withoutEncoding: true,
        });
      } else {
        if (tableRequest.range?.sortItemList) {
          tableRequest.range = {
            sortItemList: tableRequest.range.sortItemList,
          };
        } else {
          delete tableRequest.range;
        }
        try {
          let reportUuid = await fetchReportUuid(
            tableId,
            tableRequest,
            report.name,
            filename
          );
          href = `/rest/file/report/${reportUuid}`;
        } catch (e: any) {
          console.error(e);
        }
      }

      if (!href) {
        return;
      }

      downloadFile(href, filename);
    },
    buttonClick(
      data: {
        tableId: string;
        buttonId: string;
        onFetch?: (
          request: TableRequest
        ) => TableRequest | Promise<TableRequest>;
      },
      s
    ) {
      const { tableId, buttonId, onFetch } = data;

      const tableData = s.table[tableId];
      if (
        !tableData.automation.clickBindings ||
        !tableData.automation.clickBindings[buttonId]
      ) {
        return;
      }
      const func = retrieveFunction(
        tableData.automation.clickBindings[buttonId]
      );
      const automationTableModule = getAutomationTableModule(
        tableData,
        dispatch,
        onFetch
      );
      func(automationTableModule);
    },
    changePageSize: (
      data: {
        tableId: string;
        pageSize: number;
        onFetch?: (
          request: TableRequest
        ) => TableRequest | Promise<TableRequest>;
      },
      s
    ) => {
      const { tableId, pageSize, onFetch } = data;
      const tableData = s.table[tableId];
      if (!tableData.pageable || tableData.pageSize === pageSize) {
        return;
      }
      const firstRowIdx = tableData.page * tableData.pageSize;
      const newPage = Math.floor(firstRowIdx / pageSize);
      dispatch.table.fetchPageData({
        tableId,
        page: newPage,
        pageSize,
        onFetch,
      });
      dispatch.table.plainChangePageSize({ tableId, pageSize });
    },
    changePage: async (
      data: {
        tableId: string;
        page: number;
        onFetch?: (
          request: TableRequest
        ) => TableRequest | Promise<TableRequest>;
      },
      s
    ) => {
      const { tableId, page, onFetch } = data;
      const tableData = s.table[tableId];
      if (!tableData.pageable || tableData.page === page) {
        return;
      }
      const rowsCount = await dispatch.table.fetchPageData({
        tableId,
        page,
        pageSize: tableData.pageSize,
        onFetch,
      });
      const automationTableModule = getAutomationTableModule(
        tableData,
        dispatch,
        onFetch
      );

      dispatch.table.plainChangePage({
        tableId,
        page,
        automationTableModule,
        rowsCount,
      });
    },
    fetchPageData: async (
      data: {
        tableId: string;
        page: number;
        pageSize: number;
        onFetch?: (
          request: TableRequest
        ) => TableRequest | Promise<TableRequest>;
      },
      s
    ) => {
      const { tableId, page, pageSize, onFetch } = data;
      const tableData = s.table[tableId];
      /* Check if fetch needed */
      if (isPageFetched(tableData, page, pageSize)) {
        return undefined;
      }
      const table = dispatch.table as any;
      const fetchedData = await table.fetchTableData({
        tableId,
        options: {
          fields: { _p: page.toString(), _ps: pageSize.toString() },
          onFetch,
        },
      });

      return fetchedData?.rows?.length || 0;
    },
    changeGantElement: (
      data: {
        tableId: string;
        rowIndex: number;
        itemIndex: number;
        newDate: GantDateLimits;
        isPlanned: boolean;
      },
      s
    ) => {
      const { tableId, rowIndex, itemIndex, newDate, isPlanned } = data;
      dispatch.table.sendGantChangeElement({
        tableId,
        rowIndex,
        itemIndex,
        newDate,
        isPlanned,
      });
    },
    confirmFilterChanges: (
      data: {
        tableId: string;
        onFetch?: (
          request: TableRequest
        ) => TableRequest | Promise<TableRequest>;
      },
      s
    ) => {
      const { tableId, onFetch } = data;
      const tableState = s.table[tableId];
      const finderState = s.finder[tableId] || null;

      if (
        !tableState?.filterChanges &&
        !finderState?.changes &&
        !tableState?.dynamicColumnChanges
      ) {
        return;
      }
      dispatch.table.fetchTableData({
        tableId,
        options: {
          fields: tableState.filterChanges?.fields,
          finder: finderState?.changes,
          reset: true,
          onFetch,
        },
      });

      dispatch.finder.sendFinderChangesConfirm(tableId);
      dispatch.table.sendFilterChangesConfirm(tableId);
      dispatch.table.sendDynamicColsChangesConfirm(tableId);
    },

    cancelFilterChanges: (tableId: string) => {
      dispatch.finder.sendFinderChangesDeny(tableId);
      dispatch.table.sendFilterChangesDeny(tableId);
      dispatch.table.sendDynamicColsChangesDeny(tableId);
    },
    saveTable: async (
      data: {
        tableId: string;
        onFetch?: (
          request: TableRequest
        ) => TableRequest | Promise<TableRequest>;
      },
      s
    ) => {
      const { tableId, onFetch } = data;
      const tableState = s.table[tableId];
      const savingRows: {
        changed: { [k: string]: boolean };
        data: RowDataMap;
      }[] = [];
      for (let rowIdx in tableState.rowByIdx) {
        const row = tableState.rowByIdx[rowIdx];
        if (!row.changed) {
          continue;
        }
        savingRows.push({
          changed: row.changed,
          data: Object.assign({}, row.data, row.bindedData, row.changedData),
        });
      }
      if (savingRows.length === 0) {
        return;
      }
      dispatch.table.sendTableSaveStart(tableId);
      try {
        const response = await saveTableImpl(
          tableId,
          parseToServerData(tableState.columns, tableState.fields, savingRows)
        );
        response.text();
        if (response.status === 200) {
          dispatch.alert.addAlert({
            type: ALERT_LEVEL_SUCCESS,
            message: { id: "NPT_TABLE_SAVE_SUCCESS" },
          });
        } else {
          const message = await receiveServerMessage(response);
          dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message });
        }
        dispatch.table.sendTableSaveSuccess(tableId);
        dispatch.table.fetchPageData({
          tableId,
          page: tableState.page,
          pageSize: tableState.pageSize,
          onFetch,
        });
      } catch (e: any) {
        console.error(e);
        if (e instanceof ServerError) {
          dispatchErrorV2(e.message, e, dispatch);
          dispatch.table.sendTableSaveError({
            tableId,
            error: { code: e.code, message: e.message },
          });
        } else if (typeof e.message == "string") {
          dispatchErrorV2(e.message, e, dispatch);
          dispatch.table.sendTableSaveError({
            tableId,
            error: { code: -1, message: e.message },
          });
        } else {
          dispatchError("NPT_TABLE_SAVE_ERROR", e, dispatch);
          dispatch.table.sendTableSaveError({
            tableId,
            error: { code: -1, message: "Unknown error" },
          });
        }
      }
    },
    resolveDrop: async (
      data: {
        tableId: string;
        collectedData: any;
        dropInfo: {
          cell?: any;
          row?: RowData;
          rowIdx?: number;
          column?: string;
          columnIdx?: number;
        };
        resolveDropFunctionId: string;
        onFetch?: (
          request: TableRequest
        ) => TableRequest | Promise<TableRequest>;
      },
      s
    ) => {
      const {
        tableId,
        collectedData,
        dropInfo,
        resolveDropFunctionId,
        onFetch,
      } = data;
      const tableData = s.table[tableId];
      const func = retrieveFunction(resolveDropFunctionId);
      const automationTableModule = getAutomationTableModule(
        tableData,
        dispatch,
        onFetch
      );
      try {
        await func(collectedData, dropInfo, automationTableModule);
      } catch (e) {
        console.error("Failure of the table resolve drop function", e);
      }
    },
    addRow: (data: { tableId: string; row: RowDataMap }) => {
      dispatch.table.sendRowAdd(data);
    },
    changeRow: (
      data: {
        tableId: string;
        row: RowDataMap;
        rowIdx: number;
      },
      s
    ) => {
      const { tableId, row, rowIdx } = data;
      const tableData = s.table[tableId];
      if (!tableData.rowByIdx[rowIdx]) {
        dispatchError("NPT_TABLE_CHANGE_ROW_NOT_LOADED", null, dispatch, {
          rowIdx,
        });
        // dispatch(addAlert(ALERT_LEVEL_DANGER, { id: "NPT_TABLE_CHANGE_ROW_NOT_LOADED", values: { rowIdx } }));
        return;
      }
      dispatch.table.sendRowChange(data);
    },
    changeCell: (
      data: {
        tableId: string;
        value: any;
        rowIdx: number;
        column: string;
      },
      s
    ) => {
      const { tableId, value, rowIdx, column } = data;
      const tableData = s.table[tableId];
      if (!column) {
        dispatchError(
          "NPT_TABLE_CHANGE_CELL_UNDEFINED_COLUMN",
          null,
          dispatch,
          {
            column,
          }
        );
        // dispatch(addAlert(ALERT_LEVEL_DANGER, { id: "NPT_TABLE_CHANGE_CELL_UNDEFINED_COLUMN", values: { column } }));
        return;
      }
      if (!tableData.rowByIdx[rowIdx]) {
        dispatchError("NPT_TABLE_CHANGE_CELL_NOT_LOADED", null, dispatch, {
          column,
          rowIdx,
        });
        // dispatch(addAlert(ALERT_LEVEL_DANGER, { id: "NPT_TABLE_CHANGE_CELL_NOT_LOADED", values: { column, rowIdx } }));
        return;
      }
      dispatch.table.sendCellChange(data);
    },
    saveFilter: async (tableId: string, s) => {
      const tableState = s.table[tableId];
      const finderState = s.finder[tableId];
      try {
        const filter: TableUserSettings = {
          fields: tableState.fields,
          dynamicColumns: tableState.dynamicColumns,
          dynamicHiddenColumns: tableState.dynamicHiddenColumns,
          sortInfo: tableState.sortInfo,
          filterInfo: tableState.filterInfo,
          finderInfo: null,
        };
        if (finderState) {
          filter.finderInfo = parseFinderToFilter(
            finderState,
            finderState.data
          );
        }
        const response = await saveFilterImpl(tableId, filter);
        response.text();
        if (response.status === 200) {
          dispatch.alert.addAlert({
            type: ALERT_LEVEL_SUCCESS,
            message: { id: "NPT_TABLE_FILTER_SAVE_SUCCESS" },
          });
        } else {
          const message = await receiveServerMessage(response);
          dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message });
        }
      } catch (e: any) {
        console.error(e);
        if (e instanceof ServerError) {
          dispatchErrorV2(e.message, e, dispatch);
        } else if (typeof e.message == "string") {
          dispatchErrorV2(e.message, e, dispatch);
        } else {
          dispatchError("NPT_TABLE_FILTER_SAVE_ERROR", e, dispatch);
        }
      }
    },
    resetFilter: async (tableId: string) => {
      try {
        const response = await saveFilterImpl(tableId, {});
        response.text();
        if (response.status === 200) {
          dispatch.alert.addAlert({
            type: ALERT_LEVEL_SUCCESS,
            message: {
              id: "NPT_TABLE_FILTER_RESET_SUCCESS",
            },
          });
        } else {
          const message = await receiveServerMessage(response);
          dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message });
        }
      } catch (e: any) {
        console.error(e);
        if (e instanceof ServerError) {
          dispatchErrorV2(e.message, e, dispatch);
        } else if (typeof e.message == "string") {
          dispatchErrorV2(e.message, e, dispatch);
        } else {
          dispatchError("NPT_TABLE_FILTER_RESET_ERROR", e, dispatch);
        }
      }
    },
  }),
});

/**
 * Utils functions
 */

export const DEFAULT_TABLE_STATE: TableState = {
  tableId: "default",
  loading: false,
  loadingData: false,
  loadingPages: {},
  error: null,
  errorData: null,
  saving: false,
  savingError: null,
  stylesheets: {},
  parameters: {},
  fields: {},
  pageRows: [],
  selectedRowsLength: 0,
  selectedRows: {},
  toolbar: [],
  reports: [],
  columns: [],
  dynamicColumns: null,
  dynamicHiddenColumns: {},
  rowByIdx: {},
  totalRowsLength: 0,
  pageSize: -1,
  pageSelection: [],
  page: 0,
  pageable: false,
  selectType: null,
  automation: {
    cellClassBindings: null,
    valueBindings: null,
    textBindings: null,
    visibilityBindings: null,
    visibilityToolbarBindings: null,
    accumBindings: null,
    accumFilterBindings: null,
    accumInitialValues: null,
    clickBindings: null,
    postProcessBinding: null,
  },
  sortInfo: {
    columns: [],
  },
  filterInfo: {
    columns: [],
    selectionByField: {},
    rangeByField: {},
  },
  filterChanges: null,
  gantOptions: null,
  gantData: {
    dateLimits: {
      from: -1,
      to: -1,
    },
    displayScales: [],
    scale: "year",
    cellsByScale: {},
    gantMinWidth: 0,
    viewType: "read",
    selectedGroup: "",
  },
  dragOptions: null,
  dropOptions: null,
  clientSideFilter: false,
  clientSideData: null,
};

/*********************
 * Utility funcitons *
 *********************/

/**Parse user script to get binding functions */
function parseAutomation(script: string | null): AutomationBindings {
  const parsedAutomation: AutomationBindings = Object.assign(
    {},
    DEFAULT_TABLE_STATE.automation
  );
  if (!script) {
    return parsedAutomation;
  }
  const bindings: AutomationBindingFunctions =
    generateTableBindings(parsedAutomation);
  scriptCompiler(script, bindings);
  return parsedAutomation;
}

/**Apply automations for page rows */
function applyAutomations(
  tableState: TableState,
  rows: RowData[],
  automationTableModule?: AutomationTableModule
) {
  applyValueBindings(tableState, rows);
  applyClassBindings(tableState, rows);
  applyVisibilityAutomations(tableState, rows);
  applyVisibilityToolbarAutomations(tableState, rows);
  runAutomationAccumulators(tableState, rows);
  applyTextBindings(tableState, rows, automationTableModule);
}

function applyValueBindings(tableState: TableState, rows: RowData[]) {
  if (!tableState.automation.valueBindings) {
    return;
  }
  applyBindings(tableState, rows, tableState.automation.valueBindings, true);
}

function applyTextBindings(
  tableState: TableState,
  rows: RowData[],
  automationTableModule?: AutomationTableModule
) {
  if (!tableState.automation.textBindings) {
    return;
  }
  applyBindings(
    tableState,
    rows,
    tableState.automation.textBindings,
    false,
    automationTableModule
  );
}

function applyClassBindings(tableState: TableState, rows: RowData[]) {
  if (!tableState.automation.cellClassBindings) {
    return;
  }
  for (let rowIndex = 0; rowIndex < rows.length; ++rowIndex) {
    let row = rows[rowIndex];
    if (!row) {
      continue;
    }
    for (
      let columnIndex = 0;
      columnIndex < tableState.columns.length;
      ++columnIndex
    ) {
      let field = tableState.columns[columnIndex].field;
      let bindings = tableState.automation.cellClassBindings[field];
      if (!bindings) {
        continue;
      }
      let columnClassNameFunc = retrieveFunction(bindings);
      if (!columnClassNameFunc) {
        continue;
      }
      const currentTheme = ThemeService.getTheme();
      const rowData = getRowAutomationData(row);
      row.classes[field] = columnClassNameFunc(
        rowData[field],
        rowData,
        rowIndex,
        columnIndex,
        currentTheme.platformTheme
      );
    }
  }
}

function applyVisibilityAutomations(tableState: TableState, rows: RowData[]) {
  if (!tableState.automation.visibilityBindings || !tableState.columns) {
    return;
  }
  tableState.columns = tableState.columns.slice();
  for (let i = 0; i < tableState.columns.length; ++i) {
    const column = tableState.columns[i];
    const bindings = tableState.automation.visibilityBindings[column.field];
    if (!bindings) {
      continue;
    }
    const columnVisibilityFunc = retrieveFunction(bindings);
    if (!columnVisibilityFunc) {
      continue;
    }
    const rowDataList = rows.map((row) => getRowAutomationData(row));
    tableState.columns[i] = {
      ...tableState.columns[i],
      hidden: !columnVisibilityFunc(rowDataList, tableState.fields),
    };
  }
}

function applyVisibilityToolbarAutomations(
  tableState: TableState,
  rows: RowData[]
) {
  if (!tableState.automation.visibilityToolbarBindings || !tableState.toolbar) {
    return;
  }
  const visibilityToolbarBindings =
    tableState.automation.visibilityToolbarBindings;
  const rowDataList = rows.map((row) => getRowAutomationData(row));

  const getBindingFunction = (id: string) => {
    const bindings = visibilityToolbarBindings[id];
    if (!bindings) {
      return null;
    }
    const itemVisibilityFunc = retrieveFunction(bindings);
    if (!itemVisibilityFunc) {
      return null;
    }
    return itemVisibilityFunc;
  };

  const checkItemsVisibility = (items: ToolbarItem[]) => {
    for (let i = 0; i < items.length; ++i) {
      let item = items[i];
      if (isToolbarGroup(item)) {
        items[i] = { ...item };
        (items[i] as ToolbarGroup).items = item.items.slice();
        checkItemsVisibility((items[i] as ToolbarGroup).items);
      }
      const itemVisibilityFunc = getBindingFunction(item.id);
      if (!itemVisibilityFunc) {
        continue;
      }
      items[i] = {
        ...items[i],
        hidden: !itemVisibilityFunc(rowDataList, tableState.fields),
      };
    }
  };

  tableState.toolbar = tableState.toolbar.slice();
  checkItemsVisibility(tableState.toolbar);
}

function applyBindings(
  tableState: TableState,
  rows: RowData[],
  bindings: { [col: string]: string },
  ignoreBindings: boolean,
  automationTableModule?: AutomationTableModule
) {
  for (let rowIndex = 0; rowIndex < rows.length; ++rowIndex) {
    let row = rows[rowIndex];
    if (!row) {
      continue;
    }
    for (
      let columnIndex = 0;
      columnIndex < tableState.columns.length;
      ++columnIndex
    ) {
      let columnField = tableState.columns[columnIndex].field;
      let columnBindings = bindings[columnField];
      if (!columnBindings) {
        continue;
      }
      let bindingsFunc = retrieveFunction(columnBindings);
      if (!bindingsFunc) {
        continue;
      }
      const rowData = ignoreBindings ? row.data : getRowAutomationData(row);
      row.bindedData[columnField] = bindingsFunc(
        rowData[columnField],
        rowData,
        rowIndex,
        columnIndex,
        automationTableModule
      );
    }
  }
}

function runAutomationAccumulators(tableState: TableState, rows: RowData[]) {
  if (!tableState.automation) {
    return;
  }
  if (tableState.automation.accumFilterBindings) {
    tableState.fields = Object.assign({}, tableState.fields);
    runAccumulators(
      rows,
      tableState.automation.accumInitialValues,
      tableState.fields,
      tableState.automation.accumFilterBindings
    );
  }
  //Postprocess
  if (tableState.automation.postProcessBinding) {
    const func = retrieveFunction(tableState.automation.postProcessBinding);
    const rowDataList = rows.map((row) => getRowAutomationData(row));
    func(tableState.fields, rowDataList);
  }
}

function runAccumulators(
  rows: RowData[],
  initialValues: { [accum: string]: string } | null = {},
  fields: { [k: string]: string },
  bindings: { [accum: string]: string }
) {
  if (!initialValues) {
    initialValues = {};
  }
  //Initialize accumulator values
  for (let accum in bindings) {
    fields[accum] = initialValues[accum];
  }
  //Process data
  for (let row of rows) {
    if (!row) {
      continue;
    }
    const rowData = getRowAutomationData(row);
    for (let accum in bindings) {
      let func = retrieveFunction(bindings[accum]);
      let prev = fields[accum];
      fields[accum] = func(rowData, prev);
    }
  }
}

function resetTableData(tableState: TableState, savePage?: boolean) {
  tableState.rowByIdx = { ...DEFAULT_TABLE_STATE.rowByIdx };
  tableState.pageRows = DEFAULT_TABLE_STATE.pageRows.slice();
  tableState.loadingData = DEFAULT_TABLE_STATE.loadingData;
  tableState.loadingPages = { ...DEFAULT_TABLE_STATE.loadingPages };
  tableState.totalRowsLength = DEFAULT_TABLE_STATE.totalRowsLength;
  if (!savePage) {
    changePage({ tableState, page: 0 });
  }
}

function resetGantData(tableState: TableState) {
  if (tableState.gantOptions === null) {
    return;
  }
  tableState.gantData = { ...tableState.gantData };
  tableState.gantData.dateLimits = {
    from: -1,
    to: -1,
  };
}

function changePage(data: {
  tableState: TableState;
  page: number;
  automationTableModule?: AutomationTableModule;
  rowsCount?: number;
}) {
  const { page, tableState, automationTableModule, rowsCount } = data;
  tableState.page = page;
  updatePageData({ tableState, automationTableModule, rowsCount });
}

function updatePageData(data: {
  tableState: TableState;
  automationTableModule?: AutomationTableModule;
  rowsCount?: number;
}) {
  const { tableState, automationTableModule, rowsCount } = data;
  let rowStartIdx = 0;
  if (tableState.pageSize !== -1) {
    rowStartIdx += tableState.pageSize * tableState.page;
  }

  let rowEndIdx = tableState.totalRowsLength;
  if (tableState.pageSize !== -1) {
    rowEndIdx =
      rowStartIdx + tableState.pageSize < tableState.totalRowsLength
        ? rowStartIdx + tableState.pageSize
        : tableState.totalRowsLength;
  }
  tableState.pageRows = [];
  if (typeof rowsCount !== "undefined") {
    rowEndIdx = rowStartIdx + rowsCount;
  }

  if (!tableState.clientSideFilter || !tableState.clientSideData) {
    for (let i = rowStartIdx; i < rowEndIdx; ++i) {
      tableState.pageRows.push(i);
    }
  } else {
    for (let i = rowStartIdx; i < rowEndIdx; ++i) {
      tableState.pageRows.push(tableState.clientSideData.sortedRows[i]);
    }
  }
  const rows = getTableRows(tableState);
  applyAutomations(tableState, rows, automationTableModule);
}

/**Try to get limit value by parsing table data */
function calculateLimitGantDate(
  tableState: TableState,
  field: string,
  optionsLimit: number,
  isLarger: boolean
): number {
  let limit = optionsLimit;
  for (let index of tableState.pageRows) {
    const row = tableState.rowByIdx[index];
    const gantData =
      row.changedData.gantData ||
      row.bindedData.gantData ||
      row.data.gantData ||
      null;
    if (gantData === null) {
      continue;
    }
    let items: any[] = [];
    if (Array.isArray(gantData.chosenDate)) {
      items = items.concat(gantData.chosenDate);
    }
    if (Array.isArray(gantData.plannedDate)) {
      items = items.concat(gantData.plannedDate);
    }
    for (let item of items) {
      if (!item || typeof item !== "object") {
        continue;
      }
      let value = item[field];
      if (typeof value === "string") {
        value = moment(value).valueOf();
      }
      if (typeof value !== "number" || isNaN(value)) {
        continue;
      }
      if (limit === -1) {
        limit = value;
        continue;
      }
      limit = isLarger ? Math.max(limit, value) : Math.min(limit, value);
    }
  }
  return limit;
}

/**Set new date limits based on gant options, fields and table data (returns true if date was changed) */
function updateGantDateLimits(tableState: TableState, reset?: boolean) {
  if (tableState.gantOptions === null) {
    return;
  }

  tableState.gantData = { ...tableState.gantData };
  /**On reset setup undefined date limits */
  if (reset) {
    tableState.gantData.dateLimits = { from: -1, to: -1 };
    return;
  }
  tableState.gantData.dateLimits = { ...tableState.gantData.dateLimits };

  /**Get string values of date limits from options */
  const options = {
    from: replaceFieldPlaceholders(
      tableState.gantOptions.dateLimits.from,
      tableState.fields
    ),
    to: replaceFieldPlaceholders(
      tableState.gantOptions.dateLimits.to,
      tableState.fields
    ),
    maxFrom: replaceFieldPlaceholders(
      tableState.gantOptions.dateLimits.maxFrom,
      tableState.fields
    ),
    minTo: replaceFieldPlaceholders(
      tableState.gantOptions.dateLimits.minTo,
      tableState.fields
    ),
  };

  /**Calculate actual date limits */
  let from = parseGantDate(options.from);
  let to = parseGantDate(options.to);
  /**If value was not already defined, than we need to calculate value from data */
  if (from === -1) {
    let maxFrom = parseGantDate(options.maxFrom);
    from = calculateLimitGantDate(tableState, "from", maxFrom, false);
  }
  if (to === -1) {
    let minTo = parseGantDate(options.minTo);
    to = calculateLimitGantDate(tableState, "to", minTo, true);
  }
  /**Check if limits is still undefined and set current date as value */
  if (from === -1) {
    from = to !== -1 ? to : Date.now();
  }
  if (to === -1) {
    /**At this moment 'from' value is defined */
    to = from;
  }
  const dateLimits = {
    from: roundGantTimestamp(from, tableState.gantOptions.scale, "floor"),
    to: roundGantTimestamp(to, tableState.gantOptions.scale, "ceil"),
  };
  if (tableState.gantOptions.fullWeeks) {
    dateLimits.from = roundGantTimestamp(dateLimits.from, "week", "floor");
    dateLimits.to = roundGantTimestamp(dateLimits.to, "week", "ceil");
  }
  let changed = false;
  /**Date limits can only grow */
  if (
    tableState.gantData.dateLimits.from === -1 ||
    tableState.gantData.dateLimits.from > dateLimits.from
  ) {
    tableState.gantData.dateLimits.from = dateLimits.from;
    changed = true;
  }
  if (
    tableState.gantData.dateLimits.to === -1 ||
    tableState.gantData.dateLimits.to < dateLimits.to
  ) {
    tableState.gantData.dateLimits.to = dateLimits.to;
    changed = true;
  }
  if (changed) {
    console.log(
      "Update date limits:",
      new Date(tableState.gantData.dateLimits.from),
      new Date(tableState.gantData.dateLimits.to)
    );
  }
  const fromDate = new Date(tableState.gantData.dateLimits.from);
  if (tableState.gantOptions.scale == "day" && fromDate.getMonth() == 11) {
    const toDate = new Date();
    toDate.setDate(1);
    toDate.setMonth(0);
    // toDate.setDate(31);
    // toDate.setMonth(11);
    toDate.setFullYear(fromDate.getFullYear() + 1);
    toDate.setHours(0, 0, 0, 0);
    tableState.gantData.dateLimits.to = toDate.getTime();
  }
  return changed;
}

function updateDisplayedScales(tableState: TableState) {
  if (tableState.gantOptions === null) {
    return;
  }
  tableState.gantData = { ...tableState.gantData };
  tableState.gantData.scale = tableState.gantOptions.scale;
  tableState.gantData.displayScales = getStandartDisplayedScales(
    tableState.gantOptions.scale
  );
  /**TODO: check options for defined displayed scales */
}

/**Find column with minimal width and calculate gant min width based on it */
function updateGantMinimumWidth(tableState: TableState) {
  tableState.gantData = { ...tableState.gantData };

  let columnMinCoef = 1;
  for (let scale of tableState.gantData.displayScales) {
    const cells = tableState.gantData.cellsByScale[scale];
    if (!cells) {
      continue;
    }
    for (let cell of cells) {
      if (cell.width < columnMinCoef) {
        columnMinCoef = cell.width;
      }
    }
  }
  /**TODO: use reducer column min width */
  const minCellWidth = tableState.gantOptions?.minCellWidth || 20;
  tableState.gantData.gantMinWidth = minCellWidth / columnMinCoef;
}

/**Create scale cells for every scale inside of date limits */
function updateGantDateCells(tableState: TableState) {
  if (tableState.gantOptions === null) {
    return;
  }

  tableState.gantData = { ...tableState.gantData };
  tableState.gantData.cellsByScale = {};

  const cells = {
    hour: [],
    day: [],
    week: [],
    month: [],
    year: [],
  };
  const currentWidth = {
    hour: 0,
    day: 0,
    week: 0,
    month: 0,
    year: 0,
  };
  const initialDate = new Date(tableState.gantData.dateLimits.from);
  let endDate = new Date(tableState.gantData.dateLimits.to);

  initialDate.setTime(
    initialDate.getTime() + initialDate.getTimezoneOffset() * 60 * 1000
  );
  endDate.setTime(endDate.getTime() + endDate.getTimezoneOffset() * 60 * 1000);
  const values = {
    hour: getValueByScale(initialDate, "hour"),
    day: getValueByScale(initialDate, "day"),
    week: checkGantDateValue("week", initialDate, null),
    month: getValueByScale(initialDate, "month"),
    year: getValueByScale(initialDate, "year"),
  };

  const step: number =
    tableState.gantOptions.scale === "hour"
      ? getMinScaleValue("hour")
      : getMinScaleValue("day");
  let totalCells = 0;

  const makeStep = () => {
    addTime(initialDate, step);
    ++totalCells;
    for (let scale of tableState.gantData.displayScales) {
      ++currentWidth[scale];
      let newValue = checkGantDateValue(scale, initialDate, values[scale]);
      if (newValue === null) {
        continue;
      }
      addGantDateCell(scale, cells[scale], values[scale], currentWidth[scale]);
      values[scale] = newValue;
      currentWidth[scale] = 0;
    }
  };

  /**Add all full cells */
  while (initialDate.getTime() < endDate.getTime()) {
    makeStep();
  }
  /**Check if last cell is not full and add it */
  for (let scale of tableState.gantData.displayScales) {
    if (currentWidth[scale] !== 0) {
      addGantDateCell(
        scale as GantDisplayScale,
        cells[scale],
        getValueByScale(initialDate, scale),
        currentWidth[scale]
      );
    }
  }
  /**Setup widths of cells */
  for (let scale of tableState.gantData.displayScales) {
    for (let cell of cells[scale]) {
      const headerCell: GantHeaderCell = cell as GantHeaderCell;
      headerCell.width = headerCell.cellspan / totalCells;
    }
  }

  for (let scale of tableState.gantData.displayScales) {
    tableState.gantData.cellsByScale[scale] = cells[scale];
  }
  updateGantMinimumWidth(tableState);
}

/**Check page gant data and create dynamic gant groups for all data with unidentified group */
function updateGantGroups(tableState: TableState) {
  if (tableState.gantOptions === null) {
    return;
  }
  const groupsMap = getGantGroupMap(tableState.gantOptions);
  let isChanged = false;
  const getUnidentifiedGroup = () => {
    if (!groupsMap["_unidentified"]) {
      /**Initialize default unidentified group */
      groupsMap["_unidentified"] = createGantGroup("_unidentified", "?");
      isChanged = true;
    }
    return groupsMap["_unidentified"];
  };
  for (let rowIdx of tableState.pageRows) {
    const row = tableState.rowByIdx[rowIdx];
    const gantData =
      row &&
      (row.changedData.gantData ||
        row.bindedData.gantData ||
        row.data.gantData);
    if (!gantData || !Array.isArray(gantData.chosenDate)) {
      continue;
    }
    for (let date of gantData.chosenDate) {
      if (!Array.isArray(date.group)) {
        if (groupsMap[date.group]) {
          continue;
        }
        isChanged = true;
        console.log("Gant group not found:", date.group, date, row);
        date.group = [getUnidentifiedGroup()];
        continue;
      }
      if (date.group.length === 0) {
        date.group = [getUnidentifiedGroup()];
        continue;
      }
      for (let i = 0; i < date.group.length; ++i) {
        if (groupsMap[date.group[i]]) {
          continue;
        }
        isChanged = true;
        console.log("Gant group not found:", date.group, date, row);
        date.group[i] = getUnidentifiedGroup();
      }
    }
  }
  if (isChanged) {
    tableState.gantOptions = { ...tableState.gantOptions };
    tableState.gantOptions.gantGroups = [];
    for (let id in groupsMap) {
      tableState.gantOptions.gantGroups.push(groupsMap[id]);
    }
  }
}

function updateGantData(tableState: TableState, reset?: boolean) {
  if (tableState.gantOptions === null) {
    return;
  }
  updateGantGroups(tableState);
  const limitsChanged = updateGantDateLimits(tableState, reset);
  if (limitsChanged) {
    updateGantDateCells(tableState);
  }
}

function updateColumnsResizeInfo(tableState: TableState) {
  const generatedColsIncrement = tableState.selectType != null ? 1 : 0;
  tableState.columns = tableState.columns.slice();
  let columnInfoList = [];
  if (tableState.dynamicColumns) {
    const columnIdxMap: { [k: string]: number } = {};
    const visibilityMap: { [k: string]: boolean } = {};
    for (let i = 0; i < tableState.dynamicColumns.length; ++i) {
      const columnField = tableState.dynamicColumns[i];
      columnIdxMap[columnField] = i;
      visibilityMap[columnField] = true;
    }
    for (let i = 0; i < tableState.columns.length; ++i) {
      if (tableState.columns[i].resizeInfo) {
        tableState.columns[i] = { ...tableState.columns[i] };
        delete tableState.columns[i].resizeInfo;
      }
      if (!visibilityMap[tableState.columns[i].field]) {
        continue;
      }
      columnInfoList.push({
        idx: i,
        column: tableState.columns[i],
      });
    }
    columnInfoList = columnInfoList.sort(
      (infoA, infoB) =>
        columnIdxMap[infoA.column.field] - columnIdxMap[infoB.column.field]
    );
  } else {
    for (let i = 0; i < tableState.columns.length; ++i) {
      if (tableState.columns[i].resizeInfo) {
        tableState.columns[i] = { ...tableState.columns[i] };
        delete tableState.columns[i].resizeInfo;
      }
      if (tableState.columns[i].hidden && tableState.columns[i].dynamic) {
        continue;
      }
      columnInfoList.push({
        idx: i,
        column: tableState.columns[i],
      });
    }
  }
  let prevVisibleColumnPos: number | null = null;
  for (let i = 0; i < columnInfoList.length; ++i) {
    const column = columnInfoList[i].column;
    if (
      (column.hidden && !column.dynamic) ||
      tableState.dynamicHiddenColumns?.[column.field]
    ) {
      continue;
    }
    if (prevVisibleColumnPos === null) {
      prevVisibleColumnPos = i;
      continue;
    }
    /**Index of column in original array */
    const prevColumnIdx = columnInfoList[prevVisibleColumnPos].idx;
    const columnIdx = columnInfoList[i].idx;
    /**Visible position of column */
    const prevColumnPos = prevVisibleColumnPos + generatedColsIncrement;
    const columnPos = i + generatedColsIncrement;
    tableState.columns[prevColumnIdx] = {
      ...tableState.columns[prevColumnIdx],
    };
    tableState.columns[columnIdx] = { ...tableState.columns[columnIdx] };
    const prevColumn = tableState.columns[prevColumnIdx];
    const curColumn = tableState.columns[columnIdx];
    if (!prevColumn.resizeInfo) {
      prevColumn.resizeInfo = {
        curColumnIdx: prevColumnPos,
      };
    }
    if (!curColumn.resizeInfo) {
      curColumn.resizeInfo = {
        curColumnIdx: columnPos,
      };
    }
    prevColumn.resizeInfo.nextColumnIdx = columnPos;
    curColumn.resizeInfo.prevColumnIdx = prevColumnPos;
    prevVisibleColumnPos = i;
  }
}

function isEmptyObject(obj: any): boolean {
  if (typeof obj != "object") {
    return false;
  }
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

function setPageLoading(
  tableState: TableState,
  page: number,
  pageSize: number
) {
  tableState.loadingPages = { ...tableState.loadingPages };
  tableState.loadingPages[`${page}_${pageSize}`] = true;
  tableState.loadingData = true;
  return;
}

function setPageLoaded(tableState: TableState, page: number, pageSize: number) {
  tableState.loadingPages = { ...tableState.loadingPages };
  delete tableState.loadingPages[`${page}_${pageSize}`];
  if (isEmptyObject(tableState.loadingPages)) {
    tableState.loadingData = false;
  }
  return;
}

function initializeChangedFilter(tableState: TableState): TableFilterChanges {
  let filterChanges: TableFilterChanges;
  if (tableState.filterChanges) {
    filterChanges = { ...tableState.filterChanges };
  } else {
    filterChanges = {
      fields: null,
    };
  }
  if (!filterChanges.fields) {
    filterChanges.fields = { ...tableState.fields };
  }
  return filterChanges;
}

function checkNumberValue(value: number, filter: NumberFilterData): boolean {
  if (filter.operation === "between") {
    if (filter.valueFrom === null || filter.valueTo === null) {
      return true;
    }
    return value >= filter.valueFrom && value <= filter.valueTo;
  }
  if (filter.value === null) {
    return true;
  }
  if (filter.operation === "less") {
    return value < filter.value;
  }
  if (filter.operation === "lessOrEqual") {
    return value <= filter.value;
  }
  if (filter.operation === "equal") {
    return value == filter.value;
  }
  if (filter.operation === "more") {
    return value > filter.value;
  }
  if (filter.operation === "moreOrEqual") {
    return value >= filter.value;
  }
  return true;
}

function checkDateValue(
  value: number,
  filter: DateFilterData | DateTimeFilterData
): boolean {
  if (filter.operation === "between") {
    if (filter.valueFrom === null || filter.valueTo === null) {
      return true;
    }
    const fromValue = moment(filter.valueFrom + "z").valueOf();
    const toValue = moment(filter.valueTo + "z").valueOf();
    return value >= fromValue && value <= toValue;
  }
  if (filter.value === null) {
    return true;
  }
  const filterValue = moment(filter.value).valueOf();
  if (filter.operation === "less") {
    return value < filterValue;
  }
  if (filter.operation === "lessOrEqual") {
    return value <= filterValue;
  }
  if (filter.operation === "equal") {
    return value == filterValue;
  }
  if (filter.operation === "more") {
    return value > filterValue;
  }
  if (filter.operation === "moreOrEqual") {
    return value >= filterValue;
  }
  return true;
}

function clientSideFilterData(tableState: TableState) {
  if (!tableState.clientSideFilter) {
    tableState.clientSideData = null;
    return;
  }
  if (!tableState.clientSideData) {
    tableState.clientSideData = {
      filteredRows: [],
      sortedRows: [],
    };
  } else {
    tableState.clientSideData = { ...tableState.clientSideData };
  }
  tableState.clientSideData.filteredRows = Object.keys(tableState.rowByIdx)
    .map((idx) => Number(idx))
    .filter((rowIdx) => {
      if (tableState.filterInfo.columns.length === 0) {
        return true;
      }
      const row = tableState.rowByIdx[rowIdx];
      for (let columnFilter of tableState.filterInfo.columns) {
        const fieldId = columnFilter.field;
        let rowValue = null;
        if (typeof row.bindedData[fieldId] !== "undefined") {
          rowValue = row.bindedData[fieldId];
        } else if (typeof row.data[fieldId] !== "undefined") {
          rowValue = row.data[fieldId];
        }
        if (isStringFilterData(columnFilter.data)) {
          const checkSelection = Boolean(
            Object.values(columnFilter.data.selected).find(
              (selected) => selected === true
            )
          );
          const stringValue: string = rowValue ? rowValue.toString() : "";
          if (
            (checkSelection && !columnFilter.data.selected[stringValue]) ||
            !stringValue
              .toLocaleLowerCase()
              .includes(columnFilter.data.contain.toLocaleLowerCase())
          ) {
            return false;
          }
          continue;
        }
        if (isNumberFilterData(columnFilter.data)) {
          if (rowValue === null) {
            return false;
          }
          const numberValue: number = Number(rowValue);
          if (
            isNaN(numberValue) ||
            !checkNumberValue(numberValue, columnFilter.data)
          ) {
            return false;
          }
          continue;
        }
        if (
          isDateFilterData(columnFilter.data) ||
          isDateTimeFilterData(columnFilter.data)
        ) {
          if (!rowValue) {
            return false;
          }
          const numberValue: number = moment(rowValue).valueOf();
          console.log(
            `Data filter. value:${rowValue}, from:${columnFilter.data.valueFrom}, to:${columnFilter.data.valueTo}`
          );
          if (
            isNaN(numberValue) ||
            !checkDateValue(numberValue, columnFilter.data)
          ) {
            return false;
          }
          continue;
        }
        if (
          isBooleanFilterData(columnFilter.data) &&
          Boolean(rowValue) !== columnFilter.data.selected
        ) {
          return false;
        }
      }
      return true;
    });
  tableState.totalRowsLength = tableState.clientSideData.filteredRows.length;
  clientSideSortData(tableState);
}

function clientSideSortData(tableState: TableState) {
  if (!tableState.clientSideFilter) {
    tableState.clientSideData = null;
    return;
  }
  if (!tableState.clientSideData) {
    const rows = Object.keys(tableState.rowByIdx).map((idx) => Number(idx));
    tableState.clientSideData = {
      filteredRows: rows,
      sortedRows: [],
    };
  } else {
    tableState.clientSideData = { ...tableState.clientSideData };
  }
  tableState.clientSideData.sortedRows = tableState.clientSideData.filteredRows
    .slice()
    .sort((idxA, idxB) => {
      if (tableState.sortInfo.columns.length === 0) {
        return 0;
      }
      let result = 0;
      const rowA = tableState.rowByIdx[idxA as any];
      const rowB = tableState.rowByIdx[idxB as any];
      for (let i = tableState.sortInfo.columns.length - 1; i >= 0; --i) {
        const columnSort = tableState.sortInfo.columns[i];
        const fieldId = columnSort.field;
        let valueA = null;
        if (typeof rowA.bindedData[fieldId] !== "undefined") {
          valueA = rowA.bindedData[fieldId];
        } else if (typeof rowA.data[fieldId] !== "undefined") {
          valueA = rowA.data[fieldId];
        }
        let valueB = null;
        if (typeof rowB.bindedData[fieldId] !== "undefined") {
          valueB = rowB.bindedData[fieldId];
        } else if (typeof rowB.data[fieldId] !== "undefined") {
          valueB = rowB.data[fieldId];
        }
        if (valueA === valueB) {
          continue;
        }
        /**Check if first value is defined and second is not */
        if (valueA === null) {
          result = -1;
          break;
        }
        /**Check if second value is defined and first is not */
        if (valueB === null) {
          result = 1;
          break;
        }
        /**Check column type */
        const column = tableState.columns.find(
          (columnData) => columnData.field === fieldId
        );
        if (!column) {
          continue;
        }
        if (column.format === "date" || column.format === "dateTime") {
          valueA = moment(valueA).valueOf();
          valueB = moment(valueB).valueOf();
        } else if (column.format === "currency") {
          valueA = Number(valueA);
          valueB = Number(valueB);
        }
        if (typeof valueA === "string" && typeof valueB === "string") {
          result = valueA.localeCompare(valueB);
        } else {
          result = valueA > valueB ? 1 : -1;
        }
        if (columnSort.direction === "desc") {
          result = -result;
        }
      }
      return result;
    });
}

/****************
 *   Reducers   *
 ****************/
function receiveTableInitialData(
  state: TableReducerState,
  payload: { initialSorting?: ColumnSortInfo[] },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState: TableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: cutOffModel(tableId),
  };
  if (payload.initialSorting) {
    tableState.sortInfo = {
      columns: payload.initialSorting,
    };
  }
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableHeader(
  state: TableReducerState,
  payload: { header: TableHeader },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
    loading: false,
    parameters: payload.header.parameters,
    stylesheets: payload.header.stylesheets,
    toolbar: payload.header.toolbar,
    reports: payload.header.reports,
    columns: payload.header.columns,
    pageable: payload.header.pageable,
    pageSize: payload.header.pagination.size,
    pageSelection: payload.header.pagination.selection,
    selectType: payload.header.selectType,
    automation: parseAutomation(payload.header.automation),
    gantOptions: payload.header.gantOptions,
    dragOptions: payload.header.dragOptions || null,
    dropOptions: payload.header.dropOptions || null,
  };
  if (payload.header.userSettings) {
    tableState.fields = payload.header.userSettings.fields || tableState.fields;
    tableState.dynamicColumns =
      payload.header.userSettings.dynamicColumns || tableState.dynamicColumns;
    tableState.dynamicHiddenColumns =
      payload.header.userSettings.dynamicHiddenColumns ||
      tableState.dynamicHiddenColumns;
    tableState.sortInfo =
      payload.header.userSettings.sortInfo || tableState.sortInfo;
    tableState.filterInfo =
      payload.header.userSettings.filterInfo || tableState.filterInfo;
    const existColumnsMap: { [k: string]: boolean } = {};
    for (let column of tableState.columns) {
      existColumnsMap[column.field] = true;
    }
    if (tableState.dynamicColumns) {
      /**Check if dynamic columns doesn't contain all static columns */
      const dynamicColumnsMap: { [k: string]: boolean } = {};
      for (let columnField of tableState.dynamicColumns) {
        dynamicColumnsMap[columnField] = true;
      }
      for (let column of tableState.columns) {
        if (column.dynamic || dynamicColumnsMap[column.field]) {
          continue;
        }
        tableState.dynamicColumns.push(column.field);
        if (
          typeof tableState.dynamicHiddenColumns[column.field] === "undefined"
        ) {
          tableState.dynamicHiddenColumns[column.field] = true;
        }
      }
      /**Remove non-existing dynamic columns */
      tableState.dynamicColumns = tableState.dynamicColumns.filter(
        (columnField) => existColumnsMap[columnField]
      );
    }
    /**Remove non-existing columns from other user settings */
    for (let columnField of Object.keys(tableState.dynamicHiddenColumns)) {
      if (!existColumnsMap[columnField]) {
        delete tableState.dynamicHiddenColumns[columnField];
      }
    }
    tableState.sortInfo.columns = tableState.sortInfo.columns.filter(
      (column) => existColumnsMap[column.field]
    );
    tableState.filterInfo.columns = tableState.filterInfo.columns.filter(
      (column) => existColumnsMap[column.field]
    );
  }
  updateDisplayedScales(tableState);
  updateGantData(tableState, true);
  updateColumnsResizeInfo(tableState);
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableData(
  state: TableReducerState,
  payload: {
    data: TableData;
    getAutomationTableModule?: (state: TableState) => AutomationTableModule;
    reset: boolean;
    savePage?: boolean;
  },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState: TableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: cutOffModel(tableId),
    loading: false,
    loadingData: false,
    errorData: null,
  };

  if (payload.reset) {
    resetTableData(tableState, payload.savePage);
    resetGantData(tableState);
  }

  tableState.fields = { ...tableState.fields, ...payload.data.fields };
  tableState.modelParameters = {
    ...tableState.modelParameters,
    ...payload.data.modelParameters,
  };
  if (
    tableState.modelParameters &&
    !Object.values(tableState.modelParameters).length
  ) {
    tableState.modelParameters = undefined;
  }
  if (typeof payload.data.totalRowsLength != "undefined") {
    tableState.totalRowsLength = payload.data.totalRowsLength;
  }
  const receivedPageSize = payload.data.pageSize;
  const receivedPage = payload.data.page;

  let rowStartIdx = 0;
  if (receivedPageSize !== -1) {
    rowStartIdx += receivedPageSize * receivedPage;
  }

  if (!payload.reset) {
    tableState.rowByIdx = { ...tableState.rowByIdx };
    setPageLoaded(tableState, receivedPage, receivedPageSize);
  }
  for (let i = 0; i < payload.data.rows.length; ++i) {
    const row: RowData = {
      data: { ...payload.data.rows[i].data },
      bindedData: {},
      changed: null,
      changedData: {},
      classes: {},
    };
    tableState.rowByIdx[rowStartIdx + i] = row;
  }
  const automationModule = payload.getAutomationTableModule?.(tableState);
  clientSideFilterData(tableState);
  const rowsCount = payload.data.rows?.length || 0;
  updatePageData({
    tableState,
    automationTableModule: automationModule,
    rowsCount,
  });
  updateGantData(tableState);

  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableLoading(
  state: TableReducerState,
  payload: null,
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
    loading: true,
  };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableError(
  state: TableReducerState,
  payload: { error: FetchError },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
    loading: false,
    error: payload.error,
  };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableDataLoading(
  state: TableReducerState,
  payload: { page: number; pageSize: number; abort?: AbortController },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  setPageLoading(tableState, payload.page, payload.pageSize);
  tableState.abortLoading = payload.abort;
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableDataError(
  state: TableReducerState,
  payload: { error: FetchError; page: number; pageSize: number },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
    errorData: payload.error,
  };
  setPageLoaded(tableState, payload.page, payload.pageSize);
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableFilterSelectionLoading(
  state: TableReducerState,
  payload: { field: string },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterInfo = { ...tableState.filterInfo };
  tableState.filterInfo.selectionByField = {
    ...tableState.filterInfo.selectionByField,
    [payload.field]: { loading: true, error: false, selection: [] },
  };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableFilterSelectionError(
  state: TableReducerState,
  payload: { field: string; error: FetchError },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterInfo = { ...tableState.filterInfo };
  tableState.filterInfo.selectionByField = {
    ...tableState.filterInfo.selectionByField,
    [payload.field]: { loading: false, error: true, selection: [] },
  };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableFilterSelection(
  state: TableReducerState,
  payload: { field: string; selection: any[] },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
    page: 0,
  };
  tableState.filterInfo = { ...tableState.filterInfo };
  tableState.filterInfo.selectionByField = {
    ...tableState.filterInfo.selectionByField,
    [payload.field]: {
      loading: false,
      error: false,
      selection: payload.selection,
    },
  };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableFilterRangeLoading(
  state: TableReducerState,
  payload: { field: string },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterInfo = { ...tableState.filterInfo };
  tableState.filterInfo.rangeByField = {
    ...tableState.filterInfo.rangeByField,
    [payload.field]: { loading: true, error: false, range: null },
  };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableFilterRangeError(
  state: TableReducerState,
  payload: { field: string; error: FetchError },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterInfo = { ...tableState.filterInfo };
  tableState.filterInfo.rangeByField = {
    ...tableState.filterInfo.rangeByField,
    [payload.field]: { loading: false, error: true, range: null },
  };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableFilterRange(
  state: TableReducerState,
  payload: { field: string; range: { min: any; max: any } },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterInfo = { ...tableState.filterInfo };
  tableState.filterInfo.rangeByField = {
    ...tableState.filterInfo.rangeByField,
    [payload.field]: { loading: false, error: false, range: payload.range },
  };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableSortOptions(
  state: TableReducerState,
  payload: { sortColumns: ColumnSortInfo[]; filterColumns: string[] },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.sortInfo = { ...tableState.sortInfo };
  tableState.sortInfo.columns = payload.sortColumns.slice();
  tableState.filterInfo = { ...tableState.filterInfo };
  tableState.filterInfo.columns = tableState.filterInfo.columns.filter(
    (column) => payload.filterColumns.indexOf(column.field) !== -1
  );
  tableState.filterInfo.columns.sort((columnA, columnB) => {
    const idxA = payload.filterColumns.indexOf(columnA.field);
    const idxB = payload.filterColumns.indexOf(columnB.field);
    return idxA - idxB;
  });
  clientSideFilterData(tableState);
  if (tableState.clientSideFilter) {
    updatePageData({ tableState });
  }
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableColumns(
  state: TableReducerState,
  payload: {
    selectedColumns: string[] | null;
    hiddenColumns: { [k: string]: boolean };
  },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.dynamicColumns = payload.selectedColumns;
  tableState.dynamicHiddenColumns = payload.hiddenColumns;
  updateColumnsResizeInfo(tableState);
  let filterColsOnRemove: string[] = [];
  if (tableState.filterInfo.columns.length) {
    if (!tableState.dynamicColumns) {
      tableState.filterInfo = {
        columns: [],
        rangeByField: {},
        selectionByField: {},
      };
    } else {
      for (let c of tableState.filterInfo.columns) {
        const existed = tableState.dynamicColumns.find((dc) => dc == c.field);
        if (!existed) {
          filterColsOnRemove.push(c.field);
        }
      }
    }
  }
  if (filterColsOnRemove.length) {
    tableState.filterInfo = { ...tableState.filterInfo };
    tableState.filterInfo.columns = tableState.filterInfo.columns.filter(
      (c) => !filterColsOnRemove.includes(c.field)
    );
    tableState.filterInfo.rangeByField = {
      ...tableState.filterInfo.rangeByField,
    };
    tableState.filterInfo.selectionByField = {
      ...tableState.filterInfo.selectionByField,
    };

    filterColsOnRemove.forEach((col) => {
      delete tableState.filterInfo.rangeByField[col];
      delete tableState.filterInfo.selectionByField[col];
    });
  }
  nextState[tableId] = tableState;
  // const dynamicCols = tableState.columns
  //   .filter((c) => c.dynamic && payload.selectedColumns?.includes(c.field))
  //   .map((c) => c.field);
  // console.log("dynamic cols====", dynamicCols);
  // nextState[tableId].dynamicColumnChanges =
  //   dynamicCols.length > 0 ? dynamicCols : undefined;
  return nextState;
}

function receiveSelectRow(
  state: TableReducerState,
  payload: { key: string },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  if (tableState.selectType === "radio") {
    const radioValue = !Boolean(tableState.selectedRows[payload.key]);
    tableState.selectedRows = {};
    if (radioValue) {
      tableState.selectedRows[payload.key] = true;
    }
    tableState.selectedRowsLength = radioValue ? 1 : 0;
  } else {
    tableState.selectedRows = { ...tableState.selectedRows };
    tableState.selectedRows[payload.key] =
      !tableState.selectedRows[payload.key];
    if (tableState.selectedRows[payload.key]) {
      tableState.selectedRowsLength++;
    } else {
      tableState.selectedRowsLength--;
    }
  }
  nextState[tableId] = tableState;
  return nextState;
}

function receiveSelectAll(
  state: TableReducerState,
  payload: null,
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.selectedRows = {};
  const selected = tableState.selectedRowsLength !== 0;
  tableState.selectedRowsLength = 0;
  if (!selected) {
    for (let rowIdx of tableState.pageRows) {
      const key = tableState.rowByIdx[rowIdx].data.key;
      if (!key) {
        continue;
      }
      tableState.selectedRows[key] = true;
      tableState.selectedRowsLength++;
    }
  }
  nextState[tableId] = tableState;
  return nextState;
}

function receivePageSize(
  state: TableReducerState,
  payload: { pageSize: number },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  const firstPageRowIdx = tableState.page * tableState.pageSize;
  tableState.pageSize = payload.pageSize;
  const page = Math.floor(firstPageRowIdx / payload.pageSize);
  changePage({ tableState, page });
  nextState[tableId] = tableState;
  return nextState;
}

function receivePage(
  state: TableReducerState,
  payload: {
    page: number;
    automationTableModule: AutomationTableModule;
    rowsCount?: number;
  },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  const totalPages = Math.ceil(
    tableState.totalRowsLength / tableState.pageSize
  );
  let page = payload.page;
  if (page < 0) {
    page = 0;
  } else if (page >= totalPages) {
    page = totalPages - 1;
  }
  changePage({
    tableState,
    page,
    automationTableModule: payload.automationTableModule,
    rowsCount: payload.rowsCount,
  });
  nextState[tableId] = tableState;
  return nextState;
}

function receiveSortKey(
  state: TableReducerState,
  payload: { key: string },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.sortInfo = { ...tableState.sortInfo };
  tableState.sortInfo.columns = tableState.sortInfo.columns.slice();

  const existingSortInfoIdx = tableState.sortInfo.columns.findIndex(
    (columnSortInfo) => columnSortInfo.field === payload.key
  );
  if (existingSortInfoIdx === -1) {
    tableState.sortInfo.columns.push({
      field: payload.key,
      direction: "asc",
    });
  } else {
    const columnSortInfo = tableState.sortInfo.columns[existingSortInfoIdx];
    if (columnSortInfo.direction === "desc") {
      tableState.sortInfo.columns.splice(existingSortInfoIdx, 1);
    } else {
      tableState.sortInfo.columns[existingSortInfoIdx] = {
        field: payload.key,
        direction: "desc",
      };
    }
  }
  clientSideSortData(tableState);
  if (tableState.clientSideFilter) {
    updatePageData({ tableState });
  }
  nextState[tableId] = tableState;
  return nextState;
}

function receiveFilterData(
  state: TableReducerState,
  payload: { field: string; filterData: FilterData | null },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterInfo = { ...tableState.filterInfo };
  tableState.filterInfo.columns = tableState.filterInfo.columns.slice();

  const existingFilterInfoIdx = tableState.filterInfo.columns.findIndex(
    (columnFilterInfo) => columnFilterInfo.field === payload.field
  );
  if (existingFilterInfoIdx === -1) {
    tableState.filterInfo.columns.push({
      field: payload.field,
      data: payload.filterData,
    });
  } else {
    if (!payload.filterData) {
      tableState.filterInfo.columns.splice(existingFilterInfoIdx, 1);
    } else {
      tableState.filterInfo.columns[existingFilterInfoIdx] = {
        field: payload.field,
        data: payload.filterData,
      };
    }
  }
  clientSideFilterData(tableState);
  if (tableState.clientSideFilter) {
    updatePageData({ tableState });
  }
  nextState[tableId] = tableState;
  return nextState;
}

function receiveLocalTableInitialData(
  state: TableReducerState,
  payload: InitialLocalTableData,
  tableId: string
): TableReducerState {
  let nextState = { ...state };
  nextState[tableId] = { ...DEFAULT_TABLE_STATE };
  const page = payload.page || DEFAULT_TABLE_STATE.page;
  const pageSize = payload.pageSize || DEFAULT_TABLE_STATE.pageSize;
  if (payload.pageable) {
    nextState[tableId].pageable = true;
    nextState[tableId].page = page;
    nextState[tableId].pageSize = pageSize;
  }
  nextState = receiveTableData(
    nextState,
    {
      data: {
        fields: payload.fields,
        rows: payload.data,
        page: page,
        pageSize: pageSize,
        totalRowsLength: payload.data.length,
      },
      reset: true,
    },
    tableId
  );
  const { automation, ...initialData } = payload;
  const tableState = { ...nextState[tableId], ...initialData };
  if (automation) {
    tableState.automation = parseAutomation(automation);
  }
  nextState[tableId] = tableState;
  return nextState;
}

function receiveLocalTableUpdateData(
  state: TableReducerState,
  payload: LocalTableData,
  tableId: string
): TableReducerState {
  const nextState = receiveTableData(
    state,
    {
      data: {
        fields: {},
        rows: payload.data,
        page: state[tableId].page || DEFAULT_TABLE_STATE.page,
        pageSize: state[tableId].pageSize || DEFAULT_TABLE_STATE.pageSize,
        totalRowsLength: payload.data.length,
      },
      reset: true,
    },
    tableId
  );
  const tableState = { ...nextState[tableId], ...payload };
  nextState[tableId] = tableState;
  return nextState;
}

function receiveLocalTableUninitialize(
  state: TableReducerState,
  payload: null,
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  if (nextState[tableId]) {
    delete nextState[tableId];
  }
  return nextState;
}

function receiveField(
  state: TableReducerState,
  payload: { parameter: string; value: string },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterChanges = initializeChangedFilter(tableState);
  tableState.filterChanges.fields = { ...tableState.filterChanges.fields };
  tableState.filterChanges.fields[payload.parameter] = payload.value;
  nextState[tableId] = tableState;
  return nextState;
}

function receiveGantViewType(
  state: TableReducerState,
  payload: { type: GantViewType },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  const gantData = { ...tableState.gantData };
  gantData.viewType = payload.type;
  if (gantData.viewType === constants.GANT_READ) {
    gantData.selectedGroup = "";
  } else if (gantData.viewType === constants.GANT_CHOSEN_DATE) {
    gantData.selectedGroup = tableState.gantOptions?.gantGroups[0]?.id || "";
  } else {
    gantData.selectedGroup = "plannedDocs";
  }
  tableState.gantData = gantData;
  nextState[tableId] = tableState;
  return nextState;
}

function receiveGantSelectedGroup(
  state: TableReducerState,
  payload: { group: string },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  const gantData = { ...tableState.gantData };
  gantData.selectedGroup = payload.group;
  tableState.gantData = gantData;
  nextState[tableId] = tableState;
  return nextState;
}

function receiveGantChangeElement(
  state: TableReducerState,
  payload: {
    rowIndex: number;
    itemIndex: number;
    newDate: GantDateLimits;
    isPlanned: boolean;
  },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  const dataType = payload.isPlanned
    ? constants.GANT_PLANNED_DATE
    : constants.GANT_CHOSEN_DATE;
  const roundedFrom = roundGantTimestamp(
    payload.newDate.from,
    (tableState.gantOptions as TableGantOptions).scale,
    "auto"
  );
  const roundedTo = roundGantTimestamp(
    payload.newDate.to,
    (tableState.gantOptions as TableGantOptions).scale,
    "auto"
  );
  if (roundedFrom === roundedTo) {
    //TODO: delete element
    return nextState;
  }
  tableState.rowByIdx = { ...tableState.rowByIdx };
  tableState.rowByIdx[payload.rowIndex] = {
    ...tableState.rowByIdx[payload.rowIndex],
  };
  if (
    !tableState.rowByIdx[payload.rowIndex].changed ||
    !(tableState.rowByIdx[payload.rowIndex].changed as { [k: string]: boolean })
      .gantData
  ) {
    tableState.rowByIdx[payload.rowIndex].changed = Object.assign(
      {},
      tableState.rowByIdx[payload.rowIndex].changed,
      { gantData: true }
    );
  }
  tableState.rowByIdx[payload.rowIndex].changedData = {
    ...tableState.rowByIdx[payload.rowIndex].changedData,
  };
  tableState.rowByIdx[payload.rowIndex].changedData.gantData = {
    ...tableState.rowByIdx[payload.rowIndex].bindedData.gantData,
    ...tableState.rowByIdx[payload.rowIndex].changedData.gantData,
  };
  tableState.rowByIdx[payload.rowIndex].changedData.gantData[dataType] =
    tableState.rowByIdx[payload.rowIndex].changedData.gantData[
      dataType
    ].slice();
  const gantRowItems =
    tableState.rowByIdx[payload.rowIndex].changedData.gantData[dataType];
  gantRowItems[payload.itemIndex] = Object.assign(
    {},
    gantRowItems[payload.itemIndex]
  );
  if (typeof gantRowItems[payload.itemIndex].originalFrom === "undefined") {
    gantRowItems[payload.itemIndex].originalFrom =
      gantRowItems[payload.itemIndex].from;
    gantRowItems[payload.itemIndex].originalTo =
      gantRowItems[payload.itemIndex].to;
  }
  Object.assign(gantRowItems[payload.itemIndex], {
    from: roundedFrom,
    to: roundedTo,
  });
  nextState[tableId] = tableState;
  return nextState;
}

function receiveFilterChangesConfirm(
  state: TableReducerState,
  payload: null,
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.fields = {
    ...tableState.fields,
    ...tableState.filterChanges?.fields,
  };
  tableState.filterChanges = null;
  nextState[tableId] = tableState;
  return nextState;
}

function receiveFilterChangesDeny(
  state: TableReducerState,
  payload: null,
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.filterChanges = null;
  nextState[tableId] = tableState;

  return nextState;
}

function receiveTableSaveStart(
  state: TableReducerState,
  payload: null,
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.savingError = null;
  tableState.saving = true;
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableSaveSuccess(
  state: TableReducerState,
  payload: null,
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.savingError = null;
  tableState.saving = false;
  tableState.rowByIdx = { ...tableState.rowByIdx };
  for (let rowIdx in tableState.rowByIdx) {
    if (!tableState.rowByIdx[rowIdx].changed) {
      continue;
    }
    delete tableState.rowByIdx[rowIdx];
  }
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableSaveError(
  state: TableReducerState,
  payload: { error: FetchError },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.savingError = payload.error;
  tableState.saving = false;
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableRowAdd(
  state: TableReducerState,
  payload: { row: RowDataMap },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  const newRowIdx = tableState.totalRowsLength || 0;
  tableState.rowByIdx = { ...tableState.rowByIdx };
  const changed: { [k: string]: boolean } = {};
  for (let key of Object.keys(payload.row)) {
    changed[key] = true;
  }
  const newRow: RowData = {
    data: { ...payload.row },
    bindedData: {},
    changed: changed,
    changedData: {},
    classes: {},
  };
  tableState.rowByIdx[newRowIdx] = newRow;
  tableState.totalRowsLength++;
  clientSideFilterData(tableState);
  updatePageData({ tableState });
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableRowChange(
  state: TableReducerState,
  payload: { row: RowDataMap; rowIdx: number },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  if (payload.rowIdx >= tableState.totalRowsLength) {
    console.warn(
      `Can't change row data: idx is out of bounds (idx: ${payload.rowIdx}, total: ${tableState.totalRowsLength})`
    );
    return state;
  }
  tableState.rowByIdx = { ...tableState.rowByIdx };
  tableState.rowByIdx[payload.rowIdx] = {
    ...tableState.rowByIdx[payload.rowIdx],
  };
  tableState.rowByIdx[payload.rowIdx].changedData = {
    ...tableState.rowByIdx[payload.rowIdx].changedData,
  };
  const changed = { ...tableState.rowByIdx[payload.rowIdx].changed };
  for (let key of Object.keys(payload.row)) {
    tableState.rowByIdx[payload.rowIdx].changedData[key] = payload.row[key];
    changed[key] = true;
  }
  tableState.rowByIdx[payload.rowIdx].changed = changed;
  clientSideFilterData(tableState);
  updatePageData({ tableState });
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableCellChange(
  state: TableReducerState,
  payload: { value: any; rowIdx: number; column: string },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  if (
    payload.rowIdx >= tableState.totalRowsLength &&
    !tableState.rowByIdx[payload.rowIdx]
  ) {
    console.warn(
      `Can't change cell data: row idx is out of bounds (idx: ${payload.rowIdx}, total: ${tableState.totalRowsLength})`
    );
    return state;
  }
  if (!payload.column) {
    console.warn(`Can't change cell data: column is undefined`);
    return state;
  }
  tableState.rowByIdx = { ...tableState.rowByIdx };
  tableState.rowByIdx[payload.rowIdx] = {
    ...tableState.rowByIdx[payload.rowIdx],
  };
  tableState.rowByIdx[payload.rowIdx].changedData = {
    ...tableState.rowByIdx[payload.rowIdx].changedData,
    [payload.column]: payload.value,
  };
  tableState.rowByIdx[payload.rowIdx].changed = {
    ...tableState.rowByIdx[payload.rowIdx].changed,
    [payload.column]: true,
  };
  clientSideFilterData(tableState);
  updatePageData({ tableState });
  nextState[tableId] = tableState;
  return nextState;
}

function receiveTableClientSideFilter(
  state: TableReducerState,
  payload: { clientSideFilter: boolean },
  tableId: string
): TableReducerState {
  const nextState = { ...state };
  const tableState = {
    ...(nextState[tableId] || DEFAULT_TABLE_STATE),
    tableId: tableId,
  };
  tableState.clientSideFilter = payload.clientSideFilter;
  nextState[tableId] = tableState;
  return nextState;
}
