import React, { MouseEvent } from "react";
import { FormattedMessage } from "react-intl";
import { LoginPageInfo, LoginRequestDetails } from "../../types/security";
import UsernameGroup from "./UsernameGroup";
import DomainList from "./DomainListGroup";
import UseDomainCheckboxGroup from "./UseDomainCheckboxGroup";
import PasswordGroup from "./PasswordGroup";
import logo from "../../epsa_logo.svg";
import QRCode from "qrcode";
import {
  faCheck,
  faExclamationTriangle,
  faGlobe,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";

const domainLoginRe = /^([\w\.\-]+)\\([\w\.\-]+)$/;

function checkBasicDomain(domain: string, basicDomain: string) {
  return domain.lastIndexOf(basicDomain) == domain.length - basicDomain.length;
}

function isQRRequired(exception?: string) {
  return (
    typeof exception == "string" && exception == "TOTPTokenNotReadyException"
  );
}

function isQRGenerated(exception?: string) {
  return (
    typeof exception == "string" && exception == "TOTPTokenGeneratedException"
  );
}

function isCodeRequired(exception?: string) {
  return (
    typeof exception == "string" && (exception == "TOTPCodeRequiredException" || exception == "TOTPCodeInvalidException")
  );
}
function passwordUsedBefore(exception?: string) {
  return (
    typeof exception == "string" && exception == "UnsecurePasswordException"
  );
}
function isCredentialsExpired(exception?: string) {
  return (
    typeof exception == "string" && exception == "CredentialsExpiredException"
  );
}

/**
 * Display user friendly exceptions if login failed
 *
 * @param props
 */
const ExceptionHandler = (props: { exception: string; message?: string }) => {
  const { exception, message } = props;
  if (exception == "LockedException") {
    if (message) {
      return (
        <FormattedMessage
          id="LOGIN_PANEL_LOCKED_WM"
          defaultMessage="User is blocked: {message}"
          description="Inform user about block"
          values={{ message }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id="LOGIN_PANEL_LOCKED"
          defaultMessage="User is blocked"
          description="Inform user about block"
        />
      );
    }
  }
  if (exception == "AuthenticationServiceException") {
    return (
      <FormattedMessage
        id="LOGIN_PANEL_DB_ERROR"
        defaultMessage="User database error"
        description="Inform user about user database error"
      />
    );
  }
  if (exception == "AccountExpiredException") {
    return (
      <FormattedMessage
        id="LOGIN_PANEL_ACCOUNT_EXPIRED"
        defaultMessage="Account expired"
        description="Inform user about account expired error"
      />
    );
  }
  if (exception == "CredentialsExpiredException") {
    return (
      <FormattedMessage
        id="LOGIN_PANEL_CREDENTIALS_EXPIRED"
        defaultMessage="Credentials expired"
        description="Inform user about credentials expired error"
      />
    );
  }
  if (exception == "TOTPTokenNotReadyException") {
    return (
      <FormattedMessage
        id="LOGIN_PANEL_TOTP_TOKEN_NOT_READY"
        defaultMessage="Two-factor authentication is configured for your user but the QR code has not been generated yet."
        description="Inform user that new TOTP token must be generated"
      />
    );
  }
  if (exception == "BlockBySecuritySystemException") {
    return (
      <FormattedMessage
        id="LOGIN_BLOCK_BY_SECURITY_SYSTEM"
        defaultMessage="User was blocked system security"
        description="User was blocked by system security"
      />
    );
  }
  if (exception == "SessionAuthenticationException") {
    return (
      <FormattedMessage
        id="LOGIN_MAX_NUMBER_OF_SESSIONS"
        defaultMessage="Maximum number of allowed sessions exceeded"
        description="Maximum number of allowed sessions exceeded"
      />
    );
  }
  if (exception == "UnsecurePasswordException") {
    return (
      <FormattedMessage
        id="LOGIN_USE_OLD_PASSWORD"
        defaultMessage="Maximum number of allowed sessions exceeded"
        description="Maximum number of allowed sessions exceeded"
      />
    );
  }
  return (
    <FormattedMessage
      id="LOGIN_PANEL_BAD_CREDENTIALS"
      defaultMessage="Invalid username or password!"
      description="Inform user about invalid username and password"
    />
  );
};

export interface ValidatingFormProps extends LoginPageInfo {
  variant?: string;
  loading: boolean;
  logoutFinished: boolean;
  contextPath: string;
  login: (data: {
    username: string;
    password: string;
    newPassword?: string;
    details: LoginRequestDetails;
  }) => void;
  requestCode: () => void;
}

export interface ValidatingFormState {
  valid: boolean;
  username: string;
  useDomain: boolean;
}

/**
 * This component provides periodic checking if username or password fields are
 * filled by browser.
 *
 * We cannot use simple controlled components because:
 *
 * To handle autofills gracefully you need to give the browser control over the form.
 * The browser will not fire the onChange function when it autofills the form
 * as it's considered a security issue (password/personal data leak),
 * so you're better off leaving the form state to the browser.
 *
 * https://stackoverflow.com/questions/44431416/auto-fill-in-react-doesnt-work
 *
 * https://github.com/facebook/react/issues/1159#issuecomment-506584346
 */
export default class LoginForm extends React.Component<
  ValidatingFormProps,
  ValidatingFormState
> {
  private validateTimer: any;
  private usernameInput: React.MutableRefObject<HTMLInputElement | null>;
  private passwordInput: React.MutableRefObject<HTMLInputElement | null>;
  private newPasswordInput: React.MutableRefObject<HTMLInputElement | null>;
  private retryPasswordInput: React.MutableRefObject<HTMLInputElement | null>;
  private codeInput: React.MutableRefObject<HTMLInputElement | null>;
  private domainListInput: React.MutableRefObject<HTMLSelectElement | null>;
  private useDomainInput: React.MutableRefObject<HTMLInputElement | null>;

  constructor(props: ValidatingFormProps) {
    super(props);
    this.state = {
      valid: false,
      username: "",
      useDomain: this.props.ldapAuth ? true : false,
    };
    //Initialize references with nulls
    this.usernameInput = { current: null };
    this.passwordInput = { current: null };
    this.newPasswordInput = { current: null };
    this.retryPasswordInput = { current: null };
    this.codeInput = { current: null };
    this.domainListInput = { current: null };
    this.useDomainInput = { current: null };

    //Event listeners
    this.onDomainChange = this.onDomainChange.bind(this);
    this.onLoginChange = this.onLoginChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onNewPasswordChange = this.onNewPasswordChange.bind(this);
    this.onRetryPasswordChange = this.onRetryPasswordChange.bind(this);
    this.onCodeChange = this.onCodeChange.bind(this);
    this.onUseDomainChange = this.onUseDomainChange.bind(this);

    //Periodic validate for autofill
    this.onValidate = this.onValidate.bind(this);
    this.onRestLogin = this.onRestLogin.bind(this);
    this.onGenerateQR = this.onGenerateQR.bind(this);
  }

  getCommonDetails(): LoginRequestDetails {
    const details: LoginRequestDetails = {};
    if (
      this.props.ldapAuth &&
      this.useDomainInput.current &&
      this.useDomainInput.current.checked
    ) {
      details.ad = true;
    }
    console.log(this.isCodeRequired(), this.props.exception, this.codeInput);
    if (this.isCodeRequired() && this.codeInput.current) {
      details.code = this.codeInput.current.value;
    }
    console.log(details);
    return details;
  }

  onRestLogin(evt: MouseEvent<HTMLButtonElement>) {
    evt.preventDefault();
    const isPasswordExpired = this.isCredentialsExpired();
    if (
      !this.state.valid ||
      !this.usernameInput.current ||
      !this.passwordInput.current ||
      (isPasswordExpired && !this.newPasswordInput.current)
    ) {
      return;
    }
    const details = this.getCommonDetails();
    this.props.login({
      username: this.state.username,
      password: this.passwordInput.current.value,
      newPassword: this.newPasswordInput.current?.value,
      details,
    });
  }

  onGenerateQR(evt: MouseEvent<HTMLButtonElement>) {
    evt.preventDefault();
    if (
      !this.state.valid ||
      !this.usernameInput.current ||
      !this.passwordInput.current
    ) {
      return;
    }
    const details = this.getCommonDetails();
    details.generateToken = true;
    this.props.login({
      username: this.state.username,
      password: this.passwordInput.current.value,
      newPassword: undefined,
      details,
    });
  }

  componentDidMount() {
    //We check all input fields if they have been filled by browser!
    this.validateTimer = setInterval(this.onValidate, 500);
  }

  componentWillUnmount() {
    clearInterval(this.validateTimer);
  }

  componentDidUpdate(prevProps: ValidatingFormProps) {
    if (isQRGenerated(this.props.exception)) {
      const element = document.getElementById("qrcode");
      const url = this.props.message;
      if (element && url) {
        QRCode.toCanvas(element, url, (e) => {
          if (e) {
            console.error(e);
          }
        });
      }
    }
  }

  onLoginChange(login: string) {
    this.validate();
  }

  onPasswordChange(password: string) {
    this.validate();
  }
  onNewPasswordChange(password: string) {
    this.validate();
  }
  onRetryPasswordChange(password: string) {
    this.validate();
  }

  onCodeChange(code: string) {
    this.validate();
  }

  onDomainChange(domain: string) {
    const loginInput = this.usernameInput.current;
    //Check for empty domain and that we have login reference
    if (domain && loginInput != null) {
      const login = loginInput.value;
      const m = login.match(domainLoginRe);
      if (m) {
        loginInput.value = domain + "\\" + m[2];
      }
    }
    this.validate();
  }

  onUseDomainChange(useDomain: boolean) {
    const ldapAuth = this.props.ldapAuth ? true : false;
    this.setState({ useDomain: ldapAuth && useDomain });
    this.validate();
  }

  onValidate() {
    //console.log("Periodic validate");
    this.validate();
  }

  validate() {
    const { exception } = this.props;
    const login = this.usernameInput.current
      ? this.usernameInput.current.value.trim()
      : "";
    const password = this.passwordInput.current
      ? this.passwordInput.current.value
      : "";
    const newPassword = this.newPasswordInput.current
      ? this.newPasswordInput.current.value
      : "";
    const retryPassword = this.retryPasswordInput.current
      ? this.retryPasswordInput.current.value
      : "";
    const useDomain =
      this.props.ldapAuth &&
      this.useDomainInput.current &&
      this.useDomainInput.current.checked;
    const domain = this.domainListInput.current
      ? this.domainListInput.current.value
      : "";
    let username = login;
    let valid = login ? true : false;
    if (valid && useDomain) {
      if (login.indexOf("\\") >= 0) {
        //login is with domain information
        const m = login.match(domainLoginRe);
        valid = m ? true : false;
        if (m) {
          //make typescript happy
          username = m[1].toLowerCase() + "\\" + m[2];
          if (this.props.netbiosMap && this.props.fqdnMap) {
            //Check against supplied list of domains
            const domainByNetbios = this.props.netbiosMap[m[1].toUpperCase()];
            if (domainByNetbios) {
              username = domainByNetbios.fqdn.toLowerCase() + "\\" + m[2];
              //!!!!Change domain name
              if (this.domainListInput.current) {
                //make typescript happy
                this.domainListInput.current.value = domainByNetbios.name;
              }
            } else {
              let fqdn = m[1].toUpperCase();
              if (this.props.basicDomain) {
                const basicDomain = this.props.basicDomain.toUpperCase();
                if (!checkBasicDomain(fqdn, basicDomain)) {
                  fqdn += basicDomain;
                }
              }
              const domainByFQDN = this.props.fqdnMap[fqdn];
              if (domainByFQDN) {
                username = domainByFQDN.fqdn.toLowerCase() + "\\" + m[2];
                //!!!!Change domain name
                if (this.domainListInput.current) {
                  //make typescript happy
                  this.domainListInput.current.value = domainByFQDN.name;
                }
              } else {
                username = fqdn.toLowerCase() + "\\" + m[2];
                if (this.domainListInput.current) {
                  //make typescript happy
                  this.domainListInput.current.value = "";
                }
              }
            }
          } else {
            //We do not have domain list
            let fqdn = m[1].toUpperCase();
            if (this.props.basicDomain) {
              const basicDomain = this.props.basicDomain.toUpperCase();
              if (!checkBasicDomain(fqdn, basicDomain)) {
                fqdn += basicDomain;
              }
            }
            username = fqdn.toLowerCase() + "\\" + m[2];
          }
        }
      } else {
        //login without domain information
        const domainByNetbios =
          domain &&
          this.props.netbiosMap &&
          this.props.netbiosMap[domain.toUpperCase()];
        if (domainByNetbios) {
          username = domainByNetbios.fqdn.toLowerCase() + "\\" + login;
        } else {
          valid = false;
        }
      }
    }
    //Check password at the end
    valid = valid && password ? true : false;
    const isPasswordExpired = this.isCredentialsExpired();
    if (isPasswordExpired) {
      const notChanged = this.isPasswordNotChanged();
      valid =
        !!newPassword &&
        !!retryPassword &&
        !notChanged &&
        newPassword == retryPassword;
    }
    this.setState({ valid, username });
  }

  getLogo() {
    if (!this.props.logo) {
      return logo;
    }
    return this.props.logo;
  }

  isQRGenerated() {
    return isQRGenerated(this.props.exception);
  }

  isCodeRequired() {
    return isCodeRequired(this.props.exception);
  }
  isCredentialsExpired() {
    const { exception } = this.props;
    return isCredentialsExpired(exception) || passwordUsedBefore(exception);
  }
  renderLoginHeader = () => {
    const { variant } = this.props;
    if (!variant || variant !== "scm") {
      return (
        <div className="row p-2 d-flex flex-row justify-content-between">
          <a>
            <img src={this.getLogo()} style={{ height: "3em" }} />
          </a>
        </div>
      );
    }
    return (
      <div className="row p-2 d-flex flex-row justify-content-center">
        <h3 className="text-center">
          <FontAwesomeIcon className="mr-2" icon={faGlobe} />
          <FormattedMessage id="SCM_LOGIN_TITLE" />
        </h3>
      </div>
    );
  };
  validatePasswordMismatch() {
    const newPassword = this.newPasswordInput.current?.value;
    const retryPassword = this.retryPasswordInput.current?.value;

    if (!newPassword && !retryPassword) {
      return false;
    }
    return !(newPassword == retryPassword);
  }

  isPasswordValid() {
    const isCredentialsExpired = this.isCredentialsExpired();
    if (!isCredentialsExpired) {
      return null;
    }
    let mismatch = this.validatePasswordMismatch();
    let password = this.newPasswordInput.current?.value || "";

    const lettersAndNumbers = new RegExp(/^[a-zA-Z0-9!@#$%^&*-]*$/);
    return {
      acceptLenght: password.trim().length >= 10,
      containSymbols:
        (password?.trim() && lettersAndNumbers.test(password)) || false,
      mismatch,
    };
  }
  renderPasswordMismatch() {
    const newPassword = this.newPasswordInput.current?.value || "";
    const confirmNewPassword = this.retryPasswordInput.current?.value || "";
    if (!newPassword?.trim() && !confirmNewPassword?.trim()) {
      return null;
    }
    const match = newPassword?.trim() === confirmNewPassword?.trim();
    return (
      <Form.Text>
        <FontAwesomeIcon
          className={`mr-1 font-weight-bold text-success ${
            match ? "text-success" : "text-danger"
          }`}
          icon={match ? faCheck : faTimes}
        />
        <FormattedMessage id={"REQUIREMENT_SAME"} />
      </Form.Text>
    );
  }
  isPasswordNotChanged = () => {
    const oldPass = this.passwordInput?.current?.value;
    const newPass = this.newPasswordInput?.current?.value;
    return oldPass && newPass && newPass == oldPass;
  };
  renderPasswordValidationMessage() {
    const validationObject = this.isPasswordValid();
    if (!validationObject) {
      return null;
    }
    const { containSymbols, acceptLenght } = validationObject;
    const notChanged = this.isPasswordNotChanged();
    if (notChanged) {
      return (
        <Form.Group controlId="confirmNewPassword">
          <Form.Text className="text-danger">
            <FontAwesomeIcon className={"mr-1"} icon={faExclamationTriangle} />
            <FormattedMessage id={"REQUIREMENT_PASSWORD_NOT_CHANGED"} />
          </Form.Text>
        </Form.Group>
      );
    }
    return (
      <Form.Group controlId="confirmNewPassword">
        <Form.Text>
          <FontAwesomeIcon
            className={`mr-1 font-weight-bold ${
              containSymbols ? "text-success" : "text-danger"
            }`}
            icon={containSymbols ? faCheck : faTimes}
          />
          <FormattedMessage id={"REQUIREMENT_ONLY_NUMBERS_AND_LETTERS"} />
        </Form.Text>
        <Form.Text>
          <FontAwesomeIcon
            className={`mr-1 font-weight-bold ${
              acceptLenght ? "text-success" : "text-danger"
            }`}
            icon={acceptLenght ? faCheck : faTimes}
          />

          <FormattedMessage id={"REQUIREMENT_MIN_SIX_SYMBOLS"} />
        </Form.Text>
        {this.renderPasswordMismatch()}
      </Form.Group>
    );
  }
  render() {
    //Waiting
    if (this.props.loading) {
      //Waiting for rest auth to complete
      return (
        <div className="card-body p-2 npt-loading-login">
          <div className="d-flex flex-row">
            <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
            <div className="d-flex flex-column justify-content-center">
              <FormattedMessage
                id="LOGIN_PANEL_WAITING_FOR_REST"
                defaultMessage="Waiting for authentification to complete..."
                description="Inform user that authentification is in progress"
              />
            </div>
          </div>
        </div>
      );
    }
    if (this.isQRGenerated()) {
      return (
        <>
          <div className="card-header">{this.renderLoginHeader()}</div>
          <div className="card-body p-2">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <canvas
                  id="qrcode"
                  style={{ paddingRight: "5px", float: "left" }}
                ></canvas>
              </div>
              <div className="row justify-content-center">
                <button
                  className="btn btn-primary p-1"
                  onClick={() => this.props.requestCode()}
                >
                  <FormattedMessage
                    id="LOGIN_PANEL_HIDE_QR"
                    defaultMessage="Yes, I scanned"
                    description="User confirmed that he have scanned QR code"
                  />
                </button>
              </div>
            </div>
          </div>
        </>
      );
    }
    const isScm = this.props.variant === "scm";
    return (
      <>
        <div className="card-header">{this.renderLoginHeader()}</div>
        <div className="card-body p-2">
          <div className="container-fluid">
            {!isScm && (
              <div className="row">
                <h3 className="text-center">
                  <FormattedMessage
                    id="LOGIN_PANEL_LOGIN_INVITATION"
                    defaultMessage="Please Login"
                    description="Label to invite user to login"
                  />
                </h3>
              </div>
            )}
            <div className="row">
              <form className="col-md-12 nopadding" role="form">
                <div className="col-md-12">
                  <UsernameGroup
                    ldapAuth={this.state.useDomain}
                    inputRef={this.usernameInput}
                    onChange={this.onLoginChange}
                  />
                  <PasswordGroup
                    isCode={false}
                    inputRef={this.passwordInput}
                    onChange={this.onPasswordChange}
                  />
                  {this.props.ldapAuth && this.props.domains && this.state.useDomain && (
                    <DomainList
                      selectRef={this.domainListInput}
                      domains={this.props.domains}
                      onChange={this.onDomainChange}
                    />
                  )}
                  {this.props.ldapAuth && (
                    <UseDomainCheckboxGroup
                      checkBoxRef={this.useDomainInput}
                      onChange={this.onUseDomainChange}
                    />
                  )}
                  {this.isCodeRequired() && (
                    <PasswordGroup
                      isCode={true}
                      inputRef={this.codeInput}
                      onChange={this.onCodeChange}
                    />
                  )}
                  {this.isCredentialsExpired() && (
                    <>
                      <PasswordGroup
                        isCode={false}
                        inputRef={this.newPasswordInput}
                        onChange={this.onNewPasswordChange}
                        label={{ id: "LOGIN_PANEL_NEW_PASSWORD_LABEL" }}
                        id="newPasswordInput"
                        name="newPassword"
                      />
                      <PasswordGroup
                        isCode={false}
                        inputRef={this.retryPasswordInput}
                        onChange={this.onRetryPasswordChange}
                        label={{ id: "LOGIN_PANEL_RETRY_PASSWORD_LABEL" }}
                        id="retryPasswordInput"
                        name="retryPassword"
                      />
                    </>
                  )}
                  {this.renderPasswordValidationMessage()}
                  <div className="form-group text-center npt-login-button">
                    <button
                      disabled={!this.state.valid}
                      className="btn btn-primary p-1"
                      onClick={this.onRestLogin}
                    >
                      <FormattedMessage
                        id="LOGIN_PANEL_LOGIN_BUTTON"
                        defaultMessage="Log in"
                        description="Button to submit login information"
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {(this.props.exception || this.props.logoutFinished) && (
          <div className="card-footer p-2">
            {this.props.exception && !isCodeRequired(this.props.exception) && (
              <ExceptionHandler
                exception={this.props.exception}
                message={this.props.message}
              />
            )}
            {isQRRequired(this.props.exception) && (
              <div className="text-center">
                <button
                  disabled={!this.state.valid}
                  className="btn btn-success mt-1"
                  onClick={this.onGenerateQR}
                >
                  <FormattedMessage
                    id="LOGIN_PANEL_GENERATE_QR"
                    defaultMessage="Generate"
                    description="Allow user to generate QR code"
                  />
                </button>
              </div>
            )}
            {this.props.logoutFinished && (
              <div
                className="alert alert-info"
                role="alert"
                style={{ marginBottom: "0px" }}
              >
                <span
                  className="fa fa-fw fa-exclamation-triangle"
                  aria-hidden="true"
                >
                  {" "}
                </span>
                <FormattedMessage
                  id="LOGIN_PANEL_LOGOUT"
                  defaultMessage="Logout from system!"
                  description="Inform user that logout from system is complete"
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}
