import * as React from "react";
import { FinderOptionsSettings } from "../../types/finder";

import {
  faCodeBranch,
  faCubes,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DebounceInput from "../debounce/debounceinput";

import styles from "./Search.module.css";

interface SearchInputProps {
  className?: string;
  value: string;
  finderOptions?: FinderOptionsSettings;
  visibility?: { [k: string]: boolean };
  onChange: (value: string) => void;
  onFinish: (value: string) => void;
  changeVisibility?: (changes: { [k: string]: boolean }) => void;
}
export const SearchInput: React.FunctionComponent<SearchInputProps> =
  React.memo((props) => {
    const onChange = (value: string | {}) => {
      let stringValue: string = "";
      if (typeof value === "string") {
        stringValue = value;
      }
      props.onChange(stringValue);
    };
    const onFinish = (value: string | {}) => {
      let stringValue: string = "";
      if (typeof value === "string") {
        stringValue = value;
      }
      props.onFinish(stringValue);
    };
    const sidebarBadge = Boolean(
      props.finderOptions?.fragmentTree?.model ||
        props.finderOptions?.classTree?.levels?.length
    );
    const criteriaBadge = Boolean(props.finderOptions?.criteriaTree?.path);
    return (
      <div
        className={`${styles.searchInputContainer} ${props.className || ""}`}
      >
        <div className={`${styles.searchInput}`}>
          <div className={`d-flex ${styles.searchInputCard}`}>
            <DebounceInput
              value={props.value}
              className={"d-flex flex-fill form-control flex-grow-1"}
              format={"string"}
              editable={true}
              updateOnChange={true}
              change={onChange}
              onEnter={onFinish}
            />
            {props.value && (
              <FontAwesomeIcon
                icon={faTimes}
                className={`text-secondary ${styles.searchClearIcon}`}
                onClick={onChange.bind(null, "")}
              />
            )}
            {props.value && <div className={`${styles.searchIconDelimeter}`} />}
            {sidebarBadge && (
              <StickIcon
                sticked={props.visibility?.sidebar}
                onClick={props.changeVisibility?.bind(null, {
                  sidebar: !props.visibility?.sidebar,
                })}
              >
                <FontAwesomeIcon
                  icon={faCubes}
                  className={`text-primary ${styles.searchInputIcon}`}
                />
              </StickIcon>
            )}
            {criteriaBadge && (
              <StickIcon
                sticked={props.visibility?.criteria}
                onClick={props.changeVisibility?.bind(null, {
                  criteria: !props.visibility?.criteria,
                })}
              >
                <FontAwesomeIcon
                  icon={faCodeBranch}
                  className={`text-primary ${styles.searchInputIcon}`}
                />
              </StickIcon>
            )}
            {(sidebarBadge || criteriaBadge) && (
              <div className={`${styles.searchIconDelimeter}`} />
            )}
            <FontAwesomeIcon
              icon={faSearch}
              className={`text-primary ${styles.searchInputIcon}`}
              onClick={onFinish.bind(null, props.value)}
            />
          </div>
        </div>
      </div>
    );
  });

interface StickIconProps {
  sticked?: boolean;
  onClick?: () => void;
}
const StickIcon: React.FunctionComponent<StickIconProps> = React.memo(
  (props) => {
    return (
      <div
        className={`${styles.searchInputStickIcon} ${
          props.sticked ? styles.sticked : ""
        }`}
        onClick={props.onClick}
      >
        {props.children}
      </div>
    );
  }
);

export default SearchInput;
