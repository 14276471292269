import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import {
  FinderClassesStore,
  FinderClassLevel,
  FinderFragmentsStore,
  FinderData,
  FinderOptions,
  FragmentTreeResponseLevel,
  FragmentTreeParsedLevel,
} from "../../types/finder";
import { MSG_SELECT_PLACEHOLDER } from "../messages";

import styles from "./Finder.module.css";
import { TreeNode } from "../../types/tree";

function deleteKeysFromArray(array: any[], valueArray: any) {
  valueArray = valueArray.slice();
  for (let i = array.length - 1; i >= 0; --i) {
    for (let j = valueArray.length - 1; j >= 0; --j) {
      if (array[i] == valueArray[j]) {
        array.splice(i, 1);
        valueArray.splice(j, 1);
        break;
      }
    }
    if (valueArray.length == 0) {
      return;
    }
  }
}

function getSelectOptions(
  normalizedArray: { byId: any },
  localSelection: string[]
) {
  let options: { value: string; label: string }[] = [];
  localSelection.map(function (fragmentId, index) {
    let object = normalizedArray.byId[fragmentId];
    options.push({ value: object.id, label: object.label });
  });
  console.log("options====", options, normalizedArray);
  return options;
}

interface FinderSideBarProps {
  isFragmentsLoading: boolean
  isFragmentsError: boolean
  isHidden: boolean;
  isManual?: boolean;
  loadedFragments: FinderFragmentsStore;
  loadedClasses: FinderClassesStore;
  finderOptions: FinderOptions;
  finderData: FinderData;
  finderDataChanges: FinderData | null;
  selectFragment: (nodeId: string, unselect?: boolean) => void
  fetchClasses: (parentClassId: string) => void;
  selectClass: (
    oldClassId: string | null,
    newClassId: string | null,
    classLevelIdx: number
  ) => void;
  sendHidden: (value?: boolean) => void;
}

/****************************
 * Finder SideBar Component *
 ****************************/
const FinderSideBar: React.FunctionComponent<FinderSideBarProps> = (props) => {
  const isHidden = props.isHidden;
  const finderActualData = props.finderDataChanges || props.finderData;
  if (isHidden) {
    return null;
  }
  const isFragmentTreeVisible =
    props.finderOptions.fragmentTree &&
    !props.finderOptions.fragmentTree?.hidden;
  const isClassTreeVisible =
    props.finderOptions.classTree && !props.finderOptions.classTree?.hidden;

  if (!isFragmentTreeVisible && !isClassTreeVisible) {
    return null;
  }

  const minimize =
    props.isManual || props.finderOptions.criteriaTree
      ? null
      : props.sendHidden;

  return (
    <div
      className="d-flex flex-column col-md-2 h-100 p-0"
      style={{ overflowY: "auto" }}
    >
      {isFragmentTreeVisible && (
        <FragmentLevels
          isLoading={props.isFragmentsLoading}
          isError={props.isFragmentsError}
          selected={finderActualData.sideBar.selectedFragmentsByNodeId}
          loadedFragments={props.loadedFragments}
          selectFragment={props.selectFragment}
          minimize={minimize}
        />
      )}
      {isClassTreeVisible && (
        <ClassLevels
          loadedClasses={props.loadedClasses}
          classLevels={finderActualData.sideBar.classLevels}
          fetchClasses={props.fetchClasses}
          selectClass={props.selectClass}
          minimize={minimize}
        />
      )}
    </div>
  );
};

interface FragmentLevelsProps {
  isLoading: boolean
  isError: boolean
  selected: {[nodeId: string]: boolean}
  loadedFragments: FinderFragmentsStore;
  selectFragment: (nodeId: string, unselect?: boolean) => void
  minimize: null | (() => void);
}
const FragmentLevels: React.FunctionComponent<FragmentLevelsProps> = React.memo(
  (props) => {
  
    return (
      <div className={`card ${styles.sideBarCard} npt-finder-sidebar-card`}>
        <div className={`card-header ${styles.tableHeading}`}>
          <h6 className="card-title font-weight-bold">
            <FormattedMessage
              id="FINDER_SELECTION_AREA"
              defaultMessage="Selection area"
              description="Selection area"
            />
          </h6>
          {props.minimize ? (
            <span
              className={`fa fa-window-minimize ${styles.toggleButton}`}
              onClick={props.minimize}
            >
              &nbsp;
            </span>
          ) : null}
        </div>
        <div className="card-body panel-bar">
          {props.loadedFragments.levels.filter(level => !level.hidden).map((level, index) => {
            return (
              <FragmentLevelSelection
                key={index}
                isLoading={props.isLoading}
                isError={props.isError}
                nodes={level.nodes || []}
                selected={props.selected}
                loadedFragments={props.loadedFragments}
                level={level}
                index={index}
                selectFragment={props.selectFragment}
              />
            );
          })}
        </div>
      </div>
    );
  }
);

interface FragmentLevelSelectionProps {
  isLoading: boolean
  isError: boolean
  nodes: TreeNode[];
  selected: {[nodeId: string]: boolean}
  index: number;
  loadedFragments: FinderFragmentsStore;
  level: FragmentTreeParsedLevel;
  selectFragment: (nodeId: string, unselect?: boolean) => void
}
const FragmentLevelSelection: React.FunctionComponent<FragmentLevelSelectionProps> =
  React.memo((props) => {

    const canSelect =  Array.isArray(props.level.nodes) && props.level.nodes.length > 0;

    /* Check if selection is enabled */
    const selectionDisabled = props.isLoading || props.isError || !canSelect

    let className = `col-md-12 input-group ${styles.sideBarFragment}`;


    return (
      <div className={`col-md-12 ${styles.sideBarFragmentContainer}`}>
        <label>
          <b>{props.level.level.label}</b>
        </label>
        <FragmentSelectionRow
          className={className}
          isLoading={props.isLoading}
          isError={props.isError}
          isFetched={true}
          isDisabled={selectionDisabled}
          nodes={props.level.nodes || []}
          selected={props.selected}
          selectFragment={props.selectFragment}
        />
      </div>
    );
  });

interface ClassLevelsProps {
  loadedClasses: FinderClassesStore;
  classLevels: FinderClassLevel[];
  fetchClasses: (parentClassId: string) => void;
  selectClass: (
    oldClassId: string | null,
    newClassId: string | null,
    classLevelIdx: number
  ) => void;
  minimize: null | (() => void);
}
const ClassLevels: React.FunctionComponent<ClassLevelsProps> = React.memo(
  (props) => {
    if (!props.classLevels || props.classLevels.length === 0) {
      return null;
    }
    return (
      <>
        {props.classLevels.map((level, index) => (
          <ClassLevelsCard
            key={index}
            loadedClasses={props.loadedClasses}
            classLevel={level}
            classLevelIdx={index}
            fetchClasses={props.fetchClasses}
            selectClass={props.selectClass}
            minimize={props.minimize}
          />
        ))}
      </>
    );
  }
);

interface ClassLevelsCardProps {
  loadedClasses: FinderClassesStore;
  classLevel: FinderClassLevel;
  classLevelIdx: number;
  fetchClasses: (parentClassId: string) => void;
  selectClass: (
    oldClassId: string | null,
    newClassId: string | null,
    classLevelIdx: number
  ) => void;
  minimize: null | (() => void);
}
const ClassLevelsCard: React.FunctionComponent<ClassLevelsCardProps> =
  React.memo((props) => {
    return (
      <div className={`card ${styles.sideBarCard} npt-finder-sidebar-card`}>
        <div className={`card-header ${styles.tableHeading}`}>
          <h6 className="card-title font-weight-bold">
            <span>{props.classLevel.name}</span>
          </h6>
          {props.minimize ? (
            <span
              className={`fa fa-window-minimize ${styles.toggleButton}`}
              onClick={props.minimize}
            >
              &nbsp;
            </span>
          ) : null}
        </div>
        <div className="card-body panel-bar">
          <div className="col-md-12">
            <ClassLevelSelection
              loadedClasses={props.loadedClasses}
              classLevel={props.classLevel}
              classLevelIdx={props.classLevelIdx}
              fetchClasses={props.fetchClasses}
              selectClass={props.selectClass}
            />
          </div>
        </div>
      </div>
    );
  });

interface ClassLevelSelectionProps {
  loadedClasses: FinderClassesStore;
  classLevel: FinderClassLevel;
  classLevelIdx: number;
  fetchClasses: (parentClassId: string) => void;
  selectClass: (
    oldClassId: string | null,
    newClassId: string | null,
    classLevelIdx: number
  ) => void;
}
const ClassLevelSelection: React.FunctionComponent<ClassLevelSelectionProps> =
  React.memo((props) => {
    let selection =
      props.loadedClasses.selection[props.classLevel.id]?.slice() || [];
    let selectedClasses = props.classLevel.selected;

    /* Remove already selected ids from selection array */
    deleteKeysFromArray(selection, selectedClasses);

    /* Check if all dependencies was downloaded */
    let isLoading = props.loadedClasses.loading[props.classLevel.id];
    let isFetched = props.loadedClasses.fetched[props.classLevel.id];
    /* Check if selection is enabled */
    let selectionEnabled = selection.length != 0 || isLoading || !isFetched;

    let className = `col-md-12 input-group ${styles.sideBarClass}`;

    const changeClass = (classId: string | null) => {
      if (!classId) {
        /* Happens when user use backspace on empty search */
        return;
      }
      props.selectClass(null, classId, props.classLevelIdx);
    };

    const selectClass = (
      oldClassId: string | null,
      newClassId: string | null
    ) => {
      props.selectClass(oldClassId, newClassId, props.classLevelIdx);
    };

    return (
      <ClassSelectionRow
        normalizedObjects={props.loadedClasses}
        className={className}
        isLoading={isLoading}
        isFetched={isFetched}
        isDisabled={!selectionEnabled}
        removeDisabled={false}
        selection={selection}
        selectedObjects={selectedClasses}
        changeHandler={changeClass}
        selectHandler={selectClass}
        openHandler={props.fetchClasses.bind(this, props.classLevel.id)}
      />
    );
  });

interface ClassSelectionRowProps {
  normalizedObjects: { byId: { [k: string]: any } };
  className: string;
  isLoading: boolean;
  isFetched: boolean;
  isDisabled: boolean;
  removeDisabled: boolean;
  selection: string[];
  selectedObjects: string[];
  changeHandler: (fragmentId: string | null) => void;
  selectHandler: (oldClassId: string | null, newClassId: string | null) => void;
  openHandler: () => void;
}
const ClassSelectionRow: React.FunctionComponent<ClassSelectionRowProps> = React.memo(
  (props) => {
    console.log("class selection normalized objects: ", props.normalizedObjects)
    return (
      <div className="col-md-12 p-0">
        <Selection
          normalizedObjects={props.normalizedObjects}
          objectId={null}
          isDisabled={props.isDisabled}
          isLoading={props.isLoading}
          isFetched={props.isFetched}
          selection={props.selection}
          className={props.className}
          isSingle={props.selectedObjects.length == 0}
          changeHandler={props.changeHandler}
          openHandler={props.openHandler}
        />
        {props.selectedObjects.map((objectId, index) => (
          <SelectedObject
            key={index}
            objectLabel={props.normalizedObjects.byId[objectId]?.label || objectId}
            objectId={objectId}
            isDisabled={props.removeDisabled}
            unselect={(objectId) => props.selectHandler(objectId, null)}
          />
        ))}
      </div>
    );
  }
);
interface FragmentSelectionRowProps {
  className: string;
  isLoading: boolean;
  isError: boolean;
  isFetched: boolean;
  isDisabled: boolean;
  nodes: TreeNode[]
  selected: {[nodeId: string]: boolean}
  selectFragment: (nodeId: string, unselect?: boolean) => void
}
const FragmentSelectionRow: React.FunctionComponent<FragmentSelectionRowProps> =
  React.memo((props) => {

    const noOptions = props.nodes.length == 0;
    const singleOption = props.nodes.length == 1;

    return (
      <div className="col-md-12 p-0">
        <FragmentSelection
          objectId={null}
          isLoading={noOptions && props.isLoading}
          isDisabled={noOptions || props.isLoading || props.isError}
          isFetched={props.isFetched}
          nodes={props.nodes} 
          selected={props.selected}
          className={props.className}
          selectFragment={props.selectFragment}/>
        {props.nodes.filter(n => props.selected[n.nodeId]).map((n, index) => (
          <SelectedObject
            key={index}
            objectId={n.nodeId}
            objectLabel={n.label}
            isDisabled={singleOption || props.isError || props.isLoading}
            unselect={(id) => props.selectFragment(id, true)}
          />
        ))}
      </div>
    );
  });

interface SelectionProps {
  normalizedObjects: { byId: { [k: string]: any } };
  objectId: string | null;
  isDisabled: boolean;
  isLoading: boolean;
  isFetched: boolean;
  selection: string[];
  className: string;
  isSingle: boolean;
  changeHandler: (fragmentId: string | null) => void;
  openHandler: () => void;
}
const Selection: React.FunctionComponent<SelectionProps> = React.memo(
  (props) => {
    const intl = useIntl();
    if (props.isDisabled) {
      return (
        <div className="col-md-12 input-group">
          <Select
            name="select"
            className="minified-react-select npt-select w-100"
            loadingMessage={() =>
              intl.formatMessage({ id: "MSG_SELECT_LOADING" })
            }
            placeholder={MSG_SELECT_PLACEHOLDER}
            noOptionsMessage={() =>
              intl.formatMessage({ id: "MSG_SELECT_NO_RESULTS" })
            }
            value={null}
            isClearable={false}
            isDisabled={true}
          />
        </div>
      );
    }
    let value = null;
    let localSelection = props.selection.slice();
    if (props.objectId) {
      localSelection.unshift(props.objectId);
    }
    const selectOptions = getSelectOptions(
      props.normalizedObjects,
      localSelection
    );

    if (props.objectId) {
      value = selectOptions[0];
    }

    const changeHandler = (
      option?: { value: string; label: string } | null
    ) => {
      if (!option) {
        props.changeHandler(null);
        return;
      }
      props.changeHandler(option.value);
    };

    return (
      <div className={props.className}>
        <Select
          name="select"
          className="minified-react-select npt-select w-100"
          loadingMessage={() =>
            intl.formatMessage({ id: "MSG_SELECT_LOADING" })
          }
          placeholder={MSG_SELECT_PLACEHOLDER}
          noOptionsMessage={() =>
            intl.formatMessage({ id: "MSG_SELECT_NO_RESULTS" })
          }
          isLoading={props.isLoading}
          onMenuOpen={!props.isFetched ? props.openHandler : undefined}
          value={value}
          options={selectOptions}
          onChange={changeHandler}
          isClearable={false}
        />
      </div>
    );
  }
);

interface FragmentSelectionProps {
  objectId: string | null;
  isLoading: boolean;
  isDisabled: boolean
  isFetched: boolean;
  nodes: TreeNode[]
  selected: {[nodeId: string]: boolean}
  className: string;
  selectFragment: (nodeId: string, unselect?: boolean) => void
}
const FragmentSelection: React.FunctionComponent<FragmentSelectionProps> =
  React.memo((props) => {
    const intl = useIntl();

    const changeHandler = (
      option?: { value: string; label: string } | null
    ) => {
      if (option) {
        props.selectFragment(option.value);
      }
    };
    
    const options = props.nodes.filter(n => {
      const selected = props.selected[n.nodeId]? true: false;
      return !selected;
    }).map(n => {
      return {
        value: n.nodeId,
        label: n.label
      }
    });

    //We have empty list of left options => everything is selected!
    if (options.length == 0 && !props.isLoading && !props.isDisabled) {
      return null
    }

    return (
      <div className={props.className}>
        <Select
          name="select"
          className="minified-react-select npt-select w-100"
          loadingMessage={() =>
            intl.formatMessage({ id: "MSG_SELECT_LOADING" })
          }
          placeholder={MSG_SELECT_PLACEHOLDER}
          noOptionsMessage={() =>
            intl.formatMessage({ id: "MSG_SELECT_NO_RESULTS" })
          }
          isLoading={props.isLoading}
          value={null}
          options={options}
          onChange={changeHandler}
          isClearable={false}
          isDisabled={props.isDisabled}
        />
      </div>
    );
  });

interface SelectedObjectProps {
  objectId: string;
  objectLabel: string
  isDisabled: boolean;
  unselect: (objectId: string) => void;
}
const SelectedObject: React.FunctionComponent<SelectedObjectProps> = React.memo(
  (props) => {
    return (
      <div
        className={`col-md-12 input-group input-group-sm ${styles.sideBarFragment}`}
      >
        <input
          type="text"
          className="form-control card-input"
          disabled={true}
          value={props.objectLabel}
        />
        {!props.isDisabled && (
          <span className="input-group-append">
            <button
              className={`btn btn-secondary btn-xs  card-input ${styles.sideBarFragmentButton} h-100`}
              type="button"
              onClick={() => props.unselect(props.objectId)}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
          </span>
        )}
      </div>
    );
  }
);

export default FinderSideBar;
