import { Action } from "redux";

import { Predicate } from "./profile";
import { SubjectData } from "./subject";
import { ServerTreeNode, TreeHeader, TreeNode } from "./tree";
import { AlertLevelType } from "./alert";
import {
  CancelCallback,
  CloseCallback,
  I18NString,
  ModalOptions,
  OkCallback,
} from "./modal";

import * as finderConstants from "../constants/finder";

/* API for using finder from react */
export interface FinderApi { }

/* Props that will get connected finder */
export interface FinderProps extends FinderApi {
  //Here is table model or tree model path
  finderId: string;
  //Class tree model path
  model: string
  //Finder is tree
  isTree?: boolean
  initialized: boolean;
  isFragmentsLoading: boolean
  isFragmentsError: boolean
  isHidden: boolean;
  view: FinderViewType;
  loadedFragments: FinderFragmentsStore;
  loadedClasses: FinderClassesStore;
  loadedFields: FinderFieldsStore;
  loadedObjectcards: FinderObjectcardsStore;
  loadedPredicates: FinderPredicatesStore;
  options: FinderOptions;
  data: FinderData;
  changes: FinderData | null;
  initializeFinder: (finderOptions: FinderOptionsSettings) => void;
  fetchFields: (parentId: string) => void;
  fetchObjectcard: (rdfId: string) => void;
  selectFragment: (nodeId: string, unselect?: boolean) => void
  fetchClasses: (parentClassId: string) => void;
  selectClass: (
    oldClassId: string | null,
    newClassId: string | null,
    classLevelIdx: number
  ) => void;
  sendHidden: (value?: boolean) => void;
  changeFinderView: (view: FinderViewType) => void;
  addCriteria: (criteriaGroupId?: string) => void;
  addCriteriaRelation: (criteriaId: string) => void;
  removeCriteria: (criteriaId: string) => void;
  removeCriteriaRelation: (criteriaId: string, relationIdx: number) => void;
  changeCriteriaRelation: (
    criteriaId: string,
    relation: FinderRelation,
    relationIdx: number
  ) => void;
  changeCriteriaField: (criteriaId: string, fieldId: string) => void;
  openModal: (
    type: string,
    options: ModalOptions,
    okCallback?: OkCallback,
    cancelCallback?: CancelCallback,
    closeCallback?: CloseCallback
  ) => void;
  addAlert: (type: AlertLevelType, message: string | I18NString) => void;
}

/**Map of finder states in reducer state */
export interface FinderReducerState {
  [finderId: string]: FinderState;
}

/**State of finder */
export interface FinderState {
  /**Finder ID. May be used to identify redux store data location.*/
  finderId: string;
  /**Initialised flag of finder*/
  initialized: boolean;
  /**Flag if finder ready and table can fetch data*/
  isReady: boolean;
  /**Options of finder view */
  options: FinderOptions;
  /* Loading flag */
  isFetching: boolean;
  /* Minimize flag */
  isHidden: boolean;
  /* Fields from server */
  loadedFields: FinderFieldsStore;
  /* Fields from server */
  loadedPredicates: FinderPredicatesStore;
  /* Fields from server */
  loadedObjectcards: FinderObjectcardsStore;
  /* Enumerations from server */
  loadedEnumerations: FinderEnumerationsStore;
  /* Fragments from server */
  loadedFragments: FinderFragmentsStore;
  /* Classes from server */
  loadedClasses: FinderClassesStore;
  /* Map for fast reaching */
  classMap: { [k: string]: string };
  /* Finder view type */
  view: FinderViewType;
  /* Finder data */
  data: FinderData;
  /* Finder data changes */
  changes: FinderData | null;
  /* Filter that was imported on initialization */
  initialFilter: ServerFinderFilter | null;
}

/**Finder filter fields */
export interface FinderData {
  /* Single criteria */
  criteria: FinderCriteriaStore;
  /* Group of criteria (and) */
  criteriaGroup: FinderCriteriaGroupStore;
  /* List of criteria "and" groups */
  criteriaGroupList: string[];
  /* SideBar fetched data */
  sideBar: FinderSideBarData;
  /**Search string of finder */
  searchString: string | null;
}

/**Format of finder filter on server */
export interface ServerFinderFilter {
  orBlocks: ServerFinderFilterOrBlock[];
  criteriaFetchList: string[];
  predicateFetchList: string[];
  subjectFetchList: string[];
  classList: string[] | null;
  classLevelFetchList: string[];
  fragmentList: string[] | null;
}
/**Format of finder filter on server */
export interface ServerFinderFilterWithQuery extends ServerFinderFilter {
  queryText: string | null;
}
export interface ServerFinderFilterOrBlock {
  andPredicates: ServerFinderFilterAndBlock[];
}
export interface ServerFinderFilterAndBlock {
  field: string;
  predicate: string;
  orConditions: ServerFinderFilterOrCondition[];
}
export interface ServerFinderFilterOrCondition {
  operator: FinderNumberRelationType;
  parameters: (string | number | boolean)[] | null;
}

export type EqualityValue =
  | "less"
  | "lessOrEqual"
  | "equal"
  | "more"
  | "moreOrEqual";

/**Finder options settings (used in finder API) */
export interface FinderOptionsSettings {
  /**Settings of fragment tree */
  fragmentTree?: FragmentTree;
  /**Settings of criteria tree */
  criteriaTree?: FinderCriteriaTreeSettings;
  /**Settings of class tree */
  classTree?: FinderClassTreeSettings;
  /**Settings of search string */
  search?: FinderSearchSettings;
}

/**Finder fragment tree options */
export interface FragmentTree {
  /**Path to model */
  model: string;
  /**Fragment levels */
  levels: FragmentTreeLevel[];
  /**Hidden flag of fragment tree */
  hidden?: boolean;
}

/**Finder criteria tree options */
export interface FinderCriteriaTreeSettings {
  /**Path to model */
  path: string;
  /**Tree selections for selected predicates */
  treeSelections?: FinderSelection[];
  /**Table selections for selected predicates */
  tableSelections?: FinderSelection[];
  /**Hidden flag of fragment tree */
  hidden?: boolean;
}

/**Finder selection for predicates */
export interface FinderSelection {
  predicates: FinderSelectionPredicate[];
  path: string;
}

/**Finder selection predicate */
export interface FinderSelectionPredicate {
  name: string;
}

/**Finder class tree options */
export interface FinderClassTreeSettings {
  /**Class levels */
  levels: FinderClassLevelSettings[];
  /**Hidden flag of class tree */
  hidden?: boolean;
}

/**Finder level of class tree */
export interface FinderClassLevelSettings {
  id: string;
  label: string;
  joinId?: string;
  classes?: string[];
}

/**Finder search options */
export interface FinderSearchSettings {
  /**Initial value of search string */
  value?: string;
  /**Time of debounce reaction */
  debounceTime?: number;
  /**Hidden flag of search string */
  hidden?: boolean;
}

/**Finder single "or" condition */
export interface FinderOrCondition {
  andConditions: FinderAndCondition[];
}

/**Finder single "and" condition */
export interface FinderAndCondition {
  relations: FinderRelationList;
  path: string;
  predicate: string;
}

/**List of single-type relations */
export type FinderRelationList = FinderRelation[];

/**Finder relation type relations */
export type FinderRelation =
  | FinderInitialRelation
  | FinderNumberRelation
  | FinderDateRelation
  | FinderStringRelation
  | FinderFileRelation
  | FinderFragmentRelation
  | FinderReferenceRelation
  | FinderEnumerationRelation
  | FinderBooleanRelation;

/**Initial relation */
export interface FinderInitialRelation {
  type: null;
  value?: any;
  from?: any;
  to?: any;
}

/**Number-type relation */
export interface FinderNumberRelation {
  type: FinderNumberRelationType;
  value?: number;
  from?: number;
  to?: number;
}

/**Type of number relation */
export type FinderNumberRelationType =
  | "equal"
  | "notEqual"
  | "moreOrEqual"
  | "lessOrEqual"
  | "more"
  | "less"
  | "between";

/**Date-type relation */
export interface FinderDateRelation {
  type: FinderDateRelationType;
  value?: string;
  from?: string;
  to?: string;
}

/**Type of date relation */
export type FinderDateRelationType =
  | "equal"
  | "notEqual"
  | "moreOrEqual"
  | "lessOrEqual"
  | "more"
  | "less"
  | "between";

/**String-type relation */
export interface FinderStringRelation {
  type: FinderStringRelationType;
  value?: string;
}

/**Type of string relation */
export type FinderStringRelationType =
  | "contains"
  | "notContains"
  | "equalIgnoreCase"
  | "notEqualIgnoreCase";

/**File-type relation */
export interface FinderFileRelation {
  type: FinderFileRelationType;
  value?: string;
}

/**Type of file relation */
export type FinderFileRelationType = "attached" | "notAttached";

export function isFileRelation(type: any): type is FinderFileRelation {
  return type === "attached" || type === "notAttached";
}

/**Fragment-type relation */
export interface FinderFragmentRelation {
  type: FinderFragmentRelationType;
  value?: string;
}

/**Type of fragment relation */
export type FinderFragmentRelationType = "equal";

/**Reference-type relation */
export interface FinderReferenceRelation {
  type: FinderReferenceRelationType;
  value?: string;
}

/**Type of Reference relation */
export type FinderReferenceRelationType = "equal";

/**Enumeration-type relation */
export interface FinderEnumerationRelation {
  type: FinderEnumerationRelationType;
  value?: string;
}

/**Type of Enumeration relation */
export type FinderEnumerationRelationType = "equal" | "notEqual";

/**Boolean-type relation */
export interface FinderBooleanRelation {
  type: FinderBooleanRelationType;
  value?: boolean;
}

/**Type of Boolean relation */
export type FinderBooleanRelationType = "equal";

/**Finder options */
export interface FinderOptions extends FinderOptionsSettings { }

/**Finder stored fields data */
export interface FinderFieldsStore {
  byId: { [k: string]: FinderField };
  fetched: { [k: string]: boolean };
  loading: { [k: string]: boolean };
  idByPredicateName: { [k: string]: string };
  children: { [k: string]: string[] };
  rootIds: string[];
  allIds: string[];
}

/**Finder field data */
export interface FinderField {
  id: string;
  label?: string;
  name?: string;
  namespace?: string;
  parentId: string | null;
  joinId?: string;
  predicate?: string;
}

/**Finder stored predicate data */
export interface FinderPredicatesStore {
  byName: { [k: string]: FinderPredicate };
}

/**Finder predicate data */
export interface FinderPredicate extends Predicate {
  format?: string;
  type?: number;
}

/**Finder stored objectcards data */
export interface FinderObjectcardsStore {
  byId: { [k: string]: FinderObjectcard };
  fetched: { [k: string]: boolean };
}

/**Finder predicate data */
export interface FinderObjectcard extends SubjectData { }

/**Finder stored enumerations data */
export interface FinderEnumerationsStore {
  byId: { [k: string]: FinderEnumeration };
  fetched: { [k: string]: boolean };
  idByPredicateName: { [k: string]: string };
  rootIds: string[];
  allIds: string[];
}

/**Finder enumeration data */
export interface FinderEnumeration {
  id: string;
  label: string;
  children: string[];
  parentId: string | null;
}

/**Finder stored fragments data */
export interface FinderFragmentsStore {
  //Loading indicator
  loading: boolean
  //Indicate error on loading
  error: boolean
  //Fragment tree levels to be displayed
  //Together with information about hidden/tree nodes per level
  levels: FragmentTreeParsedLevel[]
}

/**Finder stored classes data */
export interface FinderClassesStore {
  byId: { [k: string]: FinderClass };
  fetched: { [k: string]: boolean };
  loading: { [k: string]: boolean };
  selection: { [k: string]: string[] };
  idByClassId: { [k: string]: string };
  children: { [k: string]: string[] };
  allIds: string[];
}

/**Finder class data */
export interface FinderClass extends ServerFinderClass {
  level: number;
}

/**Finder stored criteria data */
export interface FinderCriteriaStore {
  byId: { [k: string]: FinderCriteria };
  allIds: string[];
}

/**Finder criteria data */
export interface FinderCriteria {
  id: string;
  fieldId: string | null;
  relations: FinderRelationList;
  criteriaGroupId: string;
  locked?: boolean;
}

/**Finder stored criteria group data */
export interface FinderCriteriaGroupStore {
  byId: { [k: string]: FinderCriteriaGroup };
  allIds: string[];
}

/**Finder criteria group data */
export interface FinderCriteriaGroup {
  criteriaList: string[];
}

/**Finder sidebar data */
export interface FinderSideBarData {
  //Nodes selected in fragment tree. Node id is "3._2d302ee9-29a6-4a2c-a751-f72c644f7d80"
  selectedFragmentsByNodeId: { [nodeId: string]: boolean }
  classLevels: FinderClassLevel[];
  selectedClass: string | null;
}

/**Finder fragment level */
export interface FragmentTreeLevel {
  label: string;
  types: string[];
}

export interface FragmentTreeResponseLevel {
  level: FragmentTreeLevel
  hidden: boolean
  nodes?: ServerTreeNode[]
}

export interface FragmentTreeParsedLevel {
  level: FragmentTreeLevel
  hidden: boolean
  nodes: TreeNode[]
}

export interface FragmentTreeRequest {
  tree: FragmentTree
  selected: string[]
}

export interface FragmentTreeResponse {
  levels: FragmentTreeResponseLevel[]
  selected: string[]
}

/**Finder class level */
export interface FinderClassLevel {
  id: string;
  name: string;
  selected: string[];
  joinId?: string | null;
}

/**Finder view type */
export type FinderViewType =
  | finderConstants.FINDER_VIEW_TYPE_EDIT
  | finderConstants.FINDER_VIEW_TYPE_ADD;


/**Server finder class format */
export interface ServerFinderClass {
  id: string;
  label?: string;
  name: string;
  className: string;
}

/**Server finder field format */
export interface ServerFinderField extends FinderField { }

///////////
//Actions//
///////////
export interface SendFinderHidden extends Action {
  type: finderConstants.SEND_FINDER_HIDDEN;
  finderId: string;
  payload: {
    hidden?: boolean;
  };
}

export interface SendFinderOptions extends Action {
  type: finderConstants.SEND_FINDER_OPTIONS;
  finderId: string;
  payload: {
    options: FinderOptions;
    filter?: ServerFinderFilter | null;
  };
}

export interface SendFinderView extends Action {
  type: finderConstants.SEND_FINDER_VIEW;
  finderId: string;
  payload: {
    view: FinderViewType;
  };
}

export interface SendFinderClassesLoading extends Action {
  type: finderConstants.SEND_FINDER_CLASSES_LOADING;
  finderId: string;
  payload: {
    levelId: string;
  };
}

export interface SendFinderClasses extends Action {
  type: finderConstants.SEND_FINDER_CLASSES;
  finderId: string;
  payload: {
    levelId: string;
    classes: ServerFinderClass[];
  };
}

export interface SendFinderSelectClass extends Action {
  type: finderConstants.SEND_FINDER_SELECT_CLASS;
  finderId: string;
  payload: {
    oldClassId: string | null;
    newClassId: string | null;
    classLevelIdx: number;
  };
}

export interface SendFinderFieldsLoading extends Action {
  type: finderConstants.SEND_FINDER_FIELDS_LOADING;
  finderId: string;
  payload: {
    parentId: string | null;
  };
}

export interface SendFinderFields extends Action {
  type: finderConstants.SEND_FINDER_FIELDS;
  finderId: string;
  payload: {
    parentId: string | null;
    fields: ServerFinderField[];
  };
}

export interface SendFinderObjectcard extends Action {
  type: finderConstants.SEND_FINDER_OBJECTCARD;
  finderId: string;
  payload: {
    rdfId: string;
    objectcard: FinderObjectcard;
  };
}

export interface SendFinderObjectcardError extends Action {
  type: finderConstants.SEND_FINDER_OBJECTCARD_ERROR;
  finderId: string;
  payload: {
    rdfId: string;
    error: Error;
  };
}

export interface SendFinderCriteriaAdd extends Action {
  type: finderConstants.SEND_FINDER_CRITERIA_ADD;
  finderId: string;
  payload: {
    criteriaGroupId?: string;
  };
}

export interface SendFinderCriteriaRelationAdd extends Action {
  type: finderConstants.SEND_FINDER_CRITERIA_RELATION_ADD;
  finderId: string;
  payload: {
    criteriaId: string;
  };
}

export interface SendFinderCriteriaRemove extends Action {
  type: finderConstants.SEND_FINDER_CRITERIA_REMOVE;
  finderId: string;
  payload: {
    criteriaId: string;
  };
}

export interface SendFinderCriteriaRelationRemove extends Action {
  type: finderConstants.SEND_FINDER_CRITERIA_RELATION_REMOVE;
  finderId: string;
  payload: {
    criteriaId: string;
    relationIdx: number;
  };
}

export interface SendFinderCriteriaRelationsUnlock extends Action {
  type: finderConstants.SEND_FINDER_CRITERIA_RELATIONS_UNLOCK;
  finderId: string;
  payload: {
    criteriaId: string;
  };
}

export interface SendFinderCriteriaRelation extends Action {
  type: finderConstants.SEND_FINDER_CRITERIA_RELATION;
  finderId: string;
  payload: {
    criteriaId: string;
    relationIdx: number;
    relation: FinderRelation;
  };
}

export interface SendFinderPredicate extends Action {
  type: finderConstants.SEND_FINDER_PREDICATE;
  finderId: string;
  payload: {
    fieldId?: string;
    predicateName: string;
    predicate: FinderPredicate;
  };
}

export interface SendFinderCriteriaField extends Action {
  type: finderConstants.SEND_FINDER_CRITERIA_FIELD;
  finderId: string;
  payload: {
    criteriaId: string;
    fieldId: string;
  };
}

export interface SendFinderSearch extends Action {
  type: finderConstants.SEND_FINDER_SEARCH;
  finderId: string;
  payload: {
    value: string;
  };
}

export interface SendFinderChangesConfirm extends Action {
  type: finderConstants.SEND_FINDER_CHANGES_CONFIRM;
  finderId: string;
  payload: null;
}

export interface SendFinderChangesDeny extends Action {
  type: finderConstants.SEND_FINDER_CHANGES_DENY;
  finderId: string;
  payload: null;
}


//It may be combined  with |
export type FinderAction =
  | SendFinderOptions
  | SendFinderHidden
  | SendFinderView
  | SendFinderClassesLoading
  | SendFinderClasses
  | SendFinderSelectClass
  | SendFinderFieldsLoading
  | SendFinderFields
  | SendFinderObjectcard
  | SendFinderObjectcardError
  | SendFinderCriteriaAdd
  | SendFinderCriteriaRelationAdd
  | SendFinderCriteriaRemove
  | SendFinderCriteriaRelationRemove
  | SendFinderCriteriaRelationsUnlock
  | SendFinderCriteriaRelation
  | SendFinderPredicate
  | SendFinderCriteriaField
  | SendFinderSearch
  | SendFinderChangesConfirm
  | SendFinderChangesDeny;
