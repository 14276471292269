import {
  faChevronDown,
  faCommentDots,
  faFile,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form } from "react-bootstrap";
import styles from "./FAQ.module.css";
interface Ref {
  title: string;
  ref: string;
  icon: string;
  download: boolean;
}

interface Image {
  src: string;
  width?: string;
  height?: string;
}

interface Description {
  text: string;
  ref: Ref;
  img?: Image;
}

interface FAQData {
  id: number;
  title: string;
  description: Description[];
}
interface FAQInfo {
  ids: number[];
  filteredIds: number[];
  byId: { [ID: number]: FAQData };
  expanded: { [ID: number]: boolean };
}
interface FAQProps {
  questions?: FAQData[];
}

interface FAQState {
  search: string;
  faqInfo: FAQInfo;
}
export class Faq extends React.Component<FAQProps, FAQState> {
  constructor(props: FAQProps) {
    super(props);
    this.state = {
      search: "",
      faqInfo: { byId: {}, ids: [], filteredIds: [], expanded: {} },
    };
  }
  parseDataToInfo(data?: FAQData[]) {
    const faqInfo: FAQInfo = {
      byId: {},
      ids: [],
      filteredIds: [],
      expanded: {},
    };
    if (!data?.length) {
      return faqInfo;
    }
    data.forEach((d) => {
      const { description, id, title } = d;
      faqInfo.ids.push(id);
      faqInfo.filteredIds.push(id);
      faqInfo.byId[id] = d;
      faqInfo.expanded[id] = false;
    });
    return faqInfo;
  }
  componentDidMount() {
    const { questions } = this.props;
    const faqInfo = this.parseDataToInfo(questions);
    this.setState({ faqInfo: faqInfo });
  }
  matchInDescription = (search: string, description: Description[]) => {
    for (let d of description) {
      const { ref, text } = d;
      if (ref?.ref?.toLowerCase()?.includes(search.toLowerCase())) {
        return true;
      }
      if (ref?.title?.toLowerCase()?.includes(search.toLowerCase())) {
        return true;
      }
      if (text?.toLowerCase()?.includes(search.toLowerCase())) {
        return true;
      }
    }
    return false;
  };
  changeSearch = (e: any) => {
    const search = e.target.value;
    const { faqInfo } = this.state;
    const newFaqInfo = { ...faqInfo };
    newFaqInfo.filteredIds = [];

    const searchStr = search?.trim()?.toLowerCase();
    if (searchStr) {
      newFaqInfo.ids.forEach((id) => {
        const { description: desc, title: t } = newFaqInfo.byId[id];

        const title = t?.toLowerCase();
        const isDescriptionMatched = this.matchInDescription(searchStr, desc);
        if (isDescriptionMatched || title?.includes(searchStr)) {
          newFaqInfo.filteredIds.push(id);
        }
      });
    } else {
      newFaqInfo.filteredIds = newFaqInfo.ids;
    }

    this.setState({ search, faqInfo: newFaqInfo });
  };
  toggleQuestion = (id: number) => {
    const { faqInfo } = this.state;
    const newFaq = { ...faqInfo };
    const oldExp = !!newFaq.expanded[id];
    newFaq.expanded[id] = !oldExp;
    this.setState({ faqInfo: newFaq });
  };
  renderQuestionList = () => {
    const { search, faqInfo } = this.state;

    if (!faqInfo?.filteredIds?.length) {
      return (
        <div className="p-2 d-flex justify-content-center ">
          <div>Ничего не найдено</div>
        </div>
      );
    }
    return faqInfo.filteredIds.map((id) => (
      <Question
        isLast={id === faqInfo.filteredIds.length - 1}
        question={faqInfo.byId[id]}
        expanded={!!faqInfo.expanded[id]}
        toggle={this.toggleQuestion}
      />
    ));
  };
  render() {
    const { search, faqInfo } = this.state;
    return (
      <div>
        <div>
          <Form.Control
            placeholder="Введите свой вопрос"
            autoFocus
            size="lg"
            value={search}
            onChange={this.changeSearch}
          />
        </div>
        <div
          className={`rounded shadow-sm border mt-3 ${styles.questionContainer}`}
        >
          {this.renderQuestionList()}
        </div>
      </div>
    );
  }
}

interface QuestionProps {
  expanded: boolean;
  question: FAQData;
  isLast: boolean;
  toggle: (id: number) => void;
}
const Question = (props: QuestionProps) => {
  const { expanded, question, toggle } = props;
  const { description, id, title } = question;
  const expandStyle = expanded
    ? { height: "max-content", padding: ".8rem" }
    : { height: "0", overflow: "hidden" };
  const renderImage = (img?: Image) => {
    if (!img) {
      return null;
    }
    const { src, width, height } = img;
    return (
      <div className="w-100 h-auto">
        <img
          src={src}
          style={{
            width,
            height,
          }}
        />
      </div>
    );
  };
  const renderDescription = () => {
    if (!description) {
      return null;
    }

    return description.map((d) => {
      const { ref, text, img } = d;

      return (
        <div className="mb-2">
          <div>{text}</div>
          {ref && (
            <div>
              <a download={ref.download} href={ref.ref} target="_blank">
                {ref.icon && (
                  <span className="mr-2">
                    <i className={ref.icon}></i>
                  </span>
                )}
                <span>{ref.title}</span>
              </a>
            </div>
          )}
          {renderImage(img)}
        </div>
      );
    });
  };
  return (
    <div className={`   ${styles.question}    `}>
      <div
        className={` p-2 d-flex  border-bottom`}
        onClick={() => toggle(id)}
        style={{ fontSize: "1.3rem", cursor: "pointer" }}
      >
        <div className="mr-4">
          <FontAwesomeIcon
            style={{
              transform: expanded ? "rotate(180deg)" : "",
              transition: ".5s",
            }}
            icon={faChevronDown}
          />
        </div>
        <div>{title}</div>
      </div>
      <div
        className="bg-secondary d-flex "
        style={{
          ...expandStyle,
          transition: ".5s",
          fontSize: "1rem",
          boxSizing: "border-box",
        }}
      >
        <div className="text-primary border-right border-primary pr-3">
          <FontAwesomeIcon
            style={{ width: "3rem", height: "3rem" }}
            icon={faCommentDots}
          />
        </div>
        <div
          className="h-100 bg-primary ml-1 mr-3"
          style={{ width: "3px" }}
        ></div>
        <div>{renderDescription()}</div>
      </div>
    </div>
  );
};
