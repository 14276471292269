import { createModel } from "@rematch/core";
import shortid from "shortid";
import { RootModel } from ".";
import { AlertLevelType, AlertOptions } from "../types/alert";
import {
  CancelCallback,
  CloseCallback,
  I18NString,
  ModalCallbacksRegistry,
  ModalOptions,
  ModalState,
  ModalStatus,
  OkCallback,
  PortalCallback,
} from "../types/modal";
import * as Action from "../constants/modal";
/**This is hack: because we cannot pass callbacks to reducer (they are not serializable so we store them in global map) */
const modalCallbacksRegistry: ModalCallbacksRegistry = {};
export function getPortalCallback(id: string): PortalCallback | undefined {
  return modalCallbacksRegistry[id]?.portalCallback;
}

const initialState: ModalState = { modalList: [] };

export const modal = createModel<RootModel>()({
  state: initialState,
  reducers: {
    // sendRemoveAlert(state, id: string) {
    //   const alertList = state.alertList.filter((alert) => alert.id != id);
    //   return { ...state, alertList };
    // },
    sendOpenModal(
      state,
      payload: {
        id: string;
        type: string;
        options: ModalOptions;
        okCallback?: OkCallback;
        cancelCallback?: CancelCallback;
        closeCallback?: CloseCallback;
        portalCallback?: PortalCallback;
      }
    ) {
      const modalList = [...state.modalList, payload];
      return { ...state, modalList };
    },
    updateModal(state, payload: { id: string; options: ModalOptions }) {
      const modalList = state.modalList.map((m) => {
        if (m.id != payload.id) {
          return m;
        }
        return { ...m, options: payload.options };
      });
      return { ...state, modalList };
    },
    updateModalTitle(
      state,
      payload: { id: string; title?: string | I18NString }
    ) {
      const modalList = state.modalList.map((m) => {
        if (m.id != payload.id) {
          return m;
        }
        const options = { ...m.options };
        options.title = payload.title;
        return { ...m, options };
      });
      return { ...state, modalList };
    },
    plainCloseModal(state, payload: { id: string; status: ModalStatus }) {
      const modalList = state.modalList.filter((m) => m.id != payload.id);
      return { ...state, modalList };
    },
  },
  effects: (dispatch) => ({
    closeModal(
      data: {
        id: string;
        status: ModalStatus;
        result?: any;
        extraData?: any;
      },
      state
    ) {
      const { id, status, result, extraData } = data;
      const callbacks = modalCallbacksRegistry[id];
      if (callbacks) {
        switch (status) {
          case Action.MODAL_STATUS_OK:
            if (callbacks.okCallback) {
              const okResult = callbacks.okCallback(result, extraData);
              if (okResult === false) {
                return;
              }
            }
            break;
          case Action.MODAL_STATUS_CANCEL:
            if (callbacks.cancelCallback) {
              callbacks.cancelCallback();
            }
            break;
          case Action.MODAL_STATUS_CLOSE:
            if (callbacks.cancelCallback) {
              callbacks.cancelCallback();
            }
            break;
        }
        delete modalCallbacksRegistry[id];
      }
      dispatch.modal.plainCloseModal({ id, status });
    },
    openModal(
      data: {
        id: string;
        type: string;
        options: ModalOptions;
        okCallback?: OkCallback;
        cancelCallback?: CancelCallback;
        closeCallback?: CloseCallback;
        portalCallback?: PortalCallback;
      },
      state
    ) {
      const { id, portalCallback, okCallback, cancelCallback, closeCallback } =
        data;

      let newId = id;
      while (true) {
        if (modalCallbacksRegistry[newId]) {
          newId = `${id}_${shortid.generate()}`;
        } else {
          break;
        }
      }
      let newData = data;
      if (newId != id) {
        newData = { ...data, id: newId };
      }
      modalCallbacksRegistry[newId] = {
        okCallback,
        cancelCallback,
        closeCallback,
        portalCallback,
      };
      dispatch.modal.sendOpenModal(newData);
    },
  }),
});
