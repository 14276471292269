import { connect } from "react-redux";

import { ServerError } from "../actions/utils";
import { ContextNode, ListTree, Node } from "../components/tree/ListTree";
import { NODE_DND_ID, TreeEvent } from "../constants/tree";
import { FetchError, isFetchError } from "../types/error";
import { CimClass, Package } from "../types/profile";
import { isLoggedInUser } from "../types/security";
import { createObject } from "../types/selection";
import { SourceTreeNode } from "../types/sourceeditor";
import { isSubject, Subject, SubjectData } from "../types/subject";
import {
  ExpandTreeNodeEventOptions,
  isTreeNode,
  isTreeNodeAddAction,
  isTreeNodeGenericAction,
  isTreeNodes,
  ModelParameters,
  ParentNode,
  SelectionHandler,
  ServerTreeNode,
  TreeAutomationBindingFunctions,
  TreeAutomationBindings,
  TreeHeader,
  TreeHeaderFilter,
  TreeNode,
  TreeNodeAddAction,
  TreeNodeData,
  TreeNodeGenericAction,
  TreeNodeType,
  TreeReducerState,
  TreeResponseData,
  TreeState,
} from "../types/tree";
import {
  registerFunction,
  retrieveFunction,
  scriptCompiler,
} from "./automation";
import {
  composeAddUrl,
  composePasteUrl,
  composeFetchUrl,
} from "./npt-treebeard";
import * as c from "../constants/tree";
import { initialTreeState } from "../model/tree";
import { Dispatch, RootState } from "../store";

export function expandTreeNodeFire(treeId: string, nodeId: string) {
  let eventOptions: ExpandTreeNodeEventOptions = {
    treeId,
    nodeId,
  };
  const newSubjectEvent = new CustomEvent(TreeEvent.EXPAND_NODE, {
    detail: eventOptions,
  });

  document.dispatchEvent(newSubjectEvent);
}

export function sortTreeNodes(
  nodes: TreeNode[],
  parentNode?: TreeNode,
  automation?: TreeAutomationBindings
): TreeNode[] {
  let sortedNodes = nodes.sort((nodeA, nodeB) => {
    if (!nodeA.leaf) {
      if (nodeB.leaf) {
        /**NodeA is directory, nodeB isn't */
        return -1;
      }
    } else if (!nodeB.leaf) {
      /**NodeB is directory, nodeA isn't */
      return 1;
    }

    /**Both nodes have same type => sort by name */
    if (nodeA.name) {
      if (!nodeB.name) {
        /**NodeA have name, nodeB haven't */
        return -1;
      }
    } else if (nodeB.name) {
      /**NodeB have name, nodeA haven't */
      return 1;
    } else {
      /**Both nodes haven't name */
      return 0;
    }

    /**Both nodes have name */
    if (nodeA.name > nodeB.name) {
      return 1;
    }
    if (nodeA.name < nodeB.name) {
      return -1;
    }
    return 0;
  });
  if (
    parentNode &&
    automation &&
    automation.sortBindings &&
    automation.sortBindings[parentNode.typeId]
  ) {
    const sortFunction = retrieveFunction(
      automation.sortBindings[parentNode.typeId]
    );
    sortedNodes = sortedNodes.sort(sortFunction);
  }
  return sortedNodes;
}

export function sortSourceTreeNodes(
  nodesIds: string[],
  nodeById: { [ID: string]: SourceTreeNode }
): string[] {
  return nodesIds.sort((nodeAId, nodeBId) => {
    const nodeA = nodeById[nodeAId];
    const nodeB = nodeById[nodeBId];

    if (nodeA) {
      if (!nodeB) {
        /**NodeA is defined, nodeB isn't */
        return -1;
      }
    } else if (nodeB) {
      /**NodeB is defined, nodeA isn't */
      return 1;
    } else {
      /**Both nodes isn't defined */
      return 0;
    }

    if (nodeA.directory) {
      if (!nodeB.directory) {
        /**NodeA is directory, nodeB isn't */
        return -1;
      }
    } else if (nodeB.directory) {
      /**NodeB is directory, nodeA isn't */
      return 1;
    }

    /**Both nodes have same type => sort by name */
    if (nodeA.name) {
      if (!nodeB.name) {
        /**NodeA have name, nodeB haven't */
        return -1;
      }
    } else if (nodeB.name) {
      /**NodeB have name, nodeA haven't */
      return 1;
    } else {
      /**Both nodes haven't name */
      return 0;
    }

    /**Both nodes have name */
    if (nodeA.name > nodeB.name) {
      return 1;
    }
    if (nodeA.name < nodeB.name) {
      return -1;
    }
    return 0;
  });
}

export function sortProfileeditorTreeNodes(
  nodesIds: string[],
  nodeById: { [ID: string]: Package | CimClass }
): string[] {
  return nodesIds.sort((nodeAId, nodeBId) => {
    const nodeA = nodeById[nodeAId];
    const nodeB = nodeById[nodeBId];

    if (nodeA) {
      if (!nodeB) {
        /**NodeA is defined, nodeB isn't */
        return -1;
      }
    } else if (nodeB) {
      /**NodeB is defined, nodeA isn't */
      return 1;
    } else {
      /**Both nodes isn't defined */
      return 0;
    }

    /**Both nodes have same type => sort by name */
    if (nodeA.label) {
      if (!nodeB.label) {
        /**NodeA have name, nodeB haven't */
        return -1;
      }
    } else if (nodeB.label) {
      /**NodeB have name, nodeA haven't */
      return 1;
    } else {
      /**Both nodes haven't name */
      return 0;
    }

    /**Both nodes have name */
    if (nodeA.label > nodeB.label) {
      return 1;
    }
    if (nodeA.label < nodeB.label) {
      return -1;
    }
    return 0;
  });
}

/**
 * Use composition function to synchronize generation of id in service and in component.
 * If new option will be added - we can prevent id mismatch errors.
 **/
export function composeNodeDecoratorId(type: string) {
  return type;
}
export function composeNodeActionId(actionId: string, type?: string) {
  if (!type) {
    return actionId;
  }
  return `${type}.${actionId}`;
}

export function generateTreeBindings(
  automation: TreeAutomationBindings
): TreeAutomationBindingFunctions {
  return {
    bindSort: function (parentType: string | string[], func: Function) {
      //function(nodeA, nodeB) => number
      if (!automation.sortBindings) {
        automation.sortBindings = {};
      }
      if (Array.isArray(parentType)) {
        for (let type of parentType) {
          automation.sortBindings[type] = registerFunction(func);
        }
      } else {
        automation.sortBindings[parentType] = registerFunction(func);
      }
    },
    bindAction: function (
      options: { action: string; type?: string | string[] },
      func: Function
    ) {
      //function(tree) => void
      if (!automation.actionBindings) {
        automation.actionBindings = {};
      }
      if (Array.isArray(options.type)) {
        for (let type of options.type) {
          automation.actionBindings[composeNodeActionId(options.action, type)] =
            registerFunction(func);
        }
      } else {
        automation.actionBindings[
          composeNodeActionId(options.action, options.type)
        ] = registerFunction(func);
      }
    },
    bindDecorator: function (
      options: { type: string | string[] },
      func: Function
    ) {
      //function(tree) => void
      if (!automation.decoratorBindings) {
        automation.decoratorBindings = {};
      }
      if (Array.isArray(options.type)) {
        for (let type of options.type) {
          automation.decoratorBindings[composeNodeDecoratorId(type)] =
            registerFunction(func);
        }
      } else {
        automation.decoratorBindings[composeNodeDecoratorId(options.type)] =
          registerFunction(func);
      }
    },
    bindReverseDecorator: function (
      options: { type: string | string[] },
      func: Function
    ) {
      //function(tree) => void
      if (!automation.reverseDecoratorBindings) {
        automation.reverseDecoratorBindings = {};
      }
      if (Array.isArray(options.type)) {
        for (let type of options.type) {
          automation.reverseDecoratorBindings[composeNodeDecoratorId(type)] =
            registerFunction(func);
        }
      } else {
        automation.reverseDecoratorBindings[
          composeNodeDecoratorId(options.type)
        ] = registerFunction(func);
      }
    },
  };
}

function getFilterValue(
  nodeFilterValue: string,
  filter: TreeHeaderFilter
): boolean | null {
  const filterOption = filter.options.find(
    (option) => option.value === nodeFilterValue
  );
  if (filterOption) {
    return filterOption.checked;
  }
  return null;
}

/**Check if node is hidden by filter */
export function isNodeHiddenByFilter(
  node: TreeNode,
  filter?: TreeHeaderFilter
) {
  if (!filter || !node.cache || !node.cache[filter.key]) {
    return false;
  }
  const nodeFilterValue = node.cache[filter.key];
  if (typeof nodeFilterValue === "string") {
    const filterValue = getFilterValue(nodeFilterValue, filter);
    if (filterValue === false) {
      return true;
    }
    return false;
  }
  if (!Array.isArray(nodeFilterValue)) {
    return false;
  }
  let hidden = true;
  for (let value of nodeFilterValue) {
    const filterValue = getFilterValue(value, filter);
    if (filterValue === true) {
      hidden = false;
    }
  }
  return hidden;
}

/**Search for node path in loaded tree */
export function getNodePathByRdfId(
  tree: TreeState,
  rdfId: string
): string[] | null {
  const nodeId = tree.nodeIdByRdfId?.[rdfId];
  if (!nodeId) {
    return null;
  }
  let node = tree.nodeById?.[nodeId];
  if (!node) {
    return null;
  }
  const path = [node.id];
  while (node?.parentId) {
    path.unshift(node.parentId);
    node = tree.nodeById?.[node.parentId];
  }
  return path;
}

/*************************
 * Tree props connectors *
 *************************/
function getDecoratedNodeProps(
  treeState: TreeState,
  nodeById: { [ID: string]: TreeNode },
  subjects: { [SUBJECT_KEY: string]: Subject | FetchError },
  nodeId: string
) {
  const node = nodeById[nodeId] as TreeNode;
  const rdfId: string | null = node.data && node.data.$rdfId;
  const active = treeState.active ? treeState.active === nodeId : false;
  let icon: string | undefined = node.icon;
  let iconColor: string | null | undefined = node.iconColor;
  let iconClass: string | null | undefined = null;
  const toggled = treeState.toggled ? treeState.toggled[nodeId] : false;
  if (rdfId && subjects && subjects[rdfId]) {
    const subject = subjects[rdfId];
    if (
      isSubject(subject) &&
      !subject.isNew &&
      subject.subjectData.$lock &&
      subject.subjectData.$lock.status
    ) {
      icon = "fa-edit";
      iconColor = null;
      iconClass = `text-${active ? "white" : "success"}`;
    }
  }

  let children = undefined;
  if (!node.leaf) {
    children = (treeState.children && treeState.children[nodeId]) || null;
  }

  const hidden = isNodeHiddenByFilter(node, treeState?.header?.filter);
  return {
    name: node.label,
    expanded: toggled,
    active: active,
    hidden: hidden,
    inplace: false,
    edgeTriggerInplace: true,
    loading: treeState.loading && treeState.loading[node.id],
    error: Boolean(node.error),
    editing: false,
    changed: false,
    childrenIds: children,
    data: node,
    icon: icon,
    iconColor: iconColor,
    iconClass: iconClass,
    decorator:
      treeState.automation.decoratorBindings?.[
      composeNodeDecoratorId(node.typeId)
      ],
    reverseDecorator:
      treeState.automation.reverseDecoratorBindings?.[
      composeNodeDecoratorId(node.typeId)
      ],
    treeFilter: treeState.header?.filter,
  };
}

function getDndNodeProps(
  treeState: TreeState,
  nodeById: { [ID: string]: TreeNode },
  subjects: { [SUBJECT_KEY: string]: Subject | FetchError },
  nodeId: string,
  isSuperUser?: boolean
) {
  const decoratedProps = getDecoratedNodeProps(
    treeState,
    nodeById,
    subjects,
    nodeId
  );
  const node = nodeById[nodeId] as TreeNode;

  return {
    ...decoratedProps,
    dragType: NODE_DND_ID,
    dropTypes: [NODE_DND_ID],
    pasteTypes: node.pasteTypes,
    isSuperUser: isSuperUser,
  };
}

const nodeSimpleActionsConnector = (
  dispatch: Dispatch,
  ownProps: { treeId: string; id: string }
) => {
  const { id, treeId } = ownProps;
  return {
    toggle: (expanded: boolean) => {
      dispatch.tree.sendTreeNodeToggle({ path: treeId, nodeId: id, expanded });
    },
    activate: (node: TreeNode) => {
      dispatch.tree.sendTreeNodeActive({ path: treeId, nodeId: id });
    },
    loadChildren: () => {
      dispatch.tree.fetchTreeNodes({ path: treeId, parentNodeId: id });
    },
  };
};

const nodeActionsConnector = (
  dispatch: Dispatch,
  ownProps: {
    treeId: string;
    id: string;
    disableSelection?: boolean;
    onSelectHandler?: SelectionHandler;
  }
) => {
  const { id, treeId, disableSelection, onSelectHandler } = ownProps;
  return {
    ...nodeSimpleActionsConnector(dispatch, ownProps),
    activate: (node: TreeNode) => {
      if (!node.data) {
        return;
      }
      const rdfId = node.data.$rdfId;
      const namespace = node.data.$namespace;
      const type = node.typeId;
      dispatch.tree.sendTreeNodeActive({ path: treeId, nodeId: id });
      const select = onSelectHandler?.();
      if (disableSelection) {
        return;
      }
      if (select) {
        select(node);
        return;
      }
      if (rdfId) {
        dispatch.selection.sendSelection({
          object: createObject(rdfId, namespace),
          type: type || "",
        });
      }
    },
  };
};

const nodeWithMenuActionsConnector = (
  dispatch: Dispatch,
  ownProps: {
    treeId: string;
    id: string;
    disableSelection?: boolean;
    onSelectHandler?: SelectionHandler;
  }
) => {
  const { id, treeId } = ownProps;
  return {
    ...nodeActionsConnector(dispatch, ownProps),
    contextMenu: () => {
      dispatch.tree.sendContextMenuInfo({ treeId, nodeId: id });
    },
  };
};

const nodeDropConnector = (
  dispatch: Dispatch,
  ownProps: {
    treeId: string;
    id: string;
    disableSelection?: boolean;
    onSelectHandler?: SelectionHandler;
  }
) => {
  const { id, treeId, onSelectHandler } = ownProps;
  return {
    ...nodeWithMenuActionsConnector(dispatch, ownProps),
    drop: (data: any, isCopy: boolean) => {
      dispatch.tree.confirmPasteTreeChild({
        treeId,
        targetId: data?.targetId,
        sourceId: id,
        copy: isCopy,
      });
    },
  };
};

/*******************
 * Tree components *
 *******************/
export const ConnectedDndNode = connect(
  (state: RootState, ownProps: { treeId: string; id: string }) => {
    const { treeId, id } = ownProps;
    const treeState = state.tree?.treeInfo?.[treeId];
    if (
      !treeState ||
      !treeState?.nodeById?.[id] ||
      isFetchError(treeState.nodeById[id])
    ) {
      return {
        error: true,
      };
    }

    const { loginStatus: loginInfo } = state.security;
    const isSuperUser = isLoggedInUser(loginInfo)
      ? loginInfo.superUser
      : undefined;
    return getDndNodeProps(
      treeState,
      treeState.nodeById,
      state?.subject?.subjects || {},
      id,
      isSuperUser
    );
  },

  nodeDropConnector
)(ContextNode);

export const ConnectedSimpleNode = connect(
  (state: RootState, ownProps: { treeId: string; id: string }) => {
    const { treeId, id } = ownProps;
    const treeState = state.tree?.treeInfo?.[treeId];
    if (
      !treeState ||
      !treeState?.nodeById?.[id] ||
      isFetchError(treeState.nodeById[id])
    ) {
      return {
        error: true,
      };
    }

    return getDecoratedNodeProps(
      treeState,
      treeState.nodeById,
      state?.subject?.subjects || {},
      id
    );
  },
  nodeSimpleActionsConnector
)(Node);

export const ConnectedListTree = connect(
  (state: RootState, ownProps: { treeId: string; initRootRdfId?: string }) => {
    const { treeId, initRootRdfId } = ownProps;
    const treeState = state.tree.treeInfo?.[treeId];
    let roots = treeState?.rootNodesIds;
    if (roots?.length && initRootRdfId) {
      roots = [];
      const rootNodeId = treeState?.nodeIdByRdfId?.[initRootRdfId];
      if (rootNodeId) {
        roots.push(rootNodeId);
      }
    }

    return {
      menuId: treeId,
      treeId: treeId,
      roots: roots,
    };
  },
  (
    dispatch: Dispatch,
    ownProps: { treeId: string; modelParameters?: ModelParameters }
  ) => {
    const { treeId } = ownProps;
    return {
      loadRoots: () => {
        dispatch.tree.fetchTreeNodes({
          path: treeId,
          modelParameters: ownProps.modelParameters,
        });
      },
    };
  }
)(ListTree);

/***********************
 * Composers functions *
 ***********************/
export function getModelPath(path: string) {
  const idx = path.indexOf("?");
  if (idx >= 0) {
    return path.substring(0, idx);
  }
  return path;
}

export function composeTreeFetchUrl(path: string, parentNodeId?: string) {
  const link = `${c.TREE_FETCH_URL}${getModelPath(path)}`;
  let search = "";
  if (parentNodeId) {
    search += `?id=${parentNodeId}`;
  }
  return `${link}${search}`;
}

export function composePathParams(path: string, rdfId: string) {
  const idx = path.indexOf("?");
  if (idx >= 0) {
    //we have already model parameters (may be &m=sha1?)
    return `${path}&object=${rdfId}`;
  } else {
    return `${path}?object=${rdfId}`;
  }
}

export function composePathToNodeUrl(path: string, rdfId: string) {
  return `${c.TREE_PATH_URL}${composePathParams(path, rdfId)}`;
  // const idx = path.indexOf("?");
  // if (idx >= 0) {
  //   //we have already model parameters (may be &m=sha1?)
  //   return `${c.TREE_PATH_URL}${path}&object=${rdfId}`;
  // } else {
  //   return `${c.TREE_PATH_URL}${path}?object=${rdfId}`;
  // }
}
export function composeDecendantsRefsUrl(path: string, rdfId: string) {
  const link = `${c.TREE_DECENDANTS_REFS_URL}${getModelPath(
    path
  )}?node=${rdfId}`;
  return link;
}

export async function fetchTreeHeaderImpl(path: string): Promise<TreeHeader> {
  const response = await fetch(`${c.TREE_FETCH_HEADER_URL}${path}`);
  if (!response.ok) {
    const { status, statusText } = response;
    const resp = await response.text();
    throw new ServerError(status, resp);
  }
  return await response.json();
}

const parseParentNodes = (parents?: ParentNode[]) => {
  if (!parents?.length) {
    return [{}];
  }
  return parents.map((node) => ({ t: node.typeId, o: node.object }));
};
// const getParentObject = (id: string): ParentNode | null => {
//   if (id.indexOf(".") === -1) {
//     const typeId = +id;
//     const parentObj: ParentNode = {
//       object: id,
//     };
//     if (!isNaN(typeId)) {
//       parentObj.typeId = typeId;
//     }
//     return parentObj;
//   }

//   const typeId = +id.split(".")[0];
//   return {
//     object: id.split(".")[1],
//     typeId: isNaN(typeId) ? undefined : typeId,
//   };
// };
const getParentObject = (id: string): ParentNode | null => {
  if (id.indexOf(".") === -1) {
    const typeId = +id;
    const parentObj: ParentNode = {
      // object: id,
    };
    if (!isNaN(typeId)) {
      parentObj.typeId = typeId;
      return parentObj;
    }
    return null;
  }

  const typeId = +id.split(".")[0];
  return {
    object: id.split(".")[1],
    typeId: isNaN(typeId) ? undefined : typeId,
  };
};
export const getParentNodes = (parentIds: string[]): ParentNode[] | null => {
  const parents = [];
  if (!parentIds?.length) {
    return null;
  }
  for (let id of parentIds) {
    const obj = getParentObject(id);
    if (obj) {
      parents.push(obj);
    }
  }
  return parents;
};
export async function fetchTreeNodesImpl(data: {
  path: string;
  parentId?: string;
  parents?: ParentNode[];
  modelParameters?: ModelParameters;
}): Promise<TreeResponseData | TreeResponseData[]> {
  const { path, parentId, modelParameters, parents } = data;
  let response;

  if (modelParameters) {
    const parentsList = parseParentNodes(parents);
    response = await fetch(c.TREE_FETCH_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...modelParameters, path, parents: parentsList }),
    });
    if (!response.ok) {
      const { status, statusText } = response;
      const resp = await response.text();
      throw new ServerError(status, resp);
    }
    const result = await response.json();

    return result?.branches;
  } else {
    response = await fetch(composeTreeFetchUrl(path, parentId));
    if (!response.ok) {
      const { status, statusText } = response;
      const resp = await response.text();
      throw new ServerError(status, resp);
    }

    return await response.json();
  }
}

/*******************
 * Parse functions *
 *******************/

const DEFAULT_NODE_TYPE: TreeNodeType = {
  id: 0,
  type: "undefined",
  leaf: false
}

export function parseTreeNode(
  typeById: { [k: number]: TreeNodeType },
  node: ServerTreeNode
): TreeNode {
  const type = typeById[node.t] || { ...DEFAULT_NODE_TYPE, id: node.t }
  let nodeId =
    typeof node.m == "string" ? node.m + "." + node.t : node.t.toString();
  let nodeDescription = !node.d || node.d === "null" ? "" : node.d;
  let nodeData: TreeNodeData | null = null;
  if (node.o) {
    nodeId += `.${node.o}`;
    const objectDelimiterIdx = node.o.indexOf(":");
    if (objectDelimiterIdx !== -1) {
      nodeData = {
        $namespace: node.o.substring(0, objectDelimiterIdx),
        $rdfId: node.o.substring(objectDelimiterIdx + 1),
        $label: node.l,
        $description: nodeDescription,
      };
    } else {
      nodeData = {
        $rdfId: node.o,
        $label: node.l,
        $description: nodeDescription,
      };
    }
    if (node.f) {
      nodeData.$fragment = { $rdfId: node.f };
    }
  }
  return {
    id: nodeId,
    nodeId: nodeId,
    parentId: null,
    typeId: type.type,
    label: node.l,
    description: nodeDescription,
    leaf: type.leaf,
    visible: true, //TODO
    deleteLock: Boolean(type.deleteLock),
    moveLock: Boolean(type.moveLock),
    copyLock: Boolean(type.copyLock),
    data: nodeData,
    cache: node.cd || null,
    name: node.l,
    loading: false,
    icon: node.icon || type.icon,
    iconColor: node.iconColor || type.iconColor,
    addActions: getAddActions(type),
    genericActions: getGenericActions(type),
    syntetic: node.s,
    pasteTypes: type.pasteTypes,
  };
}

function getAddActions(type: TreeNodeType) {
  if (!type.actions) {
    return undefined;
  }
  return type.actions.filter((action) =>
    isTreeNodeAddAction(action)
  ) as TreeNodeAddAction[];
}

function getGenericActions(type: TreeNodeType) {
  if (!type.actions) {
    return undefined;
  }
  return type.actions.filter((action) =>
    isTreeNodeGenericAction(action)
  ) as TreeNodeGenericAction[];
}

export function parseTreeNodes(
  header: TreeHeader,
  treeData: TreeResponseData
): TreeNode[] {
  const typeById: { [k: number]: TreeNodeType } = {};
  for (let typeName in header.typeMap) {
    const type = header.typeMap[typeName];
    typeById[type.id] = type;
  }
  return treeData.nodes.map((node: any) => parseTreeNode(typeById, node));
}

export function getFetchIdByNodeId(
  header: TreeHeader,
  id?: string
): string | undefined {
  if (typeof id != "string") {
    return header.model;
  } else {
    return id;
  }
}
export function getNodeByRdfId(rdfId: string, nodes: TreeNode[]) {
  for (const node of nodes) {
    if (isTreeNode(node) && node.data && node.data.$rdfId === rdfId) {
      return node;
    }
  }
  return null;
}
/*******************
 * Fetch functions *
 *******************/

export async function fetchPathToNodeImpl(
  path: string,
  rdfId: string,
  modelParameters?: ModelParameters
): Promise<string[]> {
  let response;
  if (modelParameters) {
    response = await fetch(c.TREE_PATH_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...modelParameters,
        path,
        parents: [
          {
            o: rdfId,
          },
        ],
      }),
    });
  } else {
    response = await fetch(composePathToNodeUrl(path, rdfId));
  }

  if (!response.ok) {
    const { status, statusText } = response;
    const resp = await response.text();
    throw new ServerError(status, resp);
  }
  return await response.json();
}
export async function fetchDecendantsRefsImpl(
  path: string,
  nodeId: string
): Promise<any> {
  const response = await fetch(composeDecendantsRefsUrl(path, nodeId));
  if (!response.ok) {
    const { status, statusText } = response;
    const resp = await response.text();
    throw new ServerError(status, resp);
  }
  return await response.json();
}
export async function fetchAddChildImpl(
  treeId: string,
  nodeId: string,
  addActionId: string,
  params: any
): Promise<SubjectData> {
  const response = await fetch(
    composeAddUrl(treeId, nodeId, addActionId, params)
  );
  if (!response.ok) {
    const { status, statusText } = response;
    const resp = await response.text();
    throw new ServerError(status, resp);
  }
  const data: SubjectData = await response.json();
  return data;
}
export async function fetchPasteChildImpl(
  treeId: string,
  nodeId: string,
  sourceId: string,
  copy: boolean,
  lockIgnore: boolean
): Promise<{
  parentSubject: SubjectData;
  subject: SubjectData;
  targetSubject: SubjectData;
}> {
  const response = await fetch(
    composePasteUrl(treeId, nodeId, sourceId, copy, lockIgnore),
    { method: "POST" }
  );
  if (!response.ok) {
    const { status, statusText } = response;
    const resp = await response.text();
    throw new ServerError(status, resp);
  }
  const data: {
    parentSubject: SubjectData;
    subject: SubjectData;
    targetSubject: SubjectData;
  } = await response.json();
  return data;
}
export async function fetchDeleteNodeImpl(
  treeId: string,
  nodeId: string,
  isSelectedNode: boolean,
  deleteLockIgnore: boolean
): Promise<number> {
  const response = await fetch(
    composeFetchUrl(treeId, nodeId, { force: deleteLockIgnore }),
    { method: "DELETE" }
  );
  if (!response.ok) {
    const { status, statusText } = response;
    const resp = await response.text();
    throw new ServerError(status, resp);
  }
  const data = await response.json();
  const numberOfDeleted = data.numberOfDeleted;
  return numberOfDeleted;
}

export function updateTreeState(
  state: TreeReducerState,
  nodes: TreeNode[] | FetchError,
  path: string,
  parentId?: string
) {
  const nextTreeObj = { ...state.treeInfo };
  const nextTree = { ...(nextTreeObj[path] || initialTreeState) };

  if (!nextTree.loading) {
    nextTree.loading = {};
  }
  nextTree.loading[parentId || path] = false;
  if (nextTree.updateNodes) {
    if (!parentId) {
      nextTree.updateNodes = nextTree.updateNodes.filter(
        (nodeId) => nodeId !== null
      );
    } else {
      nextTree.updateNodes = nextTree.updateNodes.filter(
        (nodeId) => nodeId !== parentId
      );
    }
    if (nextTree.updateNodes.length === 0) {
      delete nextTree.updateNodes;
    }
  }
  if (!isTreeNodes(nodes)) {
    if (!parentId) {
      nextTree.error = nodes;
      return { ...state, treeInfo: nextTreeObj };
    }
    nextTree.nodeById = { ...nextTree.nodeById };
    nextTree.nodeById[parentId] = {
      ...nextTree.nodeById[parentId],
      error: nodes,
    };
    if (nextTree.toggled && nextTree.toggled[parentId]) {
      nextTree.toggled = { ...nextTree.toggled };
      nextTree.toggled[parentId] = false;
    }
    return { ...state, treeInfo: nextTreeObj };
  }
  if (!parentId) {
    delete nextTree.error;
  } else if (
    nextTree.nodeById &&
    nextTree.nodeById[parentId] &&
    nextTree.nodeById[parentId].error
  ) {
    nextTree.nodeById = { ...nextTree.nodeById };
    nextTree.nodeById[parentId] = { ...nextTree.nodeById[parentId] };
    delete nextTree.nodeById[parentId].error;

    /**Prevent recursion */
    const parentIdsMap = { [parentId]: true };
    let parent = nextTree.nodeById[parentId];
    while (parent.parentId) {
      parentIdsMap[parent.parentId] = true;
      parent = nextTree.nodeById[parent.parentId];
    }

    let allowedNodes: TreeNode[] = [];
    for (let node of nodes) {
      if (!parentIdsMap[node.id]) {
        allowedNodes.push(node);
      }
    }
    nodes = allowedNodes;
  }

  const ids = nodes.map((n) => n.id);
  if (!parentId) {
    nextTree.rootNodesIds = ids;
  } else {
    nextTree.children = { ...nextTree.children, [parentId]: ids };
  }

  let byRdfId: { [RDF_ID: string]: string } = {};
  let byId: { [ID: string]: TreeNode } = {};

  nodes.forEach((node) => {
    node.parentId = parentId || null;
    if (node.data && !node.syntetic) {
      byRdfId[node.data.$rdfId] = node.id;
    }
    byId[node.id] = node;
  });
  nextTree.nodeIdByRdfId = { ...nextTree.nodeIdByRdfId, ...byRdfId };
  nextTree.nodeById = { ...nextTree.nodeById, ...byId };
  nextTreeObj[path] = nextTree;
  return { ...state, treeInfo: nextTreeObj };
}

// /*******************
//  * Utils funcitons *
//  *******************/

/**Parse user script to get binding functions */
export function parseTreeAutomation(script?: string): TreeAutomationBindings {
  const parsedAutomation: TreeAutomationBindings = Object.assign(
    {},
    initialTreeState.automation
  );
  if (!script) {
    return parsedAutomation;
  }
  const bindings: TreeAutomationBindingFunctions =
    generateTreeBindings(parsedAutomation);
  scriptCompiler(script, bindings);
  return parsedAutomation;
}

// /*********************
//  * Reducer funcitons *
//  *********************/

export function recursiveExpand(node: TreeNode | FetchError, tree: TreeState) {
  if (!isTreeNode(node)) {
    return;
  }
  if (!tree.toggled) {
    tree.toggled = {};
  }
  tree.toggled[node.id] = true;
  node.parentId &&
    tree?.nodeById?.[node.parentId] &&
    recursiveExpand(tree?.nodeById?.[node.parentId] as TreeNode, tree);
}
export function expandTreeWithEditingNodes(
  state: TreeReducerState,
  treeId: string,
  editingNodes: string[]
): TreeReducerState {
  const treeState = { ...(state.treeInfo[treeId] || initialTreeState), treeId };

  for (const n of editingNodes) {
    const node = treeState?.nodeById?.[n];
    node && recursiveExpand(node, treeState);
  }
  const treeInfo = { ...state.treeInfo, [treeId]: treeState };
  return { ...state, treeInfo };
}

export function isFetchTreeNodesNeeded(
  state: TreeReducerState,
  path: string,
  nodeId?: string
) {
  if (!nodeId) {
    return true;
  }
  const node = state.treeInfo[path]?.nodeById?.[nodeId];
  if (!isTreeNode(node)) {
    return false;
  }
  return !node.leaf;
}

export const getTreeId = (tree: string) => {
  let treeId = tree || "";
  if (treeId && treeId[0] !== "/") {
    treeId = `/${treeId}`;
  }
  return treeId;
};

export const parseTreeModelPath = (tree: string) => {
  let model;
  const treeId = getTreeId(tree);

  if (treeId.indexOf("?") !== -1) {
    const modelParts = tree.split("?");
    const modelPart = modelParts?.[1] || "";

    if (modelPart.indexOf("=") !== -1) {
      model = modelPart.split("=")[1] || null;
    }
  }

  return { path: treeId, model };
};

export const cutOffModel = (tree: string) => {
  return tree?.split("?")?.[0] || "";
};

export const getParentId = (data: TreeResponseData) => {
  const t = data?.parent?.t?.toString();
  const o = data?.parent?.o;
  let parentId;
  let parts = [];

  if (typeof t !== "undefined") {
    parts.push(t);
  }
  if (typeof o !== "undefined") {
    parts.push(o);
  }
  if (parts.length) {
    parentId = parts.join(".");
  }
  return parentId;
};
