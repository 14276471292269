import * as React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import shortid from "shortid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

import {
  Column,
  ColumnTemplateMap,
  FilterData,
  FilterInfo,
  GantDateLimits,
  GantViewType,
  ModelParameters,
  OnTableSelect,
  RowData,
  SelectType,
  SortInfo,
  TableDragOptions,
  TableDropOptions,
  TableFilterChanges,
  TableGantData,
  TableGantOptions,
  TableParameter,
  TableRequest,
  ToolbarItem,
  ToolbarReport,
} from "../../types/table";
import { FetchError } from "../../types/error";
import {
  CancelCallback,
  CloseCallback,
  I18NString,
  ModalOptions,
  OkCallback,
} from "../../types/modal";
import { AlertLevelType } from "../../types/alert";
import { LoggedInUser } from "../../types/security";
import { FinderOptions } from "../../types/finder";

import { getSelectedRowsData } from "../../services/table";

import styles from "./Table.module.css";
import { Dispatch, RootState } from "../../store";
import { FC } from "react";
import TableFinderContainer from "../table/TableFinderContainer";
import TableContainer from "./TableContainer";

export const LoadingAlert = () => (
  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
    <div className="alert alert-info align-self-center" role="alert">
      <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
      <FormattedMessage
        id="NPT_TABLE_LOADING"
        defaultMessage="Loading..."
        description="Table loading alert"
      />
    </div>
  </div>
);

export const LoadingError = () => (
  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
    <div className="alert alert-danger align-self-center" role="alert">
      <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
      <FormattedMessage
        id="NPT_TABLE_ERROR"
        defaultMessage="Error occured while downloading data"
        description="Info text on table data downloading error"
      />
    </div>
  </div>
);

/**Properties of Table (without actions for correct extending on api) */
export interface PlainTableProps {
  modelParameters?: ModelParameters;
  /**Table ID. Using it to identify redux store data location.*/
  tableId: string;
  /**Height of table (100% by default) */
  height?: number;
  /**Scrollable flag of table (true by default) */
  scrollable?: boolean;
  /**Display indicator that table is loading*/
  loading: boolean;
  /**Display indicator that table data is loading*/
  loadingData: boolean;
  /**Stylesheet map*/
  stylesheets: { [k: string]: string };
  /**Info about table parameters*/
  parameters: { [k: string]: TableParameter };
  /**Values of table parameters*/
  fields: { [k: string]: string };
  /**Forced fields values that will be traversed to table */
  forcedFields?: { [k: string]: string };
  /**Report items of table*/
  reports: ToolbarReport[];
  /**Error data that occured on table loading*/
  error: null | FetchError;
  /**Error data that occured on table data loading*/
  errorData: null | FetchError;
  /**Flag that show if this table have pagination */
  pageable: boolean;
  /**Total number of rows */
  totalRowsLength: number;
  /**Toolbar visibility flag */
  toolbarHidden?: boolean;
  /**Toolbar items visibility flag */
  toolbarHiddenItems?: { [k: string]: boolean };
  /**Selected page of table */
  page?: number;
  /**Size of single table page */
  pageSize?: number;
  /**Possible values of table page size */
  pageSelection?: number[];
  toolbar?: FC | ToolbarItem[];
  body?: FC;
  column?: ColumnTemplateMap;
  footer?: FC;
  /**Array of table columns*/
  columns: Column[];
  /**List of selected columns for filter */
  dynamicColumns: string[] | null;
  /**Map of dynamically hidden columns for filter */
  dynamicHiddenColumns: { [k: string]: boolean };
  /**Array of table rows ids*/
  pageRows: number[];
  /**Map of row data by it idx */
  rowByIdx: { [rowIdx: number]: RowData };
  /**Select type of table (null if table rows cannot be selected) */
  selectType: SelectType | null;
  /**Map of row selected flag by key */
  selectedRows: { [k: string]: boolean };
  /**Counter of selected rows */
  selectedRowsLength: number;
  /**Info about table sorting */
  sortInfo: SortInfo;
  /**Info about table filtering */
  filterInfo: FilterInfo;
  /**Changings in filter */
  filterChanges: TableFilterChanges | null;
  /**Options of table finder (if exists) */
  finderOptions: FinderOptions | null;
  /**Options of gant diagram (if exists) */
  gantOptions: TableGantOptions | null;
  /**Data of gant diagram (if exists) */
  gantData: TableGantData;
  /**Table drag options */
  dragOptions: TableDragOptions | null;
  /**Table drop options */
  dropOptions: TableDropOptions | null;
}
/**Full properties of table */
export interface GantProps extends PlainTableProps {
  forceLoadData?: boolean;
  autoresize?: boolean;
  /**Context path of application (needed for link cells)*/
  contextPath: string;
  /**Values of fields in selection store (allows table to react for url changings)*/
  selectionFields: { [k: string]: string };
  /** List of user rights */
  userAcl: string[];
  /**Action to trigger report downloading*/
  downloadReport: (report: ToolbarReport) => void;
  /**Action to select row */
  selectRow: (key: string) => void;
  /**Action to select all rows on page */
  selectAll: () => void;
  /**Action to call automation for clicked button */
  buttonClick: (id: string) => void;
  /**Action to update table data with new fields values */
  updateTableFields: (
    fields: { [k: string]: string },
    forceLoadData?: boolean
  ) => void;
  /**Action to change table page size */
  changePageSize: (pageSize: number) => void;
  /**Action to change table selected page */
  changePage: (page: number) => void;
  /**Action to sort table by column*/
  sortByColumn: (field: string) => void;
  /**Action to filter table by column*/
  filterByColumn: (field: string, filterData: FilterData | null) => void;
  /**Fetch available selection values for specified field and filter*/
  fetchColumnSelection: (field: string, filterData: FilterData | null) => void;
  /**Fetch available values range for specified field and filter*/
  fetchColumnRange: (field: string, filterData: FilterData | null) => void;
  /**Open sorting and filtering options modal*/
  openTableSortOptions: () => void;
  /**Open dynamic columns options modal*/
  openTableDynamicColumns: () => void;
  /**Action to field value */
  changeField: (parameter: string, value: string) => void;
  /**Action to change view type of gant diagram */
  changeViewType: (type: GantViewType) => void;
  /**Action to change id of adding gant element group */
  changeGroup: (group: string) => void;
  /**Handler of gant row element changings */
  changeGantElement: (
    rowIndex: number,
    itemIndex: number,
    newDate: GantDateLimits,
    isPlanned: boolean
  ) => void;
  /**Callback function to be called after row select or any other changes of selection */
  onSelectionChange?: (
    selectedRows: { [k: string]: boolean },
    rowsData: any[]
  ) => void;
  /**Action to confirm table filter changings */
  confirmFilterChanges: () => void;
  /**Action to deny table filter changings */
  denyFilterChanges: () => void;
  /**Action to save table changings */
  saveTable: () => void;
  /**Function that will be called to dynamically change request filter */
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>;

  /**DnD actions */
  resolveDrop: (
    collectedData: any,
    dropInfo: {
      cell?: any;
      row?: RowData;
      rowIdx?: number;
      column?: string;
      columnIdx?: number;
    },
    resolveDropFunctionId: string
  ) => void;
  onSelect?: OnTableSelect;
}

interface GantState {
  dynamicColumns: Column[] | null;
  width: number;
}

/********************************
 *     Main Table Component     *
 ********************************/
class Gant extends React.Component<GantProps, GantState> {
  private tableRef: HTMLDivElement | null = null;
  private resizeObserver: ResizeObserver | null = null;
  constructor(props: GantProps) {
    super(props);

    this.state = {
      dynamicColumns: this.getDynamicColumns(),
      width: 0,
    };
  }
  onPageSizeChanged = (
    entries: ResizeObserverEntry[],
    observer: ResizeObserver
  ) => {
    if (!this.tableRef) {
      return;
    }
    const width = this.tableRef.getBoundingClientRect().width;

    this.setState({ width });
  };
  componentDidMount() {
    if (this.props.selectedRows) {
      const rows = getSelectedRowsData(
        this.props.rowByIdx,
        this.props.selectedRows
      );
      rows.length && this.onSelectRowsFire(rows);

      this.props.onSelectionChange?.(this.props.selectedRows, rows);
    }
    if (this.tableRef) {
      const width = this.tableRef.getBoundingClientRect().width;
      this.setState({ width });
      if (!this.props.autoresize) {
        return;
      }
      this.resizeObserver = new ResizeObserver(this.onPageSizeChanged);
      this.resizeObserver.observe(this.tableRef);
    }
  }
  componentWillUnmount(): void {
    if (!this.tableRef) {
      return;
    }
    if (!this.resizeObserver) {
      return;
    }
    this.resizeObserver.disconnect();
    this.resizeObserver = null;
  }
  componentDidUpdate(
    prevProps: Readonly<GantProps>,
    prevState: Readonly<GantState>,
    snapshot?: any
  ): void {
    const { width: prevWidth } = this.state;
    if (this.tableRef) {
      const width = this.tableRef.getBoundingClientRect().width;
      if (Math.abs(width - prevWidth) < 100) {
        return;
      }
      this.setState({ width });
    }
  }
  componentWillReceiveProps(nextProps: GantProps) {
    if (
      this.props.selectedRows !== nextProps.selectedRows ||
      this.props.rowByIdx !== nextProps.rowByIdx
    ) {
      const rows = getSelectedRowsData(
        nextProps.rowByIdx,
        nextProps.selectedRows
      );
      this.onSelectRowsFire(rows);
      this.props.onSelectionChange?.(nextProps.selectedRows, rows);
    }
    if (
      !nextProps.loadingData &&
      this.props.selectionFields !== nextProps.selectionFields &&
      this.props.selectionFields !== initialSelectionFields &&
      JSON.stringify(this.props.selectionFields) !==
        JSON.stringify(nextProps.selectionFields)
    ) {
      this.props.updateTableFields(
        nextProps.selectionFields,
        this.props.forceLoadData
      );
    }

    if (
      this.props.columns !== nextProps.columns ||
      this.props.dynamicColumns !== nextProps.dynamicColumns
    ) {
      this.setState({ dynamicColumns: this.getDynamicColumns(nextProps) });
    }
  }
  onSelectRowsFire = (rows: RowData[]) => {
    if (!this.props.onSelect) {
      return;
    }

    this.props.onSelect({
      target: this,
      rows,
    });
  };
  getDynamicColumns(props = this.props): Column[] | null {
    const dynamicColumns = props.dynamicColumns;
    if (!dynamicColumns) {
      return null;
    }
    const filteredColumns = props.columns.filter(
      (column) => dynamicColumns.indexOf(column.field) !== -1
    );
    const sortedColumns = filteredColumns.sort(
      (columnA, columnB) =>
        dynamicColumns.indexOf(columnA.field) -
        dynamicColumns.indexOf(columnB.field)
    );
    /**Display selected hidden columns */
    return sortedColumns.map((column) => {
      if (!column.hidden || !column.dynamic) {
        return column;
      }
      return Object.assign({}, column, { hidden: false });
    });
  }

  render() {
    if (this.props.loading) {
      return <LoadingAlert />;
    }
    if (this.props.error) {
      return <LoadingError />;
    }
    const { finderOptions, toolbar } = this.props;
    const { width } = this.state;
    const tableToolbar = finderOptions ? undefined : toolbar;
    const finderToolbar = finderOptions ? toolbar : undefined;
    if (this.props.scrollable === false) {
      return (
        <div
          ref={(ref) => (this.tableRef = ref)}
          className={styles.nptTable + " d-flex flex-column mb-auto h-auto"}
        >
          <TableFinderContainer
            {...this.props}
            toolbar={finderToolbar}
            selectedDynamicColumns={this.state.dynamicColumns}
          >
            <TableContainer
              {...this.props}
              toolbar={tableToolbar}
              width={width}
              height={-1}
              selectedDynamicColumns={this.state.dynamicColumns}
            />
          </TableFinderContainer>
        </div>
      );
    }
    if (typeof this.props.height === "number") {
      return (
        <div
          ref={(ref) => (this.tableRef = ref)}
          className={styles.nptTable + " d-flex flex-column mb-auto"}
        >
          <TableFinderContainer
            {...this.props}
            toolbar={finderToolbar}
            selectedDynamicColumns={this.state.dynamicColumns}
          >
            <TableContainer
              {...this.props}
              toolbar={tableToolbar}
              width={width}
              height={this.props.height as number}
              selectedDynamicColumns={this.state.dynamicColumns}
            />
          </TableFinderContainer>
        </div>
      );
    }
    return (
      <div
        ref={(ref) => (this.tableRef = ref)}
        className={styles.nptTable + " d-flex flex-column mb-auto"}
      >
        <TableFinderContainer
          {...this.props}
          toolbar={finderToolbar}
          selectedDynamicColumns={this.state.dynamicColumns}
        >
          <TableContainer
            {...this.props}
            width={width}
            height={100}
            toolbar={tableToolbar}
            selectedDynamicColumns={this.state.dynamicColumns}
          />
        </TableFinderContainer>
      </div>
    );
  }
}

/**Using variable out of connect function to be able to watch changes by variable ref */
const initialSelectionFields = {};
let selectionFields: { [k: string]: string } = initialSelectionFields;

/** Connect table to actions */
export default connect(
  (state: RootState, ownProps: PlainTableProps) => {
    const newSelectionFields: { [k: string]: string } = {};
    let selectionChanged = false;
    for (let p in ownProps.parameters) {
      if (p === "object" || p === "type") {
        if (!state.selection.info || !state.selection.info[p]) {
          if (selectionFields[p]) {
            selectionChanged = true;
          }
          continue;
        }
        newSelectionFields[p] = state.selection.info[p] as string;
        if (selectionFields[p] !== newSelectionFields[p]) {
          selectionChanged = true;
        }
        continue;
      }
      if (!state.location.params[p]) {
        if (selectionFields[p]) {
          selectionChanged = true;
        }
        continue;
      }
      newSelectionFields[p] = state.location.params[p];
      if (selectionFields[p] !== newSelectionFields[p]) {
        selectionChanged = true;
      }
    }
    /**Update stored selection field only on changes to prevent table re-render */
    if (selectionChanged) {
      selectionFields = newSelectionFields;
    }

    return {
      selectionFields: selectionFields,
      contextPath: state.location.contextPath,
      userAcl:
        (state.security.loginStatus as LoggedInUser)?.generalAccessRules || [],
      ...ownProps,
    };
  },
  (
    dispatch: Dispatch,
    ownProps: {
      tableId: string;
      onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>;
    }
  ) => {
    const { tableId } = ownProps;
    return {
      downloadReport: (report: ToolbarReport) => {
        dispatch.table.downloadReport({
          tableId,
          report,
          onFetch: ownProps.onFetch,
        });
      },
      selectRow: (key: string) => {
        dispatch.table.sendSelectRow({ tableId, key });
      },
      selectAll: () => {
        dispatch.table.sendSelectAll(tableId);
      },
      buttonClick: (id: string) => {
        dispatch.table.buttonClick({
          tableId,
          buttonId: id,
          onFetch: ownProps.onFetch,
        });
      },
      updateTableFields: (fields: { [k: string]: string }) => {
        dispatch.table.fetchTableData({
          tableId,
          options: {
            fields,
            finder: null,
            reset: true,
            onFetch: ownProps.onFetch,
          },
        });
      },
      changePageSize: (pageSize: number) => {
        dispatch.table.changePageSize({
          tableId,
          pageSize,
          onFetch: ownProps.onFetch,
        });
      },
      changePage: (page: number) => {
        dispatch.table.changePage({ tableId, page, onFetch: ownProps.onFetch });
      },
      sortByColumn: (field: string) => {
        dispatch.table.sortByColumn({ tableId, key: field });
      },
      filterByColumn: (field: string, filterData: FilterData | null) => {
        dispatch.table.filterByColumn({ tableId, field, filterData });
      },
      openTableSortOptions: () => {
        dispatch.table.openTableSortOptions(tableId);
      },
      openTableDynamicColumns: () => {
        dispatch.table.openTableDynamicColumns(tableId);
      },
      changeField: (parameter: string, value: string) => {
        dispatch.table.sendField({ tableId, parameter, value });
      },
      changeViewType: (type: GantViewType) => {
        dispatch.table.sendGantViewType({ tableId, type });
      },
      changeGroup: (group: string) => {
        dispatch.table.sendGantSelectedGroup({ tableId, group });
      },
      changeGantElement: (
        rowIndex: number,
        itemIndex: number,
        newDate: GantDateLimits,
        isPlanned: boolean
      ) => {
        dispatch.table.changeGantElement({
          tableId,
          rowIndex,
          itemIndex,
          newDate,
          isPlanned,
        });
      },
      confirmFilterChanges: () => {
        dispatch.table.confirmFilterChanges({
          tableId,
          onFetch: ownProps.onFetch,
        });
      },
      denyFilterChanges: () => {
        dispatch.table.cancelFilterChanges(tableId);
      },
      saveTable: () => {
        dispatch.table.saveTable({ tableId, onFetch: ownProps.onFetch });
      },
      openModal: (
        type: string,
        options: ModalOptions,
        okCallback?: OkCallback,
        cancelCallback?: CancelCallback,
        closeCallback?: CloseCallback
      ) => {
        dispatch.modal.openModal({
          id: shortid.generate(),
          type,
          options,
          okCallback,
          cancelCallback,
          closeCallback,
        });
      },
      addAlert: (type: AlertLevelType, message: string | I18NString) => {
        dispatch.alert.addAlert({ type, message });
      },
      resolveDrop: (
        collectedData: any,
        dropInfo: {
          cell?: any;
          row?: RowData;
          rowIdx?: number;
          column?: string;
          columnIdx?: number;
        },
        resolveDropFunctionId: string
      ) => {
        dispatch.table.resolveDrop({
          tableId,
          collectedData,
          dropInfo,
          resolveDropFunctionId,
          onFetch: ownProps.onFetch,
        });
      },
    };
  }
)(Gant);
