import {
  faBars,
  faSpinner,
  faKey,
  faCode,
  faLock,
  faChevronLeft,
  faChevronRight,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";
import * as Redux from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import { FormattedMessage } from "react-intl";
import { connect, ReactReduxContextValue } from "react-redux";
import { StaticContext, withRouter } from "react-router";
import { Link, RouteComponentProps } from "react-router-dom";

import {
  isLoggedInUser,
  MenuData,
  UIVariant,
  UIOptions,
} from "../../types/security";
import { ReactComponent as LeftArrow } from "./left-arrow.svg";
import { Logout, MenuProps } from "./Menu";
import { ReactComponent as RightArrow } from "./right-arrow.svg";
import styles from "./SideMenuLayout.module.css";
import { Nav } from "react-bootstrap";
import { ConnectedUserMenu, ToggleWithoutArrow, UserMenu } from "./UserMenu";
import { DropDownMenu } from "./DropDownMenu";
import { MenuType } from "../../types/menu";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { BulbComponent } from "../bulb/BulbComponent";
import { Dispatch, RootState } from "../../store";
import { RematchDispatch } from "@rematch/core";
import { RootModel } from "../../model";

const ConnectedLogout = connect(null, (dispatch: Dispatch) => {
  return {
    logout: () => {
      dispatch.security.manualLogout();
    },
  };
})(Logout);

const ConnectedDropDownMenu = connect((state: RootState) => {
  return {
    language: state.locale.language,
    menu: isLoggedInUser(state.security.loginStatus)
      ? state.security.loginStatus.mainMenu
      : undefined,
  };
})(DropDownMenu);

export interface SideItemRendererProperties
  extends RouteComponentProps<any, StaticContext, any> {
  to: string;
  icon: string;
  title: string;
  minimized: boolean;
}

export interface SideItemRendererState {
  active: boolean;
}

//Class to render links in menu
class SideItemRenderer extends React.Component<
  SideItemRendererProperties,
  SideItemRendererState
> {
  unlisten?: () => void;
  state = {
    active: false,
  };

  constructor(props: SideItemRendererProperties) {
    super(props);
  }
  startWith(path: string, to: string, match: string) {
    return to.startsWith(match) && path.startsWith(match);
  }
  pathIncluded(path: string, to: string) {
    return (
      this.startWith(path, to, "/developer/profileeditor") ||
      this.startWith(path, to, "/developer/scripteditor") ||
      this.startWith(path, to, "/developer/vieweditor") ||
      this.startWith(path, to, "/admin/process") ||
      this.startWith(path, to, "/admin/roles") ||
      this.startWith(path, to, "/admin/users") ||
      this.startWith(path, to, "/admin/fragment")
    );
  }

  locationMatcher(path: string, to: string) {
    let active = this.state.active;
    if (this.pathIncluded(path, to)) {
      active = true;
    } else if (path === to) {
      active = true;
    } else {
      active = false;
    }
    return active;
  }
  onLocationChange(path: string) {
    //Set state to force rendering
    this.setState({ active: this.locationMatcher(path, this.props.to) });
  }

  componentDidMount() {
    const history = this.props.history;
    const onLocationChange = this.onLocationChange.bind(this);
    this.unlisten = history.listen((location, action) => {
      onLocationChange(location.pathname);
    });
    /* Set first active value */
    onLocationChange(this.props.location.pathname);
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  render() {
    const { to: path, icon, title, minimized } = this.props;
    return (
      <Link
        to={path}
        className={
          styles.item + " " + (this.state.active ? styles.highlight : "")
        }
      >
        <i className={icon + " mr-1"} aria-hidden="true"></i>
        <span
          className={
            "ml-2 " +
            (minimized ? styles.minimizedItem : styles.openMinimizedItem)
          }
        >
          {title}
        </span>
      </Link>
    );
  }
}

const SideItem = withRouter(SideItemRenderer);

interface SideBarProps extends RouteComponentProps<any, StaticContext, any> {
  minimized: boolean;
  closed: boolean;
  animationCssClass: string;
  m: MenuData;
  activeMenu?: MenuType;
  onMinimize: (minimize: boolean) => void;
  onClose: (close: boolean) => void;
  renderItem: (id: string, menu: MenuData) => any;
  setActiveMenu: (menu: MenuType) => void;
}

class SideBarRenderer extends React.Component<SideBarProps> {
  unlisten?: () => void;
  setMenuActive = (path: string) => {
    const { activeMenu, setActiveMenu } = this.props;
    if (path.startsWith("/developer") && activeMenu !== "developerMenu") {
      setActiveMenu("developerMenu");
    } else if (path.startsWith("/admin") && activeMenu !== "adminMenu") {
      setActiveMenu("adminMenu");
    } else if (path.startsWith("/security") && activeMenu !== "securityMenu") {
      setActiveMenu("securityMenu");
    }
  };
  componentDidMount() {
    const history = this.props.history;

    this.unlisten = history.listen((location, action) => {
      this.setMenuActive(location.pathname);
    });
    this.setMenuActive(this.props.location.pathname);
  }
  renderHeaderInfo() {
    const { activeMenu, setActiveMenu } = this.props;
    let info: [string, IconProp | string] | null = null;
    if (activeMenu === "developerMenu") {
      info = ["DEVELOPER_TITLE", faCode];
    } else if (activeMenu === "adminMenu") {
      info = ["ADMIN_MENU_TITLE", "fa fa-key fa-lg"];
    } else if (activeMenu === "securityMenu") {
      info = ["SECURITY_MENU_TITLE", faLock];
    }

    if (!info) {
      return (
        <FormattedMessage
          id="SIDEMENU_MINIMIZE"
          defaultMessage="Minimize menu"
          description="User must click button to minimize side menu"
        />
      );
    }

    return (
      <>
        {typeof info[1] === "string" ? (
          <i className={info[1]} />
        ) : (
          <FontAwesomeIcon className="ml-1" icon={info[1]} />
        )}
        <span className="font-weight-bold">
          {" "}
          <FormattedMessage id={info[0]} />{" "}
        </span>
      </>
    );
  }
  getHeader() {
    const { closed, minimized, onClose, onMinimize, activeMenu } = this.props;
    if (!minimized) {
      return [
        <a
          href="#"
          key="closeBtn"
          className="close"
          onClick={() => onClose(true)}
        >
          <span aria-hidden="true">&times;</span>
        </a>,
        <a
          key="minimizeBtn"
          style={{ display: "inline-block", marginTop: "2px" }}
          href="#"
          onClick={() => onMinimize(true)}
        >
          {/* <LeftArrow className="svg-inline--fa" /> */}
          <FontAwesomeIcon icon={faChevronLeft} />
          {!closed && <span className="ml-2">{this.renderHeaderInfo()}</span>}
        </a>,
      ];
    }
    return (
      <a
        href="#"
        className="p-1"
        style={{ marginTop: "2px", marginBottom: "4px" }}
        onClick={() => onMinimize(false)}
      >
        {/* <RightArrow className="svg-inline--fa" /> */}
        <FontAwesomeIcon icon={faChevronRight} />
      </a>
    );
  }
  // padding-bottom: 22px;
  render() {
    const { minimized, animationCssClass, m, renderItem, closed, activeMenu } =
      this.props;
    const staticSidebarState = minimized
      ? styles.minimizedSidebar
      : styles.openedSidebar;
    return (
      <div
        className={`${
          closed && styles.sidebar
        } ${staticSidebarState}    ${animationCssClass}  bg-secondary   d-flex flex-column npt-sidebar npt-sidebar`}
      >
        <div className={`border-bottom border-primary  `}>
          <h6
            className={` ${styles.header} ${minimized && styles.headerClosed}`}
          >
            {this.getHeader()}
          </h6>
        </div>
        <div
          className={
            styles.sidebarBody + " overflow-auto d-flex flex-fill flex-column"
          }
        >
          {typeof m.roots != "undefined" &&
            m.roots.map((id) => (
              <React.Fragment key={id}>{renderItem(id, m)}</React.Fragment>
            ))}
        </div>
      </div>
    );
  }
}

const ConnectedSideBarRenderer = connect(
  (state: RootState) => {
    const status = isLoggedInUser(state.security.loginStatus)
      ? state.security.loginStatus
      : undefined;
    const options =
      status &&
      status.options &&
      status.options.info &&
      status.options.info.cluster;
    return {
      activeMenu: state.menu.active,
      uiOptions: options,
    };
  },
  (dispatch: RematchDispatch<RootModel>) => {
    return {
      setActiveMenu: (active: MenuType) => dispatch.menu.sendMenuActive(active),
    };
  }
)(SideBarRenderer);

const SideBar = withRouter(ConnectedSideBarRenderer);

interface SidebarAnimationCtxProps {
  animation: string;
  closed: boolean;
  animateClose: (close: boolean) => void;
}
const SidebarAnimationCtx = React.createContext<SidebarAnimationCtxProps>({
  animation: "",
  closed: false,
  animateClose: (close: boolean) => {},
});

export interface SideMenuLayoutProps extends MenuProps {
  title?: any;
  toolbar?: any;
  search?: any;
  userLabel: string;
  // userMenu?:any
  closed: boolean;
  minimized: boolean;
  // variant?: UIVariant
  uiOptions?: UIOptions;
  setupMinimize: (minimize: boolean) => void;
  setupClose: (close: boolean) => void;
  children: any;
}

export interface SideMenuLayoutState {
  // closed: boolean
  // minimized: boolean,
  animation: string;
}

/**
 * Inspired by https://bootstrapious.com/p/bootstrap-sidebar
 */
export default class SideMenuLayout extends React.Component<
  SideMenuLayoutProps,
  SideMenuLayoutState
> {
  constructor(props: SideMenuLayoutProps) {
    super(props);

    this.state = {
      animation: props.closed
        ? styles.close
        : props.minimized
        ? styles.minimize
        : "",
    };
  }
  componentDidMount() {
    if (this.props.minimized) {
      this.setState({ animation: "" });
    } else if (this.props.closed) {
      this.setState({ animation: styles.closeInstant });
    }
  }
  componentDidUpdate(prevProps: SideMenuLayoutProps) {
    const { closed } = this.props;
    if (closed && !prevProps.closed) {
      this.setState({
        animation: styles.close,
      });
    } else if (!closed && prevProps.closed) {
      this.setState({
        animation: styles.openClosed,
      });
    }
  }
  renderItem(id: string, menu: MenuData) {
    const { language, minimized, uiOptions } = this.props;

    const item = menu.itemById[id];
    const variant = uiOptions && uiOptions.variant;
    if (
      variant &&
      item.v &&
      variant.toLocaleLowerCase() !== item.v.toLocaleLowerCase()
    ) {
      return null;
    }

    const title = (item.t && item.t[language]) || item.n || id;
    let icon = item.i || "fa fa-bars fa-5x";
    const ref = item.r || "";
    const isClusterMode = uiOptions && uiOptions.info && uiOptions.info.cluster;
    if (ref.includes("cluster") && !isClusterMode) {
      return null;
    }
    if (icon.includes("fa-5x")) {
      icon = icon.replace("fa-5x", "");
    }

    return (
      <SideItem
        key={id}
        to={ref}
        icon={icon}
        title={title}
        minimized={minimized}
      />
    );
  }

  onClose(close: boolean) {
    const { setupClose } = this.props;
    setupClose(close);
    // this.setState(
    //   {
    //     animation: close ? styles.close : styles.openClosed,
    //   },
    //   () => {
    //     setupClose(close);
    //   }
    // );
  }

  onMinimize(minimize: boolean) {
    const { setupMinimize } = this.props;
    this.setState(
      {
        animation: minimize ? styles.minimize : styles.openMinimized,
      },
      () => {
        setupMinimize(minimize);
      }
    );
    //  evt.preventDefault();
  }

  render() {
    const { minimized, closed, dark, userLabel } = this.props;
    const m = this.props.menu;
    let sidebar = null;

    if (typeof m != "undefined") {
      sidebar = (
        <SideBar
          animationCssClass={this.state.animation}
          closed={closed}
          minimized={minimized}
          onMinimize={this.onMinimize.bind(this)}
          onClose={this.onClose.bind(this)}
          renderItem={this.renderItem.bind(this)}
          m={m}
        />
      );
    }
    return (
      <SidebarAnimationCtx.Provider
        value={{
          animation: this.state.animation,
          animateClose: (close: boolean) => this.onClose(close),
          closed: this.props.closed,
        }}
      >
        <div
          className={styles.wrapper + " d-flex flex-row  npt-side-menu-layout"}
        >
          {sidebar}
          <div className="d-flex flex-column flex-fill npt-content  overflow-hidden ">
            {this.props.children}
          </div>
        </div>
      </SidebarAnimationCtx.Provider>
    );
  }
}

interface CloseMenuButtonProps {
  closed?: boolean;
  close: () => void;
}

export const CloseMenuButton: React.FC<CloseMenuButtonProps> = ({
  closed,
  close,
}) => {
  if (!closed) {
    return null;
  }
  return (
    <Button
      variant="outline-secondary"
      className="mr-3 ml-1 px-2 npt-close-menu-btn"
      onClick={close}
    >
      <FontAwesomeIcon icon={faBars} />
    </Button>
  );
};

interface NavbarWrapperProps {
  title?: any;
  toolbar?: any;
  search?: any;
  userLabel: string;
  children: any;
  closed: boolean;
  isAvatar: boolean;
  style?: React.CSSProperties;
  className?: string;
  invokeAvatarHelp?: () => void;
}
export const NavbarWrapper = (props: NavbarWrapperProps) => {
  const {
    userLabel,
    children,
    search,
    title,
    toolbar,
    closed,
    style,
    isAvatar,
    invokeAvatarHelp,
    className,
  } = props;
  const avatarNavbar = isAvatar ? "avatar-navbar" : "";
  return (
    <SidebarAnimationCtx.Consumer>
      {({ animateClose }) => (
        <>
          <Navbar
            className={`${avatarNavbar} border-bottom  mb-0 ${className}`}
            bg={"primary"}
            expand="lg"
          >
            <CloseMenuButton
              closed={closed}
              close={() => animateClose(false)}
            />
            {/* {closed && (
              <Button
                variant="outline-secondary"
                className="mr-3 ml-1 px-2 npt-close-menu-btn"
                onClick={() => animateClose(false)}
              >
                <FontAwesomeIcon icon={faBars} />
              </Button>
            )} */}
            <Navbar.Brand className="px-2" href="#">
              {title}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="sidemenu-navbar" />
            <Navbar.Collapse id="sidemenu-navbar">
              <div className="mr-auto">{toolbar}</div>
              <Nav className="ml-auto mr-2">
                {isAvatar && (
                  <Nav.Item key="" className="  px-2">
                    <BulbComponent type="warning">
                      <span
                        onClick={invokeAvatarHelp}
                        className="w-100 h-100 d-flex justify-content-center pt-1"
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon
                          className="text-info"
                          icon={faExclamation}
                        />
                      </span>
                    </BulbComponent>
                  </Nav.Item>
                )}
                {search}
                <div className="mx-1 d-flex justify-content-center align-items-center text-white">
                  <span style={{ fontSize: ".8rem" }}>{userLabel}</span>
                </div>
                <ConnectedUserMenu as={ToggleWithoutArrow} />
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <div
            key="contents"
            className="d-flex card flex-fill overflow-auto"
            style={style}
          >
            <div className="card-body d-flex h-100">{children}</div>
          </div>
        </>
      )}
    </SidebarAnimationCtx.Consumer>
  );
};

export const closeMenuWithStore =
  () =>
  (type: "adminMenu" | "developerMenu" | "securityMenu" = "developerMenu") => {
    return class extends React.Component {
      render() {
        return (
          <SidebarAnimationCtx.Consumer>
            {({ animateClose, closed }) => {
              return (
                <CloseMenuButton
                  closed={closed}
                  {...this.props}
                  close={() => animateClose(false)}
                />
              );
            }}
          </SidebarAnimationCtx.Consumer>
        );
      }
    };
  };
