import React from "react";
import { connect } from "react-redux";
import { LayoutNode, Layout, isSubject } from "../../../types/subject";
import CardArea from "../CardArea";
import { UI_GRID } from "../../../constants/subject";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { RootState } from "../../../store";

export interface GridProps {
  subjectKey: string;
  nodeId: string;
  layout?: Layout;
  childrenIds?: string[];
  visible?: boolean;
}

const getCols = (node: LayoutNode): number => {
  if (node.options && typeof node.options.cols == "number") {
    return node.options.cols;
  }
  return 12;
};

class Grid extends React.Component<GridProps> {
  getClassName = (node: LayoutNode) => {
    if (!node) {
      return "";
    }
    const className = node?.options?.class;
    return className || "";
  };
  getStyle = (node: LayoutNode) => {
    if (!node) {
      return;
    }
    let style = node?.options?.style;
    if (style) {
      try {
        style = JSON.parse(style);
      } catch (e) {}
    }
    return style;
  };
  render() {
    if (!this.props.layout || !this.props.childrenIds || !this.props.visible) {
      return null;
    }

    const parentNodeId = this.props.nodeId;
    for (let nodeId of this.props.childrenIds) {
      const node = this.props.layout.nodeById[nodeId];

      if (!node) {
        continue;
      }

      /* In nested grid case use inner grid as content */
      if (node.ui == UI_GRID) {
        return (
          <Row
            style={this.getStyle(node)}
            className={`mx-0 px-0 mb-1 overflow-auto grid-row ${this.getClassName(
              node
            )}`}
          >
            <Col key={nodeId} md={getCols(node)} className="px-0">
              <CardArea
                subjectKey={this.props.subjectKey}
                nodeId={parentNodeId}
              />
            </Col>
          </Row>
        );
      }
    }
    const node = this.props.layout.nodeById[parentNodeId];

    return (
      <Row
        style={this.getStyle(node)}
        className={`mx-0 px-0 mb-1 overflow-auto grid-row ${this.getClassName(
          node
        )}`}
      >
        <CardArea subjectKey={this.props.subjectKey} nodeId={parentNodeId} />
      </Row>
    );
  }
}

export default connect(
  (state: RootState, ownProps: { subjectKey: string; nodeId: string }) => {
    const subject =
      state.subject && state.subject.subjects[ownProps.subjectKey];
    if (!isSubject(subject)) {
      return null;
    }
    const childrenIds = subject && subject.childrenNodesById[ownProps.nodeId];
    const visible = subject.visibility[ownProps.nodeId] ? true : false;
    return {
      layout: subject,
      childrenIds: childrenIds,
      // childrenIds:ownProps.nodeId && [ownProps.nodeId] || undefined,
      visible,
    };
  }
)(Grid);
