import shortid from "shortid";

import { ServerError } from "../actions/utils";
import {
  getFormatById,
  validate,
} from "../components/debounce/formatvalidator";
import {
  ADD_NEW_SUBJECT_EVENT,
  NEW_SUBJECT_ADDED_EVENT,
} from "../constants/subject";
import { AlertLevelType } from "../types/alert";
import {
  ModalOptions,
  OkCallback,
  CancelCallback,
  CloseCallback,
  I18NString,
} from "../types/modal";
import { SelectionInfo } from "../types/selection";
import {
  UploadTask,
  FileValue,
  NewSubjectEventOptions,
  SubjectData,
  AddedSubjectEventOptions,
  getSubjectKey,
  AutomationForm,
  Column,
  DiffState,
  EnumerationInfo,
  EnumerationNode,
  isSubject,
  Layout,
  LayoutNode,
  Link,
  NormalizedAutomation,
  PathIndex,
  RawLink,
  Subject,
  SubjectDiff,
  SubjectState,
  UploadMetaData,
  Validation,
  Value,
  Visibility,
  ActiveTabs,
  LayoutStatus,
  OperationType,
  SaveStateType,
  Lock,
} from "../types/subject";
import { FetchError, isFetchError } from "../types/error";
import { isEmptyObject } from "./app";
import { retrieveFunction, checkMandatory, scriptCompiler } from "./automation";
import { buildLabelInfo, normalizePredicate, generateBindings } from "./layout";
import { getSearchData, buildUrl } from "./location";
import * as c from "../constants/subject";
import { constants } from "crypto";
import { RematchDispatch } from "@rematch/core";
import { RootModel } from "../model";
import { Dispatch } from "../store";
import { dispatchError } from "./alert";
import { composeDecendantsRefsUrl, composePathToNodeUrl } from "./tree";
/**Anti pattern! But nobody knows how to do it better.
Global upload map used to link between upload keys and real files
Each value is task
let task = {
    key: key, //pass unique key here
    file: file //pass file to be uploaded
}*/
const globalUploadMap: { [key: string]: any } = {};

//Called by
export function enqueUpload(file: any) {
  const key = shortid.generate();
  globalUploadMap[key] = file;
  return key;
}
export function getUpload(key: string) {
  return globalUploadMap[key];
}

function recursiveUploadSearch(
  nodeId: string,
  data: any,
  path: string[],
  taskList: UploadTask[]
) {
  if (Array.isArray(data)) {
    for (let idx in data) {
      path.push(idx);
      recursiveUploadSearch(nodeId, data[idx], path, taskList);
      path.pop();
    }
  } else if (data && typeof data === "object") {
    if (typeof data.$uploadKey != "undefined") {
      let task: UploadTask = {
        nodeId, //node id
        key: data.$uploadKey, //save key to clear upload map
        file: globalUploadMap[data.$uploadKey],
        path: ([] as any[]).concat(path), //copy path in node!
      };
      if (typeof task.file != "undefined") {
        taskList.push(task);
      } else {
        console.error("Unknown file key", data.$uploadKey);
      }
    } else {
      for (let p in data) {
        path.push(p);
        type FileValueKey = keyof FileValue;
        recursiveUploadSearch(nodeId, data[p as FileValueKey], path, taskList);
        path.pop();
      }
    }
  }
}

/**Map between node id and path of upload*/
export function getUploadTaskList(values: { [NODE_ID: string]: any }) {
  const taskList: UploadTask[] = [];

  for (const nodeId in values) {
    const value = values[nodeId];
    recursiveUploadSearch(nodeId, value, [], taskList);
  }
  return taskList;
}

export function clearUploadTasks(taskList: UploadTask[]) {
  if (taskList.length == 0) {
    return;
  }
  console.log("Before clearUploadTasks:", globalUploadMap);
  for (let task of taskList) {
    delete globalUploadMap[task.key];
  }
  console.log("After clearUploadTasks:", globalUploadMap);
}

export function buildInnerCardId(
  formId: string,
  nodeId: string,
  namespace: string,
  rdfId: string
) {
  return (
    formId +
    "_" +
    nodeId.replace(/\./g, "_") +
    "_" +
    (namespace ? namespace + "_" : "") +
    rdfId
  );
}

export function toUrlSearchParams(params: any) {
  if (Object.keys(params).length > 0) {
    return Object.keys(params)
      .map(function (k) {
        return encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
  }
  return null;
}

export function addSubjectFire(data: SubjectData, nodeId: string) {
  let eventOptions: NewSubjectEventOptions = {
    notifyId: nodeId,
    data: data,
  };
  const newSubjectEvent = new CustomEvent(ADD_NEW_SUBJECT_EVENT, {
    detail: eventOptions,
  });

  document.dispatchEvent(newSubjectEvent);
}

//For search update to new rdfId
export function subjectAddedFire(newRdfId: string, oldRdfId: string) {
  let eventOptions: AddedSubjectEventOptions = {
    newRdfId,
    oldRdfId,
  };
  const newSubjectAddedEvent = new CustomEvent(NEW_SUBJECT_ADDED_EVENT, {
    detail: eventOptions,
  });

  document.dispatchEvent(newSubjectAddedEvent);
}

//Parse legacy links to selection object
export function getSelectionFromLink(link: string): SelectionInfo | null {
  const searchStart = link.indexOf("?");
  if (searchStart === -1) {
    return null;
  }
  const info: SelectionInfo = {};
  const search = link.substring(searchStart + 1);
  for (let param of search.split("&")) {
    const pair = param.split("=");
    if (pair[0] === "object") {
      info.object = pair[1];
    }
    if (pair[0] === "type") {
      info.type = pair[1];
    }
  }
  return info;
}

export const getRdfId = (data: SubjectData) => {
  if (data.$rdfId) {
    return data.$rdfId;
  }
  if (data.$class && data.$class.includes(":")) {
    const [ns, rdfId] = data.$class.split(":");
    return rdfId;
  }
  console.error("no rdfId was found in", data);
  return null;
};

export const getSubjectKeyFromData = (data: SubjectData) => {
  const ns = data.$namespace;
  if (data.$rdfId) {
    return getSubjectKey(data.$rdfId, ns);
  }
  if (data.$class) {
    return data.$class;
  }
  console.error("no rdfId was found in", data);
  return null;
};

//UTIL FUNCTIONS
export function refreshLinkSubject(subjectData: any) {
  return {
    $namespace: subjectData.$namespace,
    $rdfId: subjectData.$rdfId,
    $label: subjectData.$label,
    $description: subjectData.$description,
  };
}

export async function fetchAutocomplete(
  script: string,
  search: string,
  contextSearch?: string | object
): Promise<string[]> {
  let params = [];
  if (contextSearch) {
    const param =
      typeof contextSearch !== "string"
        ? JSON.stringify(contextSearch)
        : contextSearch;
    params.push(`c=${encodeURIComponent(param)}`);
  }
  if (search) {
    params.push(`q=${encodeURIComponent(search)}`);
  }
  let queryString = params.join("&");

  if (queryString) {
    queryString = `?${queryString}`;
  }
  const response = await fetch(`${script}${queryString}`);

  if (!response.ok) {
    throw new ServerError(response.status, response.statusText);
  }
  return await response.json();
}

export function linkSubject(
  subjectKey: string,
  nodeId: string,
  valuesDiff: any,
  state: SubjectState,
  dispatch: RematchDispatch<RootModel>
) {
  const subject = state && state.subjects[subjectKey];
  if (!isSubject(subject)) {
    return;
  }

  const { className } = subject;
  const layout = state.layouts[className];
  const currentState = makeState(subject);
  dispatch.subject.sendSubjectChangeData({
    data: makeDiff(subjectKey, layout, subject, valuesDiff, currentState),
    nodeId,
  });
}

export function getLockStatus(subject: Subject) {
  if (!subject.subjectData || !subject.subjectData.$lock) {
    return false;
  }
  return subject.subjectData.$lock.status;
}
function isValueEmpty(value: any) {
  let isValueEmpty = false;
  if (typeof value === "undefined") {
    isValueEmpty = true;
  } else if (value === null) {
    isValueEmpty = true;
  } else if (typeof value === "object" && Object.keys(value).length === 0) {
    isValueEmpty = true;
  }
  return isValueEmpty;
}
const applyBindFillForValues = (
  values: Value,
  layout: Layout,
  subject: Subject
) => {
  const predicateIds = Object.keys(values);
  if (!values) {
    return values;
  }
  const newValues = { ...values };

  const hasFill =
    layout.automation && Object.keys(layout.automation.fillBindings).length > 0;

  if (layout.automation && hasFill) {
    const form = makeForm(newValues, subject.subjectData);
    for (let nodeId of predicateIds) {
      if (
        !isValueEmpty(newValues[nodeId]) ||
        !layout.automation.fillBindings[nodeId]
      ) {
        //Do not fill ready fields
        continue;
      }
      console.log("Process fill bindings:", nodeId);
      const funcId = layout.automation.fillBindings[nodeId];
      const func = retrieveFunction(funcId);
      try {
        const d = func(form);
        if (d) {
          newValues[nodeId] = d;
        }
      } catch (ex) {
        console.log("Fill binding error", nodeId, ex);
      }
    }
  }
  return newValues;
};
export function mergeSubjectValues(
  subject: Subject,
  layout: Layout,
  values: Value
) {
  //Make deep copy of subject
  const subjectData = subject.subjectData;
  const valuesDiff: Value = applyBindFillForValues(values, layout, subject);
  const newSubjectData = { ...subjectData };
  delete newSubjectData.$notifyId;
  for (let id in valuesDiff) {
    const value = valuesDiff[id];
    if (value == null) {
      continue;
    }
    const path = id.split(".");
    let obj = newSubjectData;
    while (path.length > 1) {
      const key = path.shift();
      if (key) {
        if (!obj[key]) {
          obj[key] = {};
        }
        obj = obj[key];
      }
    }
    obj[path[0]] = value;
  }
  return newSubjectData;
}

function isEditable(subject: SubjectData) {
  return (
    subject.$isNew === true || typeof subject?.$lock?.status !== "undefined"
  );
}

export function makeState(data: Subject): DiffState {
  return {
    values: data.values || {},
    validation: data.validation || {},
    visibilityDemand: data.visibilityDemand || {},
    lockDemand: data.lockDemand || {},
  };
}

function isObjectEmpty(obj: object) {
  return obj && Object.getOwnPropertyNames(obj).length === 0;
}

function wrapValue(value: any) {
  if (
    typeof value == "undefined" ||
    (typeof value == "object" && isObjectEmpty(value))
  ) {
    return null;
  }
  return value;
}

//Obtain data by path
export function obtainData(path: string | PathIndex[], data: any): any {
  //Support for string path

  let realPath: PathIndex[];
  if (typeof path == "string") {
    realPath = path.split(".");
  } else {
    realPath = path;
  }
  let result = data;
  for (let subPath of realPath) {
    if (typeof subPath == "string" && /^[0-9]+$/.test(subPath)) {
      //because javascript array index is string not number
      subPath = parseInt(subPath);
    }
    if (typeof subPath == "string") {
      if (typeof result == "object" && typeof result[subPath] != "undefined") {
        result = result[subPath];
        continue;
      }
    } else if (typeof subPath == "number") {
      if (Array.isArray(result) && typeof result[subPath] != "undefined") {
        result = result[subPath];
        continue;
      }
    }
    return null;
  }
  return result;
}

/*Create form for automation*/
export function makeForm(
  values: { [K: string]: any },
  subjectData: SubjectData,
  dispatch?: Dispatch
): AutomationForm {
  let form: AutomationForm = {
    obtain: (p: string) => {
      p = p.replace(":", ".");
      const value = values[p];
      if (typeof value != "undefined") {
        return wrapValue(value);
      }
      const path = p.split(".");
      if (path[0].indexOf("$") == 0) {
        //Special symbol and special fields
        const v = values[path[0]];
        if (typeof v != "undefined") {
          const key = path.shift(); //remove first part from path
          return wrapValue(obtainData(path, v));
        }
        //Fallback to data
        return wrapValue(obtainData(path, subjectData));
      }
      //Predicate must have at least three parts: namespace, classname, predicate name.
      //We use 4 because 3 parts were already checked at the begining!
      if (path.length < 4) {
        return wrapValue(value); //return undefined value
      }
      const p2 = [path.shift(), path.shift(), path.shift()].join(".");
      const value2 = values[p2]; //New value
      //console.log("Obtain from predicate sub value", p2, value2, path);
      if (!value2) {
        return wrapValue(value2); //return undefined or null
      }
      return wrapValue(obtainData(path, value2)); //path is rest of starting path without three elements, value2 is from values
    },
  };

  if (dispatch) {
    form.setNodeLoading = (loading: boolean, id: string) => {
      dispatch.subject.sendNodeLoading({
        subjectKey: subjectData.$rdfId,
        nodeId: id,
        loading,
      });
    };
    form.reload = () => {
      dispatch.subject.fetchSubject(
        getSubjectKey(subjectData.$rdfId, subjectData.$namespace)
      );
    };
    form.addAlert = (type: AlertLevelType, message: string) => {
      dispatch.alert.addAlert({ type, message });
    };
    form.change = (predicate: string, value: any) => {
      let nodeId = predicate.replace(":", ".");

      //TODO: change subject
      //dispatch(change(store, nodeId, value));
    };
    form.openModal = (
      type: string,
      options: ModalOptions,
      okCallback?: OkCallback | undefined,
      cancelCallback?: CancelCallback | undefined,
      closeCallback?: CloseCallback | undefined
    ) => {
      const modalId = shortid.generate();
      const safeOkCallback = (result: any) => {
        try {
          return okCallback && okCallback(result);
        } catch (e) {
          dispatchError("SUBJECT_USER_MODAL_ERROR", e, dispatch);
        }
      };
      dispatch.modal.openModal({
        id: modalId,
        type,
        options,
        okCallback: safeOkCallback,
        cancelCallback,
        closeCallback,
      });
    };
  }
  return form;
}

export function makeDiff(
  subjectKey: string,
  layout: Layout,
  subject: Subject,
  valuesDiff: Value, //Current state
  diffState: DiffState,
  invalidFormat?: any
): SubjectDiff {
  //List of invalid format nodes
  //Make validation and automation
  const { lockDemand, validation, values, visibilityDemand } = diffState;
  let nextValues = Object.assign({}, values, valuesDiff);
  //Bind values
  const form = makeForm(nextValues, subject.subjectData);
  const editable = isEditable(subject.subjectData);
  //Bind values only in editable mode
  if (editable && layout.automation) {
    //Make several iteration to obtain final result of value bindings
    for (let i = 0; i < 10; ++i) {
      let changed = false; //flag to exit from iterations
      for (let nodeId in layout.automation.valueBindings) {
        const funcId = layout.automation.valueBindings[nodeId];
        const func = retrieveFunction(funcId);
        try {
          const value = func(form);
          if (values[nodeId] != value && nextValues[nodeId] != value) {
            valuesDiff[nodeId] = value;
            changed = true;
          }
        } catch (ex) {
          console.log("Value binding error", nodeId, ex);
        }
      }
      if (!changed) {
        //nothing have been changed so exit from iterations
        break;
      }
      //Update next values
      nextValues = Object.assign({}, values, valuesDiff);
    }
  }
  //Process validation
  let validationDiff: Validation | null = null;
  for (let nodeId of layout.predicateNodesIds) {
    const value = nextValues[nodeId];
    let error: boolean | I18NString = false;

    if (invalidFormat && invalidFormat[nodeId]) {
      //If we have map of invalid format values
      error = invalidFormat[nodeId];
    }
    if (!error && layout.mandatorySet[nodeId]) {
      //Check mandatory first
      if (!checkMandatory(value)) {
        error = c.MANDATORY_ERROR;
      }
    }
    if (
      !error &&
      layout.automation &&
      layout.automation.validationBindings[nodeId]
    ) {
      //Check bindings
      const funcId = layout.automation.validationBindings[nodeId];
      const func = retrieveFunction(funcId);
      try {
        error = func(value, form);
      } catch (ex) {
        console.log("Validation binding error", nodeId, ex);
      }
    }
    const prevError = validation[nodeId];
    if (!validationDiff) {
      validationDiff = {};
    }

    if (error != (prevError || false)) {
      const prevErrorformat = getFormatById(prevError?.id);
      const isResultEmpty = isEmptyObject(nextValues[nodeId]);
      const isResultInvalid = nextValues[nodeId]?.invalid;
      if (!validationDiff) {
        validationDiff = {};
      }
      if (prevErrorformat && isResultEmpty && !Boolean(error)) {
        validationDiff[nodeId] = false;
      } else if (prevErrorformat && isResultInvalid) {
        const result = validate(`${nextValues[nodeId]}`, prevErrorformat);
        validationDiff[nodeId] = result.valid ? false : prevError;
      } else {
        validationDiff[nodeId] = error;
      }
    }
  }
  //Process visibility
  let visibilityDiff: Visibility | null = null;
  if (layout.automation) {
    for (let nodeId in layout.automation.visibilityBindings) {
      const funcId = layout.automation.visibilityBindings[nodeId];
      const func = retrieveFunction(funcId);
      try {
        const visible = func(form);
        if (visibilityDemand[nodeId] != visible) {
          if (!visibilityDiff) {
            visibilityDiff = {};
          }
          visibilityDiff[nodeId] = visible;
        }
      } catch (ex) {
        // console.log("Visibility binding error", nodeId, ex);
      }
    }
  }
  //Process locks
  let lockDiff: Lock | null = null;
  //First: make common lock checks
  if (layout.automation) {
    for (let funcId of layout.automation.commonLockChecks) {
      const func = retrieveFunction(funcId);
      for (let nodeId of layout.predicateNodesIds) {
        try {
          const lock = func(form, layout.nodeById[nodeId], nodeId);
          if (lockDemand[nodeId] != lock) {
            if (!lockDiff) {
              lockDiff = {};
            }
            lockDiff[nodeId] = lock;
          }
        } catch (ex) {
          console.log("Common lock checks error", nodeId, ex);
        }
      }
    }
  }

  //Second: make specific lock checks for predicates
  if (layout.automation) {
    for (let nodeId in layout.automation.lockBindings) {
      const funcId = layout.automation.lockBindings[nodeId];
      const func = retrieveFunction(funcId);
      try {
        const lock = func(form);
        if (lockDemand[nodeId] != lock) {
          if (!lockDiff) {
            lockDiff = {};
          }
          lockDiff[nodeId] = lock;
        } else if (lockDiff) {
          delete lockDiff[nodeId];
        }
      } catch (ex) {
        console.log("Lock binding error", nodeId, ex);
      }
    }
  }

  return {
    subjectKey,
    values: valuesDiff,
    validation: validationDiff,
    visibility: visibilityDiff,
    lock: lockDiff,
  };
}

export function syncDataAndLayout(
  subjectKey: string,
  subject: Subject,
  layout: Layout,
  forceUpdate: boolean
) {
  const currentState = makeState(subject);
  const valuesDiff: Value = {};

  if (forceUpdate || Object.keys(subject.values).length === 0) {
    currentState.values = {};
    currentState.validation = {};
    currentState.visibilityDemand = {};
    currentState.lockDemand = {};
    const predicateIds = layout.predicateNodesIds.slice();
    if (subject.ignore) {
      for (let p in subject.ignore) {
        predicateIds.push(`$ignore.${p}`);
      }
    }
    for (let nodeId of predicateIds) {
      const d = obtainData(nodeId, subject.subjectData);
      if (typeof d !== "undefined") {
        valuesDiff[nodeId] = d;
      } else {
        valuesDiff[nodeId] = null; //not undefined!
      }
    }
    //Check if we need to prefill some data
    const hasFill =
      layout.automation &&
      Object.keys(layout.automation.fillBindings).length > 0;
    const editable = isEditable(subject.subjectData);
    if (editable && layout.automation && hasFill) {
      const form = makeForm(valuesDiff, subject.subjectData);
      for (let nodeId of predicateIds) {
        if (valuesDiff[nodeId] || !layout.automation.fillBindings[nodeId]) {
          //Do not fill ready fields
          continue;
        }
        console.log("Process fill bindings:", nodeId);
        const funcId = layout.automation.fillBindings[nodeId];
        const func = retrieveFunction(funcId);
        try {
          const d = func(form);
          if (d) {
            valuesDiff[nodeId] = d;
          }
        } catch (ex) {
          console.log("Fill binding error", nodeId, ex);
        }
      }
    }
  }
  return makeDiff(subjectKey, layout, subject, valuesDiff, currentState);
}

function parseTableNode(tableNode: any, mainNode: LayoutNode) {
  /** If current node isn't predicate - it is not column */
  if (!tableNode.p) {
    if (tableNode.content && Array.isArray(tableNode.content)) {
      for (let child of tableNode.content) {
        parseTableNode(child, mainNode);
      }
    }
    return;
  }

  const column: Column = {
    id: tableNode.id,
    predicateId: tableNode.id,
    path: tableNode.id.split("."),
    label: tableNode.label,
    labelInfo: buildLabelInfo(tableNode.label),
    format: c.UI_INPUT_FORMAT[tableNode.ui] || "string",
  };
  /** Setup predicate for cell factory */
  switch (tableNode.ui) {
    case c.UI_COMBOBOX:
      column.predicate = {
        classRelationInfo: {
          peerClass: {
            stereotypeInfo: "enumeration",
          },
        },
      };
      break;
    case c.UI_FILE:
      column.predicate = {
        dataType: {
          name: "base64Binary",
        },
      };
      break;
    case c.UI_FRAGMENT:
      column.predicate = {
        dataType: {
          name: "anyURI",
        },
      };
      break;
    case c.UI_OBJECT_TABLE:
      column.predicate = {
        classRelationInfo: {
          peerClass: {},
          relationTypeInfo: "composition",
        },
      };
      break;
    case c.UI_REF_TABLE:
      column.predicate = {
        classRelationInfo: {
          peerClass: {},
          relationTypeInfo: "aggregation",
        },
      };
      break;
    case c.UI_CHECKBOX:
      column.predicate = {
        dataType: {
          name: "boolean",
        },
      };
      break;
    case c.UI_DATE:
      column.predicate = {
        dataType: {
          name: "date",
        },
      };
      break;
    case c.UI_DATE_TIME:
      column.predicate = {
        dataType: {
          name: "dateTime",
        },
      };
      break;
    case c.UI_FLOAT:
      column.predicate = {
        dataType: {
          name: "float",
        },
      };
      break;
    case c.UI_INT:
      column.predicate = {
        dataType: {
          name: "integer",
        },
      };
      break;
    default:
      column.predicate = {
        dataType: {
          name: "string",
        },
      };
      break;
  }
  column.predicate &&
    (column.predicate["table-header-width"] =
      tableNode.options && tableNode.options.width);
  Object.assign(column, tableNode.options);
  mainNode.options.columns.push(column);
}
function parseTable(node: LayoutNode) {
  node.options.columns = [];
  if (
    !node.options.layout ||
    !node.options.layout.content ||
    !Array.isArray(node.options.layout.content)
  ) {
    return;
  }
  if (node.options["columns-order"]) {
    for (let i = 0; i < node.options["columns-order"].length; ++i) {
      node.options["columns-order"][i] = normalizePredicate(
        node.options["columns-order"][i]
      );
    }
  }

  for (let child of node.options.layout.content) {
    parseTableNode(child, node);
  }
  delete node.options.layout;
}
function parsePredicate(node: LayoutNode) {
  node.label = node?.options?.label || node.label;
}
function normalizeLayout(content: any, layout: Layout, parentId?: string) {
  if (!content || !Array.isArray(content)) {
    return;
  }
  for (const item of content) {
    //Cast to layout node
    const node: LayoutNode = item;
    layout.nodeById[node.id] = node;
    if (parentId) {
      layout.parentNodeById[node.id] = parentId;
      if (layout.childrenNodesById[parentId]) {
        layout.childrenNodesById[parentId].push(node.id);
      } else {
        layout.childrenNodesById[parentId] = [node.id];
      }
    } else {
      layout.rootNodesIds.push(node.id);
    }
    //Check predicate marker
    if (item.p) {
      parsePredicate(node);
      layout.predicateNodesIds.push(node.id);
      delete item.p;
    }
    if (node?.options?.src) {
      node.options.src = normalizePredicate(node.options.src);
    }
    //Add enumeration to fetch list
    if (node.ui == c.UI_COMBOBOX && node.options.cls) {
      layout.enumerationsMap[node.options.cls] = true;
    }
    //Check mandatory flag
    let mandatory = node.mandatory;
    if (typeof node?.options?.mandatory !== "undefined") {
      mandatory = node.options.mandatory;
    }
    if (mandatory) {
      layout.mandatorySet[node.id] = true;
    }
    if (node.ui == c.UI_OBJECT_TABLE) {
      parseTable(node);
    }
    if (node.ui == c.UI_TAB) {
      layout.tabsIds.push(node.id);
    }
    //Recursive parse
    if (item.content) {
      normalizeLayout(item.content, layout, node.id);
      delete item.content;
    } else {
      item.leaf = true;
    }
  }
}
/**
 * Search layout nodes for speciefic ui that require
 * functions to be runned before objectcard save function
 */
function getPreSaveFunctions(layout: Layout) {
  let preSave: any = {};
  if (!layout.nodeById) {
    return preSave;
  }
  for (let nodeId in layout.nodeById) {
    if (layout.nodeById[nodeId].ui == c.UI_RPA_SETPOINTS) {
      preSave[c.UI_RPA_SETPOINTS] = layout.nodeById[nodeId].options.src;
    }
  }
  return preSave;
}
function getAutomation(script: string, className: string) {
  const normalizedAutomation: NormalizedAutomation = {
    optionsBindings: {},
    valueBindings: {},
    enumerationBindings: {},
    clickBindings: {},
    validationBindings: {},
    lockBindings: {},
    visibilityBindings: {},
    fillBindings: {},
    addCompound: {},
    linkBindings: {},
    commonLockChecks: [], //Lock checks not specific for the predicate
    commentBindings: {},
  };
  // console.log(script)
  if (script) {
    const bindings = generateBindings(normalizedAutomation, className);
    // console.log(script)
    scriptCompiler(script, bindings);
    // scriptCompiler(SCRIPT, bindings);
  }
  return normalizedAutomation;
}
function parseLayout(json: any, className: string) {
  const layout: Layout = {
    automation: getAutomation(json.automation, className),
    toolbar: json.toolbar,
    rootNodesIds: [],
    override: null,
    predicateNodesIds: [],
    nodeById: {},
    childrenNodesById: {},
    parentNodeById: {},
    //Placeholders
    parentPlaceHolderId: null, //Parent place holder id (ui id).
    childPlaceHolderId: null, //Child place holder id (ui id).
    selfPlaceHolderId: null, //Self placeholder id (ui id).
    enumerationsMap: {},
    mandatorySet: {},
    tabsIds: [],
    rootId: null,
  };
  /** Manually create root node */
  const rootId = "__npt.objectcard.root";
  layout.rootId = rootId;
  layout.nodeById[rootId] = {
    id: rootId,
    options: {},
  };
  layout.childrenNodesById[layout.rootId] = [];

  //Add special fields to make available for placeholders
  const specialList: LayoutNode[] = [
    {
      id: "$label",
      ui: c.UI_LABEL,
      options: {},
      leaf: true,
    },
    {
      id: "$description",
      ui: c.UI_DESCRIPTION,
      options: {},
      leaf: true,
    },
  ];
  for (let special of specialList) {
    layout.nodeById[special.id] = special;
    layout.predicateNodesIds.push(special.id);
    //Add to parent but not to child list of parent (so that those items will not be displayed by default)
    layout.parentNodeById[special.id] = layout.rootId;
  }
  normalizeLayout(json.content, layout);
  for (let nodeId of layout.rootNodesIds) {
    layout.parentNodeById[nodeId] = rootId;
    layout.childrenNodesById[layout.rootId].push(nodeId);
  }

  layout.preSave = getPreSaveFunctions(layout);

  layout.childPlaceHolderId = layout.nodeById["$child"] ? "$child" : null;
  layout.parentPlaceHolderId = layout.nodeById["$parent"] ? "$parent" : null;
  layout.selfPlaceHolderId = layout.nodeById["$self"] ? "$self" : null;

  return layout;
}

export function checkLayoutFetchNeeded(
  className: string,
  state?: SubjectState
) {
  const status = state && state.layoutsStatus[className];
  return typeof status === "undefined";
}

export function checkSavePossible(
  subject: Subject,
  subjectKey: string,
  state: SubjectState
) {
  if (!subject.subjectData) {
    return false;
  }
  const areFilesUploaded =
    state.saveState[subjectKey] == c.SAVE_STATE_UPLOADS_READY;
  return (
    (subject.subjectData.$isNew || getLockStatus(subject)) && areFilesUploaded
  );
}

function parseEnumerationChildren(json: any): EnumerationNode[] {
  const enumerationNodes: EnumerationNode[] = [];
  if (!json || !Array.isArray(json)) {
    return enumerationNodes;
  }
  for (let child of json) {
    const enumerationNode: EnumerationNode = {
      lastLevel: Boolean(child.lastLevel),
    };

    if (child.data) {
      enumerationNode.data = child.data;
    }
    if (child.children) {
      enumerationNode.children = parseEnumerationChildren(child.children);
    }
    enumerationNodes.push(enumerationNode);
  }
  return enumerationNodes;
}

export function parseEnumeration(json: any): EnumerationInfo {
  const enumerationInfo: EnumerationInfo = {
    rootLevel: Boolean(json.rootLevel),
    children: parseEnumerationChildren(json.children),
  };
  return enumerationInfo;
}

function composeValidateLinkUrl(relatedClass: string, reference: RawLink) {
  const link = `${c.VALIDATE_LINK_URL}/${relatedClass.replace(":", "/")}`;
  let search = "?";
  if (reference.$namespace) {
    search += `namespace=${reference.$namespace}&`;
  }
  search += `rdfId=${reference.$rdfId}`;
  return `${link}${search}`;
}

export function wrapSubjectData(subjectData: SubjectData) {
  const subject: Subject = {
    // ...layout,
    childrenNodesById: {},
    enumerationsMap: {},
    mandatorySet: {},
    nodeById: {},
    parentNodeById: {},
    predicateNodesIds: [],

    rootNodesIds: [],
    automation: {
      optionsBindings: {},
      clickBindings: {},
      commonLockChecks: [],
      enumerationBindings: {},
      fillBindings: {},
      linkBindings: {},
      lockBindings: {},
      addCompound: {},
      validationBindings: {},
      valueBindings: {},
      visibilityBindings: {},
      commentBindings: {},
    },
    isNew: subjectData.$isNew,
    toolbar: { buttons: [] },
    subjectData,
    className: subjectData.$class,
    values: {},
    visibility: {},
    visibilityDemand: {},
    validation: {},
    tabsIds: [],
    lock: {},
    lockDemand: {},
    ignore: {},
    comment: {},
    rootId: null,
  };
  return subject;
}

//TODO
export function makeLayout(classInfo: any) {
  // console.log('make layout')
  // if (!classInfo) {
  //     return null;
  // }
  // const layout = layoutAll(classInfo, false);
  // return layout;
  // return null
}

export function ajaxUploadFiles(
  taskList: UploadTask[],
  uploadComplete: (task: UploadTask, e: any) => void,
  uploadProgress: Function
) {
  let uploads: Promise<UploadMetaData>[] = [];
  let loadedList = [];
  let totalList = [];
  //Upload tasks
  for (let task of taskList) {
    let index = loadedList.length;
    loadedList.push(0);
    totalList.push(0);
    uploads.push(
      ajaxUpload(
        task,
        index,
        loadedList,
        totalList,
        uploadComplete,
        uploadProgress
      )
    );
  }
  return uploads;
}

//TODO
export function runPreSave(layout: Layout, callback: Function) {
  let binded = false;
  // for (let funcId in layout.preSave) {
  // if (funcId == Action.UI_RPA_SETPOINTS) {
  //     binded = true;
  //     /* Remove all previously binded save funcitons */
  //     $(window).unbind('rpasetpoints.save');
  //     $(window).bind('rpasetpoints.save', (event, rdfId) => {
  //         $(window).unbind('rpasetpoints.save');
  //         callback({ [layout.preSave[funcId]]: rdfId || "" });
  //     });
  //     dispatch(saveToServer());
  // }
  // }
  if (!binded) {
    callback();
  }
}

export async function validateLinks(
  contextPath: string,
  relatedClass: string,
  references: RawLink[],
  dispatch: RematchDispatch<RootModel>
) {
  const validLinks: Link[] = [];
  const promises = [];
  try {
    for (let reference of references) {
      promises.push(fetchValidateLink(contextPath, relatedClass, reference));
    }
  } catch (e: any) {
    dispatchError("OBJECTCARD_VALIDATION_ERROR", e, dispatch, {
      error: e?.message,
    });
  }

  await Promise.all(promises)
    .then((values) => {
      for (let value of values) {
        if (value == null) {
          continue;
        }
        validLinks.push(value);
      }
    })
    .catch((e) => {
      dispatchError("OBJECTCARD_VALIDATION_ERROR", e, dispatch, {
        error: e?.message,
      });
    });
  return validLinks;
}

export function downloadFile(href: string, filename?: string) {
  let isChromium =
    navigator.userAgent.toLowerCase().indexOf("chrome") != -1 ||
    navigator.userAgent.toLowerCase().indexOf("safari") != -1;
  let composedLink = href;
  if (isChromium && typeof getSearchData().debug === "undefined") {
    let a = document.createElement("a");
    a.href = composedLink;
    a.download = filename || "";
    a.click();
    return;
  }
  // Force file download (whether supported by server).
  if (composedLink.indexOf("?") > 0) {
    composedLink = composedLink.replace("?", "?download&");
  } else {
    composedLink += "?download";
  }
  if (filename) {
    composedLink += "&_filename=" + filename;
  }
  window.open(composedLink);
}
export function filterReferences(references: RawLink[], data: RawLink[]) {
  if (!Array.isArray(data)) {
    return references;
  }
  return references.filter((reference) => {
    if (!reference.$rdfId) {
      return false;
    }
    for (let row of data) {
      if (row.$rdfId == reference.$rdfId) {
        return false;
      }
    }
    return true;
  });
}

//////// FETCH FUNCTIONS
async function fetchPathToNodeImpl(
  path: string,
  rdfId: string
): Promise<string[]> {
  const response = await fetch(composePathToNodeUrl(path, rdfId));
  if (!response.ok) {
    const { status, statusText } = response;
    const resp = await response.text();
    throw new ServerError(status, resp);
  }
  return await response.json();
}
async function fetchDecendantsRefsImpl(
  path: string,
  nodeId: string
): Promise<any> {
  const response = await fetch(composeDecendantsRefsUrl(path, nodeId));
  if (!response.ok) {
    const { status, statusText } = response;
    const resp = await response.text();
    throw new ServerError(status, resp);
  }
  return await response.json();
}
export async function safeFetchValidateLink(
  contextPath: string,
  relatedClass: string,
  reference: any,
  omitErrors?: boolean
) {
  try {
    return {
      failed: false,
      data: await fetchValidateLink(contextPath, relatedClass, reference),
    };
  } catch (e) {
    if (omitErrors === false) {
      console.error(e);
    }
    return { failed: true, error: e };
  }
}

export async function fetchValidateLink(
  contextPath: string,
  relatedClass: string,
  reference: any
) {
  const response = await fetch(
    composeValidateLinkUrl(relatedClass, reference),
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );

  if (!response.ok) {
    const { status, statusText } = response;
    const resp = await response.text();
    throw new ServerError(status, statusText);
  }

  return await response.json();
}

export async function downloadFileImpl(url: string, fname?: string) {
  const response = await fetch(url);
  if (!response.ok) {
    const { status, statusText } = response;
    throw new ServerError(status, statusText);
  }
  const contentDesp = response.headers.get("Content-Disposition");
  let filename = fname;
  if (contentDesp) {
    filename = decodeURIComponent(contentDesp.split("filename=")[1]).replace(
      /\+/g,
      " "
    );
    filename = filename.slice(0, filename.length - 1);
  }

  response.blob().then((blob) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = filename || "";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  });
}

export async function saveSubjectImpl(
  subjectData: SubjectData
): Promise<SubjectData> {
  const response = await fetch("/rest/subject/entity", {
    method: "POST",
    headers: {
      // 'Accept': 'application/json',
      "Content-Type": "application/json",
    },
    body: JSON.stringify(subjectData),
  });
  if (!response.ok) {
    const { status, statusText } = response;
    const errorText = await response.text();
    throw new ServerError(status, errorText || statusText);
  }
  return await response.json();
}

export async function fetchLayoutImpl(
  prefix: string,
  className: string
): Promise<Layout> {
  // return parseLayout(LAYOUT, className)
  const response = await fetch(`/rest/subject/layout/${prefix}/${className}`);
  if (!response.ok) {
    const { status, statusText } = response;
    const errorText = await response.text();
    throw new ServerError(status, errorText || statusText);
  }

  return parseLayout(await response.json(), className);
}

export async function fetchSubjectImpl(
  rdfId: string,
  prefix?: string
): Promise<SubjectData> {
  const url = prefix
    ? `/rest/subject/entity/${prefix}/${rdfId}`
    : `/rest/subject/entity/${rdfId}`;
  const response = await fetch(url);
  if (!response.ok) {
    const { status, statusText } = response;
    const errorText = await response.text();
    throw new ServerError(status, errorText || statusText);
  }
  return await response.json();
}

export async function fetchLockImpl(
  rdfId: string,
  acquire: boolean,
  prefix?: string
): Promise<SubjectData> {
  const data = new FormData();
  data.append("rdfId", rdfId);
  data.append("acquire", JSON.stringify(acquire));
  prefix && data.append("namespace", prefix);
  const response = await fetch("/rest/subject/lock", {
    method: "POST",
    body: data,
  });

  if (!response.ok) {
    const { statusText, status } = response;
    const errorText = await response.text();
    throw new ServerError(status, errorText || statusText);
  }

  return await response.json();
}

export function fetchEnumeration(className: string) {
  const path = className.split(":");

  return fetch(`/rest/subject/enumeration/${path[0]}/${path[1]}`)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      return json;
    });
}

function ajaxUpload(
  task: UploadTask,
  index: number,
  loadedList: number[],
  totalList: number[],
  uploadComplete: (task: UploadTask, e: any) => void,
  uploadProgress: Function,
  headers?: { [KEY: string]: string }
): Promise<UploadMetaData> {
  let formData = new FormData();
  formData.append("file", task.file);

  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();

    // обработчик для отправки
    xhr.upload.onprogress = function (evt) {
      // log(event.loaded + ' / ' + event.total);

      if (evt.lengthComputable) {
        loadedList[index] = evt.loaded;
        totalList[index] = evt.total;
        //Sum of loaded
        let loaded: number = 0;
        for (let s of loadedList) {
          loaded += s;
        }
        //Sum of total
        let total: number = 0;
        for (let s of totalList) {
          total += s;
        }
        let percentComplete = (loaded / total) * 100;
        if (!uploadProgress(percentComplete)) {
          console.log("Abort upload!");
          xhr.abort();
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        }
      }
    };

    // обработчики успеха и ошибки
    // если status == 200, то это успех, иначе ошибка
    xhr.onload = xhr.onerror = function (e: any) {
      if (this.status == 200) {
        try {
          const upload: UploadMetaData = JSON.parse(xhr.response);
          upload.nodeId = task.nodeId;

          resolve(upload);
          uploadComplete(task, upload.sha1);
        } catch (e) {
          reject({
            status: 404,
            statusText: e,
          });
        }
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      }
    };
    if (headers) {
      Object.keys(headers).forEach(function (key) {
        xhr.setRequestHeader(key, headers[key]);
      });
    }
    xhr.open("POST", c.UPLOAD_URL, true);
    xhr.send(formData);
  });
}

export function fetchNewSubject(
  subjectKey: string,
  className: string,
  parent?: string,
  parentRef?: string,
  prototype?: string,
  initialData?: any
): Promise<any> {
  const parts = className.split(":");
  const params: { [k: string]: string } = {};
  if (parent) {
    params.parent = parent;
  }
  if (parentRef) {
    params["parent-ref"] = parentRef;
  }
  if (prototype) {
    params._prototype = prototype;
  }
  const url = buildUrl({
    url: `${c.SUBJECT_NEW_URL}/${parts[0]}/${parts[1]}`,
    search: params,
  });
  return fetch(url)
    .then((resp) => {
      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      if (typeof initialData == "object") {
        Object.assign(json, initialData);
      }
      return json;
    });
}

/**
 * Leaf nodes are valid if they were not set invalid
 * Branch nodes are valid if they do not contain invalid children
 */
function rebuildValidationTree(
  validation: Validation,
  layout: Layout,
  nodeId: string
) {
  // if (!nodeId) {
  //     const rootNodes = layout.rootNodesIds;
  //     for (let id of rootNodes) {
  //         const valid = rebuildValidationTree(validation, layout, id);
  //         if (valid === undefined) {
  //             continue;
  //         }
  //         validation[id] = valid;
  //     }
  // } else {
  const childrenIds = layout.childrenNodesById[nodeId];
  if (!Array.isArray(childrenIds)) {
    return validation[nodeId] ? false : true;
  }
  let valid = true;
  for (let childId of childrenIds) {
    //Recursively revalidate
    if (!rebuildValidationTree(validation, layout, childId)) {
      valid = false;
    }
  }
  validation[nodeId] = !valid;
  return valid;
  // }
}

/**
 * Leaf nodes are visible if they were not hidden
 * Branch nodes ara visible if they were not hidden and have one visible child
 */
function rebuildVisibilityTree(
  visibility: Visibility,
  layout: Layout,
  nodeId: string
) {
  // if (!nodeId) {
  //     const rootNodes = layout.rootNodesIds;
  //     for (let id of rootNodes) {
  //         const visible = rebuildVisibilityTree(visibility, layout, id);
  //         if (visible === undefined) {
  //             continue
  //         }
  //         visibility[id] = visible;
  //     }
  // } else {
  if (!visibility[nodeId]) {
    //Node was hidden!
    return false;
  }
  const childrenIds = layout.childrenNodesById[nodeId];
  if (!Array.isArray(childrenIds)) {
    return true; //Leaf nodes are visible if not hidden!
  }
  //Branches are visible if one of the child nodes is visible
  visibility[nodeId] = false;
  for (let childId of childrenIds) {
    //Recursively rebuild visibility tree
    if (rebuildVisibilityTree(visibility, layout, childId)) {
      visibility[nodeId] = true;
    }
  }
  return visibility[nodeId];
  // }
}

function makeVisible(layout: Layout, visibilityDemand: Visibility) {
  const visibility: Visibility = { ...visibilityDemand };
  for (let nodeId in layout.nodeById) {
    const demand = visibilityDemand[nodeId];
    if (typeof demand == "boolean") {
      visibility[nodeId] = demand;
    } else if (layout.nodeById[nodeId].hidden) {
      visibility[nodeId] = false;
    } else {
      visibility[nodeId] = true;
    }
  }
  return visibility;
}

/**
 * Locks are propogated from upped level to lower layers
 */
function propogateLock(lock: Lock, layout: Layout, nodeId: string) {
  const childrenIds = layout.childrenNodesById[nodeId];
  if (!Array.isArray(childrenIds)) {
    //no children
    return;
  }
  for (let childId of childrenIds) {
    lock[childId] = true;
    propogateLock(lock, layout, childId);
  }
}

/**
 * Leaf nodes are locked if they were locked or if parent was locked (lock is propogated)
 * Branch nodes are locked if they were locked or if all children are locked
 */
function rebuildLockTree(lock: Lock, layout: Layout, nodeId: string) {
  if (lock[nodeId]) {
    propogateLock(lock, layout, nodeId);
    return true;
  }

  const childrenIds = layout.childrenNodesById[nodeId];
  if (!Array.isArray(childrenIds)) {
    lock[nodeId] = false;
    return false;
  }
  if (childrenIds.length == 0) {
    lock[nodeId] = false; //Branch is not locked because it doesn't contain any children
  } else {
    lock[nodeId] = true; //Set lock by default for branches
    for (let childId of childrenIds) {
      if (!rebuildLockTree(lock, layout, childId)) {
        lock[nodeId] = false; //one of children is not locked. So branch is not locked
      }
    }
  }
  return lock[nodeId];
}

function updateCommentBindings(subject: Subject) {
  if (!subject.automation) {
    return;
  }
  subject.comment = { ...subject.comment };
  const form = makeForm(subject.values, subject.subjectData);
  for (let predicateId in subject.automation.commentBindings) {
    const funcId = subject.automation.commentBindings[predicateId];
    const func = retrieveFunction(funcId);
    try {
      const comment = func(subject.values[predicateId], form);
      if (typeof comment === "undefined" || comment === null) {
        delete subject.comment[predicateId];
        continue;
      }
      if (typeof comment === "string") {
        subject.comment[predicateId] = {
          text: comment,
        };
        continue;
      }
      if (typeof comment === "object") {
        subject.comment[predicateId] = {
          text: comment.text,
          color: comment.color,
          isHtml: comment.isHtml,
          hideOnLock: comment.hideOnLock,
          hideOnEdit: comment.hideOnEdit,
          hideOnCardLock: comment.hideOnCardLock,
          hideOnCardEdit: comment.hideOnCardEdit,
        };
        continue;
      }
      subject.comment[predicateId] = {
        text: comment.toString(),
      };
    } catch (ex) {
      console.log("Comment binding error", predicateId, ex);
    }
  }
}

export function layoutReceived(
  state: SubjectState,
  subjectKey: string,
  className: string,
  layout: Layout,
  storeDiffList?: SubjectDiff[]
): SubjectState {
  const layoutsStatus: LayoutStatus = {
    ...state.layoutsStatus,
    [className]: c.STATUS_READY,
  };
  const layoutsLoading = { ...state.layoutsLoading, [className]: false };
  const layouts = { ...state.layouts, [className]: layout };

  let newSubjects = { ...state.subjects };

  if (storeDiffList && storeDiffList.length > 0) {
    for (let diff of storeDiffList) {
      const subjectKey = diff.subjectKey;
      let subject = { ...newSubjects[subjectKey] };
      if (!isSubject(subject)) {
        continue;
      }

      subject.values = { ...subject.values, ...diff.values };

      if (layout.rootId) {
        subject.validation = { ...subject.validation, ...diff.validation };
        rebuildValidationTree(subject.validation, layout, layout.rootId);

        subject.visibilityDemand = { ...diff.visibility };
        subject.visibility = makeVisible(layout, subject.visibilityDemand);
        rebuildVisibilityTree(subject.visibility, layout, layout.rootId);

        subject.lockDemand = diff.lock || {};
        subject.lock = { ...subject.lock, ...subject.lockDemand };
        rebuildLockTree(subject.lock, layout, layout.rootId);
      }

      subject = { ...subject, ...layout };
      updateCommentBindings(subject);
      newSubjects[subjectKey] = subject;
    }
  } else {
    const subject = { ...newSubjects[subjectKey], ...layout };
    if (isSubject(subject)) {
      updateCommentBindings(subject);
    }
    newSubjects[subjectKey] = subject;
  }
  return {
    ...state,
    layoutsStatus,
    layoutsLoading,
    layouts,
    subjects: newSubjects,
  };
}

export function subjectReceived(
  state: SubjectState,
  subjectKey: string,
  operation: OperationType,
  subject: Subject,
  notifyId: any,
  diff?: SubjectDiff
): SubjectState {
  let newSubject = { ...subject };
  const loading = { ...state.loading, [subjectKey]: false };
  const loadingLock = { ...state.loadingLock, [subjectKey]: false };
  // if(loadingLock[subjectKey]){
  //     delete loadingLock[subjectKey];
  // }
  // if(loading[subjectKey]){
  //     delete loading[subjectKey];
  // }
  if (operation == c.SUBJECT_OPERATION_CREATE) {
    newSubject.isNew = true;
    newSubject.notifyId = notifyId;
  }
  const layout = { ...state.layouts[newSubject.className] };
  let layoutsStatus = state.layoutsStatus;
  let layouts = state.layouts;
  if (layout && diff && layout.rootId) {
    layoutsStatus = { ...state.layoutsStatus };
    layouts = { ...state.layouts };
    //Update store
    newSubject.values = diff.values || {};
    newSubject.validation = diff.validation || {};
    rebuildValidationTree(newSubject.validation, layout, layout.rootId);
    newSubject.visibilityDemand = diff.visibility || {};
    newSubject.visibility = makeVisible(layout, newSubject.visibilityDemand);
    rebuildVisibilityTree(newSubject.visibility, layout, layout.rootId);
    newSubject.lockDemand = diff.lock || {};
    newSubject.lock = { ...newSubject.lockDemand };
    rebuildLockTree(newSubject.lock, layout, layout.rootId);
    //If initialize store then cache layout
    if (operation == c.SUBJECT_OPERATION_INIT) {
      const cls = newSubject.className;
      if (!layouts[cls]) {
        layoutsStatus[cls] = c.STATUS_READY;
        layouts[cls] = { ...layout };
      }
    }

    newSubject = { ...newSubject, ...layout };
  } else {
    newSubject.values = {};
    newSubject.validation = {};
    newSubject.visibilityDemand = {};
    newSubject.visibility = {};
    newSubject.lockDemand = {};
    newSubject.lock = {};
  }

  updateCommentBindings(newSubject);
  const subjects = { ...state.subjects, [subjectKey]: newSubject };
  return { ...state, layouts, layoutsStatus, subjects, loading, loadingLock };
}

export function subjectChangeData(
  state: SubjectState,
  diff: SubjectDiff
): SubjectState {
  const { lock, subjectKey, visibility, validation, values } = diff;
  const newSubject = { ...state.subjects[subjectKey] };
  if (!isSubject(newSubject)) {
    return state;
  }
  const layout = state.layouts[newSubject.className];

  if (values) {
    newSubject.values = { ...newSubject.values, ...values };
  }
  if (validation && layout && layout.rootId) {
    newSubject.validation = { ...newSubject.validation, ...validation };
    rebuildValidationTree(newSubject.validation, layout, layout.rootId);
  }
  if (visibility && layout.rootId) {
    newSubject.visibilityDemand = {
      ...newSubject.visibilityDemand,
      ...diff.visibility,
    };
    newSubject.visibility = makeVisible(layout, newSubject.visibilityDemand);
    rebuildVisibilityTree(newSubject.visibility, layout, layout.rootId);
  }
  if (lock && layout.rootId) {
    newSubject.lockDemand = { ...newSubject.lockDemand, ...diff.lock };
    newSubject.lock = { ...newSubject.lockDemand };
    rebuildLockTree(newSubject.lock, layout, layout.rootId);
  }
  if (values || validation || visibility || lock) {
    updateCommentBindings(newSubject);
    const newSubjects = { ...state.subjects, [subjectKey]: newSubject };
    return {
      ...state,
      subjects: newSubjects,
      changeLoading: { ...state.changeLoading, [subjectKey]: false },
    };
  }
  return state;
}

export function removeSubjectFromStore(
  state: SubjectState,
  subjectKey: string
) {
  const subjects = { ...state.subjects };
  delete subjects[subjectKey];
  return { ...state, subjects };
}

export function cancelSave(state: SubjectState, subjectKey: string) {
  if (
    !state.saveState[subjectKey] ||
    state.saveState[subjectKey] == c.SAVE_STATE_WAIT_SERVER
  ) {
    //Do not cancel if state is not in progress or we are already waiting for server
    return state;
  }

  const saveState = { ...state.saveState };
  delete saveState[subjectKey];
  state.editingSubjects && (state.editingSubjects[subjectKey] = false);
  state.newSubjects && (state.newSubjects[subjectKey] = false);
  return { ...state, saveState };
}

function startSave(state: SubjectState, subjectKey: string) {
  // const saveState: { [SUBJECT_KEY: string]: SaveStateType } = { ...state.saveState, [subjectKey]: constants.SAVE_STATE_START }

  const current = state.saveState[subjectKey];
  if (current && current != c.SAVE_STATE_SHOW_ERRORS) {
    //Do not change if state is already in progress
    return state;
  }
  const subject = state.subjects[subjectKey];
  if (!isSubject(subject)) {
    return state;
  }
  const layout = state.layouts[subject.className];
  const valid =
    layout.rootId && subject.validation[layout.rootId] ? false : true; //check error for rootId
  const saveState: { [SUBJECT_KEY: string]: SaveStateType } = Object.assign(
    {},
    state.saveState,
    {
      [subjectKey]: valid ? c.SAVE_STATE_START : c.SAVE_STATE_SHOW_ERRORS,
    }
  );
  return { ...state, saveState };
}

export function changeTab(
  state: SubjectState,
  subjectKey: string,
  tabId: string,
  navId: string
) {
  let tabs: { [SUBJECT_KEY: string]: ActiveTabs } = { ...state.tabs };
  tabs[subjectKey] = { ...tabs[subjectKey], [navId]: { active: tabId } };

  return { ...state, tabs };
}

export function nodeUpdated(
  state: SubjectState,
  payload: { subjectKey: string; nodeId: string; updated: boolean }
) {
  const { subjectKey, nodeId, updated } = payload;

  const subjects = { ...state.subjects };
  const subject = subjects[subjectKey];
  if (!isSubject(subject)) {
    return state;
  }
  const updatedComponents: any = { ...subject.componentUpdated };
  if (updated) {
    updatedComponents[nodeId] = true;
  } else {
    delete updatedComponents[nodeId];
  }
  const newSubject: Subject = {
    ...subject,
    componentUpdated: updatedComponents,
  };
  subjects[subjectKey] = newSubject;
  return { ...state, subjects };
}

export const isSubjectEditing = (subject?: Subject | FetchError) => {
  if (subject && !isFetchError(subject)) {
    const serverLock = subject.subjectData?.$lock;
    const serverLockReady = serverLock && serverLock.status;
    const isNew = subject.isNew;
    const editCard = serverLockReady || isNew;
    if (editCard) {
      return true;
    }
  }
  return false;
};
