import { createModel } from "@rematch/core";
import shortid from "shortid";
import { RootModel } from ".";
import { ServerError } from "../actions/utils";
import { fillDomainMap } from "../services/security";
import { store } from "../store";
import {
  AlertInfo,
  AlertLevelType,
  AlertOptions,
  AlertState,
} from "../types/alert";
import { FetchError } from "../types/error";
import { I18NString } from "../types/modal";
import {
  isLoggedInUser,
  isLoginPageInfo,
  LoggedInUser,
  LoginRequestDetails,
  LoginStatus,
  MessagesStatistics,
  SecurityData,
  SecurityDataResponse,
  SecurityState,
} from "../types/security";
import { dispatchError } from "../services/alert";
import { ALERT_LEVEL_SUCCESS } from "../constants/alert";

const initialState: SecurityState = {
  info: [],
};

export function sendLoginInfoToBroadcast(loginInfo: LoginStatus) {
  const loginInfoChannel = new BroadcastChannel("loginStatus");
  loginInfoChannel.postMessage(loginInfo);
  loginInfoChannel.close();
}

export const security = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendLoginInfo(state, payload: { info: LoginStatus }) {
      const loginStatus = { ...payload.info };
      if (isLoginPageInfo(loginStatus)) {
        fillDomainMap(loginStatus);
      }
      return { ...state, loading: false, loginStatus };
    },
    sendLoginError(state, payload: { error: FetchError }) {
      return { ...state, loading: false, loginStatus: payload.error };
    },
    sendLoginLoading(state) {
      return { ...state, loading: true };
    },
    sendMessageStatUpdate(state, payload: { stats: MessagesStatistics }) {
      const stats = payload.stats;
      if (isLoggedInUser(state.loginStatus)) {
        const newState = { ...state };
        newState.loginStatus = { ...newState.loginStatus } as LoggedInUser;
        newState.loginStatus.messages = stats;
        return newState;
      }
      return state;
    },
    sendLoginCodeRequired(state) {
      const x = state.loginStatus;
      if (isLoginPageInfo(x)) {
        const loginStatus = { ...x };
        loginStatus.exception = "TOTPCodeRequiredException";
        return { ...state, loginStatus };
      }
      return state;
    },
    sendInfoLoading(state, payload?: boolean) {
      const loading = payload || true;
      return { ...state, infoLoading: loading };
    },
    sendSecurityInfo(state, data: SecurityDataResponse) {
      const newState: SecurityState = {
        ...state,
        infoLoading: false,
        info: data?.settings || [],
        trends: data?.trends || [],
        software: data?.software || [],
        sessions: data?.sessions || [],
        totalSecurityEvents: data.totalSecurityEvents,
        totalSessions: data.totalSessions,
      };
      return newState;
    },
  },

  effects: (dispatch) => ({
    async loginImpl(payload: {
      username: string;
      password: string;
      newPassword?: string;
      details: LoginRequestDetails;
    }) {
      try {
        const ad = payload.details.ad;
        const generateToken = payload.details.generateToken;
        const code = payload.details.code;
        const opt: RequestInit = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: payload.username,
            password: payload.password,
            newPassword: payload.newPassword,
            ad,
            generateToken,
            code,
          }),
        };
        return fetch("/rest/login", opt)
          .then((resp) => {
            if (!resp.ok) {
              /**
               * Try to convert to JSON
               * - May fail if server did not send response.
               * - That may be the case when database was not avalaible and
               * Login Page Information was no available.
               */
              const contentType = resp.headers.get("content-type");
              if (
                resp.status == 401 &&
                contentType &&
                contentType.indexOf("application/json") !== -1
              ) {
                return resp.json();
              }
              //Database failed or server not available!
              throw new ServerError(resp.status, resp.statusText);
            }
            return resp.json();
          })
          .then((json) => {
            return json;
          });
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.security.sendLoginError({
            error: { code: e.code, message: e.message },
          });
        } else if (typeof e.message == "string") {
          dispatch.security.sendLoginError({
            error: { code: -1, message: e.message },
          });
        } else {
          dispatch.security.sendLoginError({
            error: { code: -1, message: "Unknown error" },
          });
        }
      }
    },
    async login(
      payload: {
        username: string;
        password: string;
        details: LoginRequestDetails;
        newPassword?: string;
      },
      state
    ) {
      try {
        const s = state;
        if (s && s.security.loading) {
          return;
        }
        dispatch.security.sendLoginLoading();
        const loginInfo = await dispatch.security.loginImpl({
          username: payload.username,
          password: payload.password,
          details: payload.details,
          newPassword: payload.newPassword,
        });
        sendLoginInfoToBroadcast(loginInfo);
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.security.sendLoginError({
            error: { code: e.code, message: e.message },
          });
        } else if (typeof e.message == "string") {
          dispatch.security.sendLoginError({
            error: { code: -1, message: e.message },
          });
        } else {
          dispatch.security.sendLoginError({
            error: { code: -1, message: "Unknown error" },
          });
        }
      }
    },
    async logoutImpl() {
      try {
        const opt: RequestInit = {
          method: "DELETE",
        };
        return fetch("/rest/login", opt)
          .then((resp) => {
            if (!resp.ok) {
              throw new ServerError(resp.status, resp.statusText);
            }
            return resp.json();
          })
          .then((json) => {
            return json;
          });
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.security.sendLoginError({
            error: { code: e.code, message: e.message },
          });
        } else if (typeof e.message == "string") {
          dispatch.security.sendLoginError({
            error: { code: -1, message: e.message },
          });
        } else {
          dispatch.security.sendLoginError({
            error: { code: -1, message: "Unknown error" },
          });
        }
      }
    },
    async manualLogout(_: void, state) {
      await dispatch.security.logout();
      setTimeout(() => {
        dispatch.subject.resetToDefault();
        dispatch.tree.resetToDefault();
        dispatch.table.resetToDefault();
        dispatch.selection.resetToDefault();
      }, 1000);
    },
    async logout(_: void, state) {
      try {
        const s = state;
        if (s && s.security.loading) {
          return;
        }
        const loginInfo = await dispatch.security.logoutImpl();
        sendLoginInfoToBroadcast(loginInfo);
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.security.sendLoginError({
            error: { code: e.code, message: e.message },
          });
        } else if (typeof e.message == "string") {
          dispatch.security.sendLoginError({
            error: { code: -1, message: e.message },
          });
        } else {
          dispatch.security.sendLoginError({
            error: { code: -1, message: "Unknown error" },
          });
        }
      }
    },
    async getLoginInfoImpl() {
      try {
        const opt: RequestInit = {
          method: "GET",
        };
        return fetch("/rest/login", opt)
          .then((resp) => {
            if (!resp.ok) {
              throw new ServerError(resp.status, resp.statusText);
            }
            return resp.json();
          })
          .then((json) => {
            return json;
          });
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.security.sendLoginError({
            error: { code: e.code, message: e.message },
          });
        } else if (typeof e.message == "string") {
          dispatch.security.sendLoginError({
            error: { code: -1, message: e.message },
          });
        } else {
          dispatch.security.sendLoginError({
            error: { code: -1, message: "Unknown error" },
          });
        }
      }
    },
    async info(_: void, state) {
      try {
        const s = state;
        if (s && s.security.loading) {
          return;
        }
        dispatch.security.sendLoginLoading();
        dispatch.security.sendLoginInfo(
          await dispatch.security.getLoginInfoImpl()
        );
      } catch (e: any) {
        if (e instanceof ServerError) {
          dispatch.security.sendLoginError({
            error: { code: e.code, message: e.message },
          });
        } else if (typeof e.message == "string") {
          dispatch.security.sendLoginError({
            error: { code: -1, message: e.message },
          });
        } else {
          dispatch.security.sendLoginError({
            error: { code: -1, message: "Unknown error" },
          });
        }
      }
    },
    async getSecurityInfo(_: void, state) {
      const s = state;
      if (s && s.security.loading) {
        return;
      }
      dispatch.security.sendInfoLoading();
      try {
        const securityData = await dispatch.security.fetchSecurityInfoImpl();
        dispatch.security.sendSecurityInfo(securityData);
        const messge: I18NString = { id: "SECURITY_FETCH_INFO_SUCCESS" };
        dispatch.alert.addAlert({ type: ALERT_LEVEL_SUCCESS, message: messge });
      } catch (e) {
        dispatch.security.sendInfoLoading(false);
        dispatchError("SECURITY_FETCH_INFO_ERROR", e, dispatch);
      }
    },
    async fetchSecurityInfoImpl() {
      console.log("fetch secs");
      const resp = await fetch("/rest/secinfo/info", {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      if (!resp.ok) {
        throw new ServerError(resp.status, resp.statusText);
      }
      return await resp.json();
    },
  }),
});
