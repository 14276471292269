import {
  faArrowDown,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { CSSProperties } from "react";
import { ButtonGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import shortid from "shortid";

import { round } from "../../../services/app";
import { obtainData, retrieveFunction } from "../../../services/automation";
import { isCardEdit, isEditable } from "../../../services/layout";
import { makeForm } from "../../../services/subject";
import { Dispatch, RootState } from "../../../store";
import { I18NString, ModalOptions } from "../../../types/modal";
import { isLoggedInUser } from "../../../types/security";
import {
  isSubject,
  Layout,
  LayoutNode,
  NormalizedAutomation,
  SaveStateType,
  Subject,
  SubjectComment,
  SubjectData,
  SubjectTableValue,
} from "../../../types/subject";
import {
  TABLE_BODY_ROW_CLASS,
  TABLE_CLASS,
  TABLE_HEADER_COLUMN_CLASS,
} from "../../admin/VirtualizedTable";
// import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
// import cellFactory from '../cellFactory';
import { MSG_TABLE_NO_DATA_TO_DISPLAY } from "../../messages";
import ThemeCustomDark from "../../theme/ThemeCustomDark";
import cellFactory from "../cellFactory";
import LargeInput from "./LargeInput";
import { ADD_BUTTON_ICON_STYLE, REMOVE_BUTTON_ICON_STYLE } from "./Style";
import styles from "./Table.module.css";
import ToolbarInput from "./ToolbarInput";
//'<span class="order"><span class="dropdown"><span class="dropdown-toggle" style="margin: 10px 0px 10px 5px; color: rgb(204, 204, 204);"></span></span><span class="dropup"><span class="dropdown-toggle" style="margin: 10px 0px; color: rgb(204, 204, 204);"></span></span></span>'

function dateFormatter(cell: string) {
  if (!cell) {
    return "";
  }
  return moment(cell).format("L");
}

function dateTimeFormatter(cell: string) {
  if (!cell) {
    return "";
  }
  let m = moment(cell);
  return m.format("L") + " " + m.format("LTS");
}

function formatSingleFile(file: any, contextPath: string, download: any) {
  let filename = file.$originalName;
  if (typeof filename == "undefined") {
    filename = file.$label;
  }
  if (file.$sha1) {
    //File is already on server

    return (
      <div
        className={styles.DownloadColumn}
        onClick={() => {
          download(file.$sha1);
        }}
      >
        {file.$label}
      </div>
    );
    // <Link to={`${contextPath}rest/file/download/${file.$sha1}`}  download={filename}>
    //     {file.$label}
    // </Link>);
  }
  return file.$label;
}

function fileFormatter(cell: any, contextPath: string, download: any) {
  if (!cell) {
    return ""; //Field may be null
  }
  if (typeof cell === "object") {
    return formatSingleFile(cell, contextPath, download);
  } else if (Array.isArray(cell)) {
    let spanList = [];
    for (let f of cell) {
      //For each file
      let key = f.$sha1;
      if (typeof key == "undefined") {
        key = f.$uploadKey;
      }
      //Add file reference
      spanList.push(
        <span key={key}>{formatSingleFile(f, contextPath, download)}</span>
      );
      //Add separator
      spanList.push(<span key={key + "@separator"}> | </span>);
    }
    if (spanList.length != 0) {
      spanList.pop(); //remove last separator
    }
    return <span>{spanList}</span>;
  }
  return null;
}

function formatSingleReference(ref: any, contextPath: string) {
  return (
    <Link to={`${contextPath}objectcard/${ref.$rdfId}`}>{ref.$label}</Link>
  );
}

function referenceFormatter(cell: any, contextPath: string) {
  if (!cell) {
    return ""; //Field may be null
  }
  if (typeof cell == "object") {
    return formatSingleReference(cell, contextPath);
  } else if (Array.isArray(cell)) {
    let spanList = [];
    for (let ref of cell) {
      //For each file
      let key = ref.$rdfId;
      //Add file reference
      spanList.push(
        <span key={key}>{formatSingleReference(ref, contextPath)}</span>
      );
      //Add separator
      spanList.push(<span key={key + "@separator"}> | </span>);
    }
    if (spanList.length != 0) {
      spanList.pop(); //remove last separator
    }
    return <span>{spanList}</span>;
  }
  return null;
}

/* Change order of columns and remove undefined columns */
export function filterColumns(columns: any[], columnsOrder: any) {
  let sortedColumns = [];
  let originalColumnsOrder = [];
  for (let column of columns) {
    if (!column.predicateId) {
      originalColumnsOrder.push(null);
      continue;
    }
    originalColumnsOrder.push(column.predicateId);
  }
  for (let columnPath of columnsOrder) {
    for (let i = 0; i < originalColumnsOrder.length; ++i) {
      if (columnPath == originalColumnsOrder[i]) {
        sortedColumns.push(Object.assign({}, columns[i]));
        break;
      }
    }
  }
  return sortedColumns;
}

type SortDirection = "asc" | "desc" | "none";
interface SortColumnOptions {
  key: string;
  direction: "asc" | "desc" | "none";
}

interface ObjectTableProps {
  subjectKey: string;
  automation?: NormalizedAutomation;
  nodeId: string;
  subjectData?: SubjectData;
  error?: I18NString | string;
  visible: boolean;
  editable?: boolean;
  cardEditable?: boolean;
  layout?: Layout;
  node?: LayoutNode;
  value: SubjectTableValue | SubjectTableValue[];
  values?: { [K: string]: any };
  locale: string;
  contextPath?: string;
  generalAccessRules?: string[];
  serverLock?: { status: boolean };
  wasTableUpdated?: boolean;
  comment?: SubjectComment;
  download: (sha1: string) => void;
  objTableModal: (
    options: ModalOptions,
    modify: (subject: Subject) => void
  ) => void;
  change: (
    subjectKey: string,
    nodeId: string,
    data: any,
    options?: any
  ) => void;
  remove: (idxList: number[]) => void;
  add: (subjectKey: string, nodeId: string, data: Subject) => void;
  openRemoveModal: (callback: () => void) => void;
  changeAt: (
    subjectKey: string,
    nodeId: string,
    index: number,
    data: any
  ) => void;
}

interface ObjectTableStates {
  selected: { [num: number]: string };
  rows: any[];
  columnsWidths: { [columnName: string]: number };
  sortColumnsOptions: SortColumnOptions | null;
  // it's necessary to know names of two nearest columns  and x coordinate between them
  //thanks to it we know what column(it's name)   has to be expanded and which one has to be narrowed down
  draggedColumns: {
    col1: string;
    col2: string;
    x: number;
  };
}

class ObjectTable extends React.Component<ObjectTableProps, ObjectTableStates> {
  private cachedBy: any;
  private cachedValue: any;
  private rows: any[] = [];
  private headers: any = {};
  private optionsBindedValues: { [K: string]: any } | null = null;
  private nodeOptions: any;
  // private rows: any[] = [];

  constructor(props: ObjectTableProps) {
    super(props);
    this.state = {
      sortColumnsOptions: null,
      selected: {},
      rows: [],
      columnsWidths: {},
      draggedColumns: {
        col1: "",
        col2: "",
        x: 0,
      },
    };
    this.cachedBy = {
      header: {
        node: null,
      },
      rows: {
        node: null,
        value: null,
      },
    };
    this.cachedValue = {
      header: null,
      rows: null,
    };
  }

  componentDidMount() {
    this.initHeaders();
    this.rows = this.initRows(this.props.value);

    if (this.nodeOptions?.["initial-sorting"]) {
      this.changeSort(
        this.nodeOptions["initial-sorting"].key,
        this.nodeOptions["initial-sorting"].direction
      );
    } else {
      this.setState({ rows: this.rows });
    }
  }
  componentDidUpdate(prevProps: ObjectTableProps) {
    if (this.props.subjectKey !== prevProps.subjectKey) {
      this.setState({ selected: {} });
    }
    if (this.props.value !== prevProps.value) {
      this.rows = this.initRows(this.props.value);
      const rows = [...this.rows];
      const { sortColumnsOptions } = this.state;

      if (sortColumnsOptions) {
        const key = sortColumnsOptions.key;
        const format = this.headers?.[key]?.data?.format;
        const sortFunc = this.getSortFunction(
          format,
          sortColumnsOptions.direction
        );
        sortFunc && rows.sort((i1, i2) => sortFunc(i1[key], i2[key]));
      }
      this.setState({ rows });
    }
  }
  onEdit(data: Subject, subjectKey1: string, nodeId: string, index?: number) {
    const { change, changeAt, subjectKey } = this.props;
    if (typeof index !== "undefined") {
      this.props.changeAt(subjectKey, nodeId, index, data);
    } else {
      this.props.change(subjectKey, nodeId, data);
    }
  }

  editItem(data: any, index?: number) {
    let lock = this.props.serverLock;
    const { node } = this.props;

    if (!node) {
      return;
    }
    if (lock) {
      //Copy lock
      lock = Object.assign({}, lock);
      //Install lock
      data = Object.assign({}, data, { $lock: lock });
    }
    const subjectKey =
      typeof data.$rdfId !== "undefined" ? data.$rdfId : data.$tmpKey;
    const nodeId = node.id;
    if (typeof subjectKey === "undefined") {
      return;
    }

    let options: ModalOptions = {
      data: {
        initialData: data,
        isEdit: true,
        destroyOnClose: true,
      },
      size: node.options.modalSize,
    };
    this.props.objTableModal(options, (data: Subject) =>
      this.onEdit(data, subjectKey, node.id, index)
    );
  }
  editClicked() {
    const { node, value } = this.props;
    if (!node) {
      return;
    }
    if (node.multiple && Array.isArray(value)) {
      const idx: number = Number(Object.keys(this.state.selected)[0]) - 1;

      const row = value[idx];
      this.editItem(row, idx);
    } else {
      const row = value;
      this.editItem(row);
    }
  }
  onAdd = (data: Subject) => {
    const { node, subjectKey, add, change } = this.props;
    if (!node) {
      return;
    }

    if (node.multiple) {
      add(subjectKey, node.id, data);
    } else {
      change(subjectKey, node.id, data);
    }
  };

  addClicked() {
    const { node, subjectKey, automation, values, subjectData } = this.props;
    if (!node) {
      return;
    }
    const addAutomation = automation?.addCompound[node.id];
    const form =
      addAutomation && values && subjectData
        ? makeForm(values, subjectData as SubjectData)
        : null;
    const func = addAutomation ? retrieveFunction(addAutomation) : null;
    let options: ModalOptions = {
      data: {
        initialData: {
          $isNew: true,
          $tmpKey: shortid.generate(),
          $class: node.options.cls,
        },
        className: node.options.cls,
        isNew: true,
        nodeId: node.id,
        subjectKey: shortid.generate(),
      },
      size: node.options.modalSize,
    };
    console.log("add compund====", func);
    if (func) {
      const result = func(form);
      if (result && options.data.initialData) {
        options.data.initialData["$ignore"] = result;
      }
    }
    this.props.objTableModal(options, this.onAdd);
  }

  addCheckable() {
    if (this.props.editable && !this.headers["check"]) {
      const headerEntries = Object.entries(this.headers);
      headerEntries.unshift(["check", { key: "check", checkable: true }]);
      this.headers = Object.fromEntries(headerEntries);
    } else if (!this.props.editable && this.headers["check"]) {
      delete this.headers["check"];
    }
  }

  //Fix unkillable dragging
  clearSelection() {
    if (window.getSelection) {
      let selection = window.getSelection();
      if (selection == null) {
        return;
      }
      if (selection.empty) {
        // Chrome
        selection.empty();
      } else if (selection.removeAllRanges) {
        // Firefox
        selection.removeAllRanges();
      }
    }
  }

  //////////////////// END DRAGGABLE COLUMNS/////////////////////////////
  onRemove = () => {
    const { node, subjectKey, value } = this.props;
    if (!node) {
      return;
    }
    if (node.multiple) {
      this.props.remove(
        Object.keys(this.state.selected).map((k) => Number(k) - 1)
      );
    } else {
      this.props.change(subjectKey, node.id, {});
    }
    this.setState({ selected: {} });
    // updComp(true)
  };

  getToolbar() {
    const { openRemoveModal } = this.props;
    const { selected } = this.state;
    const selectedSize = Object.keys(selected).length;
    let removeDisabled = selectedSize == 0;
    let editDisabled = selectedSize > 1 || selectedSize == 0;
    return (
      <ButtonGroup className="pull-right " role="group" aria-label="...">
        <Button
          className="p-1"
          onClick={() => this.addClicked()}
          generalAccessRules={this.props.generalAccessRules}
          acl={this.nodeOptions.addAcl}
          hidden={this.nodeOptions.addHidden}
        >
          <i
            className="fa fa-plus-circle fa-fw"
            style={ADD_BUTTON_ICON_STYLE}
            aria-hidden="true"
          ></i>
          <FormattedMessage
            id="OBJECTCARD_OBJTABLE_ADD_BUTTON"
            defaultMessage="Add"
            description="User should click this button to add items into the table"
          />
        </Button>
        <Button
          className="p-1"
          onClick={() => this.editClicked()}
          disabled={editDisabled}
          generalAccessRules={this.props.generalAccessRules}
          acl={this.nodeOptions.editAcl}
          hidden={this.nodeOptions.editHidden}
        >
          <i
            className="fa fa-pencil fa-fw"
            style={ADD_BUTTON_ICON_STYLE}
            aria-hidden="true"
          ></i>
          <FormattedMessage
            id="OBJECTCARD_OBJTABLE_EDIT_BUTTON"
            defaultMessage="Edit"
            description="User should click this button to edit item in the table"
          />
        </Button>
        <Button
          className="p-1"
          onClick={() => openRemoveModal(this.onRemove)}
          disabled={removeDisabled}
          generalAccessRules={this.props.generalAccessRules}
          acl={this.nodeOptions.deleteAcl}
          hidden={this.nodeOptions.deleteHidden}
        >
          <i
            className="fa fa-minus-circle fa-fw"
            style={REMOVE_BUTTON_ICON_STYLE}
            aria-hidden="true"
          ></i>
          <FormattedMessage
            id="OBJECTCARD_OBJTABLE_REMOVE_BUTTON"
            defaultMessage="Remove"
            description="User should click this button to remove items from table"
          />
        </Button>
      </ButtonGroup>
    );
  }

  getSortFunction = (type: string | null, sortDirection: SortDirection) => {
    if (sortDirection === "none") {
      return null;
    }
    let sortFunction: Function = (item1: string, item2: string) =>
      sortDirection === "asc"
        ? item1?.localeCompare
          ? item1.localeCompare(item2)
          : -1
        : item2?.localeCompare
        ? item2.localeCompare(item1)
        : -1;
    if (type === "int" || type === "float") {
      sortFunction = (item1: number, item2: number) =>
        sortDirection === "asc" ? item1 - item2 : item2 - item1;
    }
    return sortFunction;
  };

  changeSort = (key: string, direction?: "asc" | "desc" | "none") => {
    const { sortColumnsOptions } = this.state;
    let newSortedOptions: SortColumnOptions | null = sortColumnsOptions
      ? { ...sortColumnsOptions }
      : null;
    if (direction) {
      newSortedOptions =
        direction === "asc" || direction === "desc" ? { direction, key } : null;
    } else if (!newSortedOptions) {
      newSortedOptions = { direction: "asc", key };
    } else {
      newSortedOptions.key = key;
      const sortDirection = newSortedOptions.direction;

      if (sortDirection === "none") {
        newSortedOptions.direction = "asc";
      }
      if (sortDirection === "asc") {
        newSortedOptions.direction = "desc";
      }
      if (sortDirection === "desc") {
        newSortedOptions = null;
      }
    }

    let newRows = [...this.rows];
    if (newSortedOptions) {
      const format = this.headers?.[key]?.data?.format;
      const sortFunc = this.getSortFunction(format, newSortedOptions.direction);
      sortFunc && newRows.sort((i1, i2) => sortFunc(i1[key], i2[key]));
    }
    this.setState({ sortColumnsOptions: newSortedOptions, rows: newRows });
  };

  initHeaders() {
    const nodeOptions = this.nodeOptions;
    if (!nodeOptions) {
      return [];
    }

    const thStyle = {
      overflowWrap: "break-word",
      textOverflow: "clip",
      whiteSpace: "normal",
    };
    let headerObj: any = {};
    // if (this.props.editable) {
    //     headerList.push({ key: 'checked', checkable: true })
    // }
    // const sortOptions: SortColumnOptions = {}
    headerObj["key"] = { key: "key", data: { width: 15, hidden: true } };
    if (nodeOptions.addCountColumn) {
      headerObj["num"] = {
        key: "num",
        data: {
          sortable: true,
          width: 15,
          label: { id: "OBJECTCARD_REFTABLE_NUMBER" },
        },
      };
      // sortOptions['num'] = 'none'
    }
    /* Sort columns if order is defined in options */
    let sortedColumns;

    if (nodeOptions["columns-order"]) {
      sortedColumns = filterColumns(
        nodeOptions.columns,
        nodeOptions["columns-order"]
      );
    } else {
      sortedColumns = nodeOptions.columns;
    }

    //Push predicates to the table
    for (let i = 0; i < sortedColumns.length; i++) {
      const col = sortedColumns[i];
      let options: any = cellFactory(
        col.predicate,
        this.props /*pass props as info*/
      );
      let w = col.predicate["table-header-width"] || 100;
      const key = col.id;
      const width = nodeOptions["columns-width"]?.[i] || undefined;
      const data = {
        ...options,
        label: col.label,
        width,
        format: col?.format,
        accuracy: col?.accuracy,
      };

      headerObj[key] = { key, data };
      if (data.sortable) {
        // sortOptions[key] = 'none'
      }
    }
    this.headers = headerObj;
  }

  getRow(num: number, rowData: any) {
    const nodeOptions = this.nodeOptions;
    let row: any = {};
    if (!nodeOptions) {
      return row;
    }
    if (rowData.$id) {
      row.id = rowData.$id;
    }

    row.key =
      typeof rowData.$rdfId === "undefined" ? rowData.$tmpKey : rowData.$rdfId;
    row.num = num;
    // row.data = {}
    for (let col of nodeOptions.columns) {
      let cell = obtainData(col.path, rowData);
      row[col.id] = cell;
    }
    return row;
  }

  initRows(value?: SubjectTableValue | SubjectTableValue[]) {
    const rows: any[] = [];
    if (!value) {
      return rows;
    }

    if (Array.isArray(value)) {
      //many objects

      for (let idx = 0; idx < value.length; idx++) {
        let num = idx + 1;
        rows.push(this.getRow(num, value[idx]));
      }
    } else if (typeof value === "object") {
      //one object
      rows.push(this.getRow(1, value));
    }
    if (typeof rows[0]?.id !== "undefined") {
      rows.sort((r1, r2) => r1.id - r2.id);
    }
    return rows;
  }

  selectHandler = (num: number, id: string) => {
    let newSelected: { [num: number]: string } = { ...this.state.selected };
    if (newSelected[num]) {
      delete newSelected[num];
    } else {
      newSelected[num] = String(id);
    }

    this.setState({ selected: newSelected });
  };
  selectAll = () => {
    const { value } = this.props;
    if (Array.isArray(value)) {
      if (Object.keys(this.state.selected).length !== 0) {
        this.setState({ selected: {} });
        return;
      }
      let newSelected = { ...this.state.selected };
      value.forEach((v: any, idx: number) => {
        if (typeof v.$tmpKey !== "undefined") {
          newSelected[idx + 1] = v.$tmpKey;
        } else if (typeof v.$rdfId !== "undefined") {
          newSelected[idx + 1] = String(v.$rdfId);
        }
      });
      this.setState({ selected: newSelected });
    }
  };

  getInitialColumnsWidths() {
    const colmnsWidths: { [key: string]: number } = {};
    for (const idx in this.headers) {
      const label = this.headers[idx].label;
      const key = this.headers[idx].key;
      const width = this.headers[idx].width || 40;
      colmnsWidths[key] = width;
    }
    return colmnsWidths;
  }
  ////////////////////TABLE//////////////////

  //////////END TABLE//////////////////////

  getNodeOptions() {
    const { layout, values } = this.props;
    if (!this.props.node) {
      return {};
    }
    if (!layout?.automation?.optionsBindings?.[this.props.node.id]) {
      return this.props.node.options || {};
    }
    if (values == this.optionsBindedValues) {
      return this.nodeOptions;
    }
    this.optionsBindedValues = this.props.values || null;
    //Get custom options from bindings
    if (!values) {
      return;
    }
    const funcId = layout?.automation?.optionsBindings?.[this.props.node.id];
    const func = retrieveFunction(funcId);
    const data: SubjectData = (layout as Subject).subjectData;
    const form = makeForm(values, data);
    try {
      const optionsDiff = func(form);
      return Object.assign({}, this.props.node.options, optionsDiff);
    } catch (ex) {
      console.error("Options binding error", this.props.node.id, ex);
    }
    return this.props.node.options || {};
  }

  render() {
    this.nodeOptions = this.getNodeOptions();

    let optional = {};
    const { editable, cardEditable, node, value, visible, comment, error } =
      this.props;
    const { sortColumnsOptions, rows } = this.state;
    if (!node) {
      return null;
    }
    if (editable) {
      //optional.selectRow = {
      //    mode: 'checkbox',
      //    onSelect: this.onSelect,
      //    onSelectAll: this.onSelectAll,
      //    bgColor: '#80BEE6',
      //    clickToSelect: true,
      //    selected: this.state.selected
      //};
    }

    this.addCheckable();
    const tableStye = {
      margin: "0px",
      borderRadius: "0px",
    };

    const containerStyle = {
      overflowX: "auto",
    };

    const options = {
      noDataText: MSG_TABLE_NO_DATA_TO_DISPLAY,
    };
    const table = (
      <TableComponent
        sortOptions={sortColumnsOptions}
        columns={this.headers}
        rows={rows}
        changeSort={this.changeSort}
        selected={this.state.selected}
        select={this.selectHandler}
        selectAll={this.selectAll}
        editable={editable}
      />
    );

    if (editable) {
      return (
        <ToolbarInput
          id={this.props.subjectKey + "." + this.props.nodeId}
          node={node}
          error={error}
          visible={visible}
          editable={editable}
          cardEditable={cardEditable}
          comment={comment}
        >
          {this.getToolbar()}
          {table}
        </ToolbarInput>
      );
    }
    // const contents = this.tableComponent(columns, rows);

    return (
      <LargeInput
        id={this.props.subjectKey + "." + this.props.nodeId}
        node={node}
        error={error}
        visible={visible}
        editable={editable}
        cardEditable={cardEditable}
        comment={comment}
      >
        {table}
      </LargeInput>
    );
  }
}

interface TableComponentProps {
  contextPath?: string;
  sortOptions: SortColumnOptions | null;
  columns: any;
  rows: any[];
  editable?: boolean;
  selected?: { [num: number]: string };
  select: (num: number, id: string) => void;
  selectAll: () => void;
  changeSort: (key: string) => void;
}
class TableComponent extends React.Component<TableComponentProps> {
  constructor(props: TableComponentProps) {
    super(props);
    this.bodyCellRenderer = this.bodyCellRenderer.bind(this);
  }

  noDataRowRenderer() {
    const { columns } = this.props;
    const colspan = Object.values(columns).length;
    return (
      <tr>
        <th colSpan={colspan}>
          <div className="text-center">{MSG_TABLE_NO_DATA_TO_DISPLAY}</div>
        </th>
      </tr>
    );
  }

  isColumnHidden(col: any) {
    return col?.data?.hidden;
  }
  renderSortButton = (key: string) => {
    const { sortOptions, changeSort } = this.props;
    const option = sortOptions;

    let icon = (
      <div className="d-flex flex-column">
        <span style={{ height: "8px" }}>
          <FontAwesomeIcon icon={faCaretUp} />
        </span>
        <span style={{ height: "5px" }}>
          <FontAwesomeIcon icon={faCaretDown} />
        </span>
      </div>
    );
    if (option?.key === key) {
      if (option?.direction === "desc") {
        icon = <FontAwesomeIcon icon={faCaretUp} />;
      } else if (option?.direction === "asc") {
        icon = <FontAwesomeIcon icon={faCaretDown} />;
      }
    }

    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => changeSort(key)}
        className="ml-1"
      >
        {icon}
      </div>
    );
  };
  renderHeaderColumn = (columnData: any) => {
    const { sortOptions } = this.props;
    if (columnData.checkable) {
      const checked =
        this.props.selected &&
        this.props.rows.length > 0 &&
        this.props.rows.length === Object.keys(this.props.selected).length;

      return (
        <div className="pl-1">
          {" "}
          <input
            className={styles.check}
            type="checkbox"
            checked={checked}
            onChange={() => this.props.selectAll()}
          />
        </div>
      );
    }
    if (!columnData?.data) {
      return null;
    }

    const label = columnData.data.label || columnData.key;
    if (!label) {
      return "";
    }
    let labelElement = label;
    if (typeof label !== "string") {
      labelElement = <FormattedMessage id={label.id} />;
    }
    if (columnData.data?.format) {
      labelElement = (
        <div className="d-flex">
          <span>{labelElement}</span>
          {this.renderSortButton(columnData?.key)}
        </div>
      );
    }
    return labelElement;
  };
  headerCellRenderer = (data: any) => {
    const cell = this.renderHeaderColumn(data);
    const width = data.data?.width;

    const style = {
      width: data.checkable ? "10px" : width ? `${width}%` : undefined,
    };
    return (
      <th
        key={data?.key}
        style={style}
        scope="col px-2"
        className={`npt-table-header-column`}
      >
        <div
          style={style}
          className=" w-100 d-flex flex-column justify-content-center"
        >
          {cell}
        </div>
      </th>
    );
  };

  renderHeaderColumns() {
    const { columns, rows } = this.props;
    const headerColumns: any[] = [];
    for (let c of Object.values(columns)) {
      const column = c as any;
      if (!this.isColumnHidden(column)) {
        headerColumns.push(this.headerCellRenderer(column));
      }
    }
    return headerColumns;
  }

  bodyCellRenderer = (key: string, columnData: any, data: any) => {
    const { columns, selected, select, rows } = this.props;
    if (columnData.checkable) {
      const checked = selected && selected[data.num];
      return (
        <td key={key} style={{ width: "10px" }}>
          <div className="pl-1">
            {" "}
            <input
              className={styles.check}
              type="checkbox"
              checked={!!checked}
              onChange={() => {
                const rdfId = data.key;
                select(data.num, rdfId);
              }}
            />
          </div>
        </td>
      );
    }
    let label = data[key];
    if (label && typeof label === "object" && !Object.keys(label).length) {
      label = null;
    }
    const accuracy = columnData?.data?.accuracy;
    if (columnData?.data?.accuracy) {
      label = round(label, accuracy);
    }
    if (!label) {
      return <td key={key}> </td>;
    }

    const dataFormat = columnData?.data?.dataFormat;

    if (dataFormat) {
      label = dataFormat(label, null, this.props.contextPath);
    }
    return (
      <td key={key}>
        <div>{label}</div>
      </td>
    );
  };

  bodyRowRenderer(row: any, index: number) {
    const { columns } = this.props;
    const rowData = [];
    const colEntries = Object.entries(columns);
    for (let c of colEntries) {
      const [key, value] = c;
      const column = value as any;
      const hidden = column?.data?.hidden;
      if (hidden) {
        continue;
      }
      rowData.push(this.bodyCellRenderer(key, value, row));
    }
    return <tr key={index}>{rowData}</tr>;
  }

  isLastHeaderColumn(columnKey: String) {
    let lastElement = this.props.columns.length - 1;
    return this.props.columns[lastElement].key === columnKey;
  }
  isFirstHeaderColumn(columnKey: String) {
    return this.props.columns[0].key === columnKey;
  }

  render() {
    const { rows } = this.props;

    return (
      <div className={`${TABLE_CLASS} ${styles.cardTable}`}>
        <div
          className={`rounded bg-white w-100 npt-objectcard-compound-table ${styles.compoundTable}`}
        >
          <table
            className={`table mb-0 table-bordered table-hover ${styles.table}`}
          >
            <thead className={styles.header}>
              <tr>{this.renderHeaderColumns()}</tr>
            </thead>
            <tbody>
              {rows.length === 0
                ? this.noDataRowRenderer()
                : rows.map((r, idx) => this.bodyRowRenderer(r, idx))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: RootState, ownProps: { subjectKey: string; nodeId: string }) => {
    const { subjectKey, nodeId } = ownProps;
    const subject = state.subject && state.subject.subjects[subjectKey];
    if (!isSubject(subject) || !subject) {
      return { locale: state.locale.language, visible: false };
    }
    const node = subject.nodeById[nodeId];
    const value = subject.values[nodeId];
    const error = subject.validation[nodeId];
    const cardEditable = isCardEdit(subject);
    const editable = isEditable(subject, nodeId, cardEditable);
    const wasTableUpdated =
      subject.componentUpdated && subject.componentUpdated[nodeId];
    const visible = subject.visibility[ownProps.nodeId] ? true : false;
    return {
      layout: subject,
      node,
      subjectData: subject.subjectData,
      automation: subject.automation,
      error,
      value: value || [],
      values: subject.values,
      editable,
      cardEditable,
      locale: state.locale.language,
      serverLock: subject.subjectData.$lock,
      contextPath: state.location.contextPath,
      wasTableUpdated,
      visible,
      generalAccessRules: isLoggedInUser(state.security.loginStatus)
        ? (state.security.loginStatus as any)?.["generalAccessRules"]
        : undefined,
      comment: subject.comment[nodeId],
    };
  },
  (dispatch: Dispatch, ownProps: { subjectKey: string; nodeId: string }) => {
    const { subjectKey, nodeId } = ownProps;
    return {
      add: (subjectKey: string, nodeId: string, data: Subject) =>
        dispatch.subject.add({ subjectKey, nodeId, data }),
      download: (sha1: string) => dispatch.filearchive.downloadFile({ sha1 }),
      objTableModal: (
        options: ModalOptions,
        modify: (subject: Subject) => void
      ) => dispatch.subject.addObjTableModal({ options, modify }),
      change: (subjectKey: string, nodeId: string, data: any, options?: any) =>
        dispatch.subject.change({ subjectKey, nodeId, data, options }),
      remove: (idxList: number[]) =>
        dispatch.subject.remove({ subjectKey, nodeId, indexList: idxList }),
      changeAt: (
        subjectKey: string,
        nodeId: string,
        index: number,
        data: any
      ) => dispatch.subject.changeAt({ subjectKey, nodeId, index, data }),
      openRemoveModal: (callback: () => void) =>
        dispatch.subject.confirmItemRemoveModal(callback),
    };
  }
)(ObjectTable);

interface ButtonProps {
  acl: string;
  generalAccessRules?: any;
  hidden?: boolean;
  className?: string;
  disabled?: boolean;
  onClick: (e: any) => void;
}
/**
 * onClick: ()=>{},
 * style:{},
 * icon: string,
 * disabled: boolean
 */
class Button extends React.PureComponent<ButtonProps> {
  checkAccess() {
    if (!this.props.acl) {
      return true;
    }
    const { generalAccessRules } = this.props;
    if (!generalAccessRules) {
      return false;
    }
    const aclList = this.props.acl.replace(/ /g, "").split(",");
    for (let aclRule of aclList) {
      if (generalAccessRules[aclRule]) {
        return true;
      }
    }
    return false;
  }

  isHidden() {
    return Boolean(this.props.hidden);
  }

  render() {
    const { className } = this.props;
    if (!this.checkAccess() || this.isHidden()) {
      return null;
    }
    return (
      <button
        style={{ zIndex: 0 }}
        type="button"
        className={`btn btn-secondary ${className}`}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        {this.props.children}
      </button>
    );
  }
}
