import * as React from "react";
import { connect } from "react-redux";

import {
  ColumnSortInfo,
  FilterData,
  ModelParameters,
  TableDragViewOptions,
  TableDropViewOptions,
  TableGantOptionsSettings,
  TableRequest,
} from "../../types/table";
import { SelectionInfo } from "../../types/selection";
import {
  FinderData,
  FinderOptionsSettings,
  FinderState,
  ServerFinderFilter,
} from "../../types/finder";

import Table, { PlainTableProps } from "./Table";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch, RootState } from "../../store";
import { DEFAULT_TABLE_STATE } from "../../model/table";
import Gant from "../gant/Gant";

/********************************
 *   External table connector   *
 ********************************/

/** External table that connects fetching with local table */
interface ExternalTableProps extends PlainTableProps {
  finderFilter?: ServerFinderFilter | null;
  finderData?: FinderData;
  autoresize?: boolean;
  /**Flag if table was initialized */
  initialized?: boolean;
  /** force fetch data even if  previous table data is still loading  */
  forceLoadData?: boolean;
  /**Force fetch table header */
  forceLoadHeader?: boolean;
  /**Flag of client-side filtering/sorting */
  clientSideFilter?: boolean;
  /**Table drag view options */
  drag?: TableDragViewOptions;
  /**Table drop view options */
  drop?: TableDropViewOptions;
  /**Flag if finder is ready */
  finderReady?: boolean;
  /** Function that will be called to load table info */
  fetchTable: (
    fields: { [k: string]: string },
    reset: boolean,
    forcedFields?: { [k: string]: string },
    forceLoadData?: boolean,
    forceLoadHeader?: boolean
  ) => void;
  /** Function that will be called to load table data */
  fetchTableData: (fields?: { [k: string]: string }, reset?: boolean) => void;
  /**Fetch available selection values for specified field and filter*/
  fetchColumnSelection: (field: string, filterData: FilterData | null) => void;
  /**Fetch available values range for specified field and filter*/
  fetchColumnRange: (field: string, filterData: FilterData | null) => void;
  /** Save client-side filter flag into store */
  setClientSideFilter: (clientSideFilter: boolean) => void;
  /** Callback function to be called after row select or any other changes of selection */
  onSelectionChange?: (
    selectedRows: { [k: string]: boolean },
    rowsData: any[]
  ) => void;
  /**Function that will be called to dynamically change request filter */
  onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>;
}
class ExternalTableImpl extends React.Component<ExternalTableProps> {
  private loadingTimer: any = null;
  componentDidMount() {
    this.props.setClientSideFilter(Boolean(this.props.clientSideFilter));

    this.props.fetchTable(
      this.props.fields,
      true,
      this.props.forcedFields,
      this.props.forceLoadData,
      this.props.forceLoadHeader
    );
  }

  componentDidUpdate(prevProps: ExternalTableProps) {
    const { loadingData } = this.props;

    if (
      prevProps.finderReady === false &&
      this.props.finderReady === true &&
      this.props.finderFilter
      // &&
      // prevProps.finderFilter != this.props.finderFilter
    ) {
      const _this = this;
      this.loadingTimer = setTimeout(function timout() {
        const { loadingData } = _this.props;
        if (loadingData) {
          _this.loadingTimer = setTimeout(timout, 500);
        } else {
          _this.props.fetchTable(_this.props.fields, false);
          clearTimeout(_this.loadingTimer);
        }
      }, 1000);

      return;
    }
    if (prevProps.loading) {
      return;
    }

    /**Always update data on dynamic columns changing */
    // if (prevProps.dynamicColumns !== this.props.dynamicColumns) {
    //   this.props.fetchTableData(this.props.fields, true);

    //   return;
    // }
    /**Fetch new data if forced field changed */
    if (prevProps.forcedFields !== this.props.forcedFields) {
      const prev = prevProps.forcedFields ? prevProps.forcedFields : {};
      const current = this.props.forcedFields ? this.props.forcedFields : {};

      for (let k in current) {
        if (prev[k] !== current[k]) {
          this.props.fetchTableData(this.props.fields, true);
          return;
        }
      }
      for (let k in prev) {
        if (prev[k] !== current[k]) {
          this.props.fetchTableData(this.props.fields, true);
          return;
        }
      }
    }

    /**Handle filter and sort info changings if client-side handling is setted */
    if (prevProps.clientSideFilter) {
      return;
    }

    if (
      this.props.fields?.object &&
      this.props.fields?.object !== prevProps.fields?.object
    ) {
      this.props.fetchTableData(this.props.fields, true);
    }
    if (
      prevProps.filterInfo.columns !== this.props.filterInfo.columns ||
      prevProps.sortInfo.columns !== this.props.sortInfo.columns
    ) {
      this.props.fetchTableData(this.props.fields, true);
    }
    if (
      prevProps.finderReady === false &&
      this.props.finderReady === true &&
      prevProps.finderData !== this.props.finderData
    ) {
      this.props.fetchTableData(this.props.fields, true);
    }
  }

  render() {
    const { gantData, gantOptions } = this.props;
    if (gantOptions) {
      return (
        <Gant
          autoresize={this.props.autoresize}
          column={this.props.column}
          toolbar={this.props.toolbar}
          footer={this.props.footer}
          body={this.props.body}
          tableId={this.props.tableId}
          forceLoadData={this.props.forceLoadData}
          height={this.props.height}
          scrollable={this.props.scrollable}
          loading={this.props.loading}
          loadingData={
            this.props.loadingData || this.props.finderReady === false
          }
          error={this.props.error}
          errorData={this.props.errorData}
          stylesheets={this.props.stylesheets}
          parameters={this.props.parameters}
          fields={this.props.fields}
          reports={this.props.reports}
          totalRowsLength={this.props.totalRowsLength}
          toolbarHidden={this.props.toolbarHidden}
          toolbarHiddenItems={this.props.toolbarHiddenItems}
          pageable={this.props.pageable}
          page={this.props.page}
          pageSize={this.props.pageSize}
          pageSelection={this.props.pageSelection}
          columns={this.props.columns}
          dynamicColumns={this.props.dynamicColumns}
          dynamicHiddenColumns={this.props.dynamicHiddenColumns}
          pageRows={this.props.pageRows}
          rowByIdx={this.props.rowByIdx}
          selectType={this.props.selectType}
          selectedRows={this.props.selectedRows}
          selectedRowsLength={this.props.selectedRowsLength}
          sortInfo={this.props.sortInfo}
          filterInfo={this.props.filterInfo}
          filterChanges={this.props.filterChanges}
          gantOptions={this.props.gantOptions}
          gantData={this.props.gantData}
          finderOptions={this.props.finderOptions}
          dragOptions={this.props.dragOptions}
          dropOptions={this.props.dropOptions}
          onSelectionChange={this.props.onSelectionChange}
          fetchColumnSelection={this.props.fetchColumnSelection}
          fetchColumnRange={this.props.fetchColumnRange}
          onFetch={this.props.onFetch}
          onSelect={this.props.onSelect}
        />
      );
    }
    return (
      <Table
        onSelect={this.props.onSelect}
        autoresize={this.props.autoresize}
        column={this.props.column}
        toolbar={this.props.toolbar}
        footer={this.props.footer}
        forcedFields={this.props.forcedFields}
        body={this.props.body}
        tableId={this.props.tableId}
        forceLoadData={this.props.forceLoadData}
        height={this.props.height}
        scrollable={this.props.scrollable}
        loading={this.props.loading}
        loadingData={this.props.loadingData || this.props.finderReady === false}
        error={this.props.error}
        errorData={this.props.errorData}
        stylesheets={this.props.stylesheets}
        parameters={this.props.parameters}
        fields={this.props.fields}
        reports={this.props.reports}
        totalRowsLength={this.props.totalRowsLength}
        toolbarHidden={this.props.toolbarHidden}
        toolbarHiddenItems={this.props.toolbarHiddenItems}
        pageable={this.props.pageable}
        page={this.props.page}
        pageSize={this.props.pageSize}
        pageSelection={this.props.pageSelection}
        columns={this.props.columns}
        dynamicColumns={this.props.dynamicColumns}
        dynamicHiddenColumns={this.props.dynamicHiddenColumns}
        pageRows={this.props.pageRows}
        rowByIdx={this.props.rowByIdx}
        selectType={this.props.selectType}
        selectedRows={this.props.selectedRows}
        selectedRowsLength={this.props.selectedRowsLength}
        sortInfo={this.props.sortInfo}
        filterInfo={this.props.filterInfo}
        filterChanges={this.props.filterChanges}
        gantOptions={this.props.gantOptions}
        gantData={this.props.gantData}
        finderOptions={this.props.finderOptions}
        dragOptions={this.props.dragOptions}
        dropOptions={this.props.dropOptions}
        onSelectionChange={this.props.onSelectionChange}
        fetchColumnSelection={this.props.fetchColumnSelection}
        fetchColumnRange={this.props.fetchColumnRange}
        onFetch={this.props.onFetch}
      />
    );
  }
}

function composeFields(
  fields: { [k: string]: string },
  queryFields: { [k: string]: string },
  selectionFields?: SelectionInfo,
  forcedFields?: { [k: string]: string },
  initialFields?: { [k: string]: string },
  specialFields?: { [k: string]: string }
): { [k: string]: string } {
  return Object.assign(
    {},
    initialFields,
    queryFields,
    fields,
    selectionFields,
    forcedFields,
    specialFields
  );
}

const ExternalTable = connect(
  (
    state: RootState,
    ownProps: {
      tableId: string;
      gantViewOptions?: TableGantOptionsSettings;
      finderViewOptions?: FinderOptionsSettings;
      forcedFields?: { [k: string]: string };
      initialFields?: { [k: string]: string };
      initialSorting?: ColumnSortInfo[];
      paramsReadOnly?: boolean;
      resetFields?: boolean;
      toolbar?: any;
    }
  ) => {
    const tableState = state.table[ownProps.tableId];
    const locationParams = ownProps.forcedFields?._ignoreLocationFields
      ? {}
      : state.location.params;
    const selectionParams = ownProps.forcedFields?._ignoreLocationFields
      ? {}
      : state.selection.info;
    const specialFields: { [k: string]: string } = ownProps.paramsReadOnly
      ? { _paramsReadOnly: ownProps.paramsReadOnly.toString() }
      : {};
    if (!tableState) {
      const fields = composeFields(
        {
          ...DEFAULT_TABLE_STATE.fields,
        },
        locationParams,
        selectionParams,
        ownProps.forcedFields,
        ownProps.initialFields,
        specialFields
      );

      return {
        tableId: ownProps.tableId,
        contextPath: "/",
        loading: true,
        loadingData: true,
        error: null,
        errorData: null,
        stylesheets: DEFAULT_TABLE_STATE.stylesheets,
        parameters: DEFAULT_TABLE_STATE.parameters,
        fields: fields,
        fieldsChanges: null,
        reports: DEFAULT_TABLE_STATE.reports,
        toolbar: ownProps.toolbar || DEFAULT_TABLE_STATE.toolbar,
        columns: DEFAULT_TABLE_STATE.columns,
        dynamicColumns: DEFAULT_TABLE_STATE.dynamicColumns,
        dynamicHiddenColumns: DEFAULT_TABLE_STATE.dynamicHiddenColumns,
        pageRows: DEFAULT_TABLE_STATE.pageRows,
        pageable: false,
        rowByIdx: DEFAULT_TABLE_STATE.rowByIdx,
        minWidth: 0,
        selectType: null,
        selectedRows: DEFAULT_TABLE_STATE.selectedRows,
        selectedRowsLength: 0,
        totalRowsLength: 0,
        sortInfo: DEFAULT_TABLE_STATE.sortInfo,
        filterInfo: DEFAULT_TABLE_STATE.filterInfo,
        filterChanges: null,
        gantOptions: null,
        gantData: DEFAULT_TABLE_STATE.gantData,
        finderOptions: null,
        searchString: null,
        dragOptions: null,
        dropOptions: null,
      };
    }
    const finderState = state.finder[ownProps.tableId];
    const tableFields = ownProps.resetFields
      ? DEFAULT_TABLE_STATE.fields
      : state.table[ownProps.tableId].fields;
    const fields = composeFields(
      {
        ...tableFields,
      },
      locationParams,
      selectionParams,
      ownProps.forcedFields,
      ownProps.initialFields,
      specialFields
    );

    return {
      contextPath: state.location.contextPath,
      ...tableState,
      toolbar: ownProps.toolbar || tableState.toolbar,
      fields,
      finderFilter: finderState?.initialFilter,
      fieldsChanges: tableState.filterChanges?.fields || null,
      finderOptions: finderState?.options || null,
      finderData: finderState?.data || null,
      finderReady: finderState?.isReady,
      tableId: ownProps.tableId,
    };
  },
  (
    dispatch: Dispatch,
    ownProps: {
      tableId: string;
      initialSorting?: ColumnSortInfo[];
      gantViewOptions?: TableGantOptionsSettings;
      finderViewOptions?: FinderOptionsSettings;
      drag?: TableDragViewOptions;
      drop?: TableDropViewOptions;
      paramsReadOnly?: boolean;
      resetFields?: boolean;
      modelParameters?: ModelParameters;
      onFetch?: (request: TableRequest) => TableRequest | Promise<TableRequest>;
    }
  ) => {
    return {
      fetchTable: (
        fields: { [k: string]: string } = {},
        reset: boolean,
        forcedFields?: { [k: string]: string },
        forceLoadData?: boolean,
        forceLoadHeader?: boolean
      ) => {
        dispatch.table.fetchTable({
          tableId: ownProps.tableId,
          fields,
          reset,
          // reset: false,
          initOptions: {
            ...ownProps,
            forcedFields,
          },
          forceLoadData,
          forceLoadHeader,
          modelParameters: ownProps.modelParameters,
        });
      },
      fetchTableData: (
        fields: { [k: string]: string } = {},
        reset: boolean = false
      ) => {
        dispatch.table.fetchTableData({
          tableId: ownProps.tableId,
          options: {
            fields,
            finder: null,
            modelParameters: ownProps.modelParameters,
            reset,
            onFetch: ownProps.onFetch,
          },
        });
      },
      fetchColumnSelection: (field: string, filterData: FilterData | null) => {
        dispatch.table.fetchTableFilterSelection({
          tableId: ownProps.tableId,
          columnField: field,
          filterData,
          onFetch: ownProps.onFetch,
        });
      },
      fetchColumnRange: (field: string, filterData: FilterData | null) => {
        dispatch.table.fetchTableFilterRange({
          tableId: ownProps.tableId,
          columnField: field,
          filterData,
          onFetch: ownProps.onFetch,
        });
      },
      setClientSideFilter: (clientSideFilter: boolean) => {
        dispatch.table.sendClientSideFilter({
          tableId: ownProps.tableId,
          clientSideFilter,
        });
      },
    };
  }
)(ExternalTableImpl);

export default ExternalTable;
