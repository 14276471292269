import { createModel } from "@rematch/core";
import shortid from "shortid";
import { RootModel } from ".";
import { ServerError } from "../actions/utils";
import { dispatchError, dispatchSuccess } from "../services/alert";
import { cutOffModel } from "../services/app";
import { copyTextToClipboard } from "../services/clipboard";
import { getSearchData } from "../services/location";
import { composeZipUrl } from "../services/npt-treebeard";
import { addSubjectFire, downloadFile } from "../services/subject";
import {
  expandTreeWithEditingNodes,
  fetchAddChildImpl,
  fetchDecendantsRefsImpl,
  fetchDeleteNodeImpl,
  fetchPasteChildImpl,
  fetchPathToNodeImpl,
  fetchTreeHeaderImpl,
  fetchTreeNodesImpl,
  getFetchIdByNodeId,
  getNodeByRdfId,
  getNodePathByRdfId,
  getParentId,
  getParentNodes,
  isFetchTreeNodesNeeded,
  parseTreeAutomation,
  parseTreeNodes,
  sortTreeNodes,
  updateTreeState,
} from "../services/tree";
import { FetchError, isFetchError } from "../types/error";

import { I18NString, ModalOptions } from "../types/modal";
import { isLoginStatus } from "../types/security";
import { SubjectData } from "../types/subject";
import {
  isTreeNode,
  ModelParameters,
  ParentNode,
  TreeHeader,
  TreeNode,
  TreeReducerState,
  TreeResponseData,
  TreeState,
} from "../types/tree";

export const initialTreeState: TreeState = {
  treeId: "",
  rootNodesIds: [],
  nodeById: {},
  children: {},
  toggled: {},
  active: null,
  automation: {
    sortBindings: null,
    actionBindings: null,
    decoratorBindings: null,
    reverseDecoratorBindings: null,
  },
};

export const initialState: TreeReducerState = { treeInfo: {} };

export const tree = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendContextMenuInfo(state, payload: { treeId: string; nodeId: string }) {
      const { nodeId, treeId } = payload;
      const tree: {
        [treeId: string]: TreeState;
      } = { ...state.treeInfo };
      tree[treeId].contextMenuInfo = payload;
      return { ...state, treeInfo: tree };
    },
    sendExpandTreeWithEditingSubjects(
      state,
      payload: {
        nodes: string[];
        treeId: string;
      }
    ) {
      return expandTreeWithEditingNodes(state, payload.treeId, payload.nodes);
    },
    sendTreeHeader(
      state,
      payload: {
        path: string;
        header: TreeHeader;
        modelParameters?: ModelParameters;
      }
    ) {
      const { path, header, modelParameters } = payload;
      const nextTree = { ...(state.treeInfo[path] || initialTreeState) };
      nextTree.treeId = cutOffModel(path);
      nextTree.header = header;
      const modaSize: any = nextTree.header.modalSize;
      if (modaSize) {
        const modaSize: any = nextTree.header.modalSize;
        nextTree.header.modalSize = modaSize.toLowerCase();
      }
      nextTree.automation = parseTreeAutomation(header.automation);
      nextTree.modelParameters = modelParameters;
      const nextTreeObj = { ...state.treeInfo, [path]: nextTree };
      return { ...state, treeInfo: nextTreeObj };
    },
    sendTreeNodes(
      state,
      payload: {
        path: string;
        nodes: TreeNode[] | FetchError;
        parentId?: string;
      }
    ) {
      const { nodes, path, parentId } = payload;
      const treeState = state.treeInfo?.[path];
      const parentNode = parentId ? treeState?.nodeById?.[parentId] : undefined;
      const sortedNodes = isFetchError(nodes)
        ? nodes
        : sortTreeNodes(nodes, parentNode, treeState?.automation);
      return {
        ...state,
        ...updateTreeState(state, sortedNodes, path, parentId),
      };
    },
    sendTreeLoading(
      state,
      payload: { path: string; id: string; loading?: boolean }
    ) {
      const { path, id, loading: l } = payload;
      const loading = l || true;
      const nextTree: TreeState = {
        ...(state.treeInfo[path] || initialTreeState),
      };
      if (!nextTree.loading) {
        nextTree.loading = {};
      } else {
        nextTree.loading = { ...nextTree.loading };
      }
      nextTree.loading[id] = loading;
      const nextTreeObj = { ...state.treeInfo, [path]: nextTree };
      return { ...state, treeInfo: nextTreeObj };
    },
    sendTreeNodeActive(state, payload: { path: string; nodeId: string }) {
      const { path, nodeId } = payload;
      const nextTree = { ...(state.treeInfo[path] || initialTreeState) };
      const node = nextTree?.nodeById?.[nodeId];
      if (nextTree.active && nextTree.active === nodeId) {
        /** TODO: handle deselect with ctrl */
        // nextTree.active = null
      } else {
        nextTree.active = nodeId;
        nextTree.valid = { id: nodeId, isValid: isTreeNode(node) };
      }
      const nextTreeObj = { ...state.treeInfo, [path]: nextTree };
      return { ...state, treeInfo: nextTreeObj };
    },
    sendTreeActiveNodeValid(
      state,
      payload: { path: string; isValid: boolean; id: string }
    ) {
      const { isValid, path, id } = payload;
      const nextTree: TreeState = {
        ...(state.treeInfo[path] || initialTreeState),
      };

      if (!nextTree.active) {
        return state;
      }
      nextTree.valid = { id, isValid };

      const nextTreeObj = { ...state.treeInfo, [path]: nextTree };
      return { ...state, treeInfo: nextTreeObj };
    },
    sendTreeNodeToggle(
      state,
      payload: { path: string; nodeId: string; expanded: boolean }
    ) {
      const { path, nodeId, expanded } = payload;
      const nextTree: TreeState = {
        ...(state.treeInfo[path] || initialTreeState),
      };
      if (!nextTree.toggled) {
        nextTree.toggled = {};
      } else {
        nextTree.toggled = { ...nextTree.toggled };
      }
      nextTree.toggled[nodeId] = expanded;
      const nextTreeObj = { ...state.treeInfo, [path]: nextTree };
      return { ...state, treeInfo: nextTreeObj };
    },
    sendTreeCompress(state, path: string) {
      const nextTree = { ...(state.treeInfo[path] || initialTreeState) };
      nextTree.toggled = {};
      const nextTreeObj = { ...state.treeInfo, [path]: nextTree };
      return { ...state, treeInfo: nextTreeObj };
    },
    sendTreeNodesForceUpdate(
      state,
      payload: { path: string; nodesIdList: (string | null)[] }
    ) {
      const { path, nodesIdList } = payload;
      const nextTree: TreeState = {
        ...(state.treeInfo[path] || initialTreeState),
      };
      let updateNodes = nextTree.updateNodes
        ? nextTree.updateNodes.concat(nodesIdList)
        : nodesIdList;
      /**Remove duplicates */
      updateNodes = updateNodes.filter(function (item, pos) {
        return updateNodes.indexOf(item) == pos;
      });
      nextTree.updateNodes = updateNodes;
      const nextTreeObj = { ...state.treeInfo, [path]: nextTree };
      return { ...state, treeInfo: nextTreeObj };
    },
    sendTreeFilterChange(
      state,
      payload: { path: string; value: string; checked: boolean }
    ) {
      const { path, value, checked } = payload;
      const nextTree: TreeState = {
        ...(state.treeInfo[path] || initialTreeState),
      };
      if (nextTree.header && nextTree.header.filter) {
        nextTree.header = { ...nextTree.header };
        if (nextTree.header.filter) {
          nextTree.header.filter = { ...nextTree.header.filter };
          nextTree.header.filter.options =
            nextTree.header.filter.options.slice();
          const optionIdx = nextTree.header.filter.options.findIndex(
            (option) => option.value === value
          );
          nextTree.header.filter.options[optionIdx] = {
            ...nextTree.header.filter.options[optionIdx],
            checked,
          };
        }
      }

      const nextTreeObj: {
        [treeId: string]: TreeState;
      } = { ...state.treeInfo, [path]: nextTree };
      return { ...state, treeInfo: nextTreeObj };
    },
    resetToDefault(state) {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    expandTreeNode: async (
      data: { treeId: string; nodeId: string; rdfId?: string },
      state
    ) => {
      const { treeId, nodeId, rdfId } = data;
      dispatch.tree.sendTreeNodeToggle({
        path: treeId,
        nodeId,
        expanded: true,
      });

      if (!isFetchTreeNodesNeeded(state.tree, treeId, nodeId)) {
        return;
      }
      const tree = state.tree?.treeInfo?.[treeId];
      try {
        dispatch.tree.sendTreeLoading({ path: treeId, id: nodeId });
        let treeHeader = tree?.header;
        if (!treeHeader) {
          treeHeader = await fetchTreeHeaderImpl(treeId);
          dispatch.tree.sendTreeHeader({ path: treeId, header: treeHeader });
          /**TODO: find type id by type name */
        }

        const parentNodes = getParentNodes([nodeId]) || [{}];
        const treeData = await fetchTreeNodesImpl({
          path: tree.treeId,
          parents: parentNodes,
          parentId: getFetchIdByNodeId(treeHeader, nodeId),
          modelParameters: tree.modelParameters,
        });

        const nodes = Array.isArray(treeData)
          ? parseTreeNodes(treeHeader, treeData[0])
          : parseTreeNodes(treeHeader, treeData);
        if (rdfId) {
          const activeNode: TreeNode | null = getNodeByRdfId(rdfId, nodes);
          activeNode &&
            dispatch.tree.sendTreeNodeActive({
              path: treeId,
              nodeId: activeNode.id,
            });
        }
        dispatch.tree.sendTreeNodes({
          path: treeId,
          nodes,
          parentId: nodeId,
        });
      } catch (e) {
        let fetchError: FetchError = dispatchError(
          "SUBJECT_TREE_FETCH_ERROR",
          e,
          dispatch
        );
        dispatch.tree.sendTreeNodes({
          path: treeId,
          nodes: fetchError,
          parentId: nodeId,
        });
      }
    },
    expandTreeWithEditingNodes(treeId: string, state) {
      const subjects = state.subject;
      const tree = state.tree.treeInfo[treeId];
      if (subjects && subjects.editingSubjects) {
        const editingSubjects: string[] = [];
        for (const e of Object.entries(subjects.editingSubjects)) {
          const [rdfId, v] = e;
          let nodeId = null;
          if (v && tree?.nodeById) {
            for (const n of Object.values(tree?.nodeById)) {
              if (isTreeNode(n) && n.data && n.data.$rdfId === rdfId) {
                nodeId = n.id;
                editingSubjects.push(nodeId);
                break;
              }
            }
          }
        }
        dispatch.tree.sendExpandTreeWithEditingSubjects({
          treeId,
          nodes: editingSubjects,
        });
      }
    },
    fetchTreeHeader: async (
      data: { path: string; parentNodeId?: string },
      s
    ) => {
      const state = s.tree;
      const { path, parentNodeId } = data;
      const id = path;
      const tree = state?.treeInfo?.[path];
      const loading = tree && tree.loading;
      if (loading && loading[id]) {
        return;
      }

      try {
        let treeHeader = tree && tree.header;
        if (treeHeader) {
          dispatch.tree.sendTreeLoading({ path, id, loading: false });
          return;
        }
        dispatch.tree.sendTreeHeader({
          path,
          header: await fetchTreeHeaderImpl(tree?.treeId || path),
        });
      } catch (e) {
        dispatch.tree.sendTreeLoading({ path, id, loading: false });
        dispatchError("SUBJECT_TREE_FETCH_HEADER_ERROR", e, dispatch);
      }
    },
    fetchTreeNodes: async (
      data: {
        path: string;
        parentNodeId?: string;
        modelParameters?: ModelParameters;
      },
      s
    ) => {
      const state = s.tree;
      const { parentNodeId, path, modelParameters } = data;
      const id = parentNodeId || path;
      const tree = state?.treeInfo?.[path];
      const loading = tree && tree.loading;
      if (loading && loading[id]) {
        return;
      }

      try {
        if (!state || isFetchTreeNodesNeeded(state, path, parentNodeId)) {
          dispatch.tree.sendTreeLoading({ path, id });
          let treeHeader = tree && tree.header;
          if (!treeHeader) {
            treeHeader = await fetchTreeHeaderImpl(cutOffModel(path));
            dispatch.tree.sendTreeHeader({
              path,
              header: treeHeader,
              modelParameters,
            });
          }

          const parentNodes = (parentNodeId &&
            getParentNodes([parentNodeId])) || [{}];
          const treeData = await fetchTreeNodesImpl({
            path: cutOffModel(path),
            parents: parentNodes,
            parentId: getFetchIdByNodeId(treeHeader, parentNodeId),
            modelParameters: modelParameters || tree?.modelParameters,
          });

          const nodes = Array.isArray(treeData)
            ? parseTreeNodes(treeHeader, treeData[0])
            : parseTreeNodes(treeHeader, treeData);
          dispatch.tree.sendTreeNodes({
            path,
            nodes,
            parentId: parentNodeId,
          });
        }
      } catch (e) {
        const id = parentNodeId || path;
        dispatch.tree.sendTreeLoading({ path, id, loading: false });
        dispatchError("SUBJECT_TREE_FETCH_ERROR", e, dispatch);
        let fetchError: FetchError = { code: -1, message: "unknown error" };
        if (e instanceof ServerError) {
          fetchError = { code: e.code, message: e.message };
        }
        dispatch.tree.sendTreeNodes({
          path,
          nodes: fetchError,
          parentId: parentNodeId,
        });
        // dispatch(sendTreeNodes(path,fetchError));
      }
    },
    reloadTree: async (
      data: {
        path: string;
        parentNodeId?: string;
        modelParameters?: ModelParameters;
      },
      s
    ) => {
      const state = s.tree;
      const { parentNodeId, path, modelParameters } = data;
      const id = parentNodeId || path;
      const tree = state?.treeInfo?.[path];
      const loading = tree && tree.loading;
      if (loading && loading[id]) {
        return;
      }

      try {
        let treeHeader = tree && tree.header;
        if (state && treeHeader) {
          // dispatch.tree.sendTreeLoading({ path, id });

          let parentNodes: ParentNode[] = [{}];
          if (parentNodeId) {
            parentNodes = getParentNodes([parentNodeId]) || [{}];
          } else {
            let ids = [];
            if (tree.toggled) {
              for (let key in tree.toggled) {
                // if (tree.toggled[key]) {
                ids.push(key);
                // }
              }
            }
            parentNodes = getParentNodes(ids) || [{}];
          }
          // for (let node of parentNodes) {
          //   node.object &&
          //     dispatch.tree.sendTreeLoading({ path, id: node.object });
          // }
          const treeData: TreeResponseData | TreeResponseData[] =
            await fetchTreeNodesImpl({
              path: cutOffModel(path),
              parents: parentNodes,
              parentId: getFetchIdByNodeId(treeHeader, parentNodeId),
              modelParameters: modelParameters || tree?.modelParameters,
            });

          if (Array.isArray(treeData)) {
            for (let data of treeData) {
              const parentId = getParentId(data);

              const nodes = parseTreeNodes(treeHeader, data);
              dispatch.tree.sendTreeNodes({
                path,
                nodes,
                parentId,
              });
            }
          } else {
            const nodes = parseTreeNodes(treeHeader, treeData);
            const parentId = getParentId(treeData);
            dispatch.tree.sendTreeNodes({
              path,
              nodes,
              parentId,
            });
          }
        }
      } catch (e) {
        const id = parentNodeId || path;
        dispatch.tree.sendTreeLoading({ path, id, loading: false });
        dispatchError("SUBJECT_TREE_FETCH_ERROR", e, dispatch);
        let fetchError: FetchError = { code: -1, message: "unknown error" };
        if (e instanceof ServerError) {
          fetchError = { code: e.code, message: e.message };
        }
        dispatch.tree.sendTreeNodes({
          path,
          nodes: fetchError,
          parentId: parentNodeId,
        });
        // dispatch(sendTreeNodes(path,fetchError));
      }
    },
    expandTreeUsingPath: async (
      data: {
        treeId: string;
        rdfId: string;
        rootNodeRdfId?: string;
        changeSelection?: boolean;
        modelParameters?: ModelParameters;
      },
      s
    ) => {
      const { treeId, rdfId, rootNodeRdfId, changeSelection, modelParameters } =
        data;
      try {
        const treeState = s.tree;
        const tree = treeState?.treeInfo[treeId];
        if (
          !tree ||
          (tree.nodeIdByRdfId?.[rdfId] &&
            tree.nodeIdByRdfId?.[rdfId] === tree.active)
        ) {
          return;
        }
        let nodePath: string[] =
          getNodePathByRdfId(tree, rdfId) ||
          (await fetchPathToNodeImpl(tree.treeId, rdfId, tree.modelParameters));
        /**Reduce node path to specified node to prevent additional loadings */
        if (rootNodeRdfId) {
          let pathToRoot: string[] | null =
            rootNodeRdfId === rdfId
              ? nodePath
              : getNodePathByRdfId(tree, rootNodeRdfId);
          if (!pathToRoot) {
            pathToRoot = await fetchPathToNodeImpl(
              tree.treeId,
              rootNodeRdfId,
              modelParameters
            );
          }
          if (pathToRoot.length !== 0) {
            const rootId = pathToRoot[pathToRoot.length - 1];
            const rootParentNodeId =
              pathToRoot.length === 1
                ? null
                : pathToRoot[pathToRoot.length - 2];
            const indexInPath = nodePath.indexOf(rootId);
            /**If selected node is not part of filtered tree, then we need to load only tree roop parent */
            if (indexInPath === -1) {
              // dispatchError("NAVTREE_FETCH_TREE_PATH_ERROR", new Error("Selected node isn't part of filtered tree"), dispatch);
              nodePath = rootParentNodeId ? [rootParentNodeId] : [];
            } else if (rootParentNodeId) {
              /**If parent node is not null, then node path should be sliced up to parent node id */
              nodePath = nodePath.slice(nodePath.indexOf(rootParentNodeId));
            }
          } else {
            console.error(
              `Can't set root node, object with rdfId "${rootNodeRdfId}" not exist in tree "${treeId}"`
            );
          }
        }
        for (let index = 0; index < nodePath.length; index++) {
          const nodeId = nodePath[index];
          try {
            if (!tree.children || !tree.children[nodeId]) {
              dispatch.tree.sendTreeLoading({ path: treeId, id: nodeId });
              let treeHeader = tree && tree.header;
              if (!treeHeader) {
                treeHeader = await fetchTreeHeaderImpl(treeId);
                dispatch.tree.sendTreeHeader({
                  path: treeId,
                  header: treeHeader,
                });
                /**TODO: find type id by type name */
              }
              let parentNodes: ParentNode[] = [{}];
              if (nodeId && tree.nodeById) {
                parentNodes = getParentNodes([nodeId]) || [{}];
              }
              const treeData = await fetchTreeNodesImpl({
                path: tree.treeId,
                parentId: nodeId,
                parents: parentNodes,
                modelParameters,
              });
              if (!Array.isArray(treeData)) {
                const nodes = parseTreeNodes(treeHeader, treeData);
                dispatch.tree.sendTreeNodes({
                  path: treeId,
                  nodes,
                  parentId: nodeId,
                });
              }
            }
            if (index !== nodePath.length - 1) {
              if (!tree.toggled || !tree.toggled[nodeId]) {
                dispatch.tree.sendTreeNodeToggle({
                  path: treeId,
                  nodeId,
                  expanded: true,
                });
              }
              continue;
            }
            if (index === nodePath.length - 1 && tree.active !== nodeId) {
              dispatch.tree.sendTreeNodeActive({ path: treeId, nodeId });
            }
          } catch (e) {
            let fetchError: FetchError = dispatchError(
              "SUBJECT_TREE_FETCH_ERROR",
              e,
              dispatch
            );
            dispatch.tree.sendTreeNodes({
              path: treeId,
              nodes: fetchError,
              parentId: nodeId,
            });
          }
        }
      } catch (e) {
        // dispatchError("NAVTREE_FETCH_TREE_PATH_ERROR", 'fail to expand tree', dispatch);
      }
    },
    fetchDeleteNode: async (
      data: {
        treeId: string;
        nodeId: string;
        isSelectedNode: boolean;
        deleteLockIgnore: boolean;
      },
      s
    ) => {
      try {
        const { treeId, nodeId, isSelectedNode, deleteLockIgnore } = data;
        const parentId =
          s.tree.treeInfo[treeId]?.nodeById?.[nodeId]?.parentId || undefined;
        const deletedCount = await fetchDeleteNodeImpl(
          treeId,
          nodeId,
          isSelectedNode,
          deleteLockIgnore
        );
        dispatchSuccess("SUBJECT_TREE_DELETE_SUCCESS", dispatch, {
          count: deletedCount,
        });
        if (isSelectedNode) {
          dispatch.selection.sendSelection({ object: "", type: "" });
        }
        dispatch.tree.fetchTreeNodes({ path: treeId, parentNodeId: parentId });
      } catch (e) {
        let fetchError: FetchError = dispatchError(
          "SUBJECT_TREE_DELETE_ERROR",
          e,
          dispatch
        );
        // dispatch(sendTreeNodes(path, fetchError, parentNodeId));
      }
    },
    //TODO: implement confirm delete
    confirmDeleteTreeNode(data: { node: TreeNode; treeId: string }, s) {
      const { node, treeId } = data;
      const options = {
        title: { id: "MSG_CONFIRM_ACTION" },
        body: { id: "NAVTREE_DELETE_CONFIRM", values: { node: node.label } },
        type: "checkbox",
        options: [
          { value: "deleteLock", label: { id: "NAVTREE_IGNORE_LOCK" } },
        ],
      };
      const loginStatus = s.security.loginStatus;
      let isSuperUser = isLoginStatus(loginStatus) && loginStatus.superUser;
      dispatch.modal.openModal({
        id: "tree.confirmDelete",
        type: isSuperUser ? "quiz" : "confirm",
        options,
        okCallback: async (result) => {
          const deleteLockIgnore = isSuperUser && result !== null;
          let isSelectedNode = false;
          if (node.data) {
            const searchData = getSearchData();
            if (
              node.data.$namespace == searchData["namespace"] &&
              node.data.$rdfId == searchData["object"]
            ) {
              isSelectedNode = true;
            }
          }
          dispatch.tree.fetchDeleteNode({
            treeId,
            nodeId: node.id,
            isSelectedNode,
            deleteLockIgnore,
          });
        },
      });
    },
    addChild(payload: { data: SubjectData; nodeId: string }) {
      const { data, nodeId } = payload;
      addSubjectFire(data, nodeId);
      dispatch.subject.sendNewSubject({
        rdfId: data.$rdfId || data.$class,
        isNew: true,
      });
    },
    fetchAddChild: async (
      data: {
        treeId: string;
        nodeId: string;
        addActionId: string;
        prototype?: any;
      },
      s
    ) => {
      const { addActionId, nodeId, treeId, prototype } = data;
      try {
        const data: SubjectData = await fetchAddChildImpl(
          treeId,
          nodeId,
          addActionId,
          { prototype }
        );
        dispatch.tree.addChild({ data, nodeId });
      } catch (e) {
        let fetchError: FetchError = dispatchError(
          "SUBJECT_TREE_FETCH_ADD_ERROR",
          e,
          dispatch
        );
        // dispatch(sendTreeNodes(path, fetchError, parentNodeId));
      }
    },
    fetchPasteChild: async (
      data: {
        treeId: string;
        targetId: string;
        sourceId: string;
        copy: boolean;
        lockIgnore: boolean;
      },
      s
    ) => {
      const { treeId, targetId, sourceId, copy, lockIgnore } = data;
      const treeState = s.tree.treeInfo[treeId];
      const sourceNode = treeState?.nodeById?.[sourceId];
      const sourceParentId = sourceNode?.parentId || undefined;
      try {
        const result = await fetchPasteChildImpl(
          treeId,
          targetId,
          sourceId,
          copy,
          lockIgnore
        );
        dispatch.tree.fetchTreeNodes({ path: treeId, parentNodeId: targetId });
        if (copy) {
          dispatchSuccess("SUBJECT_TREE_COPY_SUCCESS", dispatch);
          dispatch.tree.expandTreeUsingPath({
            treeId,
            rdfId: result?.subject?.$rdfId,
          });
          dispatch.selection.sendSelection({
            object: result?.subject?.$rdfId,
            type: s.selection.info?.type || "",
          });
          return;
        }
        dispatchSuccess("SUBJECT_TREE_MOVE_SUCCESS", dispatch);
        dispatch.tree.fetchTreeNodes({
          path: treeId,
          parentNodeId: sourceParentId,
        });
        return;
      } catch (e) {
        dispatchError(
          copy ? "SUBJECT_TREE_COPY_ERROR" : "SUBJECT_TREE_MOVE_ERROR",
          e,
          dispatch
        );
      }
    },
    //TODO implement modal
    confirmPasteTreeChild(
      data: {
        treeId: string;
        targetId: string;
        sourceId: string;
        copy: boolean;
      },
      s
    ) {
      const { treeId, targetId, sourceId, copy } = data;
      const treeState = s.tree.treeInfo[treeId];
      const sourceNode = treeState?.nodeById?.[sourceId];
      const targetNode = treeState?.nodeById?.[targetId];
      const sourceParentId = sourceNode?.parentId || undefined;
      const options: ModalOptions = {
        title: { id: "MSG_CONFIRM_ACTION" },
        type: "checkbox",
        options: [{ value: "lock", label: { id: "NAVTREE_IGNORE_LOCK" } }],
      };
      const loginStatus = s.security.loginStatus;
      let isSuperUser = isLoginStatus(loginStatus) && loginStatus.superUser;
      if (!isSuperUser) {
        if (copy && sourceNode?.copyLock) {
          dispatch.modal.openModal({
            id: "tree.confirmPaste",
            type: "alert",
            options: {
              body: {
                id: "NAVTREE_COPY_LOCKED",
                values: { node: sourceNode?.label },
              },
            },
          });

          return;
        }
        if (!copy && sourceNode?.moveLock) {
          dispatch.modal.openModal({
            id: "tree.confirmPaste",
            type: "alert",
            options: {
              body: {
                id: "NAVTREE_MOVE_LOCKED",
                values: { node: sourceNode?.label },
              },
            },
          });

          return;
        }
      }
      if (copy) {
        if (targetId === sourceParentId) {
          options.body = {
            id: "NAVTREE_DUPLICATE_CONFIRM",
            values: { node: sourceNode?.label },
          };
        } else {
          options.body = {
            id: "NAVTREE_COPY_CONFIRM",
            values: { target: targetNode?.label, source: sourceNode?.label },
          };
        }
      } else {
        options.body = {
          id: "NAVTREE_MOVE_CONFIRM",
          values: { target: targetNode?.label, source: sourceNode?.label },
        };
      }
      dispatch.modal.openModal({
        id: "tree.confirmPaste",
        type: isSuperUser ? "quiz" : "confirm",
        options,
        okCallback: async (result) => {
          const lockIgnore = isSuperUser && result !== null;
          dispatch.tree.fetchPasteChild({
            treeId,
            targetId,
            sourceId,
            copy,
            lockIgnore,
          });
        },
      });
    },
    copyNodeRef(node: TreeNode) {
      if (!node.data) {
        return;
      }
      const ref = JSON.stringify([
        { $rdfId: node.data.$rdfId, $namespace: node.data.$namespace || null },
      ]);

      if (copyTextToClipboard(ref)) {
        dispatchSuccess("NAVTREE_REF_COPY_SUCCESS", dispatch);
      } else {
        dispatchError(
          "NAVTREE_REF_COPY_ERROR",
          "fail to copy node reference",
          dispatch
        );
      }
    },
    copyNodeWithDescendantsRefs: async (
      data: { treeId: string; nodeId: string },
      s
    ) => {
      const { treeId, nodeId } = data;
      const refs = await fetchDecendantsRefsImpl(treeId, nodeId);

      if (copyTextToClipboard(JSON.stringify(refs))) {
        dispatchSuccess("NAVTREE_REF_COPY_SUCCESS", dispatch);
      } else {
        dispatchError(
          "NAVTREE_REF_COPY_ERROR",
          "fail to copy to clip board",
          dispatch
        );
      }
    },

    confirmDownloadNodeZip(data: { treeId: string; nodeId: string }, s) {
      const { treeId, nodeId } = data;
      const treeState = s.tree.treeInfo[treeId];
      const node = treeState?.nodeById?.[nodeId];
      const options: ModalOptions = {
        title: { id: "MSG_CONFIRM_ACTION" },
        body: {
          id: "NAVTREE_DOWNLOAD_ZIP_CONFIRM",
          values: { node: node?.label },
        },
        type: "checkbox",
        options: [{ value: "lock", label: { id: "NAVTREE_IGNORE_LOCK" } }],
      };
      const loginStatus = s.security.loginStatus;
      let isSuperUser = isLoginStatus(loginStatus) && loginStatus.superUser;
      dispatch.modal.openModal({
        id: "tree.confirmDownloadZip",
        type: isSuperUser ? "quiz" : "confirm",
        options,
        okCallback: async (result) => {
          const lockIgnore = isSuperUser && result !== null;
          downloadFile(
            composeZipUrl(treeId, nodeId, lockIgnore),
            `${node?.label}.zip`
          );
        },
      });
    },
    notifyTreeNodeUpdate(nodeId: string, state) {
      const treeState = state.tree;
      for (let treeId in treeState.treeInfo) {
        if (!treeState.treeInfo[treeId].nodeById?.[nodeId]) {
          continue;
        }
        // dispatch.tree.
        dispatch.tree.sendTreeNodesForceUpdate({
          path: treeId,
          nodesIdList: [nodeId],
        });
      }
    },
    notifyTreeSubjectUpdate(subjectKey: string, s) {
      const treeState = s.tree;
      for (let treeId in treeState.treeInfo) {
        const nodeId = treeState.treeInfo[treeId].nodeIdByRdfId?.[subjectKey];
        if (!nodeId) {
          continue;
        }
        const node = treeState.treeInfo[treeId].nodeById?.[nodeId];
        if (!node || node.syntetic) {
          continue;
        }
        dispatch.tree.sendTreeNodesForceUpdate({
          path: treeId,
          nodesIdList: [node.parentId],
        });
      }
    },
  }),
});
