import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";

import {
  Column,
  isToolbarCopyRefs,
  isToolbarField,
  isToolbarGroup,
  TableParameter,
  TableGantOptions,
  TableGantData,
  ToolbarCopyRefs,
  ToolbarField,
  ToolbarGroup,
  ToolbarItem,
  ToolbarItemFormat,
  ToolbarReport,
  TableSelectOption,
  TableStringSelectOption,
  TableFormattedSelectOption,
  isTableFormattedOption,
  GantPermissions,
  GantViewType,
  GantPlannedGroups,
  GantGroup,
  TableFilterChanges,
} from "../../types/table";
import {
  VIEW_MODE_LIST,
  GANT_READ,
  GANT_FULL,
  GANT_PLANNED_DATE,
} from "../../constants/table";

import DebounceInput from "../debounce/debounceinput";
import DateTimePicker from "../datetimepicker/DateTimePicker";
import FinderSearchString from "../finder/FinderSearchString";
import FinderChangesConfirm from "../finder/FinderChangesConfirm";

import styles from "./Table.module.css";
import { obtainData } from "../../services/subject";
import { RootState } from "../../store";

/**Properties of Table toolbar */
interface TableToolbarProps {
  dynamicColumnChanges?: any;
  /**Id of table*/
  tableId: string;
  /**Display indicator that table data is loading*/
  loadingData: boolean;
  /**Info about table parameters*/
  parameters: { [k: string]: TableParameter };
  /**Values of table parameters*/
  fields: { [k: string]: string };
  /**Values of changed table parameters*/
  filterChanges: TableFilterChanges | null;
  /**Flag if finder of table was changed*/
  finderChanged: boolean;
  /**Options of table gant diagram*/
  gantOptions: TableGantOptions | null;
  /**Data of table gant diagram*/
  gantData: TableGantData;
  /**Report items of table*/
  reports: ToolbarReport[];
  /**Array of table toolbar items*/
  toolbar: ToolbarItem[];
  /**Array of table columns*/
  columns: Column[];
  /** List of user rights */
  userAcl: string[];
  /** Number of rows in table */
  totalRowsLength: number;
  /** Toolbar visibility flag */
  hidden?: boolean;
  /** Toolbar items visibility flag */
  hiddenItems?: { [k: string]: boolean };
  /**Action to trigger report downloading*/
  downloadReport: (report: ToolbarReport) => void;
  /**Action to call automation for clicked button */
  buttonClick: (id: string) => void;
  /**Action to change field value */
  changeField: (parameter: string, value: string) => void;
  /**Action to change view type of gant diagram */
  changeViewType: (type: GantViewType) => void;
  /**Action to change id of adding gant element group */
  changeGroup: (group: string) => void;
  /**Action to confirm table filter changings */
  confirmFilterChanges: () => void;
  /**Action to deny table filter changings */
  denyFilterChanges: () => void;
  /**Action to save table changings */
  saveTable: () => void;
}

/********************************
 *    Table Toolbar Component   *
 ********************************/
class TableToolbar extends React.PureComponent<TableToolbarProps> {
  toggleColsChagned = (value: boolean) => {
    this.setState((state) => ({ dynamicColsChanged: value }));
  };

  render() {
    if (this.props.hidden) {
      return null;
    }
    const fieldsChanges = this.props.filterChanges?.fields || null;
    const notConfirmed = Boolean(
      this.props.filterChanges ||
        this.props.finderChanged ||
        this.props.dynamicColumnChanges
    );
    let className = styles.nptTableToolbar;
    if (notConfirmed) {
      className += ` ${styles.nptNotConfirmed}`;
    }
    return (
      <div className={className}>
        <div className={`w-100 form-inline ${styles.nptTableToolbarButtons}`}>
          {this.props.toolbar?.map((item, idx) => (
            <AclChecker key={idx} item={item} userAcl={this.props.userAcl}>
              <Item
                loadingData={this.props.loadingData}
                parameters={this.props.parameters}
                gantOptions={this.props.gantOptions}
                gantData={this.props.gantData}
                fields={this.props.fields}
                reports={this.props.reports}
                columns={this.props.columns}
                userAcl={this.props.userAcl}
                item={item}
                confirmChanges={fieldsChanges}
                totalRowsLength={this.props.totalRowsLength}
                hiddenItems={this.props.hiddenItems}
                buttonClick={this.props.buttonClick}
                onChange={this.props.changeField}
                downloadReport={this.props.downloadReport}
                changeViewType={this.props.changeViewType}
                changeGroup={this.props.changeGroup}
                saveTable={this.props.saveTable}
              />
            </AclChecker>
          ))}
        </div>
        <FinderSearchString
          finderId={this.props.tableId}
          confirmChanges={this.props.confirmFilterChanges}
        />
        <FinderChangesConfirm
          finderId={this.props.tableId}
          visible={notConfirmed}
          confirm={this.props.confirmFilterChanges}
          deny={this.props.denyFilterChanges}
        />
      </div>
    );
  }
}

/** Single item of toolbar */
interface AclCheckerProps {
  item: ToolbarItem;
  userAcl: string[];
}
const AclChecker: React.FunctionComponent<AclCheckerProps> = React.memo(
  (props) => {
    const checkAcl = () => {
      if (!props.item.acl) {
        return true;
      }
      let displayed = false;
      const itemAcl = props.item.acl.split(",").map((acl) => acl.trim());
      for (let acl of itemAcl) {
        if (props.userAcl.indexOf(acl) !== -1) {
          displayed = true;
          break;
        }
      }
      return displayed;
    };
    const [visible, setVisible] = React.useState(checkAcl());
    React.useEffect(() => {
      setVisible(checkAcl());
    }, [props.item.acl, props.userAcl]);
    if (!visible) {
      return null;
    }
    return <>{props.children}</>;
  }
);

/** Single item of toolbar */
interface ItemProps {
  loadingData: boolean;
  parameters: { [k: string]: TableParameter };
  fields: { [k: string]: string };
  gantOptions: TableGantOptions | null;
  gantData: TableGantData;
  item: ToolbarItem;
  confirmChanges: { [k: string]: string } | null;
  reports: ToolbarReport[];
  columns: Column[];
  userAcl: string[];
  totalRowsLength: number;
  hiddenItems?: { [k: string]: boolean };
  buttonClick: (id: string) => void;
  onChange: (parameter: string, value: string) => void;
  downloadReport: (report: ToolbarReport) => void;
  changeViewType: (type: GantViewType) => void;
  changeGroup: (group: string) => void;
  saveTable: () => void;
}
const Item: React.FunctionComponent<ItemProps> = React.memo(
  (props: ItemProps) => {
    const checkAcl = () => {
      if (!props.item.acl) {
        return true;
      }
      let displayed = false;
      const itemAcl = props.item.acl.split(",").map((acl) => acl.trim());
      for (let acl of itemAcl) {
        if (props.userAcl.indexOf(acl) !== -1) {
          displayed = true;
          break;
        }
      }
      return displayed;
    };
    const [visible, setVisible] = React.useState(checkAcl());
    React.useEffect(() => {
      let newVisible =
        !props.item.hidden && !props.hiddenItems?.[props.item.id];

      if (!newVisible) {
        if (visible) {
          setVisible(false);
        }
        return;
      }
      newVisible = checkAcl();
      if (newVisible !== visible) {
        setVisible(newVisible);
      }
    }, [
      props.item.acl,
      props.userAcl,
      props.item.hidden,
      props.hiddenItems?.[props.item.id],
    ]);
    if (!visible) {
      return null;
    }

    if (isToolbarGroup(props.item)) {
      return (
        <Group
          disabled={props.loadingData}
          group={props.item}
          userAcl={props.userAcl}
          hiddenItems={props.hiddenItems}
          buttonClick={props.buttonClick}
        />
      );
    }
    if (isToolbarField(props.item)) {
      return (
        <Field
          loadingData={props.loadingData}
          parameter={props.parameters[props.item.path]}
          fields={props.fields}
          columns={props.columns}
          item={props.item}
          confirmChanges={props.confirmChanges}
          totalRowsLength={props.totalRowsLength}
          onChange={props.onChange}
        />
      );
    }
    if (isToolbarCopyRefs(props.item)) {
      return <CopyRefs item={props.item as ToolbarCopyRefs} />;
    }
    switch (props.item.type) {
      case "report":
        return (
          <Report
            disabled={props.loadingData}
            variant={props.item.variant}
            label={props.item.label}
            icon={props.item.icon}
            reports={props.reports}
            downloadReport={props.downloadReport}
          />
        );
      case "gant":
        return (
          <Gant
            disabled={props.loadingData}
            variant={props.item.variant}
            width={props.item.width}
            icon={props.item.icon}
            label={props.item.label}
            gantOptions={props.gantOptions}
            gantData={props.gantData}
            format={props.item.format}
            changeViewType={props.changeViewType}
            changeGroup={props.changeGroup}
          />
        );
    }
    if (props.item.format === "save") {
      return (
        <SaveButton
          disabled={props.loadingData}
          variant={props.item.variant}
          icon={props.item.icon}
          label={props.item.label}
          save={props.saveTable}
        />
      );
    }
    const buttonClick = function () {
      props.buttonClick(props.item.id);
    };
    return (
      <Button
        disabled={props.loadingData}
        variant={props.item.variant}
        id={props.item.id}
        icon={props.item.icon}
        label={props.item.label}
        formattedId={props.item.formattedId}
        onClick={buttonClick}
      />
    );
  }
);

/** Group of toolbar items (currently can be only dropdown) */
interface GroupProps {
  disabled: boolean;
  group: ToolbarGroup;
  userAcl: string[];
  hiddenItems?: { [k: string]: boolean };
  buttonClick: (id: string) => void;
}
const Group: React.FunctionComponent<GroupProps> = React.memo(
  (props: GroupProps) => {
    if (!props.group.items) {
      return null;
    }
    const items = props.group.items.filter(
      (item) => !item.hidden && !props.hiddenItems?.[item.id]
    );
    if (items.length === 0) {
      return null;
    }
    return (
      <DropdownGroup
        disabled={props.disabled}
        group={props.group}
        items={items}
        userAcl={props.userAcl}
        buttonClick={props.buttonClick}
      />
    );
  }
);

/** Dropdown group of toolbar items */
interface DropdownGroupProps {
  disabled: boolean;
  group: ToolbarGroup;
  items: ToolbarItem[];
  userAcl: string[];
  buttonClick: (id: string) => void;
}
const DropdownGroup: React.FunctionComponent<DropdownGroupProps> = React.memo(
  (props: DropdownGroupProps) => {
    return (
      <Dropdown className={styles.nptToolbarItem}>
        <Dropdown.Toggle
          className={`${styles.nptToolbarButton} dropdown-toggle ${
            props.disabled ? " disabled" : ""
          }`}
          variant={props.group.variant || "secondary"}
          id="dropdown-group"
        >
          <Icon icon={props.group.icon} />
          {props.group.label}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {props.items.map((dropdownItem) => (
            <AclChecker
              key={dropdownItem.id}
              item={dropdownItem}
              userAcl={props.userAcl}
            >
              <Dropdown.Item
                className={`dropdown-item ${styles.nptToolbarDropdownItem}`}
                onClick={props.buttonClick.bind(null, dropdownItem.id)}
              >
                {dropdownItem.label}
              </Dropdown.Item>
            </AclChecker>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);

/** Toolbar field item */
interface FieldProps {
  loadingData: boolean;
  parameter: TableParameter;
  fields: { [k: string]: string };
  columns: Column[];
  confirmChanges: { [k: string]: string } | null;
  item: ToolbarField;
  totalRowsLength: number;
  onChange: (parameter: string, value: string) => void;
}
const Field: React.FunctionComponent<FieldProps> = React.memo(
  (props: FieldProps) => {
    const itemPath = props.item.path || "";
    if (itemPath === "_totalRowsLength") {
      return (
        <FieldInput
          editable={false}
          parameter={""}
          value={props.totalRowsLength.toString()}
          label={props.item.label}
          icon={props.item.icon}
          width={props.item.width}
        />
      );
    }
    const param = props.parameter;
    const paramName = (param && param.name) || "";
    const editable = param && !props.loadingData;

    let value =
      typeof props.confirmChanges?.[itemPath] != "undefined"
        ? props.confirmChanges[itemPath]
        : obtainData(itemPath.split("."), props.fields);
    if (typeof value == "undefined" || value == null) {
      value = "";
    }
    if (props.item.format === "selectColumn") {
      return (
        <SelectColumn
          key={value}
          columns={props.columns}
          editable={editable}
          parameter={paramName}
          value={value}
          label={props.item.label}
          icon={props.item.icon}
          width={props.item.width}
          variant={props.item.variant}
          onChange={props.onChange}
        />
      );
    }
    if (props.item.format === "boolean") {
      return (
        <Checkbox
          key={value}
          editable={editable}
          parameter={paramName}
          value={value}
          label={props.item.label}
          icon={props.item.icon}
          onChange={props.onChange}
        />
      );
    }
    if (props.item.format === "date") {
      return (
        <DateInput
          key={value}
          editable={editable}
          parameter={paramName}
          value={value}
          label={props.item.label}
          icon={props.item.icon}
          width={props.item.width}
          onChange={props.onChange}
        />
      );
    }
    if (props.item.format === "dateTime") {
      return <DateTimeInput />;
    }
    return (
      <FieldInput
        key={value}
        editable={editable}
        parameter={paramName}
        value={value}
        label={props.item.label}
        icon={props.item.icon}
        width={props.item.width}
        onChange={props.onChange}
      />
    );
  }
);

/** Dropdown select from pre-defined items */
interface SelectColumnProps {
  columns: Column[];
  editable: boolean;
  parameter: string;
  value: string;
  label: string;
  icon?: string;
  width?: number;
  variant?: string;
  onChange: (parameter: string, value: string) => void;
}
const SelectColumn: React.FunctionComponent<SelectColumnProps> = React.memo(
  (props: SelectColumnProps) => {
    const [options, setOptions] = React.useState<TableStringSelectOption[]>([]);

    React.useEffect(() => {
      const options: TableStringSelectOption[] = [];
      for (let column of props.columns) {
        if (column.hidden || column.autoGenerated) {
          continue;
        }
        options.push({
          value: column.field,
          msg: column.name,
        });
      }
      setOptions(options);
    }, [props.columns]);

    return (
      <FieldSelect
        disabled={!props.editable}
        variant={props.variant}
        label={props.label}
        icon={props.icon}
        options={options}
        value={props.value}
        width={props.width}
        onChange={props.onChange.bind(this, props.parameter)}
      />
    );
  }
);

/** Checkbox input for Boolean fields */
interface CheckboxProps {
  editable: boolean;
  parameter: string;
  label: string;
  icon?: string;
  value: string | boolean;
  onChange: (parameter: string, value: string) => void;
}
const Checkbox: React.FunctionComponent<CheckboxProps> = React.memo(
  (props: CheckboxProps) => {
    const booleanValue =
      typeof props.value === "string"
        ? props.value === "true"
        : Boolean(props.value);
    return (
      <div className={styles.nptToolbarItem}>
        <div className="input-group">
          <span
            style={{ padding: "2px" }}
            className="input-group-prepend input-group-text"
          >
            <Icon icon={props.icon} />
            <strong>{props.label}</strong>
          </span>
          <div
            className={`${styles.nptToolbarItemCheckbox} form-control ${
              props.editable ? "" : styles.disabled
            }`}
          >
            <input
              type="checkbox"
              checked={booleanValue}
              disabled={!props.editable}
              onChange={props.onChange.bind(
                this,
                props.parameter,
                booleanValue ? "false" : "true"
              )}
            />
          </div>
        </div>
      </div>
    );
  }
);

/** Input for date fields with formatter */
interface DateInputProps {
  editable: boolean;
  parameter: string;
  value: string;
  label: string;
  icon?: string;
  width?: number;
  onChange: (parameter: string, value: string) => void;
}
const DateInput: React.FunctionComponent<DateInputProps> = React.memo(
  (props: DateInputProps) => {
    const onChange = (data: Date | null) => {
      if (data) {
        const value = moment(data, "L").format("YYYY-MM-DD");
        props.onChange(props.parameter, value);
      }
    };
    return (
      <div className={styles.nptToolbarItem}>
        <DateTimePicker
          date={props.value}
          disabled={!props.editable}
          getCurrentDate={onChange}
          inputSize="sm"
          style={{ padding: 2, width: props.width || 40 }}
        >
          <span
            style={{ padding: "2px" }}
            className="input-group-prepend input-group-text"
          >
            <Icon icon={props.icon} />
            <strong>{props.label}</strong>
          </span>
        </DateTimePicker>
      </div>
    );
  }
);

/** Input for dateTime fields with formatter */
interface DateTimeInputProps {}
const DateTimeInput: React.FunctionComponent<DateTimeInputProps> = React.memo(
  (props: DateTimeInputProps) => {
    return null;
  }
);

/** Standart input for all fields */
interface FieldInputProps {
  editable: boolean;
  parameter: string;
  value: string;
  label: string;
  locale?: string;
  format?: string;
  icon?: string;
  width?: number;
  onChange?: (parameter: string, value: string) => void;
}
const FieldInput: React.FunctionComponent<FieldInputProps> = React.memo(
  (props: FieldInputProps) => {
    const editable = Boolean(props.editable && props.onChange);
    const onFinish = (value: string) => {
      if (!props.onChange) {
        return;
      }
      props.onChange(props.parameter, value);
    };
    return (
      <div className={styles.nptToolbarItem}>
        <DebounceInput
          format={props.format}
          editable={editable}
          style={{ padding: 2, width: props.width || 40 }}
          className={`${styles.nptToolbarField} form-control`}
          onFinish={onFinish}
          value={props.value}
        >
          <span
            style={{ padding: "2px" }}
            className="input-group-prepend input-group-text"
          >
            <Icon icon={props.icon} />
            <strong>{props.label}</strong>
          </span>
        </DebounceInput>
      </div>
    );
  }
);

/** Toolbar copyRefs item */
interface CopyRefsProps {
  item: ToolbarCopyRefs;
}
const CopyRefs: React.FunctionComponent<CopyRefsProps> = React.memo(
  (props: CopyRefsProps) => {
    /* TODO */
    return null;
  }
);

/** Toolbar save item */
interface SaveButtonProps {
  disabled: boolean;
  variant?: string;
  icon?: string;
  label?: string;
  formattedId?: string;
  save: () => void;
}
const SaveButton: React.FunctionComponent<SaveButtonProps> = React.memo(
  (props) => {
    return (
      <Button
        id={"tableSave"}
        disabled={props.disabled}
        variant={props.variant}
        icon={props.icon}
        label={props.label}
        formattedId={props.formattedId}
        onClick={props.save}
      />
    );
  }
);

/** Toolbar report item */
interface ReportProps {
  disabled: boolean;
  variant?: string;
  label: string;
  reports: ToolbarReport[];
  icon?: string;
  downloadReport: (report: ToolbarReport) => void;
}
const Report: React.FunctionComponent<ReportProps> = React.memo(
  (props: ReportProps) => {
    if (!props.reports) {
      return null;
    }
    //By default use buttons
    return (
      <Dropdown className={styles.nptToolbarItem}>
        <Dropdown.Toggle
          className={`${styles.nptToolbarButton} dropdown-toggle ${
            props.disabled ? " disabled" : ""
          }`}
          variant={props.variant || "secondary"}
          id="dropdown-group"
        >
          <Icon icon={props.icon} />
          {props.label}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {props.reports.map((report) => (
            <Dropdown.Item
              key={report.name}
              className={`dropdown-item ${styles.nptToolbarDropdownItem}`}
              onClick={() =>
                props.disabled ? null : props.downloadReport(report)
              }
            >
              {report.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);

/** Toolbar gant item */
interface GantProps {
  disabled: boolean;
  width?: number;
  variant?: string;
  icon?: string;
  label: string;
  formattedId?: string;
  gantOptions: TableGantOptions | null;
  gantData: TableGantData;
  format?: ToolbarItemFormat;
  changeViewType: (type: GantViewType) => void;
  changeGroup: (group: string) => void;
}
const Gant: React.FunctionComponent<GantProps> = React.memo(
  (props: GantProps) => {
    if (!props.gantOptions) {
      return null;
    }
    if (props.format === "view") {
      return (
        <GantView
          width={props.width}
          variant={props.variant}
          disabled={props.disabled}
          icon={props.icon}
          label={props.label}
          permissions={props.gantOptions.permissions}
          viewType={props.gantData.viewType}
          changeViewType={props.changeViewType}
        />
      );
    }
    if (props.format === "group") {
      return (
        <GantGroupSelect
          width={props.width}
          variant={props.variant}
          disabled={props.disabled}
          icon={props.icon}
          label={props.label}
          permissions={props.gantOptions.permissions}
          viewType={props.gantData.viewType}
          chosenGroups={props.gantOptions.gantGroups}
          plannedGroups={props.gantOptions.gantPlannedGroups}
          selectedGroup={props.gantData.selectedGroup}
          changeGroup={props.changeGroup}
        />
      );
    }
    return null;
  }
);

interface GantViewProps {
  disabled: boolean;
  width?: number;
  variant?: string;
  icon?: string;
  label: string;
  permissions: GantPermissions;
  viewType: GantViewType;
  changeViewType: (type: GantViewType) => void;
}
const GantView: React.FunctionComponent<GantViewProps> = React.memo(
  (props: GantViewProps) => {
    let options: TableFormattedSelectOption[];
    if (props.permissions.value != GANT_READ && props.permissions.chosenOnly) {
      options = VIEW_MODE_LIST.chosenOnly[props.permissions.value];
    } else {
      options = VIEW_MODE_LIST[props.permissions.value];
    }
    return (
      <FieldSelect
        disabled={props.disabled}
        variant={props.variant}
        label={props.label}
        icon={props.icon}
        options={options}
        value={props.viewType}
        width={props.width}
        onChange={(value: string) =>
          props.changeViewType(value as GantViewType)
        }
      />
    );
  }
);

interface GantGroupSelectProps {
  disabled: boolean;
  width?: number;
  variant?: string;
  icon?: string;
  label: string;
  permissions: GantPermissions;
  viewType: GantViewType;
  chosenGroups: GantGroup[];
  plannedGroups: GantPlannedGroups;
  selectedGroup: string;
  changeGroup: (group: string) => void;
}
const GantGroupSelect: React.FunctionComponent<GantGroupSelectProps> =
  React.memo((props: GantGroupSelectProps) => {
    const intl = useIntl();
    const [options, setOptions] = React.useState<TableStringSelectOption[]>([]);
    React.useEffect(() => {
      if (
        props.permissions.value !== GANT_FULL ||
        props.viewType === GANT_READ
      ) {
        return;
      }
      let options: TableStringSelectOption[];
      if (props.viewType === GANT_PLANNED_DATE) {
        options = [
          {
            value: props.plannedGroups.plannedDocs.id,
            msg: props.plannedGroups.plannedDocs.name
              ? props.plannedGroups.plannedDocs.name
              : intl.formatMessage({ id: "NPT_GANT_PLANNED_DOCS" }),
          },
          {
            value: props.plannedGroups.plannedWork.id,
            msg: props.plannedGroups.plannedWork.name
              ? props.plannedGroups.plannedWork.name
              : intl.formatMessage({ id: "NPT_GANT_PLANNED_WORK" }),
          },
        ];
      } else {
        options = [];
        for (let group of props.chosenGroups) {
          options.push({
            value: group.id,
            msg: group.name,
          });
        }
      }
      setOptions(options);
    }, [props.viewType, props.chosenGroups, props.plannedGroups]);

    if (props.permissions.value !== GANT_FULL || props.viewType === GANT_READ) {
      return null;
    }
    return (
      <FieldSelect
        disabled={props.disabled}
        variant={props.variant}
        label={props.label}
        icon={props.icon}
        options={options}
        value={props.selectedGroup}
        width={props.width}
        onChange={props.changeGroup}
      />
    );
  });

/** Toolbar select for fields */
interface FieldSelectProps {
  disabled: boolean;
  label: string;
  variant?: string;
  icon?: string;
  options: TableSelectOption[];
  value: string;
  width?: number;
  onChange: (value: string) => void;
}
const FieldSelect: React.FunctionComponent<FieldSelectProps> = React.memo(
  (props: FieldSelectProps) => {
    const intl = useIntl();
    const [selectedOption, setSelectedOption] =
      React.useState<TableSelectOption>({ msg: "--", value: "" });
    React.useEffect(() => {
      let nextOption: TableSelectOption = { msg: "--", value: "" };
      for (let option of props.options) {
        if (option.value === props.value) {
          nextOption = option;
          break;
        }
      }
      if (nextOption.value === selectedOption.value) {
        return;
      }
      setSelectedOption(nextOption);
    }, [props.options, props.value]);

    const inputValue = isTableFormattedOption(selectedOption)
      ? intl.formatMessage({ id: selectedOption.formattedMsg })
      : selectedOption.msg;
    return (
      <div className={styles.nptToolbarItem}>
        <div className="input-group input-group-sm input-group-prepend">
          <div className="input-group-btn">
            <Dropdown>
              <Dropdown.Toggle
                className={`${styles.nptToolbarButton} dropdown-toggle ${
                  props.disabled ? " disabled" : ""
                }`}
                variant={props.variant || "secondary"}
                id="dropdown-group"
              >
                <Icon icon={props.icon} />
                {props.label}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {props.options.map((option, i) => {
                  return (
                    <Dropdown.Item
                      key={i}
                      className="dropdown-item"
                      onClick={props.onChange.bind(this, option.value)}
                    >
                      {isTableFormattedOption(option) ? (
                        <FormattedMessage id={option.formattedMsg} />
                      ) : (
                        option.msg
                      )}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <input
            type="text"
            className="form-control"
            disabled={true}
            value={inputValue}
            style={{ padding: 2, width: props.width || 40 }}
          />
        </div>
      </div>
    );
  }
);

/** Toolbar button item */
interface ButtonProps {
  id: string;
  variant?: string;
  icon?: string;
  label?: string;
  formattedId?: string;
  disabled?: boolean;
  onClick: () => void;
}
const Button: React.FunctionComponent<ButtonProps> = React.memo(
  (props: ButtonProps) => {
    return (
      <button
        type="button"
        className={`${styles.nptToolbarButton} ${
          styles.nptToolbarItem
        } btn btn-${props.variant || "secondary"}`}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <Icon icon={props.icon} />
        <Label formattedId={props.formattedId} label={props.label} />
      </button>
    );
  }
);

/** Icon element */
interface IconProps {
  icon?: string;
}
const Icon: React.FunctionComponent<IconProps> = React.memo(
  (props: IconProps) => {
    if (!props.icon) {
      return null;
    }
    let className = "fa fa-fw " + props.icon;
    return <i className={className} aria-hidden="true"></i>;
  }
);

/** Label element */
interface LabelProps {
  label?: string;
  formattedId?: string;
}
const Label: React.FunctionComponent<LabelProps> = React.memo(
  (props: LabelProps) => {
    if (props.formattedId) {
      return <FormattedMessage id={props.formattedId} />;
    }
    if (props.label) {
      return <span>{props.label}</span>;
    }
    return null;
  }
);

export default connect((state: RootState, ownProps: { tableId: string }) => {
  const finderState = state.finder[ownProps.tableId];
  return {
    finderChanged: Boolean(finderState?.changes),
    dynamicColumnChanges:
      state.table[ownProps.tableId].dynamicColumnChanges || undefined,
  };
})(TableToolbar);
