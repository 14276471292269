import React from "react";
import ReactDOM from "react-dom";
import * as Redux from "redux";
import * as ReactRouterDOM from "react-router-dom";
import * as ReactRedux from "react-redux";
import * as ReactDND from "react-dnd";
import * as ReactIntl from "react-intl";
import * as ReactContextMenu from "react-contextmenu";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "font-awesome/css/font-awesome.min.css";
import "./css/npt-icons.css";
import "react-perfect-scrollbar/dist/css/styles.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import moment from "moment";
import "moment/locale/ru";

//Redux

import { Provider } from "react-redux";

//Store state

//Locales
import ruMessages from "./translations/locales/ru.json";
import defaultMessages from "./translations/locales/defaultMessages.json";

//Public components
import components from "./PublicComponents";
import {
  buildParamsString,
  getContextPath,
  getObjectFromUrl,
  getSearchData,
  isObjectcardView,
} from "./services/location";
import { isLoginPageInfo } from "./types/security";
import { fillDomainMap } from "./services/security";
import { Module, ModulesRegistry } from "./types";
import { SelectionState } from "./types/selection";
import { DEFAULT_VERSION_STATE } from "./types/version";
import { createUtils } from "./PublicUtils";
import { RootState, store } from "./store";
import { InitialData, SearchData } from "./types/location";

/**
 * Global instance of module registry
 */
const modulesRegistry: ModulesRegistry = {
  modules: {},
  resolvers: {},
  rejectors: {},
};

/**
 * Function which implements dynamic import of modules (views, cards, etc.)
 *
 * @param path to module
 */
function _import(path: string): Promise<Module> {
  //Check if we have already requested this module
  if (typeof modulesRegistry.modules[path] != "undefined") {
    return modulesRegistry.modules[path];
  }
  //Create new promise
  const promise = new Promise<Module>(async function (resolve, reject) {
    modulesRegistry.resolvers[path] = resolve;
    modulesRegistry.rejectors[path] = reject;

    //Fetch module
    try {
      const response = await fetch(path);
      if (!response.ok) {
        console.log("Module fetch error:", response.statusText);
        reject({
          code: response.status,
          message: ` ${response.status} (${path})`,
        });
        return;
      }
      const moduleScript = await response.text();

      //Append script
      const script = document.createElement("script");
      script.innerHTML = moduleScript;
      document.body.appendChild(script);
    } catch (e) {
      console.log("Module parse error:", e);
      reject(`Fail to parse module ${path}`);
    }
  });
  //Add to registry
  modulesRegistry.modules[path] = promise;
  return promise;
}

function _resolve(path: string | RegExp, module: Module) {
  //Path is string
  if (typeof path == "string") {
    const r = modulesRegistry.resolvers[path];
    if (!r) {
      //Module was already resolved or was not requested
      return;
    }
    //Resolve module
    r(module);
    return;
  }

  //Path is regular expresion!
  const regexp = path;
  for (let p in modulesRegistry.resolvers) {
    if (regexp.test(p)) {
      console.log(`Resolved path from regular expression: ${p}`);
      const r = modulesRegistry.resolvers[p];
      r(module);
      break;
    }
  }
}

/**
 * Function which resets imported modules
 */
function _reset() {
  modulesRegistry.modules = {};
  modulesRegistry.resolvers = {};
  modulesRegistry.rejectors = {};
}

//Initialize NPT globals
(window as any).__NPT__ = {
  React,
  ReactDOM,
  Redux,
  ReactRedux,
  ReactRouterDOM,
  ReactDND,
  ReactIntl,
  ReactContextMenu,
  //Import module (fetch from server)
  import: _import,
  //Resolve module (used in module javascript file)
  resolve: _resolve,
  //Reset modules (used on user change)
  reset: _reset,
  //Add store so that plugins could install models there
  store,
  //Public NPT Platform components
  components,
};

export function getNavigatorLanguage() {
  const lang = navigator.language;
  const idx = lang.indexOf("-");
  if (idx > 0) {
    return lang.substring(0, idx);
  }
  return lang;
}

const selectionState: SelectionState = {
  info: {},
};

let searchData = getSearchData();
if (isObjectcardView()) {
  const objectRdfId = getObjectFromUrl();
  if (objectRdfId) {
    searchData["object"] = objectRdfId;
  }
}

try {
  //Restore selection
  const href = window.location.href;
  const reloadHref = sessionStorage.getItem("reload-href");
  if (reloadHref && href == reloadHref) {
    const selectionRaw = sessionStorage.getItem("reload-selection");
    if (selectionRaw) {
      const selection = JSON.parse(selectionRaw);
      searchData = { ...searchData, ...selection };
    }
  }
  sessionStorage.removeItem("reload-href");
  sessionStorage.removeItem("reload-selection");
} catch (ex) {
  console.error("Failed to restore", ex);
}

if (searchData) {
  let selection = null;
  if (searchData["object"]) {
    selection = { object: searchData["object"], type: searchData["type"] };
  }
  if (selection) {
    store.dispatch.selection.sendSelection(selection);
  }

  store.dispatch.location.sendSearchParameters({
    params: searchData,
    reset: false,
  });
}

moment.locale(getNavigatorLanguage());
const w = window as any;

if (typeof w.__login_status__ == "object" && w.__login_status__ != null) {
  const security = store.getState().security;
  const status = (security.loginStatus = w.__login_status__);
  if (isLoginPageInfo(status)) {
    fillDomainMap(status);
  }
}

//Restore selection into URL
(window as any).onbeforeunload = (e: any): any => {
  const info = store.getState().selection.info;
  const params = store.getState().location.params;
  let newSearchData: SearchData = {};
  if (params) {
    newSearchData = { ...newSearchData, ...params };
  }
  if (info) {
    const { object, type } = info;
    if (object) {
      newSearchData["object"] = object;
    }
    if (type) {
      newSearchData["type"] = type;
    }
  }
  sessionStorage.setItem("reload-href", window.location.href);
  sessionStorage.setItem("reload-selection", JSON.stringify(newSearchData));
};

//Create storage for named modals
(window as any).__NPT__.namedModals = {} as any;

//Create storage for named tooltips
(window as any).__NPT__.namedTooltips = {} as any;

//Export public utils
(window as any).__NPT__.utils = createUtils(store);

export type Translations = { [id: string]: string };

const enMessages: Translations = {};
for (let file of defaultMessages) {
  for (let descr of file.descriptors) {
    enMessages[descr.id] = descr.defaultMessage;
  }
}

export const messages: { [locale: string]: Translations } = {
  ru: ruMessages,
  en: enMessages,
};

const ConnectedIntlProvider = ReactRedux.connect((state: RootState) => {
  return {
    messages: messages[state.locale.language],
    locale: state.locale.language,
  };
})(ReactIntl.IntlProvider);

//Wrapper components used to provide redux store and i18n
const UIProvider = ({ children }: { children: any }) => {
  return (
    <Provider store={store}>
      <ConnectedIntlProvider>{children}</ConnectedIntlProvider>
    </Provider>
  );
};

//Export UIProvider so it could be reused in plugins
//to embed connected CimUI components
(window as any).__NPT__.UIProvider = UIProvider;

//Render component
ReactDOM.render(
  <UIProvider>
    <App />
  </UIProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
